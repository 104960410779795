import { Component, OnInit, ViewChild } from '@angular/core';
import {
    ErrorHandlerService,
    ModalService, PermissionService,
    UserService
} from 'src/app/_services';
import {User} from 'src/app/_models';
import { Title } from '@angular/platform-browser';
import {MatTable} from '@angular/material/table';
import {QueryParamService} from '../../_filters/query-param.service';
import {AlertService} from '../../_primitive_services';
import {BackendFilterService} from '../../_filters/backend-filter.service';
import {PaginationService} from '../../_services/pagination.service';
import {SortService} from '../../_services/sort.service';
import {MultiSelectionService} from '../../_services/multi-selection.service';
import {BackendParamService} from '../../_filters/backend-param.service';
import {
    FromTrashFilterConfig, SearchFilterConfig, UserGroupFilterConfig
} from '../../_filters/generic-filter/sample-filter-configs';
import {BackendPaginationService} from '../../_services/backend-pagination.service';
import {MultiRequestService} from '../../_services/multi-request.service';

@Component({
    selector: 'app-user',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService, SortService, MultiSelectionService,
        {provide: BackendParamService, useClass: QueryParamService},
        MultiRequestService,
    ]
})
export class UserListComponent implements OnInit {

    users: User[] = [];
    columnsToDisplay = ['id', 'first_name', 'last_name', 'username', 'actions'];

    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(private userService: UserService,
                private titleService: Title,
                private filterService: BackendFilterService,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                public modalService: ModalService,
                public permissionService: PermissionService,
                public queryParamService: BackendParamService,
                ) {
        this.titleService.setTitle('Użytkownicy');
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new SearchFilterConfig(),
            new UserGroupFilterConfig('group', 'Grupy'),
            new FromTrashFilterConfig(),
        ]);
        this.subscribeOnChanges();
    }

    private refreshData(): void {
        this.errorHandler.showLoader(true);
        this.userService.getAll(this.getBackendParamMap()
        ).subscribe((response: User[]) => {
            this.errorHandler.showLoader(false);
            this.users.length = 0;
            this.users.push(...response);
            this.matPositions.renderRows();
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }


}
