<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">Grupa maszyn - szczegóły</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="machineGroup">
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@Edit" mat-button
                        routerLink="/machine-group/{{machineGroup.id}}/edit"
                        *ngIf="permissionService.checkPermission('perm_write_machine_group')">Edytuj
                </button>
                <span class="line" *ngIf="permissionService.checkPermission('perm_write_machine_group')">|</span>
                <button class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/machine-group', machineGroup)"
                        *ngIf="permissionService.checkPermission('perm_write_machine_group')"
                        i18n="@@Remove">Usuń
                </button>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="machineGroup">
            <mat-card-content>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Nazwa</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ machineGroup.name }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Kod</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ machineGroup.codename }}
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Stawka godzinowa</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ moneyService.parseMoney(machineGroup.workingCostPerHour) }}
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Zewnętrzny</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        <mat-icon>
                            {{isChecked('external', machineGroup)}}
                        </mat-icon>
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Aktywne przewodniki</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        <div *ngFor="let guide of machineGroup.activeGuides">
                            <a routerLink="/guide/{{guide.id}}">
                            {{guide.number}}</a>
                            <br>
                        </div>

                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
