import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MoneyService {

    constructor() {
    }

    public static parseMoney(money: number): string {
        let prefix = '';
        if (money < 0){
            money = -money;
            prefix = '-';
        }
        const price = Math.floor(money / 100);
        const rest = Math.floor(money % 100);
        if (money === undefined) {
            return '-';
        }
        if (money % 100 < 10) {
            return prefix + price + ',' + 0 + rest + ' ' + 'zł';
        } else {
            return prefix + price + ',' + rest + ' ' + 'zł';
        }
    }

    public static parseMoneyWithUnits(money: number, units: string): string {
        let prefix = '';
        if (money < 0){
            money = -money;
            prefix = '-';
        }
        const price = Math.floor(money / 100);
        const rest = Math.floor(money % 100);
        if (money === undefined) {
            return '-';
        }
        if (money % 100 < 10) {
            return prefix + price + ',' + 0 + rest + ' ' + units;
        } else {
            return prefix + price + ',' + rest + ' ' + units;
        }
    }
}
