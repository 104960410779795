import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { CheckedPermission, Group, User } from 'src/app/_models';
import {ApiTranslatorService, ErrorHandlerService, GroupService, MachineGroupService, ModalService} from 'src/app/_services';
import { first } from 'rxjs/operators';
import { UserPermissionService } from '../../_services/user-permission.service';
import { Title } from '@angular/platform-browser';
import {MultiSelectionService} from '../../_services/multi-selection.service';
import { AlertService } from '../../_primitive_services';
import { Order } from '../../_models/order';


@Component({
    selector: 'app-user-group-edit',
    templateUrl: './user-group-edit.component.html',
    styleUrls: ['./user-group-edit.component.css'],
    providers: [MultiSelectionService]
})
export class UserGroupEditComponent implements OnInit {

    public title = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private groupService: GroupService,
        private machineGroupService: MachineGroupService,
        private apiTranslator: ApiTranslatorService,
        private formBuilder: FormBuilder,
        private permissionService: UserPermissionService,
        private titleService: Title,
        private alertService: AlertService,
        public modalService: ModalService,
        private errorHandler: ErrorHandlerService,
        public multiSelectionService: MultiSelectionService,
    ) {
    }

    get f(): any {
        return this.editGroupForm.controls;
    }
    userGroup: Group;
    editGroupForm: FormGroup;
    permissions: CheckedPermission[] = [];
    public isEditComponent = true;

    private static createNewGroup(): Group {
        return new Group({
            id: undefined,
            name: '',
            permissions: [],
        });
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        this.isEditComponent = this.activatedRoute.snapshot.paramMap.has('id');
        if (this.isEditComponent){
            this.title = 'Grupy użytkowników - edycja';
        }
        else {
            this.title = 'Grupy użytkowników - tworzenie';
        }
        this.titleService.setTitle(this.title);
        this.permissionService.getAll().subscribe(permissions => {
            this.errorHandler.showLoader(false);
            this.permissions.length = 0;
            this.permissions.push(...permissions.map(el => new CheckedPermission(el, false)));
            this.multiSelectionService.populateWithData(this.permissions);
            if (this.isEditComponent){
                this.getGroupFromBackend();
            }
            else {
                this.userGroup = UserGroupEditComponent.createNewGroup();
                this.buildForm();
            }
        });
    }


    private getGroupFromBackend(): void {
        this.groupService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(data => {
            this.userGroup = data;
            const checkedPermissions = new Set<number>(this.userGroup.permissionIds);
            for (const permission of this.permissions) {
                permission.checked = checkedPermissions.has(permission.id);
            }
            this.buildForm();
        });
    }

    buildForm(): void {
        this.editGroupForm = this.formBuilder.group({
            name: [this.userGroup.name, Validators.required],
        });
    }

    onChangeGroup(event: Event, permission: CheckedPermission): void {
        permission.checked = (event.target as HTMLInputElement).checked;
    }

    onSubmit(): any {

        if (this.editGroupForm.invalid) {
            return;
        }
        this.userGroup.permissions.length = 0;
        this.userGroup.permissionIds.length = 0;
        this.userGroup.permissions.push(...this.permissions.filter(el => el.checked));
        this.userGroup.permissionIds.push(...this.userGroup.permissions.map(el => el.id));
        if (this.isEditComponent){

            this.groupService.updateGroup(this.userGroup).pipe(first()).subscribe(
                data => {
                    this.errorHandler.showLoader(false);
                    this.router.navigate(['group', this.userGroup.id]);
                    this.alertService.success(Group.getModelName() + ': <strong>' + this.userGroup.name + '' +
                        '</strong> została pomyślnie edytowana');
                }, error => {

                    this.alertService.error(Group.getModelName + ' nie została edytowana. Błąd: ', this.errorHandler.error(error));
                    this.errorHandler.showLoader(false);
                    console.log(error);
                }
            );
        }
        else {
            this.groupService.createGroup(this.userGroup).pipe(first()).subscribe(
                userGroup => {
                    this.router.navigate(['/group', userGroup.id]);
                }, error => {
                    this.alertService.error(Group.getModelName() + ' nie została utworzona. Błąd: ', this.errorHandler.error(error));
                    this.errorHandler.showLoader(false);
                }
            );
        }
    }

    changeName(event: Event): void {
        this.userGroup.name = (event.target as HTMLInputElement).value;
    }
}
