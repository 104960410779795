import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiTranslatorService, ErrorHandlerService, GuidesService, ModalService, PermissionService} from 'src/app/_services';
import {Wz} from 'src/app/_models';
import { Title } from '@angular/platform-browser';
import {WzService} from '../../_services/wz.service';
import {DateService} from '../../_primitive_services';
import {PaginationService} from '../../_services/pagination.service';
import {BackendFilterService} from '../../_filters/backend-filter.service';
import {BackendPaginationService} from '../../_services/backend-pagination.service';
import {SortService} from '../../_services/sort.service';
import {MultiSelectionService} from '../../_services/multi-selection.service';
import {BackendParamService} from '../../_filters/backend-param.service';
import {QueryParamService} from '../../_filters/query-param.service';
import {MultiRequestService} from '../../_services/multi-request.service';
import {Sort} from '@angular/material/sort';
import {PaginatedTranslatedResponse} from '../../_interfaces/PaginatedApiResponse';
import {MatTable} from '@angular/material/table';
import {ContractorFilterConfig, FromTrashFilterConfig} from '../../_filters/generic-filter/sample-filter-configs';
import {FilterConfigStringInput} from '../../_filters/components/filter-string-input/filter-string-input.component';
import {FilterConfigDateRange} from '../../_filters/components/filter-date-range/filter-date-range.component';
import {environment} from '../../../environments/environment';


@Component({
    selector: 'app-wz',
    templateUrl: './wz-list.component.html',
    styleUrls: ['./wz-list.component.css'],
    providers: [BackendFilterService, PaginationService, BackendPaginationService, SortService, MultiSelectionService,
        {provide: BackendParamService, useClass: QueryParamService},
        GuidesService, MultiRequestService, WzService]
})
export class WzListComponent implements OnInit {

    wzs: Wz[] = [];
    columnsToDisplay = ['number', 'order', 'contractorName', 'creationDate', 'actions'];
    initialSort: Sort = {active: 'year,lp', direction: 'desc'};

    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(private wzService: WzService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                public modalService: ModalService,
                public dateService: DateService,
                public paginationService: PaginationService,
                public filterService: BackendFilterService,
                public sortService: SortService,
                public queryParamService: BackendParamService,
                public errorHandler: ErrorHandlerService,
                public permissionService: PermissionService,
    ) {
        console.log('Constructor');
        this.titleService.setTitle('Dokumenty WZ');
    }

    ngOnInit(): void {
        console.log('Init');
        this.filterService.setFilterConfigs([
            new ContractorFilterConfig(),
            new FilterConfigStringInput('number', 'Numer WZ'),
            new FilterConfigStringInput('contractor_order_number', 'Numer zlecenia'),
            new FilterConfigStringInput('guide_number', 'Numer przewodnika'),
            new FilterConfigDateRange( 'creation_date', 'Data wystawienia'),
            new FromTrashFilterConfig(),
        ]);
        this.sortService.enableSorting(this.initialSort);
        this.paginationService.enablePagination();
        this.subscribeOnChanges();
    }

    refreshData(): void{
        this.errorHandler.showLoader(true);
        this.wzService.getAll(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<Wz>) => {
            this.errorHandler.showLoader(false);
            this.wzs.length = 0;
            this.wzs.push(...response.results);
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
        });
    }

    private subscribeOnChanges(): void {
        console.log('Subscribed');
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }

    getPDF(id: number): void {
        const urlAdress = `${environment.apiUrl}/wz/${id}/pdf`;
        window.open(urlAdress, '_blank');

    }
}
