import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../_services';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
    showLoadingIndicator = false;

    constructor(
        private router: Router,
        private errorHandler: ErrorHandlerService,
        private cdRef: ChangeDetectorRef,
    ) {

    }

    ngOnInit(): void {
        this.errorHandler.showLoadingIndicator.subscribe(value => {
            this.showLoadingIndicator = value;
            this.cdRef.detectChanges();
        });
    }

}
