import { Component, OnInit } from '@angular/core';
import {MachineGroup} from 'src/app/_models';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ApiTranslatorService,
    ErrorHandlerService,
    GroupService,
    MachineGroupService,
    ModalService
} from 'src/app/_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import {AlertService} from '../../_primitive_services';
import {BackendErrorResponse} from '../../_interfaces/BackendErrorResponse';

@Component({
    selector: 'app-machine-group-edit',
    templateUrl: './machine-group-edit.component.html',
    styleUrls: ['./machine-group-edit.component.css']
})
export class MachineGroupEditComponent implements OnInit {
    machineGroup: MachineGroup;
    machineGroupForm: FormGroup;
    cost = 0;
    formGroup: FormGroup;
    zlFromServer = 0;
    grFromServer = 0;

    isEditComponent: boolean;
    title: string;

    constructor(private activatedRoute: ActivatedRoute,
                private machineGroupService: MachineGroupService,
                private groupService: GroupService,
                private apiTranslator: ApiTranslatorService,
                private formBuilder: FormBuilder,
                public modalService: ModalService,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                private router: Router,
                private titleService: Title) {
        this.titleService.setTitle('Grupa maszyn - edycja');
    }

    get f(): any {
        return this.machineGroupForm.controls;
    }

    ngOnInit(): void {
        this.isEditComponent = this.activatedRoute.snapshot.paramMap.has('id');
        this.setTitle();

        if (this.isEditComponent) {
            this.errorHandler.showLoader(true);
            this.machineGroupService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10))
                .subscribe(machineGroup => {
                this.machineGroup = machineGroup;
                this.errorHandler.showLoader(false);
                this.zlFromServer = Math.floor(this.machineGroup.workingCostPerHour / 100);
                this.grFromServer = Math.floor(this.machineGroup.workingCostPerHour % 100);
                this.buildForm();
            });
        } else {
            this.machineGroup = new MachineGroup({
                id: undefined,
                name: '',
                codename: '',
                workingCostPerHour: 0,
                external: false,
                activeGuides: [],
            });
            this.buildForm();
        }
    }

    private setTitle(): void{
        this.title = this.isEditComponent ? 'Grupa maszyn - edycja' : 'Grupa maszyn - dodawanie';
        this.titleService.setTitle(this.title);
    }

    buildForm(): void {
        this.machineGroupForm = this.formBuilder.group({
            name: [this.machineGroup.name, [Validators.required, Validators.maxLength(255)]],
            codename: [this.machineGroup.codename, [Validators.required, Validators.maxLength(255)]],
            workingCostPerHour: [this.machineGroup.workingCostPerHour],
            external: [this.machineGroup.external],
        });
    }

    onSubmit(): void {
        if (this.machineGroupForm.invalid) {
            return;
        }

        if (this.isEditComponent) {
            this.machineGroupService.updateMachineGroup(this.machineGroup).pipe(first())
                .subscribe(r => this.handleSuccess(r), e => this.handleError(e));
        } else {
            this.machineGroupService.createMachineGroup(this.machineGroup).pipe(first())
                .subscribe(r => this.handleSuccess(r), e => this.handleError(e));
        }
    }

    private handleSuccess(machineGroup: MachineGroup): void {
        this.router.navigate(['/machine-group', machineGroup.id]);
        this.alertService.success('Grupa maszyn: <strong>' + machineGroup.name + '</strong> ' +
            'została pomyślnie utworzona/edytowana', { autoClose: true });
    }

    private handleError(error: BackendErrorResponse): void {
        if (error.error_code === 713  || error.error_code === 705) {
            this.alertService.error('Grupa maszyn o nazwie ' + this.machineGroup.name + ' już istnieje');
        } else {
            this.alertService.error('Błąd: ', this.errorHandler.error(error));
        }
    }


    connectCost(event, from): void {
        const value = parseInt(event.target.value, 10);
        if (from === 'workingCostPerHourZl') {
            this.zlFromServer = value;
        }

        if (from === 'workingCostPerHourGr') {
            this.grFromServer = value;
        }

        this.machineGroup.workingCostPerHour = (this.zlFromServer * 100 + this.grFromServer);
    }

    changeValue(name: string, event): void {
        this.machineGroup[name] = event.target.value;
    }

    changeBooleanValue(name: string, event): void {
        this.machineGroup[name] = event.target.checked;
    }
}


