export interface IContractor {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    orderStatsUniqueColumn: boolean;
}

export class Contractor {
    static modelName = 'Kontrahent';
    static path = 'contractor';

    id: number;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    orderStatsUniqueColumn: boolean;

    constructor(object?: IContractor) {
        if (object) {
            this.id = object.id;
            this.name = object.name;
            this.firstName = object.firstName;
            this.lastName = object.lastName;
            this.email = object.email;
            this.orderStatsUniqueColumn = object.orderStatsUniqueColumn;
        }
    }

    getModelName(): string {
        return Contractor.modelName;
    }

    getPath(): string {
        return Contractor.path;
    }

    getName(): string {
        return this.name;
    }
}
