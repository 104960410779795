import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/_models';
import { ApiTranslatorService, ErrorHandlerService, ModalService, PermissionService, UserService } from 'src/app/_services';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
    user: User;

    constructor(private activatedRoute: ActivatedRoute,
                private userService: UserService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                private errorHandler: ErrorHandlerService,
                public modalService: ModalService,
                public permissionService: PermissionService) {
        this.titleService.setTitle('Użytkownik - szczegóły');
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        this.userService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(user => {
            console.log(user);
            this.user = user;
            // this.user = this.apiTranslator.modelToCamelCase(User, user);
            console.log(this.user);
            this.errorHandler.showLoader(false);
        });
    }

}
