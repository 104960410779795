<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">Zamówienia - terminy</h1>
            <span class="spacer"></span>
        </mat-toolbar>

        <mat-table [dataSource]="deadlineLocalFilterService.getObjects()"
                   *ngIf="deadlineLocalFilterService.getObjects()" class="mat-effects">

            <ng-container matColumnDef="plannedEndDate">
                <mat-header-cell *matHeaderCellDef class="col-sm-1">Data zakończenia</mat-header-cell>
                <mat-cell *matCellDef="let orderDeadline" class="col-sm-1">
                    {{ dateService.dateToStringDate(orderDeadline.plannedEndDate) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="firstGuideNumber">
                <mat-header-cell *matHeaderCellDef class="col-sm-1">Nr przewodnika</mat-header-cell>
                <mat-cell *matCellDef="let orderDeadline" class="col-sm-1">
                    <a href="/guide/{{orderDeadline.firstGuide}}">
                        {{ orderDeadline.firstGuideNumber }}
                    </a>
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="guideCount">
                <mat-header-cell *matHeaderCellDef class="col-sm-1">Ilość przewodników</mat-header-cell>
                <mat-cell *matCellDef="let orderDeadline" class="col-sm-1">
                    {{ orderDeadline.guideCount }}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="firstFeature">
                <mat-header-cell *matHeaderCellDef class="col-sm-2">Cecha</mat-header-cell>
                <mat-cell *matCellDef="let orderDeadline" class="col-sm-2">
                    {{ orderDeadline.firstFeature }}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="contractorName">
                <mat-header-cell *matHeaderCellDef class="col-sm-2">Kontrahent</mat-header-cell>
                <mat-cell *matCellDef="let orderDeadline" class="col-sm-2">
                    {{ orderDeadline.contractorName }}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="contractorOrderNumber">
                <mat-header-cell *matHeaderCellDef class="col-sm-2">Nr zamówienia</mat-header-cell>
                <mat-cell *matCellDef="let orderDeadline" class="col-sm-2">
                    <a href="/order/{{orderDeadline.order}}">
                        {{ orderDeadline.contractorOrderNumber }}
                    </a>
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="cost">
                <mat-header-cell *matHeaderCellDef class="col-sm-2">Cena</mat-header-cell>
                <mat-cell *matCellDef="let orderDeadline" class="col-sm-2">
                    {{ moneyService.parseMoney(orderDeadline.cost) }}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="accumulatedCost">
                <mat-header-cell *matHeaderCellDef class="col-sm-2">Suma</mat-header-cell>
                <mat-cell *matCellDef="let orderDeadline" class="col-sm-2">
                    {{ moneyService.parseMoney(orderDeadline.accumulatedCost) }}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
