import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Group, IGroup} from 'src/app/_models';
import { environment } from 'src/environments/environment';
import { ApiTranslatorService } from './api-translator.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    constructor(private http: HttpClient,
                private apiTranslator: ApiTranslatorService) {
    }

    getAll(): Observable<Group[]> {
        return this.http.get<IGroup[]>(`${environment.apiUrl}/group/`).pipe(map(group => {
            return group.map(x => new Group(x));
        }));
    }

    getDetails(id): Observable<Group> {
        return this.http.get<IGroup>(`${environment.apiUrl}/group/` + id + `/`).pipe(map(x => new Group(x)));
    }

    updateGroup(group: Group): Observable<Group> {
        const groupJson: any = this.apiTranslator.modelToSnakeCase(group);

        groupJson.permission_ids = groupJson.permissions.map(permission => permission.id);
        delete groupJson.permissions;

        return this.http.put<IGroup>(`${environment.apiUrl}/group/` + group.id + `/`, groupJson)
            .pipe(map(el => new Group(el)));
    }

    createGroup(group: Group): Observable<Group> {
        const groupJson: any = this.apiTranslator.modelToSnakeCase(group);

        groupJson.permission_ids = groupJson.permissions.map(permission => permission.id);
        delete groupJson.permissions;

        return this.http.post<IGroup>(`${environment.apiUrl}/group/`, groupJson)
            .pipe(map(el => new Group(el)));
    }
}
