<div>
	<div class="filters">
		<app-generic-filter class="filter-group" *ngFor="let filterConfig of filterConfigs"
							[filterConfig]="filterConfig"
		>
		</app-generic-filter>
		<br>
	</div>
	<mat-table [dataSource]="features  | paginate: {
      id: 'main_list_pagination',
      itemsPerPage: paginationService.getPageSize(),
      currentPage: paginationService.getPage(),
      totalItems: paginationService.getCount()
    }" *ngIf="features" class="mat-effects">
		<br>
		<br>
		<br>
		<ng-container matColumnDef="feature">
			<mat-header-cell *matHeaderCellDef i18n="@@Feature">Cecha</mat-header-cell>
			<mat-cell *matCellDef="let offer" matTooltip="{{offer.feature}}">{{offer.feature}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="name">
			<mat-header-cell class="col-sm-3" *matHeaderCellDef i18n="@@Name">Nazwa</mat-header-cell>
			<mat-cell class="col-sm-3" *matCellDef="let offer" matTooltip="{{offer.name}}"> {{ offer.name }}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="number">
			<mat-header-cell class="col-sm-3" *matHeaderCellDef i18n="@@Number">Numer oferty/zlecenia</mat-header-cell>
			<mat-cell class="col-sm-3" *matCellDef="let offer">
				<a routerLink="/offer/{{ offer.offer }}" target="_blank" *ngIf="offer.offer">{{offer.offerNumber}}</a>
				<a routerLink="/order/{{ offer.order }}" target="_blank" *ngIf="offer.order">
                    {{offer.orderContractorOrderNumber}}</a>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef class="mat-lp">Użyj
			</mat-header-cell>
			<mat-cell *matCellDef="let offer" class="mat-lp">
				<mat-icon (click)="selectOffer(offer)" i18n-matTooltip="@@GuideUser"
						  matTooltip="Użyj oferty">
					keyboard_arrow_left
				</mat-icon>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
	</mat-table>
	<br>
	<pagination-controls id="main_list_pagination"
						 (pageChange)="paginationService.pageChanged($event)"
						 (pageBoundsCorrection)="paginationService.pageChanged($event)"
						 maxSize="7"
						 directionLinks="true"
						 responsive="true"
						 previousLabel="Wstecz"
						 nextLabel="Dalej"
	>
	</pagination-controls>
</div>

