import {DateService} from '../../_primitive_services';

export interface IOperationStateEdit {
    id: number;
    operationLp: number;
    operationName: string;
    start: string;
    end: string;
    realTimeInSeconds: number;
    author: number;
    authorName: string;
    state: string;
}



export class OperationStateEdit {
    id: number;
    operationLp: number;
    operationName: string;
    start: Date;
    end: Date;
    realTimeInSeconds: number;
    author: number;
    authorName: string;
    state: string;
    shouldUpdate = false;

    constructor(operationLp: number, operationName: string, start: Date, end: Date, realTimeInSeconds: number,
                author: number, authorName: string, state: string, id: number) {
        this.operationLp = operationLp;
        this.operationName = operationName;
        this.start = start;
        this.end = end;
        this.realTimeInSeconds = realTimeInSeconds;
        this.author = author;
        this.authorName = authorName;
        this.state = state;
        this.id = id;
    }

    // noinspection JSUnusedGlobalSymbols
    public getStartLocalCompleteDate(): string {
        return DateService.dateToLocalCompleteDate(this.start);
    }


    // noinspection JSUnusedGlobalSymbols
    public getEndLocalCompleteDate(): string {
        return DateService.dateToLocalCompleteDate(this.end);
    }
}

export interface IOperationStateWriteEdit {
    start: string;
    end: string;
    author: number;
}

export class OperationStateEditFactory {
    public static fromBackend(o: IOperationStateEdit): OperationStateEdit {
        return new OperationStateEdit(
            o.operationLp,
            o.operationName,
            DateService.stringDateToDate(o.start),
            DateService.stringDateToDate(o.end),
            o.realTimeInSeconds,
            o.author,
            o.authorName,
            o.state,
            o.id,
        );
    }

    public static toBackend(o: OperationStateEdit): IOperationStateWriteEdit {
        return {
            start: DateService.dateToStringIso8601(o.start),
            end: DateService.dateToStringIso8601(o.end),
            author: o.author,
        };
    }
}
