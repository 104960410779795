import {BehaviorSubject, Subscription} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BackendParamService {

    private lastSetFilter = '';
    protected paramsCache = new Map<string, Array<string>>();
    protected filtersSubject = new BehaviorSubject<unknown>(undefined);

    navigateToParamsWithChanges(replaceUrl: boolean = false): void {
        this.filtersSubject.next(undefined);
    }

    getQueryParamMap(): Map<string, Array<string>>{
        return this.paramsCache;
    }

    isFilterActive(filterName: string): boolean {
        return this.paramsCache.has(filterName) && this.paramsCache.get(filterName).length > 0;
    }


    subscribe(callback: () => void): Subscription {
        return this.filtersSubject.asObservable().subscribe(callback);
    }

    setQueryParam(attr: string, value: Array<string>, backoff = false, replaceUrl: boolean = false): void {
        const filterMap = this.paramsCache;

        if (backoff && this.lastSetFilter === attr){
            replaceUrl = true;
        }
        this.lastSetFilter = attr;
        filterMap.set(attr, value);
        this.navigateToParamsWithChanges(replaceUrl);
    }

    addQueryParams(map: Map<string, Array<string>>, replaceUrl = false): void {
        const filterMap = this.paramsCache;
        for (const [key, value] of map.entries()){
            filterMap.set(key, value);
        }
        this.lastSetFilter = undefined;
        this.navigateToParamsWithChanges(replaceUrl);
    }

    removeQueryParam(attr: string): void {
        const filterMap = this.paramsCache;
        if (filterMap.has(attr)) {
            filterMap.delete(attr);
            this.navigateToParamsWithChanges(false);
        }
        this.lastSetFilter = attr;
    }

    removeAllQueryParams(attrs: string[]): void {
        const filterMap = this.paramsCache;
        for (const name of attrs){
            if (filterMap.has(name)) {
                filterMap.delete(name);
            }
        }
        this.lastSetFilter = undefined;
        this.navigateToParamsWithChanges(false);
    }

    appendToQueryParam(attr: string, value: string | number): void{
        const valueStr = value.toString();
        const filterMap = this.paramsCache;
        if (!filterMap.has(attr)){
            filterMap.set(attr, []);
        }
        filterMap.get(attr).push(valueStr);
        this.navigateToParamsWithChanges(false);
        this.lastSetFilter = attr;
    }


    popFromQueryParam(attr: string, value: string | number): void{
        const valueStr = value.toString();
        const filterMap = this.paramsCache;
        if (filterMap.has(attr)){
            const list = filterMap.get(attr);
            const index = list.indexOf(valueStr);
            if (index !== -1){
                list.splice(index, 1);
                if (list.length === 0){
                    filterMap.delete(attr);
                }
            }
        }
        this.lastSetFilter = attr;
        this.navigateToParamsWithChanges(false);
    }
}
