import { DateService } from '../_primitive_services';

export interface IWorkTimes {
    workTimes: IWorkTime[];
}

export class WorkTimes implements IWorkTimes {
    workTimes: IWorkTime[];

    constructor(object) {
        this.workTimes = object?.workTimes.map(workTime => new WorkTime(workTime));
    }
}

export interface IWorkTime {
    autoClosed: boolean;
    end: string;
    id?: number;
    start: string;
    canSendNull: boolean;
}

export class WorkTime implements IWorkTime {
    autoClosed: boolean;
    end: string;
    id: number;
    start: string;
    canSendNull: boolean;

    constructor(object?: IWorkTime) {
        if (object) {
            this.autoClosed = object.autoClosed;
            this.end = object.end ? DateService.dateToStringTime(DateService.stringDateToDate(object.end)) : null;
            this.id = object.id;
            this.start = DateService.dateToStringTime(DateService.stringDateToDate(object.start));
            this.canSendNull = !(object.end === null || object.end);
        }
    }
}

export class WorkTimesWrite implements IWorkTimes {
    workTimes: IWorkTime[];

    constructor(date, object) {
        this.workTimes = object?.workTimes.map(workTime => new WorkTimeWrite(date, workTime));
    }
}

export class WorkTimeWrite implements IWorkTime{
    autoClosed: boolean;
    end: string;
    id?: number;
    start: string;
    canSendNull: boolean;

    constructor(date: string, object?: IWorkTime) {
        if (object) {
            const startDate = DateService.parseDateTimeLocalString(date + 'T' + object.start);
            const endDate = DateService.parseDateTimeLocalString(date + 'T' + object.end);

            if (endDate < startDate) {
                endDate.setDate(endDate.getDate() + 1);
            }

            this.autoClosed = object.autoClosed;
            this.end = object.end ? DateService.dateToLocalCompleteDate(endDate) : null;
            this.id = object.id ? object.id : undefined;
            this.start = DateService.dateToLocalCompleteDate(startDate);
        }
    }
}
