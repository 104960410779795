<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">Kontrahent - szczegóły</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="contractor">
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@Edit" mat-button
                        *ngIf="permissionService.checkPermission('perm_write_contractor')"
                        routerLink="/contractor/{{contractor.id}}/edit">Edytuj
                </button>
                <span class="line" *ngIf="permissionService.checkPermission('perm_write_contractor')">|</span>
                <button class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/contractor', contractor)"
                        *ngIf="permissionService.checkPermission('perm_write_contractor')"
                        i18n="@@Remove">Usuń
                </button><span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="contractor">
            <mat-card-content>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Nazwa</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ contractor.name }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Imię</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ contractor.firstName }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Nazwisko</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ contractor.lastName }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">E-Mail</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ contractor.email }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Pokaż w osobnej kolumnie</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        <mat-icon>
                            {{ isChecked('orderStatsUniqueColumn', contractor) }}
                        </mat-icon>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
