import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Permission, User} from '../_models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    public userProfile: User = undefined;
    private userPermissions: Permission[];


    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
    ) {
        this.authenticationService.authenticationChangedObservable.subscribe(_ => {
            this.getPermissions().then();
        });
    }

    async getPermissions(): Promise<any> {
        return await this.http.get<any>(`${environment.apiUrl}/profile/`).toPromise().then(userProfile => {
            this.userProfile = new User(userProfile);
            this.userPermissions = userProfile.permissions;
            // return Promise;
        }).catch(msg => {
            console.log('Error: ' + msg.status + ' ' + msg.statusText);
        });
    }

    getPermissionsForRFID(rfid: string): Observable<Permission[]> {
        return this.http.get<User>(`${environment.apiUrl}/profile/`, {
            headers: new HttpHeaders({
                Authorization: `RFID ${rfid}`
            })}
            ).pipe(map((user: User) => user.permissions));
    }

    checkPermission(permission: string): boolean {
        if (this.userPermissions) {
            let bool;
            bool = this.userPermissions.findIndex(x => x.codename  === permission) !== -1;
            return bool;
        }
        return false;
    }

    checkPermissionsContain(permission: string, permissions: Permission[]): boolean {
        if (permissions) {
            let bool;
            bool = permissions.findIndex(x => x.codename  === permission) !== -1;
            return bool;
        }
        return false;
    }
}
