import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApiTranslatorService {

    constructor() {
    }

    snakeToCamelCase(str): any {
        return str.replace(/[-_][a-z]/ig, letter => `${letter.toUpperCase()}`).replace(/[-_]/ig, '');
    }

    camelToSnakeCase(str): any {
        return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    }

    /**
     * Transalte given @model class instance using javascript camelCase syntax and return new snake_case object ready for Rails backend
     * @param model model in camelCase to translate => snake_case
     */
    modelToSnakeCase(model, isArr?): {} {
        if (!model) {
            return null;
        }
        let apiModel;
        if (isArr || (isArr === undefined && model instanceof Array)) {
            apiModel = [];
        } else {
            apiModel = {};
        }
        if (model) {
            for (const key of Object.keys(model)) {
                if (!!model[key] && typeof model[key] === 'object' && !(model[key] instanceof Date)) {
                    if (model[key] instanceof Array) {
                        apiModel[this.camelToSnakeCase(key)] = this.modelToSnakeCase(model[key], true);
                    } else {
                        apiModel[this.camelToSnakeCase(key)] = this.modelToSnakeCase(model[key], false);
                    }
                } else {
                    apiModel[this.camelToSnakeCase(key)] = model[key];
                }
            }
        }
        return apiModel;
    }

    modelToCamelCase<T>(type: (new (data) => T), apiModel: any): T {
        return new type(this.objectToCamelCase(apiModel));
    }

    listModelToCamelCase<T>(type: (new (data) => T), apiModel: Array<any>): Array<T> {
        const list = [];
        for (const element of apiModel) {
            list.push(new type(this.objectToCamelCase(element)));
            }
        return list;
    }

    objectToCamelCase(snakeCaseModel: any): any {
        if (!snakeCaseModel) {
            return;
        }
        let modelData: any;
        if (Array.isArray(snakeCaseModel)) {
            modelData = [];
        } else {
            modelData = {};
        }

        for (const key of Object.keys(snakeCaseModel)) {
            if (snakeCaseModel[key] === null && snakeCaseModel[key] === undefined) {
                return;
            }

            if (Array.isArray(snakeCaseModel[key])) {
                const array = [];
                for (const item of snakeCaseModel[key]) {
                    switch (typeof item) {
                        case 'string':
                            array.push(this.snakeToCamelCase(item));
                            break;
                        case 'number':
                            array.push(item);
                            break;
                        default:
                            array.push(this.objectToCamelCase(item));
                    }
                }
                modelData[this.snakeToCamelCase(key)] = array;
            } else {
                modelData[this.snakeToCamelCase(key)] = (typeof (snakeCaseModel[key]) === 'object')
                    ? this.objectToCamelCase(snakeCaseModel[key])
                    : snakeCaseModel[key];
            }
        }
        return modelData;
    }
}
