import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Group } from 'src/app/_models';
import { ApiTranslatorService, ErrorHandlerService, GroupService, ModalService, PermissionService } from 'src/app/_services';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-user-group-details',
    templateUrl: './user-group-details.component.html',
    styleUrls: ['./user-group-details.component.css']
})
export class UserGroupDetailsComponent implements OnInit {
    userGroup: Group;

    constructor(private activatedRoute: ActivatedRoute,
                private userGroupService: GroupService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                private errorHandler: ErrorHandlerService,
                public modalService: ModalService,
                public permissionService: PermissionService) {
        this.titleService.setTitle('Grupy Użytkowników');
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        this.userGroupService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(userGroup => {
            this.userGroup = userGroup;

            this.errorHandler.showLoader(false);
        });
    }

}
