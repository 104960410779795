<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@EmployeeWithGuides">Pracownicy z przewodnikami</h1>
            <span class="spacer"></span>
        </mat-toolbar>
        <mat-table [dataSource]="employeesWithOperations" class="mat-effects">
            <!-- Position Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef i18n="@@Employee">Pracownik</mat-header-cell>
                <mat-cell *matCellDef="let employeeWithOperation"> {{employeeWithOperation.employeeName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="guide_number">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideNumber">Numer przewodnika</mat-header-cell>
                <mat-cell *matCellDef="let employeeWithOperation"><a
                        routerLink="/guide/{{employeeWithOperation.guide}}">{{employeeWithOperation.guideNumber}}</a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef i18n="@@Operation">Operacja</mat-header-cell>
                <mat-cell *matCellDef="let employeeWithOperation"> {{employeeWithOperation.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef i18n="@@Actions">Akcje</mat-header-cell>
                <mat-cell *matCellDef="let employeeWithOperation"
                          [matTooltip]="employeeWithOperation.allGuideEmployeeStatesString"
                          [matTooltipClass]="'my-tooltip'"> {{employeeWithOperation.lastStateString}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
