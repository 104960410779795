<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@ContractorsStatistics">Statystyki kontrahentów</h1>
            <span class="spacer"></span>
        </mat-toolbar>

        <mat-table class="mat-effects">
            <!-- Position Column -->
            <ng-container matColumnDef="empty">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_1">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_2">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_3">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_4">
                <mat-header-cell *matHeaderCellDef>Suma całkowita: {{moneyService.parseMoney(totalSum)}}
                </mat-header-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnNames; sticky: true"></mat-header-row>
        </mat-table>
        <br/>

        <mat-table [dataSource]="contractor.orders" *ngFor="let contractor of contractors" class="mat-effects">
            <!-- Position Column -->
            <ng-container matColumnDef="empty">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_1">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderNumber">Numer zamówienia</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_2">
                <mat-header-cell *matHeaderCellDef>Data zamówienia</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_3">
                <mat-header-cell *matHeaderCellDef>Koszt</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_4">
                <mat-header-cell *matHeaderCellDef>Pozostało do zafakturowania</mat-header-cell>
            </ng-container>

            <ng-container matColumnDef="filer">
                <mat-header-cell *matHeaderCellDef i18n="@@ContractorName">Nazwa: {{contractor.name}}</mat-header-cell>
                <mat-cell *matCellDef></mat-cell>
            </ng-container>

            <ng-container matColumnDef="contractor_name">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let order"> {{order.contractorOrderNumber}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="order_date">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let order">{{order.creationDate}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let order">{{moneyService.parseMoney(order.cost)}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="rest">
                <mat-header-cell *matHeaderCellDef i18n="@@TotalRemainingInvoiceCost">
                    Suma: {{moneyService.parseMoney(contractor.totalRemainingInvoiceCost)}}
                </mat-header-cell>
                <mat-cell *matCellDef="let order">{{moneyService.parseMoney(order.remainingInvoiceCost)}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-header-row *matHeaderRowDef="columnNames; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>

        </mat-table>
        <br/>
        <mat-table [dataSource]="otherContractors.orders" *ngIf="otherContractors" class="mat-effects">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Kontrahent</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_1">
                <mat-header-cell *matHeaderCellDef>Numer zamówienia</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_2">
                <mat-header-cell *matHeaderCellDef>Data zamówienia</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_3">
                <mat-header-cell *matHeaderCellDef>Koszt</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name_4">
                <mat-header-cell *matHeaderCellDef>Pozostało do zafakturowania</mat-header-cell>
            </ng-container>

            <ng-container matColumnDef="contractor_name">
                <mat-header-cell *matHeaderCellDef i18n="@@ContractorStatisticsOthers">Inne</mat-header-cell>
                <mat-cell *matCellDef="let order"> {{order.contractorName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="order_number">
                <mat-header-cell *matHeaderCellDef i18n="@@ContractorName"></mat-header-cell>
                <mat-cell *matCellDef="let order"> {{order.contractorOrderNumber}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="order_date">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let order">{{order.creationDate}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let order">{{moneyService.parseMoney(order.cost)}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="rest">
                <mat-header-cell *matHeaderCellDef i18n="@@TotalRemainingInvoiceCost">
                    Suma: {{moneyService.parseMoney(otherContractors.totalRemainingInvoiceCost)}}
                </mat-header-cell>
                <mat-cell *matCellDef="let order">{{moneyService.parseMoney(order.remainingInvoiceCost)}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="otherColumnsToDisplay; sticky: true"></mat-header-row>
            <mat-header-row *matHeaderRowDef="otherColumnNames; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: otherColumnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
