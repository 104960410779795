/* tslint:disable:object-literal-key-quotes */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {ActionHistoryAction} from '../action-history/action-history';
import {GuideAction} from '../_models/guide';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {

    constructor() {
        TranslatorService.languageCode = environment.language;
    }

    public static translations = TranslatorService.init_translations();

    public static languageCode: string;

    private static add(translations, code, pl, en): void {
        translations[code] = {'pl': pl, 'en': en};
    }

    private static init_translations(): {} {

        const T = TranslatorService;

        const t = {};
        T.add(t, 'ok', 'Ok', 'Ok');
        T.add(t, 'not_ok', 'Nie ok', 'Not ok');
        T.add(t, 'repeat', 'Do powtórzenia', 'To repeat');
        T.add(t, 'conditionally_accepted', 'Zaakceptowane warunkowo', 'Conditionally accepted');
        T.add(t, 'not_ok_material', 'Nie Ok materiał', 'Not ok material');
        T.add(t, 'accepted_after_fix', 'Akceptowane po poprawce', 'Accepted after fix');
        T.add(t, 'not_yet_reviewed', 'Jeszcze nie ocenione', 'Not yet reviewed');
        T.add(t, 'employees_with_guides', 'Pracownicy z przewodnikami', 'Employees with guides');
        T.add(t, 'contractors_stats', 'Statystyki kontrahentów', 'Contractors statistics');
        T.add(t, 'production', 'W produkcji', 'In production');
        T.add(t, 'pause', 'Zapauzowane', 'Paused');
        T.add(t, 'new', 'Oczekujący na rozpoczęcie', 'Waiting for start');
        T.add(t, 'guide_new', 'Nieuruchomiony', 'Not started');
        T.add(t, 'users', 'Użytkownicy', 'Users');
        T.add(t, 'operation_author', 'Autor Operacji', 'Operation author');
        T.add(t, 'finished', 'Zakończona', 'Finished');
        T.add(t, 'guide_finished', 'Zakończony', 'Finished');
        T.add(t, 'quality_control', 'Kontrola jakości', 'Quality control');
        T.add(t, 'launch', 'Uruchom', 'Launch');
        T.add(t, 'finish', 'Zakończ operację', 'Finish operation');
        T.add(t, 'guide_finish', 'Zakończ przewodnik', 'Finish guide');
        T.add(t, 'leave', 'Urlop', 'Leave');
        T.add(t, 'leave_on_request', 'Urlop na żądanie', 'Leave on request');
        T.add(t, 'care', 'Opieka', 'Care');
        T.add(t, 'unpaid_leave', 'Urlop bezpłatny', 'Unpaid leave');
        T.add(t, 'l4', 'L4', 'L4');
        T.add(t, 'other', 'Inne', 'Other');
        T.add(t, 'wz_details', 'Wz - Szczegóły', 'Wz - Details');
        T.add(t, 'wz_edit', 'Wz - Edycja', 'Wz - Edit');
        T.add(t, 'wz_create', 'Wz - Tworzenie', 'Wz - Create');

        T.add(t, 'action_create', 'Utworzenie obiektu', 'Object creation');
        T.add(t, 'action_update', 'Edycja obiektu', 'Object edit');
        T.add(t, 'action_partial_update', 'Częsciowa edycja obiektu', 'Object partial edit');
        T.add(t, 'action_move_to_trash', 'Przeniesienie do kosza', 'Move to trash');
        T.add(t, 'action_destroy', 'Usunięcie', 'Deletion');
        T.add(t, 'action_restore_from_trash', 'Przywrócenie z kosza', 'Restore from trash');
        T.add(t, 'action_custom', 'Inna', 'Other');
        T.add(t, 'action_rfid_scan', 'Skan RFID', 'RFID scan');
        T.add(t, 'action_bio_scan', 'Skan BIO', 'BIO scan');
        T.add(t, 'action_auto_end_work', 'Automatyczne zakończenie pracy', 'Auto end work');
        T.add(t, 'action_work_start', 'Rozpoczęcie pracy', 'Start work');
        T.add(t, 'action_work_end', 'Zakończenie pracy', 'End work');
        T.add(t, 'action_work_switch', 'Zakończenie/rozpoczecie pracy', 'Start/end work');
        T.add(t, 'action_auto_end_pause', 'Automatyczne zakończenie przerwy', 'Auto end pause');
        T.add(t, 'action_pause_start', 'Rozpoczecie przerwy', 'Start pause');
        T.add(t, 'action_pause_end', 'Zakończenie przerwy', 'End pause');
        T.add(t, 'action_pause_switch', 'Rozpoczęcie/zakończenie przerwy', 'Start/end pause');
        T.add(t, 'action_perform_action', 'Operacja na obiekcie', 'Operation on object');
        T.add(t, 'action_perform_multi_action', 'Operacja na wielu obiektach',
            'Operation on multiple objects');

        T.add(t, 'month_1', 'Styczeń', 'January');
        T.add(t, 'month_2', 'Luty', 'February');
        T.add(t, 'month_3', 'Marzec', 'March');
        T.add(t, 'month_4', 'Kwiecień', 'April');
        T.add(t, 'month_5', 'Maj', 'May');
        T.add(t, 'month_6', 'Czerwiec', 'June');
        T.add(t, 'month_7', 'Lipiec', 'July');
        T.add(t, 'month_8', 'Sierpień', 'August');
        T.add(t, 'month_9', 'Wrzesień', 'September');
        T.add(t, 'month_10', 'Październik', 'October');
        T.add(t, 'month_11', 'Listopad', 'November');
        T.add(t, 'month_12', 'Grudzień', 'December');

        T.add(t, 'guideAction_pause', 'Zapauzuj', 'Pause');
        T.add(t, 'guideAction_start', 'Uruchom operację', 'Start operation');
        T.add(t, 'guideAction_launch_and_start', 'Uruchom i rozpocznij operację',
            'Launch and start next operation');
        T.add(t, 'guideAction_finish', 'Zakończ operację', 'Finish operation');
        T.add(t, 'guideAction_take_over', 'Przejmij operację', 'Take over operation');
        T.add(t, 'guideAction_start_group', 'Rozpocznij operację grupową', 'Start group operation');
        T.add(t, 'guideAction_pause_group', 'Zapauzuj operację grupową', 'Pause group operation');
        T.add(t, 'guideAction_finish_group', 'Zakończ operację grupową', 'Finish group operation');
        T.add(t, 'guideAction_launch', 'Uruchom przewodnik', 'Launch guide');
        T.add(t, 'guideAction_guide_finish', 'Zakończ przewodnik', 'Finish guide');

        return t;
    }

    public static staticTranslate(code): string {
        const translationObject = TranslatorService.translations[code];
        if (translationObject && translationObject[TranslatorService.languageCode]) {
            return translationObject[TranslatorService.languageCode];
        } else {
            return code;
        }
    }

    public translate(code): string {
        return TranslatorService.staticTranslate(code);
    }

    public translateGuideAction(action: GuideAction): string {
        return this.translate(`guideAction_${action.action}`);
    }

    public translateAction(action: ActionHistoryAction): string {
        return this.translate(`action_${action}`);
    }

    public translateMonth(month: number): string {
        return this.translate(`month_${month}`);
    }
}
