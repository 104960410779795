import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {LoginResponse} from 'src/app/_models';
import {Router} from '@angular/router';
import {DateService} from '../_primitive_services';

export class Authentication {
    token: string;
    scheme: string;
    expirationTime: Date | null;

    constructor(token: string, scheme: string, expirationTime: Date | null) {
        this.token = token;
        this.scheme = scheme;
        this.expirationTime = expirationTime;
    }

    isValid(): boolean {
        return this.expirationTime === null || this.expirationTime > new Date();
    }

    remainingTimeStr(): string {
        if (this.expirationTime === null){
            return '';
        }

        if (this.expirationTime < new Date()){
            return '00:00';
        }

        return DateService.differenceToMinuteSecondString(this.expirationTime.getTime() - (new Date()).getTime());
    }
}

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private currentBearerAuthentication: Authentication | null = null;
    private currentRFIDAuthentication: Authentication | null = null;
    private authenticationChangesSubject: Subject<void> = new Subject<void>();
    public authenticationChangedObservable: Observable<void> = this.authenticationChangesSubject.asObservable();
    private RFID_VALIDITY_IN_MINUTES = 5;


    constructor(
        private router: Router,
        private http: HttpClient) {
        const bearerToken = localStorage.getItem('bearerToken');
        if (bearerToken) {
            this.authenticationChangesSubject.next();
            this.currentBearerAuthentication = new Authentication(bearerToken, 'Bearer', null);
        }
    }

    public getCurrentAuthentication(): Authentication {
        if (this.currentRFIDAuthentication !== null){
            if (this.currentRFIDAuthentication.isValid()) {
                return this.currentRFIDAuthentication;
            }
            else {
                this.invalidateRFIDAuthentication();
            }
        }
        return this.currentBearerAuthentication;
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post<LoginResponse>(`${environment.apiUrl}/login/`, {username, password})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('bearerToken', user.token);
                this.currentBearerAuthentication = new Authentication(user.token, 'Bearer', null);
                this.authenticationChangesSubject.next();
                return user;
            }));
    }

    logout(): any {
        // remove user from local storage to log user out
        this.http.post<any>(`${environment.apiUrl}/logout/`, {}).pipe(first()).subscribe();
        localStorage.removeItem('bearerToken');
        this.currentBearerAuthentication = null;
        this.router.navigate(['/login']);
    }

    removeUserFromLocalStorage(): void {
        localStorage.removeItem('bearerToken');
        this.currentBearerAuthentication = null;
        this.authenticationChangesSubject.next(null);
    }

    public injectRFIDAuthentication(rfid: string): void {
        this.currentRFIDAuthentication = new Authentication(
            rfid, 'RFID', new Date(new Date().getTime() + 1000 * 60 * this.RFID_VALIDITY_IN_MINUTES));
        this.authenticationChangesSubject.next();
    }

    public invalidateRFIDAuthentication(): void {
        this.currentRFIDAuthentication = null;
        this.authenticationChangesSubject.next();
    }

    public isLoggedIn(): boolean {
        return this.currentBearerAuthentication !== null;
    }
}
