import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiTranslatorService, ErrorHandlerService, ModalService, OrderService, PermissionService } from '../../_services';
import { Title } from '@angular/platform-browser';
import { Order } from '../../_models/order';
import { Invoice } from '../../_models/invoice';
import { OrderPosition } from '../../_models/order-position';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { OrderSet } from '../../_models/order-set';
import { Guide } from '../../_models';
import { DateService, MoneyService } from '../../_primitive_services';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0', minHeight: '0', visibility: 'hidden'})),
            state('expanded', style({height: '*', visibility: 'visible'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class OrderDetailsComponent implements OnInit {


    order: Order;
    guide: Guide;
    orderSet: MatTableDataSource<OrderSet>;
    invoice: Invoice;
    orderPosition: OrderPosition;
    columnsToDisplay = ['name', 'quantity'];
    orderPositionColumnsToDisplay = ['position_feature', 'position_name', 'position_price', 'position_quantity',
        'position_progress', 'position_cost', 'position_with_guide'];
    allExpandState = false;
    dataSource: MatTableDataSource<Order>;
    expandedElement: any;
    dateService = DateService;
    moneyService = MoneyService;
    private notOkQualityControl = ['not_ok', 'not_ok_material'];

    constructor(private activatedRoute: ActivatedRoute,
                public orderService: OrderService,
                private apiTranslator: ApiTranslatorService,
                private errorHandler: ErrorHandlerService,
                private titleService: Title,
                private router: Router,
                public modalService: ModalService,
                public permissionService: PermissionService,
                ) {
        this.titleService.setTitle('Szczegóły zamówienia');
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);

        this.orderService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(order => {

            this.order = order;
            const orderSetIdToInstance = new Map<number, OrderSet>();
            for (const orderSet of this.order.orderSets){
                if (!orderSet.dummy) {
                    orderSetIdToInstance.set(orderSet.id, orderSet);
                }
            }
            const dummyOrderSet = new OrderSet([], {
                id: undefined,
                name: 'Inne',
                quantity: 1,
                clientGeneratedId: undefined,
                dummy: true,
                wzSetPositions: undefined,
                positions: [],
                tableGeneratedId: undefined,
                price: undefined
            });
            orderSetIdToInstance.set(-1, dummyOrderSet);
            for (const position of order.positions){
                if (orderSetIdToInstance.has(position.orderSetId)){
                }
                else {
                    dummyOrderSet.positions.push(position);
                }
            }
            this.order.orderSets = [...orderSetIdToInstance.values()];

            this.errorHandler.showLoader(false);
        });
    }

    isChecked(fieldName, index): any {
        return index[fieldName] ? 'done' : 'close';
    }

    getGuidePath(position: OrderPosition): string {
        // position.currentGuide? : " [queryParams]="position.currentGuide? '':{position.id}
        if (position.currentGuide && !this.notOkQualityControl.includes(position.latestQualityControl)) {
            this.router.navigate(['/guide', position.currentGuide]);
        } else if (position.withGuide) {
            this.router.navigate(['/guide/create'], {queryParams: {order_position_id: position.id}});
        } else {
            return;
        }
    }

    getLinkName(position: OrderPosition): string {
        if (position.currentGuide && !this.notOkQualityControl.includes(position.latestQualityControl)) {
            return String(position.currentGuideNumber);
        } else if (position.withGuide) {
            return 'Dodaj przewodnik';
        } else {
            return 'Brak';
        }
    }
}
