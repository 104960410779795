import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {ApiTranslatorService, ContractorService, ErrorHandlerService} from 'src/app/_services';
import { first } from 'rxjs/operators';
import { Contractor } from 'src/app/_models/contractor';
import {AlertService} from '../../_primitive_services';
import {Title} from '@angular/platform-browser';
import {BackendErrorResponse} from '../../_interfaces/BackendErrorResponse';

@Component({
    selector: 'app-contractor-edit',
    templateUrl: './contractor-edit.component.html',
    styleUrls: ['./contractor-edit.component.css']
})
export class ContractorEditComponent implements OnInit {
    contractor: Contractor;
    contractorForm: FormGroup;
    isEditComponent: boolean;
    title: string;

    constructor(private activatedRoute: ActivatedRoute,
                private contractorService: ContractorService,
                private apiTranslator: ApiTranslatorService,
                private formBuilder: FormBuilder,
                private router: Router,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                private titleService: Title
                ) {
    }

    ngOnInit(): void {
        this.isEditComponent = this.activatedRoute.snapshot.paramMap.has('id');
        this.setTitle();
        if (this.isEditComponent) {
            this.errorHandler.showLoader(true);
            this.contractorService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(data => {
                this.errorHandler.showLoader(false);
                this.contractor = data;
                this.buildEditForm();
            });
        } else {
            this.buildCreateForm();
        }
    }

    private setTitle(): void{
        this.title = this.isEditComponent ? 'Kontrahent - edycja' : 'Kontrahent - dodawanie';
        this.titleService.setTitle(this.title);
    }

    get f(): any {
        return this.contractorForm.controls;
    }

    buildCreateForm(): void {
        this.contractorForm = this.formBuilder.group({
            name: ['', Validators.required],
            firstName: [''],
            lastName: [''],
            email: ['', Validators.email],
            orderStatsUniqueColumn: [false],
        });
    }

    buildEditForm(): void {
        this.contractorForm = this.formBuilder.group({
            name: [this.contractor.name, [Validators.required, Validators.maxLength(255)]],
            firstName: [this.contractor.firstName, [Validators.maxLength(255)]],
            lastName: [this.contractor.lastName, [Validators.maxLength(255)]],
            email: [this.contractor.email, Validators.email],
            orderStatsUniqueColumn: [this.contractor.orderStatsUniqueColumn],
        });
    }

    onSubmit(): any {
        if (this.contractorForm.invalid) {
            return;
        }

        if (this.isEditComponent) {
            this.contractorForm.value.id = this.contractor.id;
            this.contractorService.updateContractor(this.contractorForm.value).pipe(first())
                .subscribe(r => this.handleSuccess(r), e => this.handleError(e));
        } else {
            this.contractorService.createContractor(this.contractorForm.value).pipe(first())
                .subscribe(r => this.handleSuccess(r), e => this.handleError(e));
        }
    }

    private handleSuccess(contractor: Contractor): void {
        this.router.navigate(['/contractor', contractor.id]);
        this.alertService.success('Kontrahent: <strong>' + contractor.name + '</strong> ' +
            'został pomyślnie utworzony/edytowany', { autoClose: true });
    }

    private handleError(error: BackendErrorResponse): void {
        if (error.error_code === 713) {
            this.alertService.error('Kontrahent o nazwie ' + this.contractorForm.value.name + ' już istnieje');
        } else {
            this.alertService.error('Błąd: ', this.errorHandler.error(error));
        }
    }
}
