import { Injectable } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CloseModalComponent, DeleteModalComponent, PasswordResetModalComponent, RestoreModalComponent } from 'src/app/_shared';


@Injectable({
    providedIn: 'root'
})
export class ModalService {
    constructor(private popupService: NgbModal) {
    }
    closeResult = '';
    modal: NgbModalRef;

    private static getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    open(content): any {
        this.modal = this.popupService.open(content);
        this.modal.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${ModalService.getDismissReason(reason)}`;
        });
        return this.modal;
    }

    close(): any {
        this.modal.close();
    }

    deleteModalForm(redirect, model): void {
        const modalRef = this.open(DeleteModalComponent);
        modalRef.componentInstance.redirect = redirect;
        modalRef.componentInstance.model = model;
    }

    restoreModalForm(redirect, model): void {
        const modalRef = this.open(RestoreModalComponent);
        modalRef.componentInstance.redirect = redirect;
        modalRef.componentInstance.model = model;
    }

    closeModalForm(model): void {
        const modalRef = this.open(CloseModalComponent);
        modalRef.componentInstance.model = model;
    }

    resetPasswordModalForm(user): void {
        const modalRef = this.open(PasswordResetModalComponent);
        modalRef.componentInstance.user = user;
    }
}
