<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@OffersPage">Oferty</h1>
            <span class="spacer"></span>
            <a class="button-spacer mat-button-custom mat-add-button" mat-button routerLink="/offer/create"
                    *ngIf="permissionService.checkPermission('perm_write_offer')"><span
                    class="add-circle">+</span> Dodaj ofertę
            </a>
        </mat-toolbar>
        <pagination-controls id="main_list_pagination"
                             (pageChange)="paginationService.pageChanged($event)"
                             (pageBoundsCorrection)="paginationService.pageChanged($event)"
                             maxSize="13"
                             directionLinks="true"
                             responsive="true"
                             previousLabel="Wstecz"
                             nextLabel="Dalej"
        >
        </pagination-controls>
        <mat-table [dataSource]="offers | paginate: {
              id: 'main_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }  | paginate: {
              id: 'bottom_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }" class="mat-effects" matSort (matSortChange)="sortService.sortData($event)"
                   matSortActive="{{initialSort.active}}" matSortDirection="{{initialSort.direction}}">

            <ng-container matColumnDef="contractor">
                <mat-header-cell *matHeaderCellDef i18n="@@Contractor">Kontrahent</mat-header-cell>
                <mat-cell *matCellDef="let offer"> {{offer.contractorName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="creationDate">
                <mat-header-cell *matHeaderCellDef i18n="@@CreationDate" mat-sort-header="creation_date">
                    Data utworzenia
                </mat-header-cell>
                <mat-cell *matCellDef="let offer"> {{dateService.dateToStringDate(offer.creationDate)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef i18n="@@Number" mat-sort-header="year,month,lp">
                    Numer
                </mat-header-cell>
                <mat-cell *matCellDef="let offer"> {{offer.number}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@OfferOperations">Opcje
                </mat-header-cell>
                <mat-cell *matCellDef="let offer" class="mat-operations">
					<a routerLink="/offer/{{offer.id}}/edit">
						<mat-icon i18n-matTooltip="@@OfferEdit"
								  *ngIf="permissionService.checkPermission('perm_write_offer')"
								  matTooltip="Edycja oferty">edit
						</mat-icon>
					</a>
					<a routerLink="/offer/{{offer.id}}">
						<mat-icon i18n-matTooltip="@@OfferDetails"
								  matTooltip="Szczegóły oferty">list_alt
						</mat-icon>
					</a>
                    <mat-icon (click)="modalService.deleteModalForm('offer', offer)"
                              i18n-matTooltip="@@OfferRemove" matTooltip="Usunięcie oferty"
                              *ngIf="permissionService.checkPermission('perm_write_offer')
                              && !queryParamService.isFilterActive('in_trash')">delete
                    </mat-icon>
                    <mat-icon (click)="modalService.restoreModalForm('/offer', offer)"
                              i18n-matTooltip="@@OfferRestore"
                              matTooltip="Przywrócenie oferty"
                              *ngIf="permissionService.checkPermission('perm_write_offer')
                              && queryParamService.isFilterActive('in_trash')">restore_from_trash
                    </mat-icon>
                    <mat-icon (click)="getPDF(offer.id)" i18n-matTooltip="@@OfferPdf"
                              matTooltip="Wyświetl PDF">get_app
                    </mat-icon>
                </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
        <br>
        <br>
        <pagination-controls id="bottom_list_pagination"
                             (pageChange)="paginationService.pageChanged($event)"
                             (pageBoundsCorrection)="paginationService.pageChanged($event)"
                             maxSize="13"
                             directionLinks="true"
                             responsive="true"
                             previousLabel="Wstecz"
                             nextLabel="Dalej"
        >
        </pagination-controls>
    </div>
</div>
