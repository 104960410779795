import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MachineGroupService } from '../_services';

@Injectable({
    providedIn: 'root'
})
export class MachineGroupResolverService implements Resolve<any> {

    constructor(private machineGroupService: MachineGroupService) {
    }

    resolve(): any {
        return this.machineGroupService.getAll(new Map());
    }
}
