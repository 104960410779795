export interface IRFID {
    id: number;
    rfid: string;
}

export class RFID implements IRFID{
    id: number;
    rfid: string;

    constructor(id: number, rfid: string) {
        this.id = id;
        this.rfid = rfid;
    }
}

export interface IRFIDDetail extends IRFID{
    user: number;
    authUser: number;
}

export class RFIDDetail extends RFID implements IRFIDDetail{
    id: number;
    rfid: string;
    user: number;
    authUser: number;


    constructor(object: IRFIDDetail) {
        super(object.id, object.rfid);
        this.user = object.user;
        this.authUser = object.authUser;
    }
}
