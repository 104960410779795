import { Component, OnInit } from '@angular/core';
import {
    ErrorHandlerService,
    GuidesService,
    ModalService, MultiActionResponse,
    OrderService,
    PermissionService,
    TimeService,
    TranslatorService
} from 'src/app/_services';
import { ActivatedRoute } from '@angular/router';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Author, Contractor, Guide, GuideOperation } from 'src/app/_models';
import {AlertService, DateService, MoneyService} from '../../_primitive_services';
import { Order } from '../../_models/order';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-guide-details',
    templateUrl: './guide-details.component.html',
    styleUrls: ['./guide-details.component.css']
})
export class GuideDetailsComponent implements OnInit {
    guide: Guide;
    order: Order;
    contractor: Contractor;
    dateService = DateService;
    timeService = TimeService;
    moneyService = MoneyService;
    barcode: any;
    columnsToDisplayPositions = ['lp', 'machineGroup', 'name', 'time', 'realTime', 'state', 'authors', 'qualityControl'];
    private readonly visibleAuthorStates = ['production', 'pause', 'finished', 'guide_finished'];
    operations: GuideOperation[];
    public canCreateNewGuide = false;

    constructor(private activatedRoute: ActivatedRoute,
                private guideService: GuidesService,
                private orderService: OrderService,
                private titleService: Title,
                private errorHandler: ErrorHandlerService,
                public modalService: ModalService,
                private sanitizer: DomSanitizer,
                private translatorService: TranslatorService,
                public permissionService: PermissionService,
                private alertService: AlertService,
                ) {
        this.titleService.setTitle('Przewodnik - szczegóły');
    }

    ngOnInit(): void {
        this.loadGuide();
    }

    private loadGuide(): void{
        this.errorHandler.showLoader(true);
        this.guideService.getGuide(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(guide => {
            this.guide = guide;
            this.fillCanCreateNewGuide();
            this.titleService.setTitle(guide.number);
            this.operations = this.guide.operations;
            this.guideService.getBarcode(guide.id).subscribe(barcode => {
                this.barcode = this.sanitizer.bypassSecurityTrustHtml(barcode);
            });
            this.errorHandler.showLoader(false);
        });
    }

    getFirstAuthor(authors: Author[]): string {
        const qualityControlAuthors = authors.filter(x => x.authorName !== null && x.authorName !== undefined
            && (x.state === 'quality_control')).map(x => x.author);
        const aut = authors.filter(x => x.authorName !== null && x.authorName !== undefined
            && (this.visibleAuthorStates.includes(x.state)) && !qualityControlAuthors.includes(x.author));
        if (aut.length > 0) {
            if (aut.length > 1) {
                return aut[aut.length - 1].authorName + '...';
            } else {
                return aut[aut.length - 1].authorName;
            }
        } else {
            return '';
        }
    }

    getAuthors(authors: Author[]): string {
        return authors.map(a => {
            if (a.authorName !== null && a.authorName !== undefined) {
                return a.authorName + ' - ' + this.translate(a.state) + ' - ' + DateService.dateToString(a.start);
            } else {
                return '';
            }
        }).reduce((prev, curr) => prev + '\n' + curr);
    }

    createQualityControl(qualityControlAuthorName?: string, qualityControlNote?: string): string {
        let preparedString = '';
        if (qualityControlAuthorName !== null && qualityControlAuthorName !== undefined && qualityControlAuthorName !== '') {
            preparedString += qualityControlAuthorName;
            if (qualityControlNote !== null && qualityControlNote !== undefined && qualityControlNote !== '') {
                preparedString += '\n' + qualityControlNote;
            }
        }
        return preparedString;
    }

    translate(code: string): string {
        return this.translatorService.translate(code);
    }
    getColor(row: GuideOperation): string {
        let state: string;
        if (this.guide.currentOperation === row.id && row.currentState !== 'guide_finished'){
            state = 'active';
        }else {
            state = row.currentState;
        }
        switch (state){
            case 'production': {
                return '#04f9d011';
            }
            case 'guide_new': {
                return '#FFFFFF';
            }
            case 'long_pause': {
                return '#0422FA11';
            }
            case 'pause': {
                return '#6376F811';
            }
            case 'finished': {
                return '#21C30411';
            }
            case 'guide_finished': {
                return '#21C30411';
            }
            case 'waiting': {
                return '#D405D411';
            }
            case 'denied': {
                return '#FF000011';
            }
            case 'active': {
                return '#fccb1d11';
            }
            default: {
                return '#FFFFFF';
            }
        }
    }

    getPDF(): void {
        const urlAdress = `${environment.apiUrl}/guide/${this.guide.id}/pdf`;
        window.open(urlAdress, '_blank');
    }

    public fillCanCreateNewGuide(): void {

        const notOkQualityControl = ['not_ok', 'not_ok_material'];
        this.canCreateNewGuide =
            notOkQualityControl.includes(this.guide.currentOperationQualityControlGrade)
            && this.guide.isCurrent;
    }

    public performMultiAction(action: string): void {
        this.errorHandler.showLoader(true);
        this.guideService.multiAction([this.guide.id], action
        ).subscribe(
            (data: MultiActionResponse[]) => {
                this.errorHandler.showLoader(false);

                const success = (data[0].code === 200);

                if (success) {
                    this.alertService.success(`Zauktualizowano przewodnik`);
                } else {
                    this.alertService.error(`Błąd: ${data[0].description}<br>`);
                }
                this.loadGuide();
            }, error => {
                this.errorHandler.showLoader(false);
                this.alertService.error(' Błąd: ', this.errorHandler.error(error));
            }
        );
    }
}
