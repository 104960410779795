import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatLineModule, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {ApiTranslateInterceptor, ErrorInterceptor, JwtInterceptor} from './_helpers';
import {
    CloseModalComponent,
    DeleteModalComponent,
    PasswordResetModalComponent, RestoreModalComponent,
} from './_shared/';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { AlertComponent } from './alert/alert.component';
import { LayoutComponent } from './layout/layout.component';

import { UserDetailsComponent, UserEditComponent, UserListComponent } from './user';
import { MachineGroupDetailsComponent, MachineGroupEditComponent, MachineGroupListComponent } from './machine-group';
import { OfferDetailsComponent, OfferEditComponent, OfferListComponent, } from './offer';
import { EmployeesWithOperationsComponent } from './employees-with-operations/employees-with-operations.component';
import { GuideDetailsComponent, GuideEditComponent, GuideListComponent } from './guide';
import { ContractorsStatisticsComponent } from './contractors-statistics';
import { OrderStatisticsComponent } from './order-statistics/order-statistics.component';
import { IndividualReportComponent } from './individual-report';

import { OrderDetailsComponent, OrderListComponent } from './order';
import { MatListModule } from '@angular/material/list';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { NgxPaginationModule } from 'ngx-pagination';
import {PaginationService} from './_services/pagination.service';
import { MatSortModule } from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DesktopComponent } from './desktop/desktop.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DesktopSearchComponent } from './desktop/desktop-search.component';
import { OrderTvListComponent } from './order/tv/order-tv-list.component';
import {
    UserGroupDetailsComponent,
    UserGroupEditComponent,
    UserGroupListComponent
} from './user-group';
import {
    ContractorDetailsComponent,
    ContractorEditComponent,
    ContractorListComponent
} from './contractor';
import {
    QualityControlDetailsComponent,
    QualityControlEditComponent,
    QualityControlListComponent
} from './quality-control';
import {ReportComponent} from './report';
import {OrderEditComponent} from './order/edit/order-edit.component';
import {PositionsWithoutGuidesListComponent} from './positions-without-guides';
import {FilterComponent} from './_filters/filter-navigation/filter.component';
import { GuideSearchComponent } from './guide/guide-search/guide-search.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BackButtonComponent } from './back-button/back-button.component';
import {MatChipsModule} from '@angular/material/chips';
import { OfferSearchComponent } from './offer/search/offer-search.component';
import { EditIndividualReportComponent } from './individual-report/edit/edit-individual-report.component';
import { FilterCheckboxComponent } from './_filters/components/filter-checkbox/filter-checkbox.component';
import {FilterChoiceListComponent} from './_filters/components/filter-choice-list/filter-choice-list.component';
import {FilterStringInputComponent} from './_filters/components/filter-string-input/filter-string-input.component';
import {FilterDateRangeComponent} from './_filters/components/filter-date-range/filter-date-range.component';
import {FilterYearMonthComponent} from './_filters/components/filter-year-month/filter-year-month.component';
import { WzListComponent, WzDetailsComponent, WzCreateComponent } from './wz';
import { FeatureSearchComponent } from './offer/feature-search/feature-search.component';
import { ScannerConfigComponent } from './scanner-config/scanner-config.component';
import { ActionHistoryComponent } from './action-history/action-history.component';
import { OrderDeadlineComponent } from './order-deadline/order-deadline.component';
import { OrderDeadlineSummaryComponent } from './order-deadline-summary/order-deadline-summary.component';
import { ToEarnEditComponent } from './order-deadline-summary/to-earn-edit/to-earn-edit.component';
import { OrderYearlyChartComponent } from './order-yearly-chart/order-yearly-chart.component';
import {GenericFilterComponent} from './_filters/generic-filter/generic-filter.component';
import { OperationStateEditComponent } from './guide/operation-state-edit/operation-state-edit.component';
import { OrderScheduleComponent } from './order-schedule/order-schedule.component';
import { ScheduleGenerateComponent } from './order-schedule/schedule-generate/schedule-generate.component';
import { WorkTimeIndividualReportComponent } from './individual-report/work-time/work-time-individual-report.component';

@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatTooltipModule,
        MatCardModule,
        MatInputModule,
        MatPaginatorModule,
        NgSelectModule,
        NgbModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatMenuModule,
        MatRippleModule,
        MatListModule,
        MatLineModule,
        DragDropModule,
        MatProgressBarModule,
        MatSelectModule,
        NgxPaginationModule,
        MatSortModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatSidenavModule,
        MatChipsModule,
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        UserListComponent,
        UserDetailsComponent,
        UserEditComponent,
        MachineGroupListComponent,
        MachineGroupDetailsComponent,
        MachineGroupEditComponent,
        NavBarComponent,
        DeleteModalComponent,
        RestoreModalComponent,
        PasswordResetModalComponent,
        OfferListComponent,
        OfferDetailsComponent,
        OfferEditComponent,
        // OfferPdfComponent,
        UserGroupListComponent,
        UserGroupEditComponent,
        UserGroupDetailsComponent,
        ContractorListComponent,
        ContractorDetailsComponent,
        ContractorEditComponent,
        AlertComponent,
        QualityControlListComponent,
        QualityControlEditComponent,
        QualityControlDetailsComponent,
        EmployeesWithOperationsComponent,
        OrderStatisticsComponent,
        ReportComponent,
        IndividualReportComponent,
        OrderDetailsComponent,
        OrderListComponent,
        CloseModalComponent,
        GuideListComponent,
        GuideEditComponent,
        LayoutComponent,
        GuideDetailsComponent,
        ContractorsStatisticsComponent,
        WzListComponent,
        OrderEditComponent,
        GenericFilterComponent,
        FilterComponent,
        PositionsWithoutGuidesListComponent,
        DesktopComponent,
        DesktopSearchComponent,
        OrderTvListComponent,
        GuideSearchComponent,
        BackButtonComponent,
        WzDetailsComponent,
        OfferSearchComponent,
        EditIndividualReportComponent,
        FilterCheckboxComponent,
        FilterChoiceListComponent,
        FilterStringInputComponent,
        FilterDateRangeComponent,
        FilterYearMonthComponent,
        WzCreateComponent,
        FeatureSearchComponent,
        ScannerConfigComponent,
        ActionHistoryComponent,
        OrderDeadlineComponent,
        OrderDeadlineSummaryComponent,
        ToEarnEditComponent,
        OrderYearlyChartComponent,
        OperationStateEditComponent,
        OrderScheduleComponent,
        ScheduleGenerateComponent,
        WorkTimeIndividualReportComponent,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ApiTranslateInterceptor, multi: true},
        Title, CurrencyPipe, DatePipe, PaginationService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        DeleteModalComponent,
        RestoreModalComponent,
        PasswordResetModalComponent
    ]
})
export class AppModule {
}
