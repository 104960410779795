<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@Order-statisticsPage">Statystyki zamówień</h1>
            <span class="spacer"></span>
        </mat-toolbar>

        <mat-card class="mat-effects" *ngIf="orderStatistics">
            <mat-card-content>
                <p class="card-row"><span class="label" i18n="@@ContractorName">Całkowity koszt</span> <span
                    class="value">{{moneyService.parseMoney(orderStatistics.totalCost)}}</span></p>
            </mat-card-content>
        </mat-card>

        <mat-table *ngIf="orderStatistics" [dataSource]="orderStatistics.orders" class="mat-effects">

            <ng-container matColumnDef="contractorName">
                <mat-header-cell *matHeaderCellDef>Kontrahent</mat-header-cell>
                <mat-cell *matCellDef="let order"> {{order.contractorName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cost">
                <mat-header-cell *matHeaderCellDef>Koszt</mat-header-cell>
                <mat-cell *matCellDef="let order">{{moneyService.parseMoney(order.cost)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="contractorOrderNumber">
                <mat-header-cell *matHeaderCellDef>Numer zamówienia
                </mat-header-cell>
                <mat-cell *matCellDef="let order"> <a href="/order/{{order.id}}">
                    {{order.contractorOrderNumber}}
                </a>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
