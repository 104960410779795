import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IndividualReportService} from '../../_services/individual-report.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorHandlerService, ModalService} from '../../_services';
import {AlertService} from '../../_primitive_services';
import {OrderDeadlineSummary} from '../order-deadline-summary';
import {OrderDeadlineService} from '../../order-deadline/order-deadline.service';
import {ToEarn} from './to-earn';

@Component({
    selector: 'app-to-earn-edit',
    templateUrl: './to-earn-edit.component.html',
    styleUrls: ['./to-earn-edit.component.css']
})
export class ToEarnEditComponent implements OnInit {

    @Input() dayToEdit: string;
    @Input() employeeId: number;
    @Input() deadlineSummary: OrderDeadlineSummary;

    formGroup: FormGroup;
    absenceCauses: string[] = ['leave', 'leave_on_request', 'care', 'unpaid_leave', 'l4', 'other'];

    constructor(
        private individualReportService: IndividualReportService,
        private formBuilder: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public modalService: ModalService,
        private alertService: AlertService,
        private errorHandler: ErrorHandlerService,
        private orderDeadlineService: OrderDeadlineService
    ){
    }

    ngOnInit(): void {
        this.buildForm();
    }

    buildForm(): void {
        this.formGroup = this.formBuilder.group({
            toEarn: [this.deadlineSummary?.toEarnInMonth / 100],
        });
    }

    onSubmit(): void {
        this.orderDeadlineService
            .editToEarn(new ToEarn(
                this.deadlineSummary.month, this.deadlineSummary.toEarnInMonth, this.deadlineSummary.year))
            .subscribe(
            _ => {
                this.modalService.close();
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                    this.router.navigate(['order-deadline-summary']));
                this.alertService.success('Wartość do zdania została pomyślnie edytowana.');
            }, error => {
                this.alertService.error('Wartość do zdania nie została edytowana. Błąd: ',
                    this.errorHandler.error(error));
            }
        );
    }

    changeToEarnValue(event: Event): void {
        this.deadlineSummary.toEarnInMonth = parseInt((event.target as HTMLInputElement).value, 10) * 100;
    }
}
