<div>
    <div class="filters">
        <app-generic-filter class="filter-group" *ngFor="let filterConfig of filterConfigs"
                            [filterConfig]="filterConfig"
        >
        </app-generic-filter>
        <br>
    </div>
    <mat-table [dataSource]="offers  | paginate: {
      id: 'main_list_pagination',
      itemsPerPage: paginationService.getPageSize(),
      currentPage: paginationService.getPage(),
      totalItems: paginationService.getCount()
    }" *ngIf="offers" class="mat-effects">
        <br>
        <br>
        <br>
        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef i18n="@@GuideNumber">Numer</mat-header-cell>
            <mat-cell *matCellDef="let offer" matTooltip="{{offer.name}}">
                <a routerLink="/offer/{{ offer.id }}" target="_blank">
                    {{offer.number}}
                </a>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="contractor">
            <mat-header-cell class="col-sm-3" *matHeaderCellDef i18n="@@GuideFeature">Kontrahent</mat-header-cell>
            <mat-cell class="col-sm-3" *matCellDef="let offer"
                      matTooltip="{{offer.contractorName}}"> {{ offer.contractorName }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="mat-lp">Użyj
            </mat-header-cell>
            <mat-cell *matCellDef="let offer" class="mat-lp">
                <mat-icon (click)="selectOffer(offer)" i18n-matTooltip="@@GuideUser"
                          matTooltip="Użyj oferty">
                    keyboard_arrow_left
                </mat-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
    </mat-table>
    <br>
    <pagination-controls id="main_list_pagination"
                         (pageChange)="paginationService.pageChanged($event)"
                         (pageBoundsCorrection)="paginationService.pageChanged($event)"
                         maxSize="7"
                         directionLinks="true"
                         responsive="true"
                         previousLabel="Wstecz"
                         nextLabel="Dalej"
    >
    </pagination-controls>
</div>

