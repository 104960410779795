<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@GuideList">Zamówienia terminy podsumowanie</h1>
            <span class="spacer"></span>
        </mat-toolbar>

        <mat-table [dataSource]="deadlineSummaryLocalFilterService.getObjects()"
                   *ngIf="deadlineSummaryLocalFilterService.getObjects()" class="mat-effects">
            <ng-container matColumnDef="year">
                <mat-header-cell *matHeaderCellDef>Rok
                </mat-header-cell>
                <mat-cell *matCellDef="let deadlineSummary">
                    {{ deadlineSummary.year }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="month">
                <mat-header-cell *matHeaderCellDef>Miesiąc
                </mat-header-cell>
                <mat-cell *matCellDef="let deadlineSummary">
                    {{ translatorService.translateMonth(deadlineSummary.month) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="nonInvoice">
                <mat-header-cell *matHeaderCellDef>Niezafakturowane
                </mat-header-cell>
                <mat-cell *matCellDef="let deadlineSummary">
                    {{moneyService.parseMoney(deadlineSummary.nonInvoice)}}
                </mat-cell>
            </ng-container>

                        <ng-container matColumnDef="nonInvoiceSum">
                <mat-header-cell *matHeaderCellDef>Niezafakturowane suma
                </mat-header-cell>
                <mat-cell *matCellDef="let deadlineSummary">
                    {{moneyService.parseMoney(deadlineSummary.nonInvoiceSum)}}
                </mat-cell>
            </ng-container>

                        <ng-container matColumnDef="invoice">
                <mat-header-cell *matHeaderCellDef>Zafakturowane
                </mat-header-cell>
                <mat-cell *matCellDef="let deadlineSummary">
                    {{moneyService.parseMoney(deadlineSummary.invoice)}}
                </mat-cell>
            </ng-container>

                        <ng-container matColumnDef="toEarnInMonth">
                <mat-header-cell *matHeaderCellDef>Do zdania
                </mat-header-cell>
                <mat-cell *matCellDef="let deadlineSummary">
                    {{moneyService.parseMoney(deadlineSummary.toEarnInMonth)}}
                </mat-cell>
            </ng-container>

                        <ng-container matColumnDef="leftToEarn">
                <mat-header-cell *matHeaderCellDef>Zostało do zdania
                </mat-header-cell>
                <mat-cell *matCellDef="let deadlineSummary">
                    {{moneyService.parseMoney(deadlineSummary.leftToEarn)}}
                </mat-cell>
            </ng-container>


                    <ng-container matColumnDef="totalBehind">
                <mat-header-cell *matHeaderCellDef>Sumaryczny bilans
                </mat-header-cell>
                <mat-cell *matCellDef="let deadlineSummary">
                    {{moneyService.parseMoney(-deadlineSummary.totalBehind)}}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="mat-operations">Opcje
                </mat-header-cell>
                <mat-cell *matCellDef="let deadlineSummary" class="mat-operations">
                    <mat-icon
                        *ngIf="permissionService.checkPermission('perm_write_order_to_earn')"
                        (click)="editToEarn(deadlineSummary)" matTooltip="Edytuj kwotę do zdania">edit</mat-icon>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
<ng-template #editModal>
	<app-to-earn-edit [deadlineSummary]="toEarnEditDeadlineSummary"></app-to-earn-edit>
</ng-template>
