import {Clickable} from '../_services/multi-selection.service';

export interface IPermission {
    id: number;
    name: string;
    codename: string;
}

export class Permission implements IPermission{
    id: number;
    name: string;
    codename: string;

    constructor(object?: IPermission) {
        if (object) {
            this.id = object.id;
            this.name = object.name;
            this.codename = object.codename;
        }
    }
}


export class CheckedPermission extends Permission implements Clickable{
    checked: boolean;

    constructor(object: Permission, checked: boolean) {
        super(object);
        this.checked = checked;
    }
}
