<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@QualityControlDetailsPage">Kontrola jakości - szczegóły</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="qualityControl">
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@EditDetails" mat-button
                        *ngIf="permissionService.checkPermission('perm_write_quality_control')"
                        routerLink="/quality-control/{{qualityControl.id}}/edit">Edytuj
                </button>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="qualityControl">
            <mat-card-content>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Nr przewodnika</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ qualityControl.guideNumber }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Cecha</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ qualityControl.feature }}
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Zadanie</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ qualityControl.operationName }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Ocena</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ grade }}
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Notatka</div>
                    <div class="col-sm-7 clearfix font-weight-bold dark-color">
                        {{ qualityControl.note }}
                    </div>
                </div>
                <hr>

            <div class="form-group row align-items-center">
                <div class="col-sm-1 col-form-label">Kontroler</div>
                <div class="col-sm-11 clearfix font-weight-bold dark-color">
                    {{ qualityControl.authorName }}
                </div>
            </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>
