import { v4 as uuidv4 } from 'uuid';

export interface IInvoice {
    name: string;
    price: number;
}


export class Invoice implements IInvoice{
    static modelName = 'Faktura';
    static path = 'invoice';
    name: string;
    price: number;
    clientGeneratedId: string;

    constructor(object?: IInvoice) {
        if (object) {
            this.name = object.name;
            this.price = object.price;
            this.clientGeneratedId = uuidv4();
        }
    }
    getModelName(): string {
        return Invoice.modelName;
    }

    getPath(): string {
        return Invoice.path;
    }
    getName(): string {
        return this.name;
    }

    getPriceZl(): number {
        return Math.floor(this.price / 100);
    }

    getPriceGr(): number {
        return Math.floor(this.price % 100);
    }

    setPriceZl(valueStr: string): void {
        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldGr = this.getPriceGr();
            this.price = value * 100 + oldGr;
        }
    }

    setPriceGr(valueStr: string): void {

        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldZl = this.getPriceZl();
            this.price = 100 * oldZl + value;
        }
    }
}

