import {Component, OnInit, ViewChild} from '@angular/core';
import {
    ApiTranslatorService,
    ErrorHandlerService,
    ModalService,
    OfferService,
    PermissionService
} from 'src/app/_services';
import {Offer} from 'src/app/_models';
import { Title } from '@angular/platform-browser';
import {BackendFilterService} from '../../_filters/backend-filter.service';
import {PaginationService} from '../../_services/pagination.service';
import {SortService} from '../../_services/sort.service';
import {MultiSelectionService} from '../../_services/multi-selection.service';
import {QueryParamService} from '../../_filters/query-param.service';
import {
    FromTrashFilterConfig,
    SearchFilterConfig
} from '../../_filters/generic-filter/sample-filter-configs';
import {PaginatedTranslatedResponse} from '../../_interfaces/PaginatedApiResponse';
import {MatTable} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {BackendPaginationService} from '../../_services/backend-pagination.service';
import {MultiRequestService} from '../../_services/multi-request.service';
import {AlertService} from '../../_primitive_services';
import {BackendParamService} from '../../_filters/backend-param.service';
import {DateService} from '../../_primitive_services';
import {environment} from '../../../environments/environment';


@Component({
    selector: 'app-offer',
    templateUrl: './offer-list.component.html',
    styleUrls: ['./offer-list.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService, SortService, MultiSelectionService,
        { provide: BackendParamService, useClass: QueryParamService },
        OfferService, MultiRequestService,
    ]
})
export class OfferListComponent implements OnInit {

    offers: Offer[] = [];
    columnsToDisplay = ['contractor', 'creationDate', 'number', 'actions'];
    dateService = DateService;

    initialSort: Sort = {active: 'offer_number', direction: 'asc'};

    @ViewChild(MatTable) matPositions: MatTable<any>;
    constructor(private offerService: OfferService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                public modalService: ModalService,
                public errorHandlerService: ErrorHandlerService,
                public permissionService: PermissionService,
                private filterService: BackendFilterService,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                public paginationService: PaginationService,
                public sortService: SortService,
                public multiSelectionService: MultiSelectionService,
                public queryParamService: BackendParamService,
    ) {
        this.titleService.setTitle('Oferty');

    }


    ngOnInit(): void {

        this.filterService.setFilterConfigs([
            new SearchFilterConfig(),
            new FromTrashFilterConfig(),
        ]);
        this.sortService.enableSorting(this.initialSort);
        this.paginationService.enablePagination();
        this.subscribeOnChanges();
        this.multiSelectionService.populateWithData(this.offers);

    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        const previouslyCheckedIds: Set<number> = new Set(this.offers.filter(offer => offer.checked).map(offer => offer.id));
        this.offerService.getAll(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<Offer>) => {
            this.errorHandler.showLoader(false);
            this.offers.length = 0;
            this.offers.push(...response.results);
            for (const offer of this.offers) {
                offer.checked = previouslyCheckedIds.has(offer.id);
            }
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
        });
    }


    getPDF(id: number): void {
        const urlAdress = `${environment.apiUrl}/offer/${id}/pdf`;
        window.open(urlAdress, '_blank');

    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe( () => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }


}
