import {MoneyService} from '../_primitive_services';

export interface IOfferPosition {
    id?: number;
    quantity: number;
    price: number;
    priceUnits: string;
    name: string;
    feature: string;

}

export class OfferPosition implements IOfferPosition{

    constructor(object?: IOfferPosition) {
        if (object) {
            this.id = object.id;
            this.quantity = object.quantity;
            this.price = object.price;
            this.priceUnits = object.priceUnits || '';
            this.name = object.name || '';
            this.feature = object.feature;
            OfferPosition.uniqueId += 1;
            this.uniqueId = OfferPosition.uniqueId;

        }
    }

    private static uniqueId = 0;
    id?: number;
    quantity: number;
    price: number;
    priceUnits: string;
    name: string;
    feature: string;
    uniqueId: number;


    getPriceZl(): number {
        return Math.floor(this.price / 100);
    }

    setPriceZl(valueStr: string): void {
        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldGr = this.getPriceGr();
            this.price = value * 100 + oldGr;
        }
    }


    getPriceGr(): number {
        return Math.floor(this.price % 100);
    }

    setPriceGr(valueStr: string): void {

        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldZl = this.getPriceZl();
            this.price = 100 * oldZl + value;
        }
    }

    getPriceStr(): string {
        return MoneyService.parseMoneyWithUnits(this.price || 0, this.priceUnits);
    }
}
