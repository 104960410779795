import { GuideState } from './guide-state';
import { DateService } from '../_primitive_services';
import { TranslatorService } from '../_services/translator.service';

export class EmployeeWithOperation {
    id: number;
    employeeName: string;
    employee: number;
    guideNumber: string;
    guide: number;
    name: string;
    lastState: GuideState;
    allGuideEmployeeStates: GuideState[];
    lastStateString: string;
    allGuideEmployeeStatesString: string;

    constructor(object?: EmployeeWithOperation) {
        if (object) {
            this.id = object.id;
            this.employeeName = object.employeeName;
            this.employee = object.employee;
            this.guideNumber = object.guideNumber;
            this.guide = object.guide;
            this.name = object.name;
            this.lastState = new GuideState(object.lastState);
            this.allGuideEmployeeStates = object.allGuideEmployeeStates.map(x => new GuideState(x));
            this.lastStateString = EmployeeWithOperation.guideStateToReadable(this.lastState);
            this.allGuideEmployeeStatesString = EmployeeWithOperation.guideStatesToReadable(this.allGuideEmployeeStates);
        }
    }

    private static guideStateToReadable(state: GuideState): string {
        return DateService.dateToString(state.start) + ' ' + TranslatorService.staticTranslate(state.state);
    }

    private static guideStatesToReadable(states: GuideState[]): string {
        let s = '';
        for (const state of states) {
            s += this.guideStateToReadable(state) + '\n';
        }
        return s;
    }

}
