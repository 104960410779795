<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@Contractors">Kontrahenci</h1>

            <span class="spacer"></span>
            <div class="actions">
                <a class="button-spacer mat-button-custom mat-add-button" i18n="@@ContractorAddButton" mat-button
                        routerLink="/contractor/create"
                        *ngIf="permissionService.checkPermission('perm_write_contractor')"><span
                        class="add-circle">+</span> Dodaj kontrahenta
                </a>
            </div>

        </mat-toolbar>

        <mat-table *ngIf="contractors" [dataSource]="contractors" class="mat-effects">

            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef class="mat-lp" i18n="@@IDContractor">ID</mat-header-cell>
                <mat-cell *matCellDef="let contractor" class="mat-lp"> {{contractor.id}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef i18n="@@NameContractor">Nazwa</mat-header-cell>
                <mat-cell *matCellDef="let contractor"> {{contractor.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef i18n="@@FirstNameContractor">Imię</mat-header-cell>
                <mat-cell *matCellDef="let contractor"> {{contractor.firstName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastName">
                <mat-header-cell *matHeaderCellDef i18n="@@LastNameContractor">Nazwisko</mat-header-cell>
                <mat-cell *matCellDef="let contractor"> {{contractor.lastName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef i18n="@@EmailContractor">E-mail</mat-header-cell>
                <mat-cell *matCellDef="let contractor"> {{contractor.email}} </mat-cell>
            </ng-container>

		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@Operations">Opcje</mat-header-cell>
			<mat-cell *matCellDef="let contractor" class="mat-operations">
				<a routerLink="/contractor/{{contractor.id}}/edit">
					<mat-icon #tooltip="matTooltip" *ngIf="permissionService.checkPermission('perm_write_contractor')"
							  matTooltip="Edycja kontrahenta"
							  i18n-matTooltip="@@ContractorEdit">edit
					</mat-icon>
				</a>
				<a routerLink="/contractor/{{contractor.id}}">
					<mat-icon #tooltip="matTooltip"
							  matTooltip="Szczegóły kontrahenta"
							  i18n-matTooltip="@@ContractorDetalis">list_alt
					</mat-icon>
				</a>
				<mat-icon (click)="modalService.deleteModalForm('/contractor', contractor)" #tooltip="matTooltip"
                          *ngIf="permissionService.checkPermission('perm_write_contractor')
                          && !queryParamService.isFilterActive('in_trash')"
						  i18n-matTooltip="@@ContractorRemove" matTooltip="Usunięcie kontrahenta">delete
				</mat-icon>
                <mat-icon (click)="modalService.restoreModalForm('/contractor', contractor)" i18n-matTooltip="@@ContractorRestore"
                          matTooltip="Przywrócenie kontrahenta"
                          *ngIf="permissionService.checkPermission('perm_write_contractor')
                          && queryParamService.isFilterActive('in_trash')">restore_from_trash
                </mat-icon>
			</mat-cell>
		</ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>

            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
