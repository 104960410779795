import { Invoice } from './invoice';
import {IOrderPosition, OrderPosition} from './order-position';
import { OrderSet } from './order-set';
import { Wz } from './wz';
import { DateService } from 'src/app/_primitive_services/date.service';
import { MoneyService } from '../_primitive_services';

export interface BackendUpdateOrder{
    contractor: number;
    creationDate: string;
    plannedEndDate: string;
    contractorOrderNumber: string;
    reclamation: boolean;
    invoices: Invoice[];
    positions: OrderPosition[];
    orderSets: OrderSet[];
    inner: boolean;
    includeInStatistics: boolean;
    includeInSchedule: boolean;
}

export interface IOrder {
    id: number;
    contractor: number;
    creationDate: string;
    plannedEndDate: string;
    contractorOrderNumber: string;
    reclamation: boolean;
    contractorName: string;
    progress: string;
    progressLimit: string;
    endDate: string;
    invoices: Invoice[];
    positions: IOrderPosition[];
    orderSets: OrderSet[];
    wzs: Wz[];
    inner: boolean;
    includeInStatistics: boolean;
    includeInSchedule: boolean;
}

export class Order {

    constructor(object?: IOrder) {
        if (object) {
            this.id = object.id;
            this.contractor = object.contractor;
            this.creationDate = Order.createDate(object.creationDate);
            this.plannedEndDate = Order.createDate(object.plannedEndDate);
            this.contractorOrderNumber = object.contractorOrderNumber;
            this.reclamation = object.reclamation;
            this.contractorName = object.contractorName;
            this.progress = object.progress;
            this.progressLimit = object.progressLimit;
            this.endDate = Order.createDate(object.endDate);
            this.invoices = object.invoices?.map(invoice => new Invoice(invoice));
            this.positions = object.positions?.map(position => new OrderPosition(position));
            this.orderSets = object.orderSets
                ?.map(orderSet => new OrderSet( this.positions.filter(pos => pos.orderSetId === orderSet.id), orderSet));
            this.wzs = object.wzs;
            this.includeInStatistics = object.includeInStatistics;
            this.includeInSchedule = object.includeInSchedule;
            this.innerOrder = object.inner;
        }
    }
    static modelName = 'Zamówienie';
    static path = 'order';
    id: number;
    contractor: number;
    creationDate: Date;
    plannedEndDate: Date;
    contractorOrderNumber: string;
    reclamation: boolean;
    description: string;
    contractorName: string;
    progress: string;
    progressLimit: string;
    endDate: Date;
    invoices: Invoice[];
    positions: OrderPosition[];
    orderSets: OrderSet[];
    wzs: Wz[];
    checked: boolean;
    innerOrder: boolean;
    includeInStatistics: boolean;
    includeInSchedule: boolean;


    private static createDate(date: string | null): Date {
        if (date) {
            return DateService.stringDateToDate(date);
        }
        return null;
    }

    convertToBackendOrder(): BackendUpdateOrder{
        return {
            contractor: this.contractor,
            creationDate: DateService.dateToStringDate(this.creationDate),
            plannedEndDate: DateService.dateToStringDate(this.plannedEndDate),
            contractorOrderNumber: this.contractorOrderNumber,
            reclamation: this.reclamation,
            invoices: this.invoices,
            positions: this.positions,
            orderSets: this.orderSets,
            inner: this.innerOrder,
            includeInStatistics: this.includeInStatistics,
            includeInSchedule: this.includeInSchedule,
        };
    }

    public isFinished(): boolean {
        return this.endDate !== null && this.endDate !== undefined;
    }

    getModelName(): string {
        return 'Zamówienie';
    }

    getPath(): string {
        return 'order';
    }

    getName(): number {
        return this.contractor;
    }

    getInvoicedSum(): string {
        let sum = 0;
        this.invoices.forEach((invoice) => {
            sum += invoice.price;
        });
        return MoneyService.parseMoneyWithUnits(sum, 'zł');
    }

    getPriceSum(): string {
        let sum = 0;
        this.orderSets.forEach(orderSet => {
            const positionsSum = orderSet.getPriceFromPositions();
            // console.log(positionsSum);
            sum += (positionsSum * orderSet.quantity);
            sum += orderSet.price * orderSet.quantity;
        });
        return MoneyService.parseMoneyWithUnits(sum, 'zł');
    }

    public clone(): Order {
        const order = new Order();
        order.id = this.id;
        order.contractor = this.contractor;
        order.creationDate = this.creationDate;
        order.plannedEndDate = this.plannedEndDate;
        order.contractorOrderNumber = this.contractorOrderNumber;
        order.reclamation = this.reclamation;
        order.description = this.description;
        order.contractorName = this.contractorName;
        order.progress = this.progress;
        order.progressLimit = this.progressLimit;
        order.endDate = this.endDate;
        order.invoices = this.invoices;
        order.positions = [...this.positions];
        order.orderSets = [...this.orderSets];
        order.wzs = this.wzs;
        order.checked = this.checked;
        order.innerOrder = this.innerOrder;
        order.includeInStatistics = this.includeInStatistics;
        order.includeInSchedule = this.includeInSchedule;
        return order;
    }
}
