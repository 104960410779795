<ng-select
    *ngIf="filterConfig.choices"
    [items]="filterConfig.choices"
    [(ngModel)]="selectedChoices"
    [multiple]="true"
    [closeOnSelect]="!filterConfig.multipleChoices"
    [hideSelected]="true"
    bindLabel="displayValue"
    bindValue="id"
    placeholder="{{filterConfig.title}}"
    (add)="addItem($event)"
    (remove)="removeItem($event)"
    (clear)="removeAllItems()"
>
    <ng-option *ngFor="let choice of filterConfig.choices" [value]="choice.id" >{{choice.id}}</ng-option>
    <ng-option [value]="'custom'">Custom</ng-option>
    <ng-template ng-label-tmp let-item="item">
        <span>{{ item.displayValue }}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <span>{{ item.displayValue }}</span>
    </ng-template>
</ng-select>
