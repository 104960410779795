import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import {AlertService} from '../../_primitive_services';
import {BackendErrorResponse} from '../../_interfaces/BackendErrorResponse';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-delete-modal',
    templateUrl: './restore-modal.component.html',
    styleUrls: ['./restore-modal.component.css']
})
export class RestoreModalComponent implements OnInit {
    @Input() model;
    @Input() redirect;

    constructor(public activeModal: NgbActiveModal,
                private alertService: AlertService,
                private router: Router,
                private route: ActivatedRoute,
                private http: HttpClient) {
    }

    ngOnInit(): void {}


    restoreModel(path, redirect, id): void {
        this.route.queryParams.pipe(take(1)).subscribe(params => {
            return this.http.post<any>(`${environment.apiUrl}/` + path + `/` + id + `/restore_from_trash/`, {}).subscribe(data => {
                this.activeModal.close();
                // this.router.navigate([redirect]);
                this.router.navigateByUrl('/home', {skipLocationChange: true}).then(() => {
                    this.router.navigate([redirect], {queryParams: params});
                });
                this.alertService.success(this.model.getModelName() + ': <strong>' + this.model.getName() + '</strong> został usunięty.');
            }, (error: BackendErrorResponse) => {
                this.activeModal.close();
                this.alertService.error(
                    this.model.getModelName() + ': <strong>' + this.model.getName() +
                    '</strong> nie został przywrócony. Błąd: ' + error.description);
            });
        });
    }

}
