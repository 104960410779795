import { Component, OnInit, ViewChild } from '@angular/core';
// import { FromTrashFilterConfig, NameFilterConfig } from '../../_filters/generic-filter/filter-config';
import { PaginationService } from '../../_services/pagination.service';
import { BackendFilterService } from '../../_filters/backend-filter.service';
import { BackendParamService } from '../../_filters/backend-param.service';
import { PaginatedTranslatedResponse } from '../../_interfaces/PaginatedApiResponse';
import { ErrorHandlerService, GuidesService, OfferService } from '../../_services';
import { Offer } from '../../_models';
import { Subject } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { FilterConfigStringInput } from '../../_filters/components/filter-string-input/filter-string-input.component';
import { FromTrashFilterConfig } from '../../_filters/generic-filter/sample-filter-configs';
import { BackendPaginationService } from '../../_services/backend-pagination.service';
import { InMemoryParamService } from '../../_filters/in-memory-param.service';
import { MultiRequestService } from '../../_services/multi-request.service';

@Component({
    selector: 'app-offer-search',
    templateUrl: './offer-search.component.html',
    styleUrls: ['./offer-search.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService,
        { provide: BackendParamService, useClass: InMemoryParamService },
        GuidesService, MultiRequestService,
    ]
})
export class OfferSearchComponent implements OnInit {
    offers: Offer[] = [];
    offerSubject: Subject<Offer> = new Subject();
    @ViewChild(MatTable) matPositions: MatTable<any>;

    columnsToDisplay = ['actions', 'number', 'contractor'];
    filterConfigs = [
        new FilterConfigStringInput('search', 'Wyszukaj po numerze, cesze lub nazwie'),
    ];

    constructor(public paginationService: PaginationService,
                public offerService: OfferService,
                private filterService: BackendFilterService,
                public queryParamService: BackendParamService,
                private errorHandler: ErrorHandlerService,
    ) {
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs(this.filterConfigs);
        this.paginationService.setPageSize(10);
        this.paginationService.enablePagination();
        this.subscribeOnChanges();
        this.queryParamService.setQueryParam('ordering', ['-year,-lp']);
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        this.offerService.getAll(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<Offer>) => {
            this.errorHandler.showLoader(false);
            this.offers.length = 0;
            this.offers.push(...response.results);
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
            console.log(this.offers);
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }

    selectOffer(offer: Offer): void {
        this.offerSubject.next(offer);
    }
}
