import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../_services';
import {BackendParamService} from '../../backend-param.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FilterConfig, FilterType} from '../../generic-filter/filter-config';
import {DisplayElement} from '../../generic-filter/display-element';

export class FilterConfigChoiceList extends FilterConfig {
    displayValueFieldName: string;
    backendValueFieldName: string;
    urlPath: string;
    filterParamName: string;
    choices: DisplayElement[];
    title: string;
    type: FilterType;
    multipleChoices: boolean;

    constructor(backendValueFieldName: string, displayValueFieldName: string, filterParamName: string, title: string,
                urlPath: string, multipleChoices: boolean) {
        super(title, FilterType.ChoiceList, filterParamName);
        this.displayValueFieldName = displayValueFieldName;
        this.backendValueFieldName = backendValueFieldName;
        this.urlPath = urlPath;
        this.choices = undefined;
        this.multipleChoices = multipleChoices;
    }
}

@Component({
    selector: 'app-filter-choice-list',
    templateUrl: './filter-choice-list.component.html',
    styleUrls: ['./filter-choice-list.component.css']
})
export class FilterChoiceListComponent implements OnInit, AfterViewInit {

    selectedChoices: string[] = [];
    @Input() filterConfig: FilterConfigChoiceList;

    constructor(
        public translator: TranslatorService,
        private queryParamService: BackendParamService,
        private http: HttpClient,
    ) { }

    ngOnInit(): void {
    }

    refreshFilterBasedOnParamMap(map: Map<string, Array<string>>): void{
        const filterNamesToCheck = [this.filterConfig.filterParamName];
        if (this.filterConfig.choices){
            for (const item of this.filterConfig.choices){
                if (item.filterParamOverride){
                    if (filterNamesToCheck.indexOf(item.filterParamOverride) === -1) {
                        filterNamesToCheck.push(item.filterParamOverride);
                    }
                }
            }
        }
        this.selectedChoices = [];
        for (const filterParamName of filterNamesToCheck) {
            if (map.has(filterParamName)) {
                const chosenIds = new Set(map.get(filterParamName));
                this.selectedChoices.push(...chosenIds);
            }
        }
    }

    ngAfterViewInit(): void{
        this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        this.queryParamService.subscribe(() => {
            this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        });
        if (this.filterConfig.urlPath){
            this.http.get<any[]>(`${environment.apiUrl}${this.filterConfig.urlPath}`).subscribe(backendItems => {
                if (this.filterConfig.choices === undefined){
                    this.filterConfig.choices = [];
                }
                this.filterConfig.choices.length = 0;
                for (const backendItem of backendItems){
                    this.filterConfig.choices.push({
                        id: backendItem[this.filterConfig.backendValueFieldName].toString(),
                        displayValue: backendItem[this.filterConfig.displayValueFieldName],
                        checked: false,
                        filterParamOverride: null
                    });
                }
            });
        }
    }


    removeAllItems(): void{
        const filtersToRemove: string[]  = [this.filterConfig.filterParamName];
        if (this.filterConfig.choices){
            for (const item of this.filterConfig.choices){
                if (item.filterParamOverride){
                    if (filtersToRemove.indexOf(item.filterParamOverride) === -1) {
                        filtersToRemove.push(item.filterParamOverride);
                    }
                }
            }
        }
        this.queryParamService.removeAllQueryParams(filtersToRemove);
    }

    removeItem(item: DisplayElement): void {
        this.queryParamService.popFromQueryParam(item.filterParamOverride || this.filterConfig.filterParamName,
            item.id);
    }

    addItem(item: DisplayElement): void {
        if (this.filterConfig.multipleChoices) {
            this.queryParamService.appendToQueryParam(item.filterParamOverride ||  this.filterConfig.filterParamName,
                item.id);
        }
        else {
            this.queryParamService.setQueryParam(item.filterParamOverride || this.filterConfig.filterParamName,
                [item.id]);
        }
    }
}
