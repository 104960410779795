import {GuideOperation, IGuideOperation} from './guide-operation';
import {DateService} from '../_primitive_services';
import {PositionsWithoutGuides} from './positions-without-guides';


export interface IGuideInFilter {
    barcode: string;
    number: string;
    id: number;
}

export class GuideInList{
    static modelName = 'Przewodnik';
    static path = 'guide';
    id: number;
    number: string;
    creationDate: Date;
    currentStateStart: Date;
    barcode: string;
    orderPosition: number;
    currentOperation: number;
    currentState: string;
    progress: number;
    progressLimit: number;
    realTimeInSeconds: string;
    declaredTimeInSeconds: string;
    contractorOrderNumber: string;
    contractorName: string;
    realCost: string;
    declaredCost: string;
    name: string;
    feature: string;
    order: number;
    currentOperationQualityControlGrade: string;
    quantity: number;

    // used in list to know whether the guide os checked
    checked: boolean;

    constructor(object?: any) {
        if (object){
            this.id = object.id;
            this.number = object.number;
            this.creationDate = DateService.stringDateToDate(object.creationDate);
            this.currentStateStart = DateService.stringDateToDate(object.currentStateStart);
            this.barcode = object.barcode;
            this.orderPosition = object.orderPosition;
            this.currentOperation = object.currentOperation;
            this.currentState = object.currentState;
            this.progress = object.progress;
            this.progressLimit = object.progressLimit;
            this.realTimeInSeconds = object.realTimeInSeconds;
            this.declaredTimeInSeconds = object.declaredTimeInSeconds;
            this.contractorOrderNumber = object.contractorOrderNumber;
            this.contractorName = object.contractorName;
            this.realCost = object.realCost;
            this.declaredCost = object.declaredCost;
            this.name = object.name;
            this.feature = object.feature;
            this.order = object.order;
            this.currentOperationQualityControlGrade = object.currentOperationQualityControlGrade;
            this.checked = false;
            this.quantity = object.quantity;
        }
    }

    getModelName(): string {
        return 'Przewodnik';
    }

    getPath(): string {
        return 'guide';
    }
    getName(): string {
        return this.number;
    }

    percentageProgress(): number {
        return Math.floor(this.progress  / this.progressLimit  * 100);
    }

}


export interface IGuide {
    id: number;
    number: string;
    creationDate: string;
    barcode: string;
    orderPosition: number;
    currentOperation: number;
    operations: IGuideOperation[];
    currentState: string;
    progress: number;
    progressLimit: number;
    realTimeInSeconds: string;
    declaredTimeInSeconds: string;
    contractorOrderNumber: string;
    contractorName: string;
    realCost: number;
    declaredCost: number;
    name: string;
    feature: string;
    order: number;
    contractor: number;
    currentOperationMachineGroup: number;
    currentOperationMachineGroupCode: string;
    currentOperationQualityControlGrade: string;
    isCurrent: boolean;
    quantity: number;
}

export interface IWriteGuide {
    creationDate: string;
    orderPosition: number;
    operations: IGuideOperation[];
}

export class Guide {

    static modelName = 'Przewodnik';
    static path = 'guide';

    id: number;
    number: string;
    creationDate: Date;
    barcode: string;
    orderPosition: number;
    currentOperation: number;
    operations: GuideOperation[] = [];
    currentState: string;
    progress: number;
    progressLimit: number;
    realTimeInSeconds: string;
    declaredTimeInSeconds: string;
    contractorOrderNumber: string;
    contractorName: string;
    realCost: number;
    declaredCost: number;
    name: string;
    feature: string;
    order: number;
    contractor: number;
    currentOperationMachineGroup: number;
    currentOperationMachineGroupCode: string;
    currentOperationQualityControlGrade: string;
    isCurrent: boolean;
    quantity: number;


    constructor(id: number, guideNumber: string, creationDate: string, barcode: string, orderPosition: number,
                currentOperation: number, operations: IGuideOperation[], currentState: string, progress: number,
                progressLimit: number, realTimeInSeconds: string, declaredTimeInSeconds: string,
                contractorOrderNumber: string, contractorName: string, realCost: number, declaredCost: number,
                name: string, feature: string, order: number, contractor: number, currentOperationMachineGroup: number,
                currentOperationMachineGroupCode: string, currentOperationQualityControlGrade: string, isCurrent: boolean,
                quantity: number, ) {
        this.id = id;
        this.number = guideNumber;
        this.creationDate = DateService.stringDateToDateSkipTime(creationDate);
        this.barcode = barcode;
        this.orderPosition = orderPosition;
        this.currentOperation = currentOperation;
        const sortedOperations = operations.sort(
            (a, b) => a.lp < b.lp ? -1 : a.lp > b.lp ? 1 : 0);
        this.operations = sortedOperations?.map(guideOperation => new GuideOperation(guideOperation));
        this.currentState = currentState;
        this.progress = progress;
        this.progressLimit = progressLimit;
        this.realTimeInSeconds = realTimeInSeconds;
        this.declaredTimeInSeconds = declaredTimeInSeconds;
        this.contractorOrderNumber = contractorOrderNumber;
        this.contractorName = contractorName;
        this.realCost = realCost;
        this.declaredCost = declaredCost;
        this.name = name;
        this.feature = feature;
        this.order = order;
        this.contractor = contractor;
        this.currentOperationMachineGroup = currentOperationMachineGroup;
        this.currentOperationMachineGroupCode = currentOperationMachineGroupCode;
        this.currentOperationQualityControlGrade = currentOperationQualityControlGrade;
        this.isCurrent = isCurrent;
        this.quantity = quantity;
    }

    getModelName(): string {
        return Guide.modelName;
    }

    getPath(): string {
        return Guide.path;
    }

    getName(): string {
        return this.number;
    }

    percentageProgress(): number {
        return Math.floor(this.progress  / this.progressLimit  * 100);
    }

    private getCurrentOperation(): GuideOperation {
        for (const operation of this.operations){
            if (this.currentOperation === operation.id ){
                return operation;
            }
        }
        return null;
    }

    public getCurrentOperationProductionTime(): number {
        let s = 0;
        const operation = this.getCurrentOperation();
        for (const state of operation.authors){
            if (state.state === 'production') {
                if (state.end) {
                    s += state.end.getTime() - state.start.getTime();
                }
                else {
                    s += (new Date()).getTime() - state.start.getTime();
                }
            }
        }
        return s / 1000;
    }

}

export class GuideFactory {

    public static fromOrderPosition(o: PositionsWithoutGuides): Guide {
        return new Guide(
            undefined,
            undefined,
            DateService.dateToStringDate(new Date()),
            undefined,
            o.id,
            undefined,
            [],
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            o.contractorOrderNumber,
            o.contractorName,
            undefined,
            o.declaredCost,
            o.name,
            o.feature,
            o.order,
            o.contractor,
            undefined,
            undefined,
            'not_yet_reviewed',
            true,
            1,
        );
    }

    public static fromBackend(g: IGuide): Guide {
        return new Guide(g.id, g.number, g.creationDate, g.barcode, g.orderPosition, g.currentOperation, g.operations,
            g.currentState, g.progress, g.progressLimit, g.realTimeInSeconds, g.declaredTimeInSeconds,
            g.contractorOrderNumber, g.contractorName, g.realCost, g.declaredCost, g.name, g.feature, g.order,
            g.contractor, g.currentOperationMachineGroup, g.currentOperationMachineGroupCode,
            g.currentOperationQualityControlGrade, g.isCurrent, g.quantity,
        );
    }

    public static toBackend(g: Guide): IWriteGuide {
        return {
            creationDate: DateService.dateToStringIso8601(g.creationDate),
            operations: g.operations,
            orderPosition: g.orderPosition,
        };
    }
}

export class IGuideAction {
    action: string;
    canExecute: boolean;
    reason: string;

}

export class GuideAction implements IGuideAction{
    action: string;
    canExecute: boolean;
    reason: string;
    visible: boolean;

     constructor(object: IGuideAction) {
        this.action = object.action;
        this.canExecute = object.canExecute;
        this.reason = object.reason;
        this.visible = true;
     }

}

export class WriteGuideAction {
    action: string;
    guideList: number[] = [];

    constructor(action: string, guideList: number[]) {
        this.action = action;
        this.guideList = guideList;
    }
}
