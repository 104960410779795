import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ContractorService } from '../_services';

@Injectable({
    providedIn: 'root'
})
export class ContractorResolverService implements Resolve<any> {

    constructor(private contractorService: ContractorService) {
    }

    resolve(): any {
        return this.contractorService.getAll(new Map());
    }
}
