import { Component, OnInit } from '@angular/core';
import {Wz} from '../../_models';
import {WzService} from '../../_services/wz.service';
import {ActivatedRoute} from '@angular/router';
import { ErrorHandlerService, ModalService, TranslatorService } from '../../_services';
import {DateService, MoneyService} from '../../_primitive_services';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-wz-details',
  templateUrl: './wz-details.component.html',
  styleUrls: ['./wz-details.component.css']
})
export class WzDetailsComponent implements OnInit {

  wz: Wz;
  dateService = DateService;
  moneyService = MoneyService;
  pageTitle = 'wz_details';
  columnsToDisplayWzSetPositions = ['lp', 'setName', 'quantity', 'price', 'value', ];
  columnsToDisplayWzPositions = ['lp', 'detail', 'quantity', 'price', 'value', ];

  constructor(
      public wzService: WzService,
      public activatedRoute: ActivatedRoute,
      public modalService: ModalService,
      public translatorService: TranslatorService,
      public errorHandler: ErrorHandlerService,
      public titleService: Title,
  ) {
        this.pageTitle = translatorService.translate(this.pageTitle);
        titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
      this.errorHandler.showLoader(true);
      this.wzService.getWz(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(wz => {
          this.wz = wz;
          this.errorHandler.showLoader(false);
      });
  }

    getPDF(id: number): void {
        const urlAdress = `${environment.apiUrl}/wz/${id}/pdf`;
        window.open(urlAdress, '_blank');
    }
}
