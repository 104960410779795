import { Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RefreshService {

    constructor() {
    }
    private refreshSystem: Subscription = null;
    private REFRESH_SYSTEM_TIME_IN_MINUTES = 60;
    private refreshSystemCountdown = timer(this.REFRESH_SYSTEM_TIME_IN_MINUTES * 60 * 1000,
        this.REFRESH_SYSTEM_TIME_IN_MINUTES * 60 * 1000);

    private enabled = true;
    private scheduledUpdate = false;

    private static reload(): void {
       window.location.reload(true);
    }

    public enable(): void{
        this.enabled = true;
        if (this.scheduledUpdate){
            RefreshService.reload();
        }
    }

    public disable(): void {
        this.enabled = false;
    }

    refreshSystemTimer(): void{
        this.refreshSystem = this.refreshSystemCountdown.subscribe(_ => {
            if (this.enabled){
                RefreshService.reload();
            } else {
                this.scheduledUpdate = true;
            }
        });
    }
}
