import { Component, OnInit, ViewChild } from '@angular/core';
import {
    EmployeesWithOperationsService,
    ErrorHandlerService,
    TranslatorService
} from 'src/app/_services';
import { EmployeeWithOperation} from 'src/app/_models';
import { Title } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';
import {MatTable} from '@angular/material/table';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {PaginationService} from '../_services/pagination.service';
import {BackendPaginationService} from '../_services/backend-pagination.service';
import {SortService} from '../_services/sort.service';
import {MultiSelectionService} from '../_services/multi-selection.service';
import {BackendParamService} from '../_filters/backend-param.service';
import {QueryParamService} from '../_filters/query-param.service';
import {MultiRequestService} from '../_services/multi-request.service';
import {
    EmployeeFilterConfig,
    StatePauseProductionFilterConfig
} from '../_filters/generic-filter/sample-filter-configs';
import {PaginatedTranslatedResponse} from '../_interfaces/PaginatedApiResponse';

@Component({
    selector: 'app-employees-with-operations',
    templateUrl: './employees-with-operations.component.html',
    styleUrls: ['./employees-with-operations.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService, SortService, MultiSelectionService,
        {provide: BackendParamService, useClass: QueryParamService},
        MultiRequestService, ]
})
export class EmployeesWithOperationsComponent implements OnInit {

    columnsToDisplay = ['name', 'guide_number', 'operation', 'actions'];
    filtersToDisplay = ['user', 'operation-state'];
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    employeesWithOperations: EmployeeWithOperation[] = [];

    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(
        private employeesWithOperationsService: EmployeesWithOperationsService,
        private titleService: Title,
        private filterService: BackendFilterService,
        public errorHandler: ErrorHandlerService,
        private translatorService: TranslatorService,
        private queryParamService: BackendParamService,
    ) {
        this.titleService.setTitle(translatorService.translate('employees_with_guides'));
    }

    ngOnInit(): void {

        this.filterService.setFilterConfigs([
            new StatePauseProductionFilterConfig('state'),
            new EmployeeFilterConfig('employee', 'Użytkownicy'),
        ]);
        this.subscribeOnChanges();

    }

    private refreshData(): void {
        this.errorHandler.showLoader(true);
        this.employeesWithOperationsService.getAll(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<EmployeeWithOperation>) => {
            this.errorHandler.showLoader(false);
            this.employeesWithOperations.length = 0;
            this.employeesWithOperations.push(...response.results);
            this.matPositions.renderRows();
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }
}

