export class QualityControl{
    static path = 'quality_control';

    id: number;
    guideNumber: string;
    guide: number;
    operation: number;
    operationName: string;
    guideName: string;
    grade: string;
    note: string;
    author?: number;
    authorName: string;
    feature: string;
    operationTime: number;

    checked: boolean;

    constructor(object?: QualityControl) {
        if (object) {
            this.id = object.id;
            this.guideNumber = object.guideNumber;
            this.guide = object.guide;
            this.operation = object.operation;
            this.operationName = object.operationName;
            this.guideName = object.guideName;
            this.grade = object.grade;
            this.note = object.note;
            this.author = object.author;
            this.authorName = object.authorName;
            this.feature = object.feature;
            this.checked = false;
            this.operationTime = object.operationTime;
        }
    }

    getModelName(): string {
        return 'kontrola jakości';
    }

    getPath(): string {
        return QualityControl.path;
    }

}
