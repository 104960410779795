import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../_services';
import {BackendParamService} from '../../backend-param.service';
import {DateService} from '../../../_primitive_services';
import {FilterConfig, FilterType} from '../../generic-filter/filter-config';

export class FilterConfigDateRange extends FilterConfig {
    filterParamName: string;
    title: string;
    type: FilterType;

    constructor(filterParamName: string, title: string) {
        super(title, FilterType.DatePicker, filterParamName);
    }

    public getAllFilterParamNames(): string[] {
        return [
            this.filterParamName + '_after',
            this.filterParamName + '_before',
        ];
    }
}

@Component({
    selector: 'app-filter-date-range',
    templateUrl: './filter-date-range.component.html',
    styleUrls: ['./filter-date-range.component.css']
})
export class FilterDateRangeComponent implements OnInit, AfterViewInit {


    @Input() filterConfig: FilterConfigDateRange;

    public endDate: Date;
    public startDate: Date;

    constructor(
        public translator: TranslatorService,
        private queryParamService: BackendParamService
    ) { }

    ngOnInit(): void {
    }

    refreshFilterBasedOnParamMap(map: Map<string, Array<string>>): void{
        if (map.has(this.filterConfig.filterParamName)){
        }
        else {
            if (map.has(this.filterConfig.filterParamName + '_after')){
                this.startDate = DateService.stringDateToDateSkipTime(map.get(this.filterConfig.filterParamName + '_after')[0]);
            }
            else {
                this.startDate = null;
            }
            if (map.has(this.filterConfig.filterParamName + '_before')){
                this.endDate = DateService.stringDateToDate(map.get(this.filterConfig.filterParamName + '_before')[0]);
            }
            else {
                this.endDate = null;
            }
        }
    }

    ngAfterViewInit(): void{
        this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        this.queryParamService.subscribe(() => {
            this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        });
    }

    setEndDate(date: Date): void {
        const dateStr = DateService.dateToStringDate(date);
        this.queryParamService.setQueryParam(this.filterConfig.filterParamName + '_before',
        [dateStr], false, true);
    }

    setStartDate(date: Date): void {
        const dateStr = DateService.dateToStringDate(date);
        this.queryParamService.setQueryParam(this.filterConfig.filterParamName + '_after',
            [dateStr], false);
    }

    clearDates(): void {
        const queryParams = [
            this.filterConfig.filterParamName + '_after', this.filterConfig.filterParamName + '_before'];
        this.queryParamService.removeAllQueryParams(queryParams);
    }

}
