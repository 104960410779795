import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../_primitive_services';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {BackendErrorResponse} from '../../_interfaces/BackendErrorResponse';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-close-modal',
    templateUrl: './close-modal.component.html',
    styleUrls: ['./close-modal.component.css']
})
export class CloseModalComponent implements OnInit {
    @Input() model;

    constructor(public activeModal: NgbActiveModal,
                private alertService: AlertService,
                private router: Router,
                private route: ActivatedRoute,
                private http: HttpClient) {
    }

    ngOnInit(): void {
    }

    closeModel(): void {
        this.route.queryParams.pipe(take(1)).subscribe(params => {
            console.log(this.model);
            return this.http.post<any>(
                `${environment.apiUrl}/` + this.model.getPath() + `/` + this.model.id + `/finish`, {}).subscribe(data => {
                console.log(data);
                this.activeModal.close();
                this.router.navigateByUrl('/home', {skipLocationChange: true}).then(() => {
                    this.router.navigate(['order'], {queryParams: params});
                });
                console.log(data);
                this.alertService.success(
                    this.model.getModelName() + ': <strong>' + this.model.getName() + '</strong> zostało zakończone.');
            }, (error: BackendErrorResponse) => {
                console.log(error);
                this.activeModal.close();
                this.alertService.error(
                    this.model.getModelName() + ': <strong>' + this.model.getName() +
                    '</strong> nie zostało zakończone. Błąd: ' + error.description);
            });
        });
    }
}
