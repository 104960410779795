import { WzSetPositions } from './wz-set-positions';
import { OrderPosition } from './order-position';

export interface IOrderSet {
    id: number;
    name: string;
    quantity: number;
    clientGeneratedId: string;
    dummy: boolean;
    wzSetPositions: WzSetPositions[];
    positions: OrderPosition[];
    tableGeneratedId: number;
    price: number;
}

export class OrderSet {
    static lastId = 0;
    id: number;
    name: string;
    quantity: number;
    clientGeneratedId: string;
    dummy: boolean;
    wzSetPositions: WzSetPositions[];
    positions: OrderPosition[];
    tableGeneratedId: number;
    price: number;
    constructor(positions: OrderPosition[], object?: IOrderSet) {
        if (object) {
            this.id = object.id;
            this.name = object.name;
            this.quantity = object.quantity;
            this.clientGeneratedId = object.clientGeneratedId;
            this.dummy = object.dummy;
            this.wzSetPositions = object.wzSetPositions;
            this.positions = positions;
            OrderSet.lastId += 1;
            this.tableGeneratedId = OrderSet.lastId;
            this.price = object.price || 0;
        }
    }

    setPriceZl(valueStr: string): void {
        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldGr = this.getPriceGr();
            this.price = value * 100 + oldGr;
        }
    }

    setPriceGr(valueStr: string): void {
        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldZl = this.getPriceZl();
            this.price = 100 * oldZl + value;
        }
    }

    getNameForWz(): string {
        if (this.dummy && this.positions.length > 0){
            return this.positions[0].feature;
        } else {
            return this.name;
        }
    }

    getPriceForWz(): number{
        if (this.dummy && this.positions.length > 0){
            return this.positions[0].price;
        } else {
            return this.getPriceFromPositions() + this.price;
        }
    }

    getQuantityForWz(): number{
        if (this.dummy && this.positions.length > 0){
            return this.positions[0].quantity;
        } else {
            return this.quantity;
        }
    }

    getPriceGr(): number {
        return Math.floor(this.price % 100);
    }

    getPriceZl(): number {
        return Math.floor(this.price / 100);
    }

    getPriceFromPositions(): number {
        let sum = 0;
        this.positions.forEach(position => {
            if (position.quantity === undefined || position.quantity === null) {
                sum += position.price;
            } else {
                sum += position.quantity * position.price;
            }
        });
        return sum;
    }
}
