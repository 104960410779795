import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Guide, QualityControl} from 'src/app/_models/';
import { Observable } from 'rxjs';
import { ApiTranslatorService } from './api-translator.service';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import {PermissionService} from './permission.service';
import {PaginatedTranslatedResponse} from '../_interfaces/PaginatedApiResponse';
import {BackendPaginationService} from './backend-pagination.service';

@Injectable({
    providedIn: 'root'
})
export class QualityControlService {

    constructor(private http: HttpClient,
                private apiTranslator: ApiTranslatorService,
                private httpService: HttpService,
                private permissionService: PermissionService,
                private backendPaginationService: BackendPaginationService,
                ) {
    }

    getAll(params?: Map<string, string | number | Array<string | number>>):
        Observable<PaginatedTranslatedResponse<QualityControl>> {
        return this.backendPaginationService.getAll<QualityControl>('quality_control',
                data => new QualityControl(data), params);
    }

    getDetails(id): Observable<QualityControl> {
        return this.http.get<QualityControl>(`${environment.apiUrl}/quality_control/` + id + `/`).pipe(map(x => new QualityControl(x)));
    }

    updateQualityControl(qualityControl): any {
        delete qualityControl.guideNumber;
        delete qualityControl.guide;
        delete qualityControl.operation;
        delete qualityControl.operationName;
        delete qualityControl.guideName;
        delete qualityControl.authorName;
        delete qualityControl.feature;
        if (!this.permissionService.checkPermission('perm_write_quality_control_author')) {
            delete qualityControl.author;
        }
        qualityControl = this.apiTranslator.modelToSnakeCase(qualityControl);
        return this.http.put<QualityControl>(`${environment.apiUrl}/quality_control/` + qualityControl.id + `/`, qualityControl);
    }

    batchUpdate(qualityControls: QualityControl[]): Observable<QualityControl[]> {
        return this.http.put <QualityControl[]>(`${environment.apiUrl}/quality_control/batch_update/`, qualityControls);
    }

}
