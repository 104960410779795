<div class="page">
    <div class="sidebar">
		<app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UsersPage">Raport</h1>
            <span class="spacer"></span>
			<a class="button-spacer mat-button-custom" mat-button *ngIf="showButtonPdf" target="_blank"
			   href="{{environment.apiUrl}}/rcp/month_summary/pdf/?year={{year}}&month={{month}}">
				Generuj PDF
			</a>
			<a class="button-spacer mat-button-custom" mat-button target="_blank"
			   href="{{environment.apiUrl}}/rcp/month_summary/pdf/?year={{year}}&month={{month}}&empty_history=true">
				Generuj listę obecności
			</a>
        </mat-toolbar>

        <mat-table [dataSource]="reports" class="mat-effects">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef class="mat-lp" i18n="@@Reportid">ID</mat-header-cell>
                <mat-cell *matCellDef="let report" class="mat-lp"> {{report.id}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef i18n="@@Reportname">Nazwa</mat-header-cell>
                <mat-cell *matCellDef="let report"> {{report.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="workTime">
                <mat-header-cell *matHeaderCellDef i18n="@@ReportworkTime">Czas pracy</mat-header-cell>
                <mat-cell *matCellDef="let report">
                    {{dateService.getTimeFromSec(dateService.roundDownSecondsTo15Min(report.workTime)) }} </mat-cell>
            </ng-container>

			<ng-container matColumnDef="guideWorkTime">
				<mat-header-cell *matHeaderCellDef i18n="@@ReportguideWorkTime">Przewodnikowy czas pracy</mat-header-cell>
			    <mat-cell *matCellDef="let report">
                    {{dateService.getTimeFromSec(dateService.roundDownSecondsTo15Min(report.guideWorkTime))}} </mat-cell>
			</ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;" [hidden]="row.workTime === 0"></mat-row>
        </mat-table>

        <!--Hidden ukrywa wiersz gdy czas pracy jest równy 0-->
    </div>
</div>
