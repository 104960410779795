export class PaginatedList {
    count: number;
    pageCount: number;
    next: string;
    previous: string;
    results: any[];

    constructor(object?: PaginatedList) {
        this.count = object.count;
        this.pageCount = object.pageCount;
        this.next = object.next;
        this.previous =  object.previous;
        this.results = object.results;
    }

}
