export enum FilterType {
    Checkbox = 1,
    ChoiceList,
    StringInput,
    DatePicker,
    DateMonthPicker
}

export class FilterConfig {
    title: string;
    type: FilterType;
    filterParamName: string;

    constructor(title: string, type: FilterType, filterParamName: string) {
        this.title = title;
        this.type = type;
        this.filterParamName = filterParamName;
    }

    public getAllFilterParamNames(): string[] {
        return [this.filterParamName];
    }
}
