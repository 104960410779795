<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">Historia Aktywności</h1>
            <span class="spacer"></span>
        </mat-toolbar>

        <pagination-controls id="main_list_pagination"
                             (pageChange)="paginationService.pageChanged($event)"
                             (pageBoundsCorrection)="paginationService.pageChanged($event)"
                             maxSize="13"
                             directionLinks="true"
                             responsive="true"
                             previousLabel="Wstecz"
                             nextLabel="Dalej"
        >
        </pagination-controls>
        <mat-table [dataSource]="actionHistoryList  | paginate: {
              id: 'main_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }  | paginate: {
              id: 'bottom_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }" *ngIf="actionHistoryList" class="mat-effects" matSort (matSortChange)="sortService.sortData($event)"
                   matSortActive="{{initialSort.active}}" matSortDirection="{{initialSort.direction}}">

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef class="col-sm-2">Użytkownik</mat-header-cell>
                <mat-cell *matCellDef="let action" class="col-sm-2"> <a href="/user/{{action.author}}">
                    {{action.authorName }}</a> </mat-cell>
            </ng-container>


            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="col-sm-2">Operacja</mat-header-cell>
                <mat-cell *matCellDef="let action" class="col-sm-2">{{translator.translateAction(action.action)}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="time">Data</mat-header-cell>
                <mat-cell *matCellDef="let action"> {{ dateService.dateToString(action.time) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="success">
                <mat-header-cell *matHeaderCellDef>Sukces</mat-header-cell>
                <mat-cell *matCellDef="let action" matTooltip="{{action.errorInfo}}">
                    <mat-icon>
                        {{isSuccessIcon(action)}}
                    </mat-icon></mat-cell>
            </ng-container>

            <ng-container matColumnDef="object">
                <mat-header-cell *matHeaderCellDef>Obiekt</mat-header-cell>
                <mat-cell *matCellDef="let action">

                    <div *ngIf="!canDisplayLink(action)">
                        {{action.objectRepr}}
                    </div>
                    <div *ngIf="canDisplayLink(action)">
                        <a href="/{{contentTypeLinks.get(action.contentTypeModel)}}/{{action.objectId}}">
                            {{action.objectRepr}}
                        </a>
                    </div>
                </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
        <br>
        <br>
        <div class="pagination_and_legend">
            <pagination-controls id="bottom_list_pagination"
                                 (pageChange)="paginationService.pageChanged($event)"
                                 (pageBoundsCorrection)="paginationService.pageChanged($event)"
                                 maxSize="13"
                                 directionLinks="true"
                                 responsive="true"
                                 previousLabel="Wstecz"
                                 nextLabel="Dalej"
            >
            </pagination-controls>
            <div id="bottom"></div>
        </div>
    </div>
</div>
