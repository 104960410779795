<div class="container text-right">
	<div class="close-modal" (click)="modalService.close()">&times;</div>
</div>
<div class="date"><strong>{{ dayToEdit }}</strong></div>
<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="container">

	<mat-table #table [dataSource]="workTimesArray.controls" class="mat-effects" formArrayName="workTimes">

		<ng-container matColumnDef="start">
			<mat-header-cell *matHeaderCellDef>Godzina rozpoczęcia pracy</mat-header-cell>
			<mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
				<input type="time" class="form-control" placeholder="Godzina rozpoczęcia pracy"
					   formControlName="start">
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="end">
			<mat-header-cell *matHeaderCellDef>Godzina zakończenia pracy</mat-header-cell>
			<mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
				<input type="time" class="form-control" placeholder="Godzina zakończenia pracy"
					   (input)="onInput(row, $event)"
					   formControlName="end">
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef>
				<mat-icon matTooltip="Dodaj wiersz" class="add-circle"
						  (click)="addWorkTime()">add_circle
				</mat-icon>
			</mat-header-cell>

			<mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
				<mat-icon matTooltip="Usuń wiersz"
						  (click)="removeWorkTime(index)">
					remove_circle
				</mat-icon>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="['start', 'end', 'actions']" class="mat-header-row-inputs"></mat-header-row>
		<mat-row *matRowDef="let row; columns: ['start', 'end', 'actions'];"></mat-row>

	</mat-table>

	<div class="form-group align-items-center row">
		<div class="col-sm-12 text-right">
			<button class="button-spacer mat-button-custom mat-button-big" mat-button>
				Zapisz
			</button>
		</div>
	</div>

</form>
