<nav class="navbar navbar-expand" [class] = "{hidden: hiddenNavbarService.isHidden()}"
     *ngIf="authenticationService.isLoggedIn()">
    <div class="navbar-logo">
		<a routerLink="/home">
        	<img src="assets/img/logo.jfif" alt="SkrawPol"/>
		</a>
    </div>
    <div class="navbar-nav">
        <ul class="menu">

            <li routerLinkActive="active">
                <a class="nav-item nav-link">Produkcja</a>
                <ul>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link" routerLinkActive="active" routerLink="/guide"
                           *ngIf="permissionService.checkPermission('perm_read_guide')"
                        >
                            Przewodniki</a>
                    </li>

                    <li routerLinkActive="active">
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_quality_control')"
                           routerLinkActive="active" routerLink="/quality-control"
                           [queryParams]="{waiting_for_quality_control: true}"
                           i18n="@@QualityControl">Kontrola jakości</a>
                    </li>

                    <li>
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_rcp_employees_with_operations')"
                           routerLinkActive="active" routerLink="/employees-with-operations"
                           i18n="@@EmployeesWithOperations">Pracownicy z przewodnikami</a>
                    </li>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link" routerLinkActive="active" routerLink="/desktop" i18n="@@DesktopApp"
                           *ngIf="permissionService.checkPermission('perm_write_desktop')"
                        >
                            Aplikacja desktopowa</a>
                    </li>
                </ul>
            </li>


            <li routerLinkActive="active">
                <a class="nav-item nav-link" routerLinkActive="active" routerLink="/order" i18n="@@IndividualReport"
                   [class.disabled]="permissionService.checkPermission('perm_read_order') ? null : true"

                >Zamówienia</a>
                <ul>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link" routerLinkActive="active" routerLink="/ordersWithoutGuides" i18n="@@Offer"
                           *ngIf="permissionService.checkPermission('perm_read_order')"
                        >Pozycje bez przewodników</a>
                    </li>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link" routerLinkActive="active" routerLink="/ordersTv" i18n="@@Offer"
                           *ngIf="permissionService.checkPermission('perm_read_order')"
                        >Zamówienia - telewizor</a>
                    </li>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link" routerLinkActive="active" routerLink="/order/schedule" i18n="@@Offer"
                           *ngIf="permissionService.checkPermission('perm_read_order_schedule')"
                        >Harmonogram</a>
                    </li>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link" routerLinkActive="active" routerLink="/wz" i18n="@@Wz"
                           *ngIf="permissionService.checkPermission('perm_read_wz')"
                        >Wz</a>
                    </li>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link" *ngIf="permissionService.checkPermission('perm_read_offer')"
                           routerLinkActive="active" routerLink="/offer" i18n="@@Offer">Oferty</a>
                    </li>
                </ul>
            </li>

            <li routerLinkActive="active">
                <a class="nav-item nav-link">Statystyki</a>
                <ul>
                    <li>
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_order_statistics')"
                           routerLinkActive="active" routerLink="/order-statistics">Statystyki zamówień</a>
                    </li>
                    <li>
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_order_contractor_statistics')"
                           routerLinkActive="active" routerLink="/contractor-statistics">Aktywne zamówienia kontrahentów</a>
                    </li>
                    <li>
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_order_deadline')"
                           routerLinkActive="active" routerLink="/order-deadline">Terminy</a>
                    </li>

                    <li>
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_order_deadline')"
                           routerLinkActive="active" routerLink="/order-deadline-summary">
                            Terminy - podsumowanie</a>
                    </li>

                    <li>
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_order_statistics')"
                           routerLinkActive="active" routerLink="/order-yearly-chart">
                            Wykresy</a>
                    </li>

                </ul>
            </li>

            <li routerLinkActive="active">
                <a class="nav-item nav-link">RCP</a>
                <ul>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_rcp_month_summary')"
                           routerLinkActive="active" routerLink="/report" i18n="@@Report">Raport</a>
                    </li>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_rcp_single_employee')"
                           routerLinkActive="active" routerLink="/individual-report" i18n="@@IndividualReport">Indywidualny
                            raport</a>
                    </li>

                </ul>
            </li>


            <li routerLinkActive="active">
                <a class="nav-item nav-link">Administracja</a>
                <ul>
                    <li>
                        <a class="nav-item nav-link" *ngIf="permissionService.checkPermission('perm_read_employee')"
                           routerLinkActive="active" routerLink="/user" i18n="@@Users">Użytkownicy</a>
                    </li>
                    <li routerLinkActive="active">
                        <a class="nav-item nav-link" *ngIf="permissionService.checkPermission('perm_read_contractor')"
                           routerLinkActive="active" routerLink="/contractor" i18n="@@Contractors">Kontrahenci</a>
                    </li>
                    <li>
                        <a class="nav-item nav-link" *ngIf="permissionService.checkPermission('perm_read_machine_group')"
                           routerLinkActive="active" routerLink="/machine-group" >Grupy maszyn</a>
                    </li>
                    <li>
                        <a class="nav-item nav-link" *ngIf="permissionService.checkPermission('perm_read_group')"
                           routerLinkActive="active" routerLink="/group" i18n="@@UserGroups">Grupy użytkowników</a>
                    </li>
                    <li>
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_system_settings')"
                           routerLinkActive="active" routerLink="/scanner-config">Konfiguracja skanerów</a>
                    </li>
                    <li>
                        <a class="nav-item nav-link"
                           *ngIf="permissionService.checkPermission('perm_read_action_history')"
                           routerLinkActive="active" routerLink="/action-history">Historia aktywności</a>
                    </li>
                </ul>
            </li>
        </ul>
        <ul class="menu menu-right">
            <li>
                <a class="nav-item nav-link"
				  [class]="!authenticationService.getCurrentAuthentication().remainingTimeStr() ? 'without-timer' : ''">
					<span class="current-user">{{getCurrentUserName()}}</span>
					<br>
                    <span class="time-left">
						{{authenticationService.getCurrentAuthentication().remainingTimeStr()}}
					</span>
				</a>
                <ul >
                    <li>
                        <a class="nav-item nav-link" [title]="getCurrentUserName()" i18n="@Logout"
                           (click)="authenticationService.logout()">Wyloguj</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>

