import {OrderContractorStats} from './order-contractor-stats';

export class ContractorStats {
    static path = 'order_statistics/contractor_statistics';
    id: number;
    name: string;
    totalRemainingInvoiceCost: number;
    orders: OrderContractorStats[];

    constructor(object?: ContractorStats){
        if (object){
            this.id = object.id;
            this.name = object.name;
            this.totalRemainingInvoiceCost = object.totalRemainingInvoiceCost;
            this.orders = object.orders;
        }
    }
    getPath(): string {
        return ContractorStats.path;
    }
}

