import { Component, OnInit } from '@angular/core';
import { QualityControl, User } from 'src/app/_models';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ApiTranslatorService,
    ErrorHandlerService,
    PermissionService,
    QualityControlService,
    TranslatorService,
    UserService
} from 'src/app/_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AlertService } from '../../_primitive_services';

@Component({
    selector: 'app-quality-control-edit',
    templateUrl: './quality-control-edit.component.html',
    styleUrls: ['./quality-control-edit.component.css']
})
export class QualityControlEditComponent implements OnInit {

    qualityControl: QualityControl;
    grades: Array<{ code: string, name: string }> = [
        {code: 'ok', name: ''},
        {code: 'not_ok', name: ''},
        {code: 'repeat', name: ''},
        {code: 'conditionally_accepted', name: ''},
        {code: 'not_ok_material', name: ''},
        {code: 'accepted_after_fix', name: ''},
        {code: 'not_yet_reviewed', name: ''}
    ];
    editQualityControlForm: FormGroup;
    grade: { code: string, name: string };
    loading: boolean;
    author: User;
    users: User[] = [];

    constructor(private activatedRoute: ActivatedRoute,
                private qualityControlService: QualityControlService,
                private userService: UserService,
                private apiTranslator: ApiTranslatorService,
                private formBuilder: FormBuilder,
                private titleService: Title,
                private router: Router,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                private translator: TranslatorService,
                private permissionService: PermissionService) {
        this.titleService.setTitle('Kontrola jakości - ocena');
        for (const grade of this.grades) {
            grade.name = translator.translate(grade.code);
        }
    }

    get f(): any {
        return this.editQualityControlForm.controls;
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        this.qualityControlService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(qualityControl => {
            this.qualityControl = qualityControl;
            console.log(this.qualityControl);
            this.grade = this.grades.find(x => x.code === this.qualityControl.grade);
            this.userService.getFilterAllUsers().subscribe(users => {
                this.users = users;
                this.author = this.qualityControl.author !== undefined ? this.users.find(
                    x => x.authUserId === this.qualityControl.author) : this.users.find(
                    x => x.authUserId === this.permissionService.userProfile.authUserId);
                this.buildForm();
            });
        });
    }

    buildForm(): void {
        this.editQualityControlForm = this.formBuilder.group({
            note: [this.qualityControl.note],
            grade2: [this.grade, Validators.required],
            author: [this.author, Validators.required],
        }, {validator: this.forbiddenNameValidator});
        if (!this.permissionService.checkPermission('perm_write_quality_control_author')) {
            this.editQualityControlForm.controls.author.disable();
        }
        this.errorHandler.showLoader(false);
    }

    onSubmit(): any {
        if (this.editQualityControlForm.invalid) {
            return;
        }

        // const user = Object.assign({}, this.user);
        const qualityControl = Object.assign({}, this.qualityControl);
        qualityControl.grade = this.grade.code;
        qualityControl.author = this.author.authUserId;
        qualityControl.note = this.editQualityControlForm.value.note;
        console.log(this.qualityControl);
        this.loading = true;
        this.qualityControlService.updateQualityControl(qualityControl).pipe(first()).subscribe(
            data => {
                this.router.navigate(['/quality-control'], {
                    queryParams: {
                        waiting_for_quality_control: 'true'
                    }
                } );
                this.alertService.success('Sukces');
            }, error => {
                this.loading = false;
                this.alertService.error('Błąd: ', this.errorHandler.error(error));
            }
        );
    }

    forbiddenNameValidator(group: FormGroup): any {
        if (group.get('grade2').value.code === 'not_yet_reviewed') {
            group.get('grade2').setErrors({forbiddenValue: true});
        }
    }

    changeGrade(event): any {
        this.grade = event;
    }

    changeAuthor(event): any {
        this.author = event;
    }
}



