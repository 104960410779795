import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
showLoadingIndicator: Subject<boolean> = new Subject<boolean>();
    constructor() {
    }

    error(error): any {
        if ((Object.keys(error.info).length === 0 && error.info.constructor === Object) || error.info === '') {
            return error.error_code + ' - ' + error.error_str + ': ' + error.description;
        } else {
            return error.info;
        }
    }
    showLoader(loading: boolean): void{
        this.showLoadingIndicator.next(loading);
    }
}
