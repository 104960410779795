import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { OfferPosition } from '../_models/offerPosition';

@Injectable({
    providedIn: 'root'
})
export class PositionService {

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<OfferPosition[]> {
        return this.http.get<OfferPosition[]>(`${environment.apiUrl}/offer_position/`);
    }

    getDetails(id): Observable<OfferPosition> {
        return this.http.get<OfferPosition>(`${environment.apiUrl}/offer_position/` + id + `/`);
    }

    // createOffer(offer): Observable<Position> {
    //   position = this.apiTranslator.modelToSnakeCase(position);
    //   return this.http.post<Position>(`${environment.apiUrl}/offer/`, offer);
    // }
}
