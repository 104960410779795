<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">{{title}}</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="user">
                <button class="button-spacer mat-button-custom" mat-button
                        *ngIf="isEditComponent && permissionService.checkPermission('perm_reset_password')"
                        (click)="modalService.resetPasswordModalForm(user)">Resetuj hasło
                </button>
                <span class="line" *ngIf="isEditComponent && permissionService.checkPermission('perm_reset_password')">|</span>
                <button class="button-spacer mat-button-custom" mat-button *ngIf="isEditComponent"
                        (click)="modalService.deleteModalForm('/user', user)">Usuń
                </button>
                <span class="line" *ngIf="isEditComponent">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="formGroup">
            <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                <div class="form-group row align-items-center">
                    <label for="firstName" class="col-sm-1 col-form-label">Imię</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="firstName" placeholder="Imię"
                               formControlName="firstName">
                        <div class="text-danger" *ngIf="f.firstName.hasError('required')">Imię jest <strong>wymagane</strong></div>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <label for="lastName" class="col-sm-1 col-form-label">Nazwisko</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="lastName" placeholder="Nazwisko"
                               formControlName="lastName">
                        <div class="text-danger" *ngIf="f.lastName.hasError('required')">Nazwizko jest <strong>wymagane</strong></div>
                        </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <label for="username" class="col-sm-1 col-form-label">Nazwa użytkownika</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="username" placeholder="Nazwa użytkownika"
                               formControlName="username">
                        <div class="text-danger" *ngIf="f.username.hasError('required')">Nazwa użytkownika jest <strong>wymagana</strong></div>
                    </div>
                </div>
                <div class="form-group row align-items-center" *ngIf="!isEditComponent">
                    <label for="password" class="col-sm-1 col-form-label">Hasło</label>
                    <div class="col-sm-3">
                        <input type="password" class="form-control" id="password" placeholder="Hasło"
                               formControlName="password">
                        <div class="text-danger" *ngIf="f.password.hasError('required')">Hasło jest
                            <strong>wymagane</strong></div>
                    </div>
                </div>
                <div class="form-group row align-items-center" *ngIf="!isEditComponent">
                    <label for="passwordRepeat" class="col-sm-1 col-form-label">Powtórz hasło</label>
                    <div class="col-sm-3">
                        <input type="password" class="form-control" id="passwordRepeat" placeholder="Powtórz hasło"
                               formControlName="passwordRepeat">
                        <div class="text-danger" *ngIf="f.passwordRepeat.hasError('notSame')">Hasła się nie pokrywają
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <label for="email" class="col-sm-1 col-form-label">E-mail</label>
                    <div class="col-sm-3">
                        <input type="email" class="form-control" id="email" placeholder="E-mail"
                               formControlName="email">
                        <div class="text-danger" *ngIf="f.email.hasError('email')">Wprowadzony e-mail jest <strong>nieprawidłowy</strong>
                        </div>
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <label class="col-sm-1 col-form-label">Grupy</label>
                    <div class="clearfix col-sm-11">
                        <div class="col-sm-12" *ngFor="let group of groups">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="group-{{ group.id }}"
                                       value="{{ group.id }}" [checked]="isUserInGroup(group.id)"
                                       (change)="onChangeGroup($event)">
                                <label class="custom-control-label" for="group-{{ group.id }}">{{ group.name }}</label>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="f.groups.hasError('required')">Użytkownik <strong>musi
                            należeć</strong> do grupy
                        </div>
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <label class="col-sm-1 col-form-label">Grupy maszyn</label>
                    <div class="col-sm-3">
                        <ng-select
                                [items]="machineGroups"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                [hideSelected]="true"
                                bindLabel="codename"
                                placeholder="Grupa Maszyn"
                                [formControlName]="'machineGroups'"
                                (change)="changeMachineGroup($event)">
                        </ng-select>
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center" *ngIf="isEditComponent">
                    <label class="col-sm-1 col-form-label">Karty</label>
                    <div class="clearfix col-sm-2">
                        <div class="col-sm-12" *ngFor="let rfid of user.rfids">
                            {{ rfid.rfid }} <span class="icon" (click)="deleteCard(rfid.id)">&times;</span>
                        </div>
                        <div class="col-sm-12" *ngFor="let rfid of deletedCards">
                            <span class="deleted">{{ rfid.rfid }}</span> <span class="icon"
                                                                               (click)="restoreCard(rfid.id)">&nbsp;&#x2713;</span>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <mat-button-toggle matRipple #scanToggle value="scan">Skanuj kartę</mat-button-toggle>
                    </div>

                </div>
                <hr *ngIf="isEditComponent">

				<div class="form-group row align-items-center">
					<div class="col-sm-1"></div>
					<div class="col-sm-11">
						<div class="col-sm-12">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" id="attendanceList"
									   formControlName="includeInAttendanceList">
								<label class="custom-control-label" for="attendanceList">Generuj listę obecności</label>
							</div>
						</div>
					</div>
				</div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <div class="col-sm-12">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="machineGroupControl"
                                       formControlName="allowMultipleMachineGroups">
                                <label class="custom-control-label" for="machineGroupControl">
                                    Zezwalaj na aktywne przewodniki na wielu grupach maszyn</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-center"
                     *ngIf="permissionService.userProfile.isSuperuser && user.id != this.permissionService.userProfile.id">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <div class="col-sm-12">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="superadmin"
                                       formControlName="superadmin">
                                <label class="custom-control-label" for="superadmin">superadmin</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <button class="button-spacer mat-button-custom mat-button-big" mat-button>
                            Zapisz
                        </button>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>
