import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {ErrorHandlerService, ModalService, PermissionService} from '../_services';
import {Title} from '@angular/platform-browser';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {ScannerConfig} from '../_models/scanner-config';
import {ScannerConfigService} from '../_services/scanner-config.service';
import {MatSelectChange} from '@angular/material/select';
import {AlertService} from '../_primitive_services';
import {catchError} from 'rxjs/operators';
import {BackendErrorResponse} from '../_interfaces/BackendErrorResponse';
import {throwError} from 'rxjs';


interface ScannerAction {
    id: string;
    name: string;
}

@Component({
  selector: 'app-scanner-config',
  templateUrl: './scanner-config.component.html',
  styleUrls: ['./scanner-config.component.css']
})
export class ScannerConfigComponent implements OnInit {
    actions: ScannerAction[] = [
        { id: 'scanner_none', name: 'Nieprzypisany', },
        { id: 'scanner_work', name: 'Wejście/wyjście z pracy', },
        { id: 'scanner_pause', name: 'Wejście/wyjście na przerwę', },
        { id: 'scanner_desktop', name: 'Aplikacja do skanowania przewodników', },
    ];

    scannerConfigs: ScannerConfig[] = [];
    columnsToDisplay = ['scannerId', 'scannerType', 'scannerAction', 'actions', ];

    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(public scannerConfigService: ScannerConfigService,
                private titleService: Title,
                private filterService: BackendFilterService,
                public modalService: ModalService,
                public permissionService: PermissionService,
                private errorHandler: ErrorHandlerService,
                private alertService: AlertService,
    ) {
        this.titleService.setTitle('Konfiguracja skanerów');
    }

    ngOnInit(): void {
        this.refreshData();
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        this.scannerConfigService.getAll()
            .subscribe((response: ScannerConfig[]) => {
            this.errorHandler.showLoader(false);
            this.scannerConfigs.length = 0;
            this.scannerConfigs.push(...response);
            this.matPositions.renderRows();
        });
    }

    private updateScannerConfig(scannerConfig: ScannerConfig): void {

        this.scannerConfigService.updateScannerConfig(scannerConfig)
            .pipe(catchError((error: BackendErrorResponse) => {
                this.alertService.error(error.description);
                return throwError('Could not perform action');
            }))
            .subscribe(
            _ => {
                this.alertService.success('Skaner został zaktualizowany');
            }
        );
    }

    updateAction(scannerConfig: ScannerConfig, event: MatSelectChange): void {
        scannerConfig.action = event.value;
        this.updateScannerConfig(scannerConfig);
    }
}
