<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">{{title}}</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="userGroup">
                <button class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/group' ,userGroup)">Usuń
                </button>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="editGroupForm">
            <form *ngIf="editGroupForm" [formGroup]="editGroupForm" (ngSubmit)="onSubmit()">
                <div class="form-group row align-items-center">
                    <label for="name" class="col-sm-1 col-form-label">Nazwa</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="name" placeholder="Nazwa"
							   formControlName="name"
                        (change)="changeName($event)">
                        <div class="text-danger" *ngIf="f.name.hasError('required')">
							Nazwa jest <strong>wymagana</strong>
						</div>
                    </div>
                </div>
                <hr>
                <div class="row align-items-center">
                    <label for="name" class="col-sm-1 col-form-label">Zaznacz wszystkie</label>
                    <div class="col-sm-1" (click)="multiSelectionService.checkboxForAllGuidesClick()">
                        <mat-checkbox
                                color="primary"
                               (click)="$event.preventDefault()"
                               [checked]="multiSelectionService.allGuidesCheckbox.allChecked"
                               [indeterminate]="multiSelectionService.allGuidesCheckbox.indeterminated"
                        ></mat-checkbox>
                    </div>
                </div>
                <hr>
                <div class="form-group row align-items-center">
                    <label class="col-sm-1 col-form-label">Uprawnienia</label>
                    <div class="clearfix col-sm-11 row">
                        <div *ngFor="let permission of permissions" class="col-4">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="permission-{{ permission.id }}"
                                       value="{{ permission.id }}" [checked]="permission.checked"
                                       (change)="multiSelectionService.changeCheckbox(permission)">
                                <label class="custom-control-label" for="permission-{{ permission.id }}">
                                    {{ permission.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <button class="button-spacer mat-button-custom mat-button-big" mat-button>
                            Zapisz
                        </button>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>
