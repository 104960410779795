import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {MultiRequestService} from '../../_services/multi-request.service';
import {IOperationStateEdit, OperationStateEdit, OperationStateEditFactory} from './operation-state-edit';
import {HttpService} from '../../_services/http.service';

@Injectable({
    providedIn: 'root'
})
export class OperationStateEditService {

    constructor(
        private http: HttpClient,
        private multiRequestService: MultiRequestService,
        private httpService: HttpService,
    ) {
    }

    getAll(guideId: number): Observable<OperationStateEdit[]> {
        const params = new Map([['guide', guideId]]);
        const path = this.httpService.parseURL(params);
        const url = `${environment.apiUrl}/operation_state/${path}`;
        return this.multiRequestService.getAll<IOperationStateEdit[]>(url)
            .pipe(map(ret => ret.map(state => OperationStateEditFactory.fromBackend(state))));
    }

    update(operationStateEdit: OperationStateEdit): Observable<OperationStateEdit> {
        const url = `${environment.apiUrl}/operation_state/${operationStateEdit.id}/`;
        const body = OperationStateEditFactory.toBackend(operationStateEdit);
        return this.http.put<IOperationStateEdit>(url, body)
            .pipe(map(ret => OperationStateEditFactory.fromBackend(ret)));
    }
}
