<div class="container text-right">
<div class="close-modal" (click)="modalService.close()">&times;</div>
</div>
<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="container">

	<div class="form-group align-items-center row">
		<label for="toEarn" class="col-sm-4 col-form-label">Wartość do zdania</label>
		<div class="col-sm-7">
			<input type="number" class="form-control" id="toEarn" placeholder="Do zdania"
				   formControlName="toEarn"
				   (change)="changeToEarnValue($event)">
		</div>
		<label class="col-sm-1" >zł</label>
	</div>

	<div class="form-group align-items-center row">
		<div class="col-sm-12 text-right">
			<button class="button-spacer mat-button-custom mat-button-big" mat-button>
				Zapisz
			</button>
		</div>
	</div>

</form>
