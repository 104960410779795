import { Component, OnInit } from '@angular/core';
import {BackendFilterService} from '../backend-filter.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent {

    constructor(public filterService: BackendFilterService) {
    }

    clear(): void {
        this.filterService.removeAllFilters();
    }
}
