import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IWz, Wz} from '../_models';
import {PaginatedTranslatedResponse} from '../_interfaces/PaginatedApiResponse';
import {BackendPaginationService} from './backend-pagination.service';

@Injectable({
  providedIn: 'root'
})
export class WzService {

    constructor(private http: HttpClient,
                public backendPaginationService: BackendPaginationService) {
    }

    getAll(params?: Map<string, string | number | Array<string | number>>, options?: {headers?: HttpHeaders}):
    Observable<PaginatedTranslatedResponse<Wz>>  {
        return this.backendPaginationService.getAll<Wz>(
            'wz',
            data => new Wz(data),
            params,
            options,
        );
    }

    getWz(id: number): Observable<Wz>{
        return this.http.get<IWz>(`${environment.apiUrl}/wz/${id}`).pipe(map(wz => {
            return new Wz(wz);
        }));
    }

    updateWz(wz: Wz): Observable<Wz>{
        return this.http
            .put<IWz>(`${environment.apiUrl}/wz/${wz.id}/`, wz.convertToBackendWz())
            .pipe(map(res => new Wz(res)));
    }

    createWz(wz: Wz): Observable<Wz>{
        return this.http.post<IWz>(`${environment.apiUrl}/wz/`, wz.convertToBackendWz())
            .pipe(map(res => new Wz(res)));
    }

   updateWzNoOrder(wz: Wz): Observable<Wz>{
        return this.http
            .put<IWz>(`${environment.apiUrl}/wz/${wz.id}/no_order`, wz.convertToBackendWzNoOrder())
            .pipe(map(res => new Wz(res)));
    }

    createWzNoOrder(wz: Wz): Observable<Wz>{
        return this.http.post<IWz>(`${environment.apiUrl}/wz/no_order`, wz.convertToBackendWzNoOrder())
            .pipe(map(res => new Wz(res)));
    }

}
