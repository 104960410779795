<div *ngIf="showLoadingIndicator" class="lds-spinner">
	<div></div>
	<div></div>
	<div></div>
	<div></div>
	<div></div>
	<div></div>
	<div></div>
	<div></div>
	<div></div>
	<div></div>
	<div></div>
	<div></div>
</div>
<router-outlet></router-outlet>