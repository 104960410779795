import {DateService} from '../_primitive_services';

export class OrderContractorStats {
    id: number;
    contractor: number;
    contractorOrderNumber: string;
    creationDate: string;
    cost: number;
    remainingInvoiceCost: number;

    constructor( object: any){
        if (object){
            this.id = object.id;
            this.contractor = object.contractor;
            this.contractorOrderNumber = object.contractorOrderNumber;
            this.creationDate = this.getDate(object.creationDate);
            this.cost = object.cost;
            this.remainingInvoiceCost = object.remainingInvoiceCost;
        }
    }
    getDate(s: string): string{
        return DateService.dateToString(DateService.stringDateToDate(s));
    }
}
