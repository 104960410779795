import { Injectable } from '@angular/core';
import { UserPermissionService } from '../_services/user-permission.service';
import {PermissionService} from "../_services";

@Injectable({
    providedIn: 'root'
})
export class PermissionResolverService {

    constructor(private userPermissionService: UserPermissionService) {
    }

    resolve(): any {
        return this.userPermissionService.getAll();
    }
}


@Injectable({
    providedIn: 'root'
})
export class ProfilePermissionResolverService {

    constructor(private permissionService: PermissionService) {
    }

    resolve(): any {
        return this.permissionService.getPermissions();
    }
}
