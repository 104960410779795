import {DateService} from '../_primitive_services';

export class IBackendScheduleOrderSet {
    id: number;
    plannedEndDate: string;
    creationDate: string;
    invoiceDate: string;
    guideCount: number;
    firstGuideNumber: string;
    firstGuide: string;
    firstFeature: string;
    order: number;
    contractor: number;
    contractorName: string;
    contractorOrderNumber: string;
    cost: number;
    accumulatedCost: number;
}

export class ScheduleOrderSet {
    id: number;
    plannedEndDate: Date;
    creationDate: Date;
    invoiceDate: Date;
    guideCount: number;
    firstGuideNumber: string;
    firstGuide: string;
    firstFeature: string;
    order: number;
    contractor: number;
    contractorName: string;
    contractorOrderNumber: string;
    cost: number;
    accumulatedCost: number;

    constructor(object?: IBackendScheduleOrderSet) {
        if (object){
            this.id = object.id;
            this.plannedEndDate = DateService.stringDateToDateSkipTime(object.plannedEndDate);
            this.creationDate = DateService.stringDateToDateSkipTime(object.creationDate);
            this.invoiceDate = DateService.stringDateToDateSkipTime(object.invoiceDate);
            this.guideCount = object.guideCount;
            this.firstGuideNumber = object.firstGuideNumber;
            this.firstGuide = object.firstGuide;
            this.firstFeature = object.firstFeature;
            this.order = object.order;
            this.contractor = object.contractor;
            this.contractorName = object.contractorName;
            this.contractorOrderNumber = object.contractorOrderNumber;
            this.cost = object.cost;
            this.accumulatedCost = object.accumulatedCost;
        }
    }
}
