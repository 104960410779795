<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">{{title}}</h1>
            <span class="spacer"></span>
            <div class="actions">
                <button *ngIf="isEditComponent && machineGroup" class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/machine-group', machineGroup)">Usuń
                </button>
                <span class="line" *ngIf="isEditComponent">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="machineGroupForm">
            <form *ngIf="machineGroupForm" [formGroup]="machineGroupForm" (ngSubmit)="onSubmit()">

                <div class="form-group row align-items-center">
                    <label for="name" class="col-sm-1 col-form-label" i18n="@@NameEdit">Nazwa</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="name" placeholder="nazwa" formControlName="name"
                               (input)="changeValue('name', $event)">
                        <div *ngIf="f.name.hasError('required')">Nazwa jest <strong>wymagana</strong></div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <label for="codename" class="col-sm-1 col-form-label" i18n="@@CodenameEdit">Kod</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="codename" placeholder="kod"
                               formControlName="codename" (input)="changeValue('codename', $event)">
                        <div *ngIf="f.codename.hasError('required')">Kod grupy maszyn jest <strong>wymagany</strong></div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <label for="workingCostPerHour" class="col-sm-1 col-form-label" i18n="@@WorkingCostEdit">Koszty
                        pracy</label>
                    <div class="col-sm-3 form-group">
                        <div class="input-group-prepend">
                            <label for="workingCostPerHour">
                            </label><input type="number" class="form-control"
                                           id="workingCostPerHour"
                                           placeholder="0"
                                           [value]="zlFromServer"
                                           (change)="connectCost($event, 'workingCostPerHourZl')">
                            <span class="input-group-text" id="basic-addon1">,</span>

                            <label for="workingCostPerHour2">
                            </label><input type="number" class="form-control"
                                           id="workingCostPerHour2"
                                           placeholder="00"
                                           [value]="grFromServer"
                                           (change)="connectCost($event, 'workingCostPerHourGr')">
                            <span class="input-group-text" id="basic-addon2">Zł</span>

                        </div>
                        <div *ngIf="f.codename.hasError('required')">Koszt pracy jest <strong>wymagany</strong></div>
                    </div>
                </div>

                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="external" formControlName="external"
                                   (input)="changeBooleanValue('external', $event)">
                            <label class="custom-control-label" for="external">Op. Zewnętrzna</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <button class="button-spacer mat-button-custom mat-button-big" mat-button>
                            Zapisz
                        </button>
                    </div>
                </div>

            </form>
        </mat-card>
    </div>
</div>
