<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">{{pageTitle}}</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="wz">
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@ShowPdf" mat-button
                        (click)="getPDF(wz.id)" >Wyświetl PDF
                </button>
                <span class="line">|</span>
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@Edit" mat-button
                        routerLink="/wz/{{wz.id}}/edit">Edytuj
                </button>
                <span class="line">|</span>
                <button class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/wz', wz)"
                        i18n="@@Remove">Usuń
                </button>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="wz">
            <mat-card-content>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Numer</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ wz.number }}
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Data utworzenia</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ dateService.dateToStringDate(wz.creationDate) }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Kontrahent</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        <a routerLink="/contractor/{{wz.contractor}}">{{ wz.contractorName }}</a>
                    </div>
                </div>
                <div *ngIf="wz.hasOrder" class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">
                        Powiązane zamówienie
                    </div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        <a routerLink="/order/{{wz.order}}">{{wz.contractorOrderNumber}}</a>
                    </div>
                </div>
                <div *ngIf="!wz.hasOrder" class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">
                        Numer zamówienia
                    </div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{wz.contractorOrderNumber}}
                    </div>
                </div>
                <hr>
                <br>
                <mat-table [dataSource]="wz.wzSetPositions" class="mat-effects">

                    <ng-container matColumnDef="lp">
                        <mat-header-cell *matHeaderCellDef class="mat-lp" i18n="@@Lp">Lp.</mat-header-cell>
                        <mat-cell *matCellDef="let position; let i = index" class="mat-lp"> {{i + 1}}.</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="setName">
                        <mat-header-cell *matHeaderCellDef>Nazwa Zestawu</mat-header-cell>
                        <mat-cell *matCellDef="let position"> {{position.orderSetName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="quantity">
                        <mat-header-cell *matHeaderCellDef>Ilość</mat-header-cell>
                        <mat-cell *matCellDef="let position"> {{position.quantity}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="price">
                        <mat-header-cell *matHeaderCellDef>Cena</mat-header-cell>
                        <mat-cell *matCellDef="let position"> {{moneyService.parseMoney(position.price)}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="value">
                        <mat-header-cell *matHeaderCellDef>Wartość</mat-header-cell>
                        <mat-cell *matCellDef="let position">
                            {{moneyService.parseMoney(position.getValue())}}
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="columnsToDisplayWzSetPositions"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: columnsToDisplayWzSetPositions;"></mat-row>
                </mat-table>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="wz" class="mat-effects">
            <mat-card-content class="totals">
                <div class="sum">
                    <p>Suma:</p>
                    <span>{{moneyService.parseMoney(wz.getSumPrice())}}</span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
