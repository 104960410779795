<div class="page">
<div class="sidebar" >
    <app-filter></app-filter>
</div>
<div class="content">
    <mat-toolbar class="mat-toolbar-custom">
        <h1 class="page-title" i18n="@@wzsPage">Dokumenty WZ</h1>

        <span class="spacer"></span>
        <a class="button-spacer mat-button-custom mat-add-button" mat-button routerLink="/wz/create"
                *ngIf="permissionService.checkPermission('perm_write_wz')">
            <span class="add-circle">+</span> Wystaw WZ bez zamówienia
        </a>
    </mat-toolbar>
    <div class="pagination_and_legend">
        <pagination-controls id="main_list_pagination"
                             (pageChange)="paginationService.pageChanged($event)"
                             (pageBoundsCorrection)="paginationService.pageChanged($event)"
                             maxSize="13"
                             directionLinks="true"
                             responsive="true"
                             previousLabel="Wstecz"
                             nextLabel="Dalej">
        </pagination-controls>
    </div>
    <mat-table [dataSource]="wzs | paginate: {
              id: 'main_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }  | paginate: {
              id: 'bottom_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }" *ngIf="wzs" matSort (matSortChange)="sortService.sortData($event)"
               matSortActive="{{initialSort.active}}" matSortDirection="{{initialSort.direction}}" class="mat-effects">

        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef i18n="@@Number" mat-sort-header="year,lp">Numer</mat-header-cell>
            <mat-cell *matCellDef="let wz"> {{wz.number}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="order">
            <mat-header-cell *matHeaderCellDef i18n="@@Number">Numer Zlecenia</mat-header-cell>
            <mat-cell *matCellDef="let wz">
                <a *ngIf="wz.hasOrder" routerLink="/order/{{wz.order}}"> {{wz.contractorOrderNumber}} </a>
                <div *ngIf="!wz.hasOrder">{{wz.contractorOrderNumber}}</div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="contractorName">
            <mat-header-cell *matHeaderCellDef i18n="@@Contractor">Kontrahent</mat-header-cell>
            <mat-cell *matCellDef="let wz"> {{wz.contractorName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header="creation_date">Data utworzenia</mat-header-cell>
            <mat-cell *matCellDef="let wz"> {{wz.dateToString(wz.creationDate)}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@UserOperations">Operacje</mat-header-cell>
            <mat-cell *matCellDef="let wz" class="mat-operations">
                <mat-icon (click)="getPDF(wz.id)" i18n-matTooltip="@@OfferPdf"
                          matTooltip="Wyświetl PDF">get_app
                </mat-icon>
				<a routerLink="/wz/{{wz.id}}">
					<mat-icon #tooltip="matTooltip" i18n-matTooltip="@@WzDetails"
							  matTooltip="Szczegóły">list_alt
					</mat-icon>
				</a>
				<a routerLink="/wz/{{wz.id}}/edit">
					<mat-icon #tooltip="matTooltip" i18n-matTooltip="@@WzDetails"
							  matTooltip="Edycja">edit_alt
					</mat-icon>
				</a>
                <mat-icon (click)="modalService.deleteModalForm('/wz', wz)" i18n-matTooltip="@@GuideRemove"
                          matTooltip="Usunięcie wz"
                          *ngIf="permissionService.checkPermission('perm_write_guide')
                          && !queryParamService.isFilterActive('in_trash')">delete
                </mat-icon>
                <mat-icon (click)="modalService.restoreModalForm('/wz', wz)" i18n-matTooltip="@@GuideRestore"
                          matTooltip="Przywrócenie wz"
                          *ngIf="permissionService.checkPermission('perm_write_guide')
                          && queryParamService.isFilterActive('in_trash')">restore_from_trash
                </mat-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
    </mat-table>
        <div class="pagination_and_legend">
            <pagination-controls id="bottom_list_pagination"
                                 (pageChange)="paginationService.pageChanged($event)"
                                 (pageBoundsCorrection)="paginationService.pageChanged($event)"
                                 maxSize="13"
                                 directionLinks="true"
                                 responsive="true"
                                 previousLabel="Wstecz"
                                 nextLabel="Dalej"
            >
            </pagination-controls>
        </div>
</div>
</div>
