import {Component, OnInit, ViewChild} from '@angular/core';
import {DateService, MoneyService} from '../_primitive_services';
import {MatTable} from '@angular/material/table';
import {ErrorHandlerService} from '../_services';
import {Title} from '@angular/platform-browser';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {BackendParamService} from '../_filters/backend-param.service';
import {QueryParamService} from '../_filters/query-param.service';
import {OrderDeadlineService} from './order-deadline.service';
import {OrderDeadline} from './order-deadline';
import {FilterConfigYearMonth} from '../_filters/components/filter-year-month/filter-year-month.component';
import {DeadlineLocalFilterService} from '../_services/local-filter.service';

@Component({
    selector: 'app-order-deadline',
    templateUrl: './order-deadline.component.html',
    styleUrls: ['./order-deadline.component.css'],
    providers: [
        BackendFilterService, {provide: BackendParamService, useClass: QueryParamService}, OrderDeadlineService,
        DeadlineLocalFilterService,
    ]
})
export class OrderDeadlineComponent implements OnInit {

    columnsToDisplay = ['plannedEndDate', 'firstGuideNumber', 'guideCount', 'firstFeature', 'contractorName',
        'contractorOrderNumber', 'cost', 'accumulatedCost'];
    dateService = DateService;
    moneyService = MoneyService;

    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(
        private orderDeadlineService: OrderDeadlineService,
        private titleService: Title,
        private filterService: BackendFilterService,
        private errorHandler: ErrorHandlerService,
        public queryParamService: BackendParamService,
        public deadlineLocalFilterService: DeadlineLocalFilterService,
    ) {
        this.titleService.setTitle('Zamówienia - terminy');
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new FilterConfigYearMonth('month', 'Miesiąc', 'year'),
        ]);
        this.deadlineLocalFilterService.onInit(
            new Map([
                ['year', [new Date().getFullYear().toString()]],
                ['month', [(new Date().getMonth() + 1).toString()]]
            ]),
            new Map([
                ['month', (value: Array<string>, deadlines: OrderDeadline[]) => {
                        const month = parseInt(value[0], 10);
                        return deadlines.filter(deadline => deadline.plannedEndDate.getMonth() + 1 === month);
                    }],
                ['year', (value: Array<string>, deadlines: OrderDeadline[]) => {
                        const year = parseInt(value[0], 10);
                        return deadlines.filter(deadline => deadline.plannedEndDate.getFullYear() === year);
                    }],
            ])
        );
        this.subscribeOnChanges();
        this.refreshData();
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        this.orderDeadlineService.getAll().subscribe((response: OrderDeadline[]) => {
            this.errorHandler.showLoader(false);
            this.deadlineLocalFilterService.setObjects(response);
        });
    }

    private subscribeOnChanges(): void {
        this.deadlineLocalFilterService.observable().subscribe(_ => this.matPositions.renderRows());
    }

}
