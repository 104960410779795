import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {IBackendUser, User} from 'src/app/_models/';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {HttpService} from './http.service';
import {MultiRequestService} from './multi-request.service';

@Injectable({providedIn: 'root'})
export class UserService {
    constructor(private http: HttpClient,
                private httpService: HttpService,
                private multiRequestService: MultiRequestService,
                ) { }

    getAll(params: Map<string, string | number | Array<string | number>>): Observable<User[]> {
        const path = this.httpService.parseURL(params);
        return this.multiRequestService.getAll<User[]>(`${environment.apiUrl}/employee/${path}`)
            .pipe(map(x => x.map(y => new User(y))));
    }

    getDetails(id): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/employee/` + id + `/`).pipe(map(x => new User(x)));
    }

    getDetailsByRFID(rfid): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/guide_action/employee_rfid/`, {
            headers: new HttpHeaders({
                Authorization: `RFID ${rfid}`
            })
        }).pipe(map(x => new User(x)));
    }

    getFilterAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiUrl}/filters/employee/`).pipe(map(users => {
            return users.map(x => new User(x));
        }));
    }

    updateUser(user: IBackendUser): Observable<IBackendUser> {
        delete user.groups;
        delete user.machineGroups;
        delete user.permissions;
        delete user.rfids;
        // user = this.apiTranslator.modelToSnakeCase(user);
        return this.http.put<IBackendUser>(`${environment.apiUrl}/employee/` + user.id + `/`, user);
    }

    createUser(user: IBackendUser): Observable<IBackendUser> {
        delete user.groups;
        delete user.machineGroups;
        delete user.permissions;
        delete user.rfids;
        delete user.rfidIds;
        // user = this.apiTranslator.modelToSnakeCase(user);
        return this.http.post<IBackendUser>(`${environment.apiUrl}/employee/`, user);
    }
}
