<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UsersPage">Zamówienia bez przewodników</h1>
            <span class="spacer"></span>
        </mat-toolbar>
        <div class="pagination_and_legend">
            <pagination-controls id="main_list_pagination"
                                 (pageChange)="paginationService.pageChanged($event)"
                                 (pageBoundsCorrection)="paginationService.pageChanged($event)"
                                 maxSize="13"
                                 directionLinks="true"
                                 responsive="true"
                                 previousLabel="Wstecz"
                                 nextLabel="Dalej"
            >
            </pagination-controls>
        </div>
        <br>
        <br>
        <br>
        <br>
        <mat-table [dataSource]="positionsWithoutGuides  | paginate: {
              id: 'main_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }  | paginate: {
              id: 'bottom_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }" *ngIf="positionsWithoutGuides" class="mat-effects" matSort (matSortChange)="sortService.sortData($event)"
                   matSortActive="{{initialSort.active}}" matSortDirection="{{initialSort.direction}}">
            <ng-container matColumnDef="feature">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideContractor">Cecha</mat-header-cell>
                <mat-cell *matCellDef="let positionsWithoutGuide"> {{ positionsWithoutGuide.feature }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="contractorOrderNumber">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideContractor">Numer <br>zamówienia</mat-header-cell>
                <mat-cell
                        *matCellDef="let positionsWithoutGuide"> {{ positionsWithoutGuide.contractorOrderNumber }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="contractorName">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideContractor">Kontrahent</mat-header-cell>
                <mat-cell
                        *matCellDef="let positionsWithoutGuide"> {{ positionsWithoutGuide.contractorName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="guide">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideContractor">Przewodnik</mat-header-cell>
                <mat-cell *matCellDef="let positionsWithoutGuide">
					<a routerLink="/guide/create/" [queryParams]="{order_position_id: positionsWithoutGuide.id}">
						<mat-icon i18n-matTooltip="@@GuideDetails"
								  matTooltip="Wystaw przewodnik">add_box
						</mat-icon>
					</a>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
        <br>
        <br>
        <div class="pagination_and_legend">
            <pagination-controls id="bottom_list_pagination"
                                 (pageChange)="paginationService.pageChanged($event)"
                                 (pageBoundsCorrection)="paginationService.pageChanged($event)"
                                 maxSize="13"
                                 directionLinks="true"
                                 responsive="true"
                                 previousLabel="Wstecz"
                                 nextLabel="Dalej"
            >
            </pagination-controls>
        </div>
    </div>
</div>
