import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../_services';
import {BackendParamService} from '../../backend-param.service';
import {FilterConfig, FilterType} from '../../generic-filter/filter-config';

export class FilterConfigStringInput extends FilterConfig {
    filterParamName: string;
    title: string;
    type: FilterType;

    constructor(filterParamName: string, title: string) {
        super(title, FilterType.StringInput, filterParamName);
    }
}


@Component({
    selector: 'app-filter-string-input',
    templateUrl: './filter-string-input.component.html',
    styleUrls: ['./filter-string-input.component.css']
})
export class FilterStringInputComponent implements OnInit, AfterViewInit {


    @Input() filterConfig: FilterConfigStringInput;

    searchStringValue = '';

    constructor(
        public translator: TranslatorService,
        private queryParamService: BackendParamService
    ) { }

    ngOnInit(): void {
    }

    refreshFilterBasedOnParamMap(map: Map<string, Array<string>>): void{
        if (map.has(this.filterConfig.filterParamName)){
            this.setStringSearch(map.get(this.filterConfig.filterParamName)[0]);
        }
        else {
            this.searchStringValue = '';
        }
    }

    ngAfterViewInit(): void{
        this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        this.queryParamService.subscribe(() => {
            this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        });
    }


    setStringSearch(str): void {
        if (str !== this.searchStringValue) {
            this.searchStringValue = str;
            this.queryParamService.setQueryParam(this.filterConfig.filterParamName, [str], true);
        }
    }

    setStringSearchFromEvent(event): void {
        this.setStringSearch(event.target.value);
    }

}
