import { Injectable } from '@angular/core';


export interface Clickable {
    checked: boolean;
}

@Injectable()
export class MultiSelectionService {

    public allGuidesCheckbox: {allChecked: boolean, indeterminated: boolean} = {allChecked: false, indeterminated: false};
    private objects?: Clickable[];

    constructor() {
    }

    public populateWithData(objects: Clickable[]): void{
        this.objects = objects;
    }

    public getAllChecked(): boolean{
        return this.allGuidesCheckbox.allChecked;
    }


    public getIndeterminated(): boolean{
        return this.allGuidesCheckbox.indeterminated;
    }

    private updateMultiSelection(): void {
        if (this.objects) {
            if (this.allGuidesCheckbox.allChecked) {
                for (const object of this.objects) {
                    object.checked = true;
                }
            } else if (!this.allGuidesCheckbox.allChecked && !this.allGuidesCheckbox.indeterminated) {
                for (const object of this.objects) {
                    object.checked = false;
                }
            }
        }
    }

    public checkboxForAllGuidesClick(): void {
        if (this.allGuidesCheckbox.allChecked || this.allGuidesCheckbox.indeterminated){
            this.allGuidesCheckbox.indeterminated = false;
            this.allGuidesCheckbox.allChecked = false;
        }
        else {
            this.allGuidesCheckbox.indeterminated = false;
            this.allGuidesCheckbox.allChecked = true;
        }

        this.updateMultiSelection();
    }


    changeCheckbox(object: Clickable): void {
        object.checked = !object.checked;
        if (this.objects) {
            if (this.objects.every(o => o.checked)) {
                this.allGuidesCheckbox.allChecked = true;
                this.allGuidesCheckbox.indeterminated = false;
                this.updateMultiSelection();
            } else if (this.objects.every(o => !o.checked)) {
                this.allGuidesCheckbox.allChecked = false;
                this.allGuidesCheckbox.indeterminated = false;
                this.updateMultiSelection();
            } else {
                this.allGuidesCheckbox.allChecked = false;
                this.allGuidesCheckbox.indeterminated = true;
            }
        }
    }
}
