import { WzPositions } from './wz-positions';


export interface IOrderPosition {
    id?: number;
    quantity: number;
    price: number;
    withGuide: boolean;
    orderSetClientGeneratedId: string;
    orderSetId?: number;
    currentGuide: number;
    currentGuideNumber: string;
    progress?: string;
    progressLimit?: string;
    realCost?: string;
    name: string;
    feature: string;
    wzPositions?: WzPositions[];
    clientGeneratedId: string;
    latestQualityControl: string;
}

export class OrderPosition implements IOrderPosition{

    constructor(object?: IOrderPosition) {
        if (object) {
            this.id = object.id;
            this.quantity = object.quantity;
            this.price = object.price;
            this.withGuide = object.withGuide;
            this.orderSetClientGeneratedId = object.orderSetClientGeneratedId;
            this.orderSetId = object.orderSetId;
            this.currentGuide = object.currentGuide;
            this.currentGuideNumber = object.currentGuideNumber;
            this.progress = object.progress;
            this.progressLimit = object.progressLimit;
            this.realCost = object.realCost;
            this.name = object.name;
            this.feature = object.feature;
            this.wzPositions = object.wzPositions;
            OrderPosition.lastId += 1;
            this.clientId = OrderPosition.lastId;
            this.clientGeneratedId = object.clientGeneratedId;
            this.latestQualityControl = object.latestQualityControl;
        }
    }
    static lastId = 0;
    id: number;
    quantity: number;
    price: number;
    withGuide: boolean;
    orderSetClientGeneratedId: string;
    orderSetId: number;
    currentGuide: number;
    currentGuideNumber: string;
    progress: string;
    progressLimit: string;
    realCost: string;
    name: string;
    feature: string;
    wzPositions: WzPositions[];
    clientId: number;
    latestQualityControl: string;

    clientGeneratedId: string;

    getPriceZl(): number {
        return Math.floor(this.price / 100);
    }

    getPriceGr(): number {
        return Math.floor(this.price % 100);
    }

    setPriceZl(valueStr: string): void {
        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldGr = this.getPriceGr();
            this.price = value * 100 + oldGr;
        }
    }

    setPriceGr(valueStr: string): void {

        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldZl = this.getPriceZl();
            this.price = 100 * oldZl + value;
        }
    }

    percentageProgress(): number {
        const progress = parseInt(this.progress, 10);
        const progressLimit = parseInt(this.progressLimit, 10);

        if (progress === 0 && progressLimit === 0) {
            return 100;
        } else {
            return Math.floor(progress / progressLimit * 100);
        }
    }
}
