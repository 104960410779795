import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService, PermissionService } from '../_services';
import {HiddenNavbarService} from '../_services/hidden-navbar.service';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

    constructor(public authenticationService: AuthenticationService,
                public permissionService: PermissionService,
                public hiddenNavbarService: HiddenNavbarService,
                ) {
    }

    ngOnInit(): void {
    }

    getCurrentUserName(): string {
        if (this.permissionService.userProfile){
            return this.permissionService.userProfile.getName();
        }
        else {
            return '';
        }
    }
}
