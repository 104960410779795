<div class="container">
    <div class="row">
        <div class="col-md-6 offset-md-3 mt-5">
            <div class="card">
                <h4 class="card-header" i18n="@@UsernamePassword">Podaj nazwe użytkownika oraz hasło</h4>
                <div class="card-body">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label i18n="@@Username">Nazwa użytkownika</label>
                            <input type="text" formControlName="username" class="form-control"
                                   [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Nazwa użytkownika jest wymagana !</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label i18n="@@Password">Hasło</label>
                            <input type="password" formControlName="password" class="form-control"
                                   [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                        </div>
                        <button [disabled]="loading" class="btn btn-primary">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            <span i18n="@@Login">Zaloguj</span>
                        </button>
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{getErrorMessage()}}</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
