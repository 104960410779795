import {Component, OnInit, ViewChild} from '@angular/core';
import { MachineGroup } from 'src/app/_models';
import {
    ErrorHandlerService,
    MachineGroupService,
    ModalService,
    PermissionService
} from 'src/app/_services';
import { Title } from '@angular/platform-browser';
import {QueryParamService} from '../../_filters/query-param.service';
import {MoneyService} from '../../_primitive_services';
import {
    FromTrashFilterConfig
} from '../../_filters/generic-filter/sample-filter-configs';
import {BackendFilterService} from '../../_filters/backend-filter.service';
import {MatTable} from '@angular/material/table';
import {BackendParamService} from '../../_filters/backend-param.service';
import {MultiRequestService} from '../../_services/multi-request.service';

@Component({
    selector: 'app-machine-group',
    templateUrl: './machine-group-list.component.html',
    styleUrls: ['./machine-group-list.component.css'],
    providers: [
        BackendFilterService, {provide: BackendParamService, useClass: QueryParamService}, MultiRequestService, ]
})
export class MachineGroupListComponent implements OnInit {
    machineGroups: MachineGroup[] = [];
    columnsToDisplay = ['id', 'name', 'codename', 'workingCostPerHour', 'external', 'actions'];
    moneyService = MoneyService;
    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(private machineGroupService: MachineGroupService,
                public modalService: ModalService,
                private titleService: Title,
                public errorHandlerService: ErrorHandlerService,
                public permissionService: PermissionService,
                public queryParamService: BackendParamService,
                private filterService: BackendFilterService,
                ) {
        this.titleService.setTitle('Grupy maszyn');
    }

    ngOnInit(): void {

        this.filterService.setFilterConfigs([
            new FromTrashFilterConfig(),
        ]);
        this.subscribeOnChanges();
    }

    private refreshData(): void {
        this.errorHandlerService.showLoader(true);
        this.machineGroupService.getAll(this.getBackendParamMap()).subscribe(machineGroups => {
            this.errorHandlerService.showLoader(false);
            this.machineGroups.length = 0;
            this.machineGroups.push(...machineGroups);
            this.matPositions.renderRows();
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, Array<string>> {
        return this.queryParamService.getQueryParamMap();
    }

    isChecked(fieldName, index): any {
        return index[fieldName] ? 'done' : 'close';
    }
}
