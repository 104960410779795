import {FilterConfigStringInput} from '../components/filter-string-input/filter-string-input.component';
import {FilterConfigCheckbox} from '../components/filter-checkbox/filter-checkbox.component';
import {FilterConfigChoiceList} from '../components/filter-choice-list/filter-choice-list.component';
import {ContentType} from '../../action-history/action-history';
import {FilterConfigYearMonth} from '../components/filter-year-month/filter-year-month.component';
import {DisplayElement} from './display-element';

export class SearchFilterConfig extends FilterConfigStringInput {

    constructor() {
        super('search', 'Wyszukaj', );
    }
}

export class PredefinedCheckboxFilterConfig extends FilterConfigCheckbox {
    constructor(choices: string[], filterParamName: string, title: string, ) {

        const parsedChoices: DisplayElement[] = choices.map(el => {
            return {
                id: el,
                displayValue: el,
                checked: false,
                filterParamOverride: null,
            };
        });

        super(filterParamName, parsedChoices, title);
    }
}

export class ActionHistoryFilterConfig extends FilterConfigCheckbox {
    constructor(filterParamName: string, title: string, ) {

        const choices = [
            'create', 'update', 'partial_update', 'move_to_trash', 'destroy', 'restore_from_trash',
            'custom', 'rfid_scan', 'bio_scan', 'auto_end_work', 'work_start', 'work_end', 'work_switch',
            'auto_end_pause', 'pause_start', 'pause_end', 'pause_switch'
        ];

        const parsedChoices: DisplayElement[] = choices.map(el => {
            return {
                id: el,
                displayValue: `action_${el}`,
                checked: false,
                filterParamOverride: null,
            };
        });

        super(filterParamName, parsedChoices, title);
    }
}


export class ContractorFilterConfig extends FilterConfigChoiceList {

    constructor(filterParamName: string = 'contractor', multipleChoices: boolean = true) {
        super('id', 'name', filterParamName, 'Kontrahenci',
            '/filters/contractor/', multipleChoices);
    }
}


export class EmployeeFilterConfig extends FilterConfigChoiceList {

    constructor(filterParamName: string, title: string = 'Użytkownicy', multipleChoices: boolean = true) {
        super('id', 'fullName', filterParamName, title, '/filters/employee/', multipleChoices);
    }
}


export class UserGroupFilterConfig extends FilterConfigChoiceList {

    constructor(filterParamName: string, title: string, multipleChoices: boolean = true) {
        super('id', 'name', filterParamName, title, '/filters/group/', multipleChoices);
    }
}


export class StateFilterConfig extends PredefinedCheckboxFilterConfig {

    constructor(filterParamName: string) {
        super(['production', 'guide_new', 'pause', 'new', 'guide_finished', 'quality_control'], filterParamName,
            'Stan przewodnika');
    }
}

export class StatePauseProductionFilterConfig extends PredefinedCheckboxFilterConfig {

    constructor(filterParamName: string) {
        super(['production', 'pause', ], filterParamName,
            'Stan przewodnika');
    }
}

export class YearFilterConfig extends FilterConfigYearMonth {
    constructor(filterParamName: string, title: string) {
        super(filterParamName, title, filterParamName, true);
    }
}

export class TrueOrEmptyFilterConfig extends FilterConfigCheckbox {

    constructor(label: string, filterParamName: string, title: string, ) {
        const choices: DisplayElement[] = [{
            id: 'true',
            displayValue: label,
            checked: false,
            filterParamOverride: null,
        }];

        super(filterParamName, choices, title);
    }
}

export class TrueAndFalseFilterConfig extends FilterConfigCheckbox {

    constructor(trueLabel: string, falseLabel: string, filterParamName: string, title: string, ) {
        const choices: DisplayElement[] = [{
            id: 'true',
            displayValue: trueLabel,
            checked: false,
            filterParamOverride: null,
        }, {
            id: 'false',
            displayValue: falseLabel,
            checked: false,
            filterParamOverride: null,
        }];

        super(filterParamName, choices, title);
    }
}

export class OrderStateFilterConfig extends FilterConfigCheckbox {
    constructor(title: string, ) {
        const choices: DisplayElement[] = [{
            id: 'true',
            displayValue: 'Zakończone',
            checked: false,
            filterParamOverride: null,
        }, {
            id: 'false',
            displayValue: 'Aktywne',
            checked: false,
            filterParamOverride: null,
        }, {
            id: 'true',
            displayValue: 'Zestawy zakończone',
            checked: false,
            filterParamOverride: 'progress_full',
        }];

        super('finished', choices, title);
    }
}

export class FromTrashFilterConfig extends FilterConfigCheckbox {

    constructor() {
        const choices: DisplayElement[] = [{
            id: 'true',
            displayValue: 'Z kosza',
            checked: false,
            filterParamOverride: null,
        }, {
            id: 'true',
            displayValue: 'Wszystkie obiekty',
            checked: false,
            filterParamOverride: 'also_trash',
        }];

        super('in_trash', choices, 'Pokaż tylko obiekty z kosza');
    }
}

export class QualityControlFilterConfig extends PredefinedCheckboxFilterConfig {

    constructor(filterParamName: string, ) {
        super(['ok', 'not_ok', 'repeat', 'conditionally_accepted', 'not_ok_material', 'accepted_after_fix',
                'not_yet_reviewed'],
            filterParamName,
            'Wynik kontroli jakości');
    }
}

export class ContentTypeFilterConfig extends FilterConfigCheckbox {

    constructor(contentTypes: ContentType[], filterParamName: string, ) {

        const allowedContentTypes = [
            'contractor', 'employee', 'guide', 'machinegroup', 'machinegroup', 'order', 'wz', 'worktime', 'qualitycontrol',
            'pausetime', 'rfid', 'group', 'offer', 'causeofabsence', 'employeedayadditionalinfo', 'systemsetting',
            'toearn', 'schedule', 'scannerconfig', ];
        const parsedChoices: DisplayElement[] = contentTypes
            .filter(el => allowedContentTypes.indexOf(el.nameRaw) !== -1)
            .map(el => {
                    return {
                        id: el.id.toString(),
                        displayValue: el.name,
                        checked: false,
                        filterParamOverride: null,
                    };
                }
            );

        super(filterParamName, parsedChoices, 'Rodzaj obiektu');
    }
}

