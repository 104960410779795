<mat-form-field class="picker" appearance="fill">
    <mat-label>{{filterConfig.title}}</mat-label>
    <input matInput disabled [value]="chosenDate | date: getDateFormat()">
    <input matInput hidden="true" [matDatepicker]="dp">
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event, dp)"
                    (monthSelected)="chosenMonthHandler($event, dp)">
    </mat-datepicker>
    <mat-datepicker-toggle matSuffix (click)="clearDates()">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
</mat-form-field>
