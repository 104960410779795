export class Feature {
    id: number;
    quantity: number;
    price: number;
    name: string;
    feature: string;
    offer?: number;
    offerNumber?: string;
    order?: number;
    orderContractorOrderNumber?: string;


    constructor(object?: Feature) {
        if (object) {
            this.id = object.id;
            this.quantity = object.quantity;
            this.price = object.price;
            this.name = object.name;
            this.feature = object.feature;
            this.offer = object?.offer;
            this.order = object?.order;
            this.offerNumber = object?.offerNumber;
            this.orderContractorOrderNumber = object?.orderContractorOrderNumber;
        }
    }
}
