import {FilterConfigDateRange} from '../components/filter-date-range/filter-date-range.component';
import {FilterConfigYearMonth} from '../components/filter-year-month/filter-year-month.component';
import {FilterConfigCheckbox} from '../components/filter-checkbox/filter-checkbox.component';
import {FilterConfigChoiceList} from '../components/filter-choice-list/filter-choice-list.component';
import {FilterConfigStringInput} from '../components/filter-string-input/filter-string-input.component';

export class FilterConfigConverter {
    public static toDateRange(filterConfig: any): FilterConfigDateRange {
        if (filterConfig instanceof FilterConfigDateRange) {
            return filterConfig as FilterConfigDateRange;
        }
        return null;
    }

    public static toYearMonth(filterConfig: any): FilterConfigYearMonth {
        if (filterConfig instanceof FilterConfigYearMonth) {
            return filterConfig as FilterConfigYearMonth;
        }
        return null;
    }

    public static toCheckbox(filterConfig: any): FilterConfigCheckbox {
        if (filterConfig instanceof FilterConfigCheckbox) {
            return filterConfig as FilterConfigCheckbox;
        }
        return null;
    }

    public static toChoiceList(filterConfig: any): FilterConfigChoiceList {
        if (filterConfig instanceof FilterConfigChoiceList) {
            return filterConfig as FilterConfigChoiceList;
        }
        return null;
    }

    public static toStringInput(filterConfig: any): FilterConfigStringInput {
        if (filterConfig instanceof FilterConfigStringInput) {
            return filterConfig as FilterConfigStringInput;
        }
        return null;
    }
}
