import {Injectable} from '@angular/core';
import {BackendParamService} from './backend-param.service';


@Injectable()
export class InMemoryParamService extends BackendParamService{

    constructor() {
        super();
    }

    navigateToParamsWithChanges(replaceUrl: boolean = false): void {
        this.filtersSubject.next(undefined);
    }

    getQueryParamMap(): Map<string, Array<string>>{
        return this.paramsCache;
    }

    isFilterActive(filterName: string): boolean {
        return this.paramsCache.has(filterName) && this.paramsCache.get(filterName).length > 0;
    }

}
