import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EditIndividualReportService {

    private subject: Subject<void> = new Subject<void>();

    public next(): void {
        this.subject.next();
    }

    public subscribe(callback: () => void): Subscription {
        return this.subject.asObservable().subscribe(_ => callback());
    }
}
