<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">{{pageTitle}}</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="wz">
                <button *ngIf="isEditComponent" class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/wz', wz)"
                        i18n="@@Remove">Usuń
                </button>
                <span class="line" *ngIf="isEditComponent">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="wz">
            <mat-card-content>
                <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                    <div class="form-group row align-items-center">
                        <label for="creationDate" class="col-sm-1 col-form-label" i18n="@@NameEdit">Data utworzenia</label>
                        <div class="col-sm-3">
                            <input type="date" required class="form-control" id="creationDate" placeholder="Data utworzenia"
                                   formControlName="creationDate"
                                   (input)="changeDate('creationDate', $event)">
                        </div>
                        <mat-error *ngIf="formGroup.get('creationDate').invalid">
                            Data utworzenia jest wymagana
                        </mat-error>
                    </div>

                    <div class="form-group row align-items-center">
                        <label class="col-sm-1 col-form-label" i18n="@@OfferAuthor">Kontrahent</label>
                        <div class="col-sm-3">
                            <ng-select
                                [items]="contractors"
                                [multiple]="false"
                                [closeOnSelect]="true"
                                [hideSelected]="false"
                                placeholder="Kontrahent"
                                bindLabel="name"
                                [formControlName]="'contractor'"
                                [clearSearchOnAdd]="false"
                                (change)="changeContractor($event)"
                                (blur)="onContractorBlur()"
                                (search)="onContractorSearch($event)"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    {{item.name}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    {{item.name}}
                                </ng-template>
                            </ng-select>
                            <div class="text-danger" *ngIf="f.contractor.hasError('required')">
                                Kontrahent jest <strong>wymagany</strong>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row align-items-center" *ngIf="wz.hasOrder">
                        <div class="col-sm-2 col-form-label">
                            Powiązane zamówienie
                        </div>
                        <div class="col-sm-10 font-weight-bold dark-color">
                            <a routerLink="/order/{{wz.order}}">{{wz.contractorOrderNumber}}</a>
                        </div>
                    </div>
                    <div *ngIf="!wz.hasOrder" class="form-group row align-items-center">
                        <label for="creationDate" class="col-sm-1 col-form-label" i18n="@@NameEdit">Numer zamówienia</label>
                        <div class="col-sm-3">
                            <input type="text" required class="form-control" id="contractorOrderNumber"
                                   placeholder="Numer zamówienia"
                                   formControlName="contractorOrderNumber"
                                   (input)="changeContractorOrderNumber($event)">
                            <div class="text-danger" *ngIf="f.contractorOrderNumber.hasError('required')">
                                Numer jest <strong>wymagany</strong>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <button *ngIf="!wz.hasOrder" type="button" class="btn btn-primary" (click)="onAddPosition()">
                        Dodaj pozycję
                    </button>
                    <mat-table [dataSource]="wz.wzSetPositions" class="mat-effects">

                        <ng-container matColumnDef="lp">
                            <mat-header-cell *matHeaderCellDef class="mat-lp" i18n="@@Lp">Lp.</mat-header-cell>
                            <mat-cell *matCellDef="let position; let i = index" class="mat-lp"> {{i + 1}}.</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="detail">
                            <mat-header-cell *matHeaderCellDef class="col-sm-3">Nazwa</mat-header-cell>
                            <mat-cell *matCellDef="let position" class="col-sm-3">
                                <div *ngIf="wz.hasOrder">{{position.getName()}} </div>
                                <div *ngIf="!wz.hasOrder">
                                    <!--suppress TypeScriptUnresolvedVariable -->
                                    <input
                                    [class]="{'form-error': formGroup.get('positionName'+position.clientGeneratedId).invalid}"
                                    required type="text"
                                    (input)="position.orderSetName=$event.target.value"
                                    class="form-control"
                                    formControlName="positionName{{position.clientGeneratedId}}"
                                    placeholder="Nazwa"
                                    [matTooltip]="formGroup.get('positionName'+position.clientGeneratedId).invalid ? 'Nazwa jest wymagana': ''">
                                </div>

                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="check">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let position">
                                <mat-checkbox *ngIf="wz.hasOrder"
                                    (change)="checkPosition(position)"
                                    [checked]="position.checked"
                                >
                                </mat-checkbox>
                                <mat-icon *ngIf="!wz.hasOrder"
                                          matTooltip="Usuń pozycję"
                                          (click)="onRemovePosition(position)">
                                    remove_circle
                                </mat-icon>
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="quantity">
                            <mat-header-cell *matHeaderCellDef class="col-sm-3">Ilość</mat-header-cell>
                            <mat-cell *matCellDef="let position" class="col-sm-3">
                                <!--suppress TypeScriptUnresolvedVariable -->
                                <input
                                    [class]="{'form-error': formGroup.get('positionQuantity'+position.clientGeneratedId).invalid}"
                                    required type="number"
                                    class="form-control col-sm-12"
                                    formControlName="positionQuantity{{position.clientGeneratedId}}"
                                    (input)="position.quantity=$event.target.value"
                                    placeholder="Ilość"
                                    [matTooltip]="formGroup.get('positionQuantity'+position.clientGeneratedId).invalid ? 'Ilość jest wymagana': ''">
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="price">
                            <mat-header-cell *matHeaderCellDef>Cena</mat-header-cell>
                            <mat-cell *matCellDef="let position">
                                <div class="input-group input-group-multiple col-sm-5 input-group-price">
                                    <!--suppress TypeScriptUnresolvedVariable -->
                                    <input required type="number" class="form-control col-sm-3 numeric-input input-zl input-zl-big"
                                           formControlName="positionPriceZl{{position.clientGeneratedId}}"
                                           (input)="position.setPriceZl($event.target.value)"
                                           [class]="{'form-error': formGroup.get('positionPriceZl' + position.clientGeneratedId).invalid}"
                                           [matTooltip]="formGroup.get('positionPriceZl' + position.clientGeneratedId).invalid ? 'Cena jest wymagana': ''">
                                    <div class="input-group-append input-group-middle">
                                        <div class="input-group-text">
                                            zł
                                        </div>
                                    </div>
                                    <!--suppress TypeScriptUnresolvedVariable -->
                                    <input required type="number" class="form-control col-sm-2 numeric-input input-gr"
                                           formControlName="positionPriceGr{{position.clientGeneratedId}}"
                                           (input)="position.setPriceGr($event.target.value)"
                                           [class]="{'form-error': formGroup.get('positionPriceGr' + position.clientGeneratedId).invalid}"
                                           [matTooltip]="formGroup.get('positionPriceGr' + position.clientGeneratedId).invalid ? 'Cena jest wymagana': ''">
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            gr
                                        </div>
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="columnsToDisplayWzPositions"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: columnsToDisplayWzPositions;"></mat-row>
                    </mat-table>
                    <mat-card *ngIf="wz" class="mat-effects">
                        <mat-card-content class="totals">
                            <div class="sum">
                                <p>Suma:</p>
                                <span>{{moneyService.parseMoney(getSum())}}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <div class="form-group row align-items-center">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-11">
                            <button class="button-spacer mat-button-custom mat-button-big" mat-button>
                                Zapisz
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>
