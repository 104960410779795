<div class="modal-header" style="border: none">
    <h2 class="modal-title modal-title-custom" i18n="@@GenerateSchedule">Generuj Harmonogram</h2>
    <button type="button" class="close" aria-label="Close" (click)="modalService.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form (ngSubmit)="onSubmit()" class="container">

    <app-filter-year-month [filterConfig]="filterConfig"></app-filter-year-month>

    <div class="form-group align-items-center row">
        <div class="col-sm-12 text-right">
            <button class="button-spacer mat-button-custom mat-button-big" mat-button>
                Generuj
            </button>
        </div>
    </div>

</form>
