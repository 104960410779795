import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IOrderDeadline, OrderDeadline, OrderDeadlineFactory} from './order-deadline';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {
    IOrderDeadlineSummary,
    OrderDeadlineSummary,
    OrderDeadlineSummaryFactory
} from '../order-deadline-summary/order-deadline-summary';
import {IToEarn, ToEarn, ToEarnFactory} from '../order-deadline-summary/to-earn-edit/to-earn';
import {IOrderYearlyStat, OrderYearlyStat, OrderYearlyStatFactory} from '../order-yearly-chart/order-yearly-stat';
import {HttpService} from '../_services/http.service';
import {MultiRequestService} from '../_services/multi-request.service';

@Injectable({
    providedIn: 'root'
})
export class OrderDeadlineService {

    constructor(
        private http: HttpClient,
        private httpService: HttpService,
        private multiRequestService: MultiRequestService,
    ) {}

    getAll():
        Observable<OrderDeadline[]> {
        return this.http
            .get<IOrderDeadline[]>(`${environment.apiUrl}/order_statistics/deadline/`)
            .pipe(map(deadlines => deadlines.map(deadline => OrderDeadlineFactory.fromBackend(deadline))));
    }

    getSummary():
        Observable<OrderDeadlineSummary[]> {
        return this.http
            .get<IOrderDeadlineSummary[]>(`${environment.apiUrl}/order_statistics/deadline_summary/`)
            .pipe(map(deadlines => deadlines.map(deadline => OrderDeadlineSummaryFactory.fromBackend(deadline))));
    }

    getYearlyStats(params?: Map<string, string | number | Array<string | number>>): Observable<OrderYearlyStat[]>{
        let query = '';
        if (params){
            query = this.httpService.parseURL(params);
        }
        return this.multiRequestService
            .getAll<IOrderYearlyStat[]>(`${environment.apiUrl}/order_statistics/order_yearly_statistics/${query}`)
            .pipe(map(stats => stats.map(stat => OrderYearlyStatFactory.fromBackend(stat))));
    }

    editToEarn(toEarn: ToEarn):
        Observable<ToEarn> {
        return this.http
            .put<IToEarn>(
                `${environment.apiUrl}/order_statistics/to_earn/${toEarn.year}/${toEarn.month}/`,
                ToEarnFactory.toBackend(toEarn))
            .pipe(map(res => ToEarnFactory.fromBackend(res)));
    }
}
