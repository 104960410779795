import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {IGuideInFilter, User} from '../_models';
import {GuidesService} from '../_services';
import {PaginatedTranslatedResponse} from '../_interfaces/PaginatedApiResponse';
import {BarcodeScanQueueService} from './barcode-scan-queue.service';
import {BackendParamService} from '../_filters/backend-param.service';
import {HiddenNavbarService} from '../_services/hidden-navbar.service';
import {FilterConfigStringInput} from '../_filters/components/filter-string-input/filter-string-input.component';
import {FilterConfig} from '../_filters/generic-filter/filter-config';

@Component({
    selector: 'app-desktop-admin',
    templateUrl: './desktop-search.component.html',
    styleUrls: ['./desktop.component.css'],
    providers: [GuidesService],
})
export class DesktopSearchComponent implements OnInit, OnChanges, OnDestroy {

    @Input() user: User;
    guides: IGuideInFilter[] = [];
    filterConfig: FilterConfig = new FilterConfigStringInput('number', 'Numer przewodnika');

    constructor(
        private guideService: GuidesService,
        private barcodeService: BarcodeScanQueueService,
        private queryParamService: BackendParamService,
        private hiddenNavbarService: HiddenNavbarService,
        ) { }

    ngOnInit(): void {
        this.queryParamService.subscribe(() => this.refreshGuides());
        this.hiddenNavbarService.hide();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.user.previousValue && !changes.user.currentValue){
        } else if (!changes.user.previousValue) {
            this.onUserSet();
        } else if (!changes.user.currentValue) {

        } else if (changes.user.currentValue.id !== changes.user.previousValue.id) {
            this.onUserSet();
        }
    }

    private onUserSet(): void {
        this.refreshGuides();
    }

    private refreshGuides(): void {
        this.guideService.getAllFromFilter(this.queryParamService.getQueryParamMap())
            .subscribe((response: PaginatedTranslatedResponse<IGuideInFilter>) => {
                this.guides.length = 0;
                this.guides.push(...response.results);
            });
    }

    onGuideScan(guide: IGuideInFilter): void {
        this.barcodeService.pushValue(guide.barcode);
    }

    ngOnDestroy(): void {
        this.hiddenNavbarService.show();
    }
}
