import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { UserService } from '../_services/';

@Injectable({
    providedIn: 'root'
})
export class UserResolverService implements Resolve<any> {

    constructor(private userService: UserService) {
    }

    resolve(route: ActivatedRouteSnapshot): any {
        return this.userService.getDetails(route.paramMap.get('id'));
    }
}
