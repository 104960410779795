import {DateService} from '../_primitive_services';

export class Author {
    stateId: number;
    state: string;
    author: number;
    authorName: string;
    start: Date;
    end: Date;

    constructor(object?: Author) {
        if (object) {
            this.stateId = object.stateId;
            this.state = object.state;
            this.author = object.author;
            this.authorName = object.authorName;
            this.start = Author.createDate(object.start);
            this.end = Author.createDate(object.end);
        }
    }
    private static createDate(date): Date {
        return DateService.stringDateToDate(date);
    }
}
