import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiTranslatorService, ErrorHandlerService } from '../_services';

@Injectable()
export class ApiTranslateInterceptor implements HttpInterceptor {
    showLoadingIndicator = true;
    showLoaderSubject = new BehaviorSubject<boolean>(true);
    showLoaderSubscriber = this.showLoaderSubject.asObservable();
    constructor(private apiTranslator: ApiTranslatorService,
                private errorHandler: ErrorHandlerService) {
    }

    intercept(request: HttpRequest<Request>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const snakeCase = this.apiTranslator.modelToSnakeCase(request.body);
        const modifiedRequest = request.clone({ body: snakeCase });
        return next.handle(modifiedRequest).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (typeof event.body !== 'string') {
                        const camelCase = this.apiTranslator.objectToCamelCase(event.body);
                        const modifiedEvent = event.clone({body: camelCase});
                        return modifiedEvent;
                    } else {
                        return event;
                    }
                }
            })
        );
    }
}
