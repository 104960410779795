import {DateService} from '../_primitive_services';

export type ActionHistoryAction =
    'create' | 'update' | 'partial_update' | 'move_to_trash' | 'destroy' | 'restore_from_trash' | 'custom' |
    'rfid_scan' | 'bio_scan' | 'auto_end_work' | 'work_start' | 'work_end' | 'work_switch' | 'auto_end_pause' |
    'pause_start' | 'pause_end' | 'pause_switch';

export interface IActionHistory {
    id: number;
    author: number;
    authorName: string;
    time: string;
    contentTypeModel: string;
    objectClass: string;
    objectRepr: string;
    objectId: number;
    details: string;
    action: ActionHistoryAction;
    success: true;
    errorInfo: string;
}

export class ActionHistory {
    id: number;
    author: number;
    authorName: string;
    time: Date;
    contentTypeModel: string;
    objectClass: string;
    objectRepr: string;
    objectId: number;
    details: string;
    action: ActionHistoryAction;
    success: true;
    errorInfo: string;

    constructor(id: number, author: number, authorName: string, time: Date, contentTypeModel: string,
                objectClass: string, objectRepr: string, objectId: number, details: string,
                action: ActionHistoryAction, success: true, errorInfo: string) {
        this.id = id;
        this.author = author;
        this.authorName = authorName;
        this.time = time;
        this.contentTypeModel = contentTypeModel;
        this.objectClass = objectClass;
        this.objectRepr = objectRepr;
        this.objectId = objectId;
        this.details = details;
        this.action = action;
        this.success = success;
        this.errorInfo = errorInfo;
    }
}

export class ActionHistoryFactory {
    public static fromBackend(a: IActionHistory): ActionHistory {
        return new ActionHistory(
            a.id,
            a.author,
            a.authorName,
            DateService.stringDateToDate(a.time),
            a.contentTypeModel,
            a.objectClass,
            a.objectRepr,
            a.objectId,
            a.details,
            a.action,
            a.success,
            a.errorInfo,
        );
    }
}


export interface ContentType {
    nameRaw: string;
    id: number;
    name: string;
}
