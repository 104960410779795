import {Component, OnInit, ViewChild} from '@angular/core';
import { Contractor } from 'src/app/_models/contractor';
import {ModalService, ContractorService, PermissionService, ErrorHandlerService} from 'src/app/_services';
import { Title } from '@angular/platform-browser';
import {QueryParamService} from '../../_filters/query-param.service';
import {BackendFilterService} from '../../_filters/backend-filter.service';
import {BackendParamService} from '../../_filters/backend-param.service';
import {MatTable} from '@angular/material/table';
import {
    FromTrashFilterConfig
} from '../../_filters/generic-filter/sample-filter-configs';

@Component({
    selector: 'app-contractor',
    templateUrl: './contractor-list.component.html',
    styleUrls: ['./contractor-list.component.css'],
    providers: [
        BackendFilterService, {provide: BackendParamService, useClass: QueryParamService},
    ]
})
export class ContractorListComponent implements OnInit {
    contractors: Contractor[] = [];
    columnsToDisplay = ['id', 'name', 'firstName', 'lastName', 'email', 'actions'];
    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(
        private contractorService: ContractorService,
        private titleService: Title,
        public modalService: ModalService,
        public permissionService: PermissionService,
        public queryParamService: BackendParamService,
        public filterService: BackendFilterService,
        private errorHandler: ErrorHandlerService,
                ) {
        this.titleService.setTitle('Kontrahenci');
    }

    ngOnInit(): void {


        this.filterService.setFilterConfigs([
            new FromTrashFilterConfig(),
        ]);
        this.subscribeOnChanges();

    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        this.contractorService.getAll(this.getBackendParamMap()
        ).subscribe((response: Contractor[]) => {
            this.errorHandler.showLoader(false);
            this.contractors.length = 0;
            this.contractors.push(...response);
            this.matPositions.renderRows();
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }

}
