import { Component, OnInit } from '@angular/core';
import { ApiTranslatorService, ErrorHandlerService, ModalService, ReportService } from '../_services';
import { Title } from '@angular/platform-browser';
import { Report } from '../_models/report';
import { MatTableDataSource } from '@angular/material/table';
import {DateService} from '../_primitive_services';
import { BackendParamService } from '../_filters/backend-param.service';
import { BackendFilterService } from '../_filters/backend-filter.service';
import {FilterConfigYearMonth} from '../_filters/components/filter-year-month/filter-year-month.component';
import {QueryParamService} from '../_filters/query-param.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-raport',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css'],
    providers: [
        {provide: BackendParamService, useClass: QueryParamService}, ]
})
export class ReportComponent implements OnInit {
    reports: Report[];
    columnsToDisplay = ['id', 'name', 'workTime', 'guideWorkTime'];
    dateService = DateService;
    filtersToDisplay = ['month'];
    dataSource: MatTableDataSource<Report>;
    month: string | number | string[] | number[];
    year: string | number | string[] | number[];
    environment = environment;
    showButtonPdf = false;

    constructor(public reportService: ReportService,
                public queryParamService: BackendParamService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                private errorHandler: ErrorHandlerService,
                private filterService: BackendFilterService,
                public modalService: ModalService) {
        this.titleService.setTitle('Raport');
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new FilterConfigYearMonth('month', 'Wybierz miesiąc'),
        ]);
        this.setInitialFilters();
        this.subscribeOnChanges();
    }

    private setInitialFilters(): void{
        if (!this.getBackendParamMap().has('month')) {
            const initialFilters = new Map<string, Array<string>>();
            initialFilters.set('year', [new Date().getFullYear().toString()]);
            initialFilters.set('month', [(new Date().getMonth() + 1).toString()]);
            this.queryParamService.addQueryParams(initialFilters, true);
        }
    }

    refreshData(): void {
        const paramsMap = this.getBackendParamMap();
        this.year = paramsMap.get('year');
        this.month = paramsMap.get('month');
        if (paramsMap.has('month')) {
            this.errorHandler.showLoader(true);
            this.reportService.getAll(paramsMap).subscribe(reports => {
                this.errorHandler.showLoader(false);
                this.reports = reports;
                this.showPdfButton();
            });
        }
    }

    showPdfButton(): void {
        let workTime = 0;
        this.reports.forEach(report => {
            workTime += report.workTime;
        });
        this.showButtonPdf = workTime !== 0;
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => {
            this.refreshData();
        });
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }

}
