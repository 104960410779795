import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }
  public static parseTime(time): any {
    const hr = Math.floor(time / 3600);
    const min =  Math.floor((time - 3600 * hr) / 60);
    if (time === undefined) {
      return '-';
    }

    let minStr: string;
    if (min < 10) {
      minStr = '0' + min.toString();
    }
    else{
      minStr = min.toString();
    }
    return hr + ' ' + 'h' + ' ' + minStr + ' ' + 'm';
  }
}
