import { Component, OnInit } from '@angular/core';
import {ApiTranslatorService, ErrorHandlerService, GroupService, ModalService, PermissionService} from 'src/app/_services';
import { Group } from 'src/app/_models';
import { Title } from '@angular/platform-browser';
import {QueryParamService} from '../../_filters/query-param.service';
import {BackendParamService} from '../../_filters/backend-param.service';

@Component({
    selector: 'app-user-group-list',
    templateUrl: './user-group-list.component.html',
    styleUrls: ['./user-group-list.component.css'],
    providers: [
        {provide: BackendParamService, useClass: QueryParamService},
    ]
})
export class UserGroupListComponent implements OnInit {
    columnsToDisplay = ['id', 'name', 'actions'];
    groups: Group[];

    constructor(private userGroupService: GroupService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                public modalService: ModalService,
                public errorHandlerService: ErrorHandlerService,
                public permissionService: PermissionService,
                public queryParamService: BackendParamService,
                ) {
        this.titleService.setTitle('Grupy Użytkowników');
    }

    ngOnInit(): void {
        this.errorHandlerService.showLoader(true);
        this.userGroupService.getAll().subscribe(groups => {
            this.errorHandlerService.showLoader(false);
            this.groups = groups;
        });
    }
}
