import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HiddenNavbarService {

    private hidden = false;

    constructor() {
    }

    isHidden(): boolean {
        return this.hidden;
    }

    hide(): void {
        this.hidden = true;
    }

    show(): void {
        this.hidden = false;
    }
}
