<div class="page">
    <div class="content">
        <div class="close_order">
            <button type="button" class="btn btn-outline-danger" (click)="modalService.closeModalForm(order)"
                    i18n="@@OrderRemove">Zakończenie zamówienia
            </button>
        </div>
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">Szczegóły</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="order">
                <a class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/order', order)">Usuń
                </a>
                <span class="line">|</span>
                <a *ngIf="permissionService.checkPermission('perm_write_order')"
                        class="button-spacer mat-button-custom" i18n-mat-button="@@Edit" mat-button
                        routerLink="/order/{{order.id}}/edit">Edytuj
                </a>
                <span class="line">|</span>
                <a *ngIf="permissionService.checkPermission('perm_write_order')"
                        class="button-spacer mat-button-custom" i18n-mat-button="@@CrateWz" mat-button
                        routerLink="/wz/create" [queryParams]="{ order_id: order.id}">Wystaw Wz
                </a>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <br>
        <mat-card class="mat-effects" *ngIf="order">
            <mat-card-content>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Nazwa kontrahenta</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        <a routerLink="/contractor/{{order.contractor.toString()}}}">{{order.contractorName}}</a>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Numer zamówienia</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ order.contractorOrderNumber }}
                    </div>
                </div>
                <hr>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Data stworzenia</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ dateService.dateToStringDate(order.creationDate) }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Planowana data zakończenia</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{dateService.dateToStringDate(order.plannedEndDate)}}
                    </div>
                </div>
                <hr>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Postęp</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ order.progress }}/{{ order.progressLimit }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Data zakończenia</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ dateService.dateToStringDate(order.endDate) }}
                    </div>
                </div>
                <hr>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Reklamacja</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        <mat-icon>
                            {{isChecked('reclamation', order)}}
                        </mat-icon>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Opis</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ order.description }}
                    </div>
                </div>
                <hr>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Faktury</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        <div *ngFor="let invoice of order.invoices">
							<p>{{ invoice.name }} - {{ moneyService.parseMoney(invoice.price) }}</p>
						</div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <br>
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">Zestawy</h1>
            <span class="spacer"></span>
        </mat-toolbar>
        <hr>
		<div *ngIf="order">
			<div *ngFor="let orderSet of order.orderSets" class="mat-spacer">
				<mat-table [dataSource]="orderSet.positions" class="mat-effects">

					<ng-container matColumnDef="orderSetName">
						<mat-header-cell *matHeaderCellDef>
							{{orderSet.name}}
						</mat-header-cell>
					</ng-container>

					<ng-container matColumnDef="orderSetQuantity">
						<mat-header-cell *matHeaderCellDef>
							{{orderSet.quantity}}
						</mat-header-cell>
					</ng-container>


					<ng-container matColumnDef="orderSetPrice">
						<mat-header-cell *matHeaderCellDef>
							{{moneyService.parseMoney(orderSet.price)}}
						</mat-header-cell>
					</ng-container>

					<ng-container matColumnDef="position_feature">
						<mat-header-cell *matHeaderCellDef>Cecha</mat-header-cell>
						<mat-cell *matCellDef="let position">
							{{position.feature}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="position_name">
						<mat-header-cell *matHeaderCellDef>Nazwa</mat-header-cell>
						<mat-cell *matCellDef="let position">
							{{position.name}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="position_price">
						<mat-header-cell *matHeaderCellDef>Cena</mat-header-cell>
						<mat-cell *matCellDef="let position">
							{{moneyService.parseMoney(position.price)}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="position_quantity">
						<mat-header-cell *matHeaderCellDef class="col-sm-1">Ilość</mat-header-cell>
						<mat-cell *matCellDef="let position" class="col-sm-1">
							{{position.quantity}}
						</mat-cell>
					</ng-container>
                    <ng-container matColumnDef="position_progress">
						<mat-header-cell *matHeaderCellDef class="col-sm-1">Postęp</mat-header-cell>
						<mat-cell *matCellDef="let position" class="col-sm-1">
							{{position.progress}}/{{position.progressLimit}}
							<mat-progress-bar mode="determinate"
											  value="{{position.percentageProgress()}}"></mat-progress-bar>
						</mat-cell>
					</ng-container>

                    <ng-container matColumnDef="position_cost">
						<mat-header-cell *matHeaderCellDef class="col-sm-2">Koszt realny/zakł.</mat-header-cell>
						<mat-cell *matCellDef="let position" class="col-sm-2">
							{{moneyService.parseMoneyWithUnits(position.realCost, 'zł')}}/
                            {{moneyService.parseMoneyWithUnits(position.quantity * position.price * orderSet.quantity, 'zł')}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="position_with_guide">
						<mat-header-cell *matHeaderCellDef>Przewodnik</mat-header-cell>
						<mat-cell *matCellDef="let position">
							<a *ngIf="position.withGuide" (click)="getGuidePath(position)">{{getLinkName(position)}}</a>
							<span *ngIf="!position.withGuide">{{getLinkName(position)}}</span>
						</mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef="['orderSetName', 'orderSetQuantity', 'orderSetPrice']"
                                    class="mat-header-row-inputs"></mat-header-row>
					<mat-header-row *matHeaderRowDef="orderPositionColumnsToDisplay" class="mat-row-positions"></mat-header-row>
					<mat-row *matRowDef="let row; columns: orderPositionColumnsToDisplay;" class="mat-row-positions"></mat-row>
				</mat-table>
			</div>
		</div>

		<mat-card *ngIf="order" class="mat-effects">
			<mat-card-content class="totals">
				<div class="sum">
					<p>Suma:</p>
					<span>{{order.getPriceSum()}}</span>
				</div>
				<div class="invoiced">
					<p>Zafakturowane:</p>
					<span>{{order.getInvoicedSum()}}</span>
				</div>
			</mat-card-content>
		</mat-card>

    </div>
</div>
