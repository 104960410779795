import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiTranslatorService } from './api-translator.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Report } from '../_models/report';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    raports: Report[];

    constructor(private http: HttpClient,
                private apiTranslator: ApiTranslatorService,
                private httpService: HttpService) {
    }

    public get allReports(): Report[] {
        return this.raports;
    }

    getAll(params?: Map<string, string | number | Array<string | number>>): Observable<Report[]> {
        let path = '';
        if (params) {
            if (params.size !== 0) {
                path = this.httpService.parseURL(params);
            }
        }
        return this.http.get<Report[]>(`${environment.apiUrl}/rcp/month_summary/` + path);
    }

    getDetails(id): Observable<Report> {
        return this.http.get<Report>(`${environment.apiUrl}/rcp/month_summary/` + id + `/`);
    }

    updateRCP(raport): Observable<Report> {
        raport = this.apiTranslator.modelToSnakeCase(raport);
        return this.http.put<Report>(`${environment.apiUrl}/rcp/month_summary/` + raport.id + `/`, raport);
    }

    getTimeFromSec(sec: number): string {
        const h = Math.floor(sec / 3600);
        const m = Math.floor(sec % 3600 / 60);
        return h + 'h ' + m + 'm';
    }
}
