<mat-drawer-container class="page" autosize>
	<mat-drawer #debug mode="side">
		<div>
			<h2>Konsola debugowa</h2>
			<form (ngSubmit)="onKeyboardInput(barcode.value)">

				<label>
					Kod kreskowy:
					<input #barcode type="text">
				</label>
				<button type="submit">Skanuj</button>
			</form>
			<form (ngSubmit)="sendRFIDToWebsocket(rfid.value)">

				<label>
					RFID:
					<input #rfid type="text">
				</label>
				<button type="submit">Skanuj</button>
			</form>
		</div>
	</mat-drawer>
	<div class="content no-data" *ngIf="!(employee || allGuides.length !== 0)">
		<h1>Prosimy zeskanować przewodnik lub kartę RFID</h1>
	</div>

    <div class="content" *ngIf="employee || allGuides.length !== 0">

		<div class="left-side">
			<div *ngIf="employee" class="employee">
				<h2>{{employee.fullName}}</h2>

				<mat-slide-toggle [checked]="acceptingMultipleGuides" (toggleChange)="toggleAcceptMultipleGuides()">
					Wiele przewodników</mat-slide-toggle>
			</div>
			<div *ngIf="allGuides.length !== 0" class="guide-container">
				<div class="guide" *ngFor="let guide of allGuides">
					<span class="close" (click)="removeGuide(guide)">&times;</span>
					<p class="guide-number">{{guide.number}}</p>
					<p class="operation">{{getOperationName(guide)}}</p>
					{{translatorService.translate(guide.currentState)}}
					<p class="time"><span class="time-in-production">czas w produkcji</span><span class="real-time">
                        {{getConvertedTime(guide.getCurrentOperationProductionTime())}}</span></p>

					<p class="machineGroup">Grupa Maszyn: <a routerLink="/machine-group/{{guide.currentOperationMachineGroup}}">
                            {{guide.currentOperationMachineGroupCode}}</a></p>
				</div>
			</div>
		</div>
		<div class="right-side">
			<div *ngIf="guideActions.length > 0 && allGuides.length > 0" class="actions">
                <div *ngFor="let action of guideActions" >
                    <div *ngIf="action.visible" class="action">
                        <button (click)="performAction(action)"
								[class]="!action.canExecute ? 'disabled' : ''">
                            <img src="/assets/img/{{action.action}}.svg" /><span>
                            {{translatorService.translateGuideAction(action)}}</span></button>
                    </div>
                </div>
			</div>

			<mat-select *ngIf="permissionService.checkPermission('perm_read_system_settings') &&
    				   permissionService.checkPermission('perm_write_extended_desktop')" class="scanner-selection"
						[(value)]="selectedScannerId" (selectionChange)="updateScanner($event)">
				<mat-option *ngFor="let scanner of scannerChoices" [value]="scanner.id">
					{{scanner.name}}
				</mat-option>
			</mat-select>
		</div>
    </div>


	<div class="bottom-actions" *ngIf="employee || allGuides.length !== 0">
		<div class="timer part">
			<p>Pozostały czas sesji: <span>{{timerConfig.minuteStr}}:{{timerConfig.secondStr}}</span></p>

		</div>
		<div class="action-buttons part">
			<button mat-button class="mat-button-big mat-button-gray" (click)="resetState()">Zakończ sesję</button>
			<button *ngIf="exitButtonVisible" mat-button class="mat-button-big" routerLink="/home">Wyjdź z aplikacji desktopowej</button>
		</div>
	</div>
	<div class="toggle-debug" [class]="{'opened': debug.opened}">
		<button type="button" mat-button (click)="debug.toggle()">Debug</button>
	</div>
	<div class="toggle-sidenav" [class]="{'opened': sidenav.opened}">
		<button type="button" mat-button (click)="sidenav.toggle()">Wyszukiwarka</button>
	</div>
	<mat-drawer #sidenav position="end" mode="side">
		<app-desktop-admin #desktopAdmin [user]="this.employee"></app-desktop-admin>
	</mat-drawer>
</mat-drawer-container>
