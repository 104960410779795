<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@GuideList">Przewodniki</h1>
            <span class="spacer"></span>
            <div *ngIf="multiSelectionService.allGuidesCheckbox.allChecked || multiSelectionService.allGuidesCheckbox.indeterminated">
                <button class="button-spacer mat-button-custom" mat-button (click)="launchGuide()"
                        *ngIf="permissionService.checkPermission('perm_write_guide_start_finish')">Uruchomienie
                    przewodnika
                </button>
                <button class="button-spacer mat-button-custom" mat-button (click)="endGuide()"
                        *ngIf="permissionService.checkPermission('perm_write_guide_start_finish')">Zakończenie
                    przewodnika
                </button>
                <button class="button-spacer mat-button-custom" mat-button (click)="pauseGuide()"
                        *ngIf="permissionService.checkPermission('perm_write_guide_pause_operation')">Zapauzowanie
                    przewodnika
                </button>
                <button class="button-spacer mat-button-custom" mat-button (click)="startOperation()"
                        *ngIf="permissionService.checkPermission('perm_write_guide_start_finish_operation')">
                    Uruchomienie
                    kolejnej
                    operacji
                </button>
                <button class="button-spacer mat-button-custom" mat-button (click)="endOperation()"
                        *ngIf="permissionService.checkPermission('perm_write_guide_start_finish_operation')">Zakończenie
                    kolejnej
                    operacji
                </button>
            </div>
        </mat-toolbar>

        <div class="pagination_and_legend">
            <pagination-controls id="main_list_pagination"
                                 (pageChange)="paginationService.pageChanged($event)"
                                 (pageBoundsCorrection)="paginationService.pageChanged($event)"
                                 maxSize="13"
                                 directionLinks="true"
                                 responsive="true"
                                 previousLabel="Wstecz"
                                 nextLabel="Dalej"


            >
            </pagination-controls>
            <mat-form-field class="legend_label" appearance="fill">
                <mat-label>Legenda</mat-label>
                <mat-select [disabled]="disableSelect.value">
                    <mat-option class="production" value="option1" disabled>W produkcji</mat-option>
                    <mat-option class="guide_new" value="option2" disabled>Wprowdzony do systemu, ale nieaktywny
                    </mat-option>
                    <mat-option class="pause" value="option3" disabled>Pauza</mat-option>
                    <mat-option class="long_pause" value="option4" disabled>Pauza dłuższa niż 3 dni</mat-option>
                    <mat-option class="finished" value="option5" disabled>Zakończony</mat-option>
                    <mat-option class="waiting" value="option6" disabled>Oczekuje na zatwierdzenie przez kontrolę
                        jakości
                    </mat-option>
                    <mat-option class="denied" value="option7" disabled>Odrzucony przez kontrolę jakości
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <br>
        <br>
        <br>
        <br>
        <mat-table [dataSource]="guides  | paginate: {
              id: 'main_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }  | paginate: {
              id: 'bottom_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }" *ngIf="guides" class="mat-effects" matSort (matSortChange)="sortService.sortData($event)"
                   matSortActive="{{initialSort.active}}" matSortDirection="{{initialSort.direction}}">

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideNumber" mat-sort-header="year,lp,same_guide_lp" class="col-sm-2">Numer</mat-header-cell>
                <mat-cell *matCellDef="let guide" class="col-sm-2" [class]="guideService.getColor(guide)"> {{guide.number }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="creationDate">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideCreationDate" mat-sort-header="creation_date">Data
                    <br>utworzenia
                </mat-header-cell>
                <mat-cell *matCellDef="let guide"> {{ dateService.dateToStringDate(guide.creationDate) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="contractor">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideContractor">Kontrahent</mat-header-cell>
                <mat-cell matTooltip="{{guide.contractorOrderNumber}}"
                          *matCellDef="let guide"> {{ guide.contractorName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="feature">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideFeature">Cecha</mat-header-cell>
                <mat-cell *matCellDef="let guide"> {{ guide.feature }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef>Ilość</mat-header-cell>
                <mat-cell *matCellDef="let guide"> {{ guide.quantity }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="progress">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideFeature">Postęp</mat-header-cell>
                <mat-cell *matCellDef="let guide"> {{ guide.progress }} / {{ guide.progressLimit }}
                    <mat-progress-bar [class]="guideService.getColor(guide) + ' bar'" mode="determinate"
                                      value="{{guide.percentageProgress()}}"></mat-progress-bar>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="time">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideFeature">Czas rzeczywisty / <br> Czas zakładany
                </mat-header-cell>
                <mat-cell *matCellDef="let guide"> {{timeService.parseTime(guide.realTimeInSeconds)}}
                    / {{timeService.parseTime(guide.declaredTimeInSeconds)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cost">
                <mat-header-cell *matHeaderCellDef i18n="@@GuideFeature">Koszt rzeczywisty / <br> Koszt zakładany
                </mat-header-cell>
                <mat-cell *matCellDef="let guide"> {{moneyService.parseMoney(guide.realCost)}}
                    / {{moneyService.parseMoney(guide.declaredCost)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="grade">
                <mat-header-cell class="cdk-column-checkbox" *matHeaderCellDef
                                 (click)="multiSelectionService.checkboxForAllGuidesClick()">
                    <label for="gradesToChange"></label>
                    <mat-checkbox

                            (click)="$event.preventDefault()"
                            [checked]="multiSelectionService.allGuidesCheckbox.allChecked"
                            [indeterminate]="multiSelectionService.allGuidesCheckbox.indeterminated"
                    ></mat-checkbox>
                </mat-header-cell>
                <mat-header-cell class="cdk-column-checkbox" *matCellDef="let guide"
                                 (click)="multiSelectionService.changeCheckbox(guide)">
                    <label for="gradesToChange"></label>
                    <mat-checkbox class="cdk-column-checkbox"
                                  id="gradesToChange"
                                  (click)="$event.preventDefault()"
                                  [checked]="guide.checked"
                    ></mat-checkbox>
                </mat-header-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@GuideOperations">Opcje
                </mat-header-cell>
                <mat-cell *matCellDef="let guide" class="mat-operations">
					<a routerLink="/guide/{{ guide.id }}/edit">
						<mat-icon i18n-matTooltip="@@GuideEdit" matTooltip="Edycja przewodnika"
								  *ngIf="permissionService.checkPermission('perm_write_guide')">
							edit
						</mat-icon>
					</a>
                    <a routerLink="/guide/{{ guide.id }}">
                        <mat-icon i18n-matTooltip="@@GuideDetails"
                                  matTooltip="Szczegóły przewodnika">list_alt
                        </mat-icon>
                    </a>
                    <mat-icon (click)="modalService.deleteModalForm('/guide', guide)" i18n-matTooltip="@@GuideRemove"
                              matTooltip="Usunięcie przewodnika"
                              *ngIf="permissionService.checkPermission('perm_write_guide')
                          && !queryParamService.isFilterActive('in_trash')">delete
                    </mat-icon>
                    <mat-icon (click)="modalService.restoreModalForm('/guide', guide)" i18n-matTooltip="@@GuideRestore"
                              matTooltip="Przywrócenie przewodnika"
                              *ngIf="permissionService.checkPermission('perm_write_guide')
                          && queryParamService.isFilterActive('in_trash')">restore_from_trash
                    </mat-icon>
					<a (click)="getPDF(guide.id)">
						<mat-icon i18n-matTooltip="@@OfferPdf"
								  matTooltip="Wyświetl PDF">get_app
						</mat-icon>
					</a>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
        <br>
        <br>
        <div class="pagination_and_legend">
            <pagination-controls id="bottom_list_pagination"
                                 (pageChange)="paginationService.pageChanged($event)"
                                 (pageBoundsCorrection)="paginationService.pageChanged($event)"
                                 maxSize="13"
                                 directionLinks="true"
                                 responsive="true"
                                 previousLabel="Wstecz"
                                 nextLabel="Dalej"
            >
            </pagination-controls>
            <div id="bottom"></div>
        </div>
    </div>
</div>
