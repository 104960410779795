import { Injectable } from '@angular/core';
import {EmployeeWithOperation, GuideInList} from 'src/app/_models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {HttpService} from './http.service';
import {PaginatedApiResponse, PaginatedTranslatedResponse} from '../_interfaces/PaginatedApiResponse';
import {BackendPaginationService} from './backend-pagination.service';

@Injectable({
    providedIn: 'root'
})
export class EmployeesWithOperationsService {


    constructor(private http: HttpClient,
                private httpService: HttpService,
                private backendPaginationService: BackendPaginationService,
                ) {
    }

    getAll(params: Map<string, string | number | Array<string | number>>):
        Observable<PaginatedTranslatedResponse<EmployeeWithOperation>> {
        return this.backendPaginationService.getAll<EmployeeWithOperation>(
                'rcp/employees_with_operations',
                data => new EmployeeWithOperation(data),
                params,
            );
    }
}
