import { Injectable } from '@angular/core';
import {PaginationService} from './pagination.service';
import {Observable, throwError} from 'rxjs';
import {PaginatedApiResponse, PaginatedTranslatedResponse} from '../_interfaces/PaginatedApiResponse';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpService} from './http.service';
import {BackendErrorResponse} from '../_interfaces/BackendErrorResponse';
import {ErrorHandlerService} from './error-handler.service';
import {MultiRequestService} from './multi-request.service';

@Injectable({
  providedIn: 'root',
})
export class BackendPaginationService {


  constructor(
      private http: HttpClient,
      private httpService: HttpService,
      private paginationService: PaginationService,
      private errorHandler: ErrorHandlerService,
      private multiRequestService: MultiRequestService,
  ) {}

  getAll<T>(prefix: string, constructor: (obj: any) => T,
            params?: Map<string, string | number | Array<string | number>>,
            options?: { headers?: HttpHeaders }):
        Observable<PaginatedTranslatedResponse<T>> {
        let path = '';
        if (params) {
            if (params.size !== 0) {
                path = this.httpService.parseURL(params);
            }
        }
        const url = `${environment.apiUrl}/${prefix}/${path}`;
        return this.multiRequestService.getAll<PaginatedApiResponse>(url, options).pipe(
            catchError(error => this.handleError(error))
        ).pipe(map(response => {
            return {
                page_count: response.page_count,
                count: response.count,
                results: response.results.map(data => constructor(data))};
        }));
    }

    private handleError(error: BackendErrorResponse): Observable<never> {

        this.errorHandler.showLoader(false);
        if (this.paginationService.handleError(error)){
            return;
        }
        return throwError('Something bad happened; please try again later.');
    }
}
