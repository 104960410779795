<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UsersPage">Użytkownicy</h1>
            <span class="spacer"></span>
            <a class="button-spacer mat-button-custom mat-add-button" mat-button routerLink="/user/create"
                    *ngIf="permissionService.checkPermission('perm_write_employee')"><span
                    class="add-circle">+</span> Dodaj użytkownika
            </a>
        </mat-toolbar>
        <mat-table [dataSource]="users" class="mat-effects">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef i18n="@@UserID">ID</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.id}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="first_name">
                <mat-header-cell *matHeaderCellDef i18n="@@UserFirstName">Imię</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.firstName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="last_name">
                <mat-header-cell *matHeaderCellDef i18n="@@UserLastName">Nazwisko</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.lastName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="username">
                <mat-header-cell *matHeaderCellDef i18n="@@UserUsername">Nazwa użytkownika</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.username}}</mat-cell>
            </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@UserOperations">Opcje</mat-header-cell>
            <mat-cell *matCellDef="let user" class="mat-operations">
                <a routerLink="/user/{{user.id}}/edit">
					<mat-icon i18n-matTooltip="@@UserEdit"
							  *ngIf="permissionService.checkPermission('perm_write_employee')"
							  matTooltip="Edycja użytkownika">edit
					</mat-icon>
				</a>
				<a routerLink="/user/{{user.id}}">
					<mat-icon i18n-matTooltip="@@UserDetails"
							  matTooltip="Szczegóły użytkownika">list_alt
					</mat-icon>
				</a>
                <mat-icon (click)="modalService.deleteModalForm('/user', user)"
                          *ngIf="permissionService.checkPermission('perm_write_employee')
                          && !queryParamService.isFilterActive('in_trash')"
                          i18n-matTooltip="@@UserRemove" matTooltip="Usunięcie użytkownika">delete
                </mat-icon>
                <mat-icon (click)="modalService.restoreModalForm('/user', user)"
                          i18n-matTooltip="@@UserRestore"
                          matTooltip="Przywrócenie użytkownika"
                          *ngIf="permissionService.checkPermission('perm_write_employee')
                          && queryParamService.isFilterActive('in_trash')">restore_from_trash
                </mat-icon>
            </mat-cell>
        </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
