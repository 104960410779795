import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Guide, GuideInList } from 'src/app/_models';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
    ApiTranslatorService,
    ErrorHandlerService,
    GuidesService,
    ModalService,
    MultiActionResponse,
    PermissionService,
    TimeService
} from 'src/app/_services';
import { Title } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { PaginationService } from '../../_services/pagination.service';
import { BackendPaginationService } from '../../_services/backend-pagination.service';
import { MultiRequestService } from '../../_services/multi-request.service';
import { PaginatedTranslatedResponse } from '../../_interfaces/PaginatedApiResponse';
import { SortService } from '../../_services/sort.service';
import { Sort } from '@angular/material/sort';
import { MultiSelectionService } from '../../_services/multi-selection.service';
import {
    ContractorFilterConfig,
    EmployeeFilterConfig, FromTrashFilterConfig,
    QualityControlFilterConfig,
    StateFilterConfig, TrueOrEmptyFilterConfig
} from '../../_filters/generic-filter/sample-filter-configs';
import { BackendFilterService } from '../../_filters/backend-filter.service';
import { QueryParamService } from '../../_filters/query-param.service';
import {AlertService} from '../../_primitive_services';
import {BackendParamService} from '../../_filters/backend-param.service';
import {DateService, MoneyService} from '../../_primitive_services';
import {environment} from '../../../environments/environment';
import {FilterConfigStringInput} from '../../_filters/components/filter-string-input/filter-string-input.component';

@Component({
    selector: 'app-guide-list',
    templateUrl: './guide-list.component.html',
    styleUrls: ['./guide-list.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService, SortService, MultiSelectionService,
        {provide: BackendParamService, useClass: QueryParamService},
        GuidesService, MultiRequestService,
    ]
})
export class GuideListComponent implements OnInit {
    guides: GuideInList[] = [];
    columnsToDisplay = ['number', 'creationDate', 'contractor', 'feature', 'quantity', 'progress', 'time', 'cost',
        'grade', 'actions'];
    dataSource: MatTableDataSource<Guide>;
    @ViewChild(MatTable) matPositions: MatTable<any>;

    dateService = DateService;
    moneyService = MoneyService;
    timeService = TimeService;
    disableSelect = new FormControl(false);

    initialSort: Sort = {active: 'year,lp,same_guide_lp', direction: 'desc'};

    constructor(public guideService: GuidesService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                private filterService: BackendFilterService,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                public modalService: ModalService,
                public permissionService: PermissionService,
                public paginationService: PaginationService,
                public sortService: SortService,
                public multiSelectionService: MultiSelectionService,
                public queryParamService: BackendParamService,
    ) {
        this.titleService.setTitle('Przewodniki');
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new EmployeeFilterConfig('employee'),
            new ContractorFilterConfig(),
            new FilterConfigStringInput('number', 'Numer Przewodnika'),
            new FilterConfigStringInput('feature', 'Cecha'),
            new FilterConfigStringInput('contractor_order_number', 'Numer zamówienia'),
            new StateFilterConfig('state'),
            new QualityControlFilterConfig('quality_control_grade'),
            new TrueOrEmptyFilterConfig('Reklamacja', 'reclamation', 'Reklamacja'),
            new FromTrashFilterConfig(),
        ]);
        this.sortService.enableSorting(this.initialSort);
        this.paginationService.enablePagination();
        this.subscribeOnChanges();
        this.multiSelectionService.populateWithData(this.guides);
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        const previouslyCheckedIds: Set<number> = new Set(this.guides.filter(guide => guide.checked).map(guide => guide.id));
        this.guideService.getAll(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<GuideInList>) => {
            this.errorHandler.showLoader(false);
            this.guides.length = 0;
            this.guides.push(...response.results);
            for (const guide of this.guides) {
                guide.checked = previouslyCheckedIds.has(guide.id);
            }
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
        });
    }

    getPDF(id: number): void {
        const urlAdress = `${environment.apiUrl}/guide/${id}/pdf`;
        window.open(urlAdress, '_blank');

    }

    performMultiAction(actionName: string): void {
        if (this.guides.filter(guide => guide.checked).length === 0) {
            return;
        }
        this.errorHandler.showLoader(true);
        this.guideService.multiAction(this.guides.filter(guide => guide.checked).map(guide => guide.id), actionName
        ).subscribe(
            (data: MultiActionResponse[]) => {
                this.errorHandler.showLoader(false);
                let successCounter = 0;
                let failCounter = 0;
                const descriptions: string[] = [];
                for (const response of data) {
                    if (response.code === 200) {
                        successCounter += 1;
                    } else {
                        failCounter += 1;
                        descriptions.push(response.description);
                    }
                }
                if (successCounter > 0) {
                    this.alertService.success(`Liczba zaktualizowanych przewodników: ${successCounter}`);
                }
                if (descriptions.length > 0) {
                    let errorMessage = '';
                    for (const description of descriptions) {
                        errorMessage += `Błąd: ${description}<br>`;
                    }
                    this.alertService.error(errorMessage);

                }
                this.refreshData();
            }, error => {
                this.errorHandler.showLoader(false);
                this.alertService.error(' Błąd: ', this.errorHandler.error(error));
            }
        );
    }

    launchGuide(): void {
        this.performMultiAction('launch');
    }

    endGuide(): void {
        this.performMultiAction('guide_finish');
    }

    pauseGuide(): void {
        this.performMultiAction('pause');
    }

    startOperation(): void {
        this.performMultiAction('start');
    }

    endOperation(): void {
        this.performMultiAction('finish');
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }
}
