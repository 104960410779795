import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ErrorHandlerService, ModalService} from '../../_services';
import {StatisticsScheduleService} from '../../_services/statistics-schedule.service';
import {AlertService} from '../../_primitive_services';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {BackendParamService} from '../../_filters/backend-param.service';
import {InMemoryParamService} from '../../_filters/in-memory-param.service';
import {FilterConfigYearMonth} from '../../_filters/components/filter-year-month/filter-year-month.component';

@Component({
    selector: 'app-schedule-generate',
    templateUrl: './schedule-generate.component.html',
    styleUrls: ['./schedule-generate.component.css'],
    providers: [{provide: BackendParamService, useClass: InMemoryParamService}, ]
})
export class ScheduleGenerateComponent implements OnInit {
    @Input() subject: Subject<any>;

    public filterConfig = new FilterConfigYearMonth('month', 'Miesiąc');

    constructor(
        public formBuilder: FormBuilder,
        public modalService: ModalService,
        public scheduleService: StatisticsScheduleService,
        public alertService: AlertService,
        public router: Router,
        public errorHandler: ErrorHandlerService,
        public queryParamService: BackendParamService,
    ) {
    }

    ngOnInit(): void {
        if (!this.queryParamService.getQueryParamMap().has('month')) {
            const initialFilters = new Map<string, Array<string>>();
            initialFilters.set('year', [new Date().getFullYear().toString()]);
            initialFilters.set('month', [(new Date().getMonth() + 1).toString()]);
            this.queryParamService.addQueryParams(initialFilters, true);
        }
    }

    onSubmit(): void{
        const params = this.queryParamService.getQueryParamMap();
        if (!params.has('month') || !params.has('year')){
            this.alertService.error('Wybierz miesiąc');
        }
        const month = parseInt(params.get('month')[0], 10);
        const year = parseInt(params.get('year')[0], 10);
        this.errorHandler.showLoader(true);
        this.scheduleService.generateSchedule(year, month).subscribe(_ => {
            this.errorHandler.showLoader(false);
            this.alertService.success('Pomyślnie wygenerowano harmonogram');
            this.subject.next();
            this.modalService.close();
        }, error => {
            this.alertService.error('Nie wygenerowano harmonogramu', this.errorHandler.error(error));
        });
    }
}
