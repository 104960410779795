import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../_services';
import {BackendParamService} from '../../backend-param.service';
import {MatDatepicker} from '@angular/material/datepicker';
import {FilterConfig, FilterType} from '../../generic-filter/filter-config';

export class FilterConfigYearMonth extends FilterConfig {
    yearParamName: string;
    filterParamName: string;
    title: string;
    type: FilterType;
    onlyYear: boolean;

    constructor(filterParamName: string, title: string, yearParamName: string = 'year', onlyYear = false) {
        super(title, FilterType.DateMonthPicker, filterParamName);
        this.yearParamName = yearParamName;
        this.onlyYear = onlyYear;
    }

    public getAllFilterParamNames(): string[] {
        const ret = [this.yearParamName];
        if (!this.onlyYear) {
            ret.push(this.filterParamName);
        }
        return ret;
    }
}

@Component({
    selector: 'app-filter-year-month',
    templateUrl: './filter-year-month.component.html',
    styleUrls: ['./filter-year-month.component.css']
})
export class FilterYearMonthComponent implements OnInit, AfterViewInit {

    @Input() filterConfig: FilterConfigYearMonth;

    public chosenDate: Date;

    constructor(
        public translator: TranslatorService,
        private queryParamService: BackendParamService
    ) { }

    ngOnInit(): void {
        this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        this.queryParamService.subscribe(() => {
            this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        });
    }

    refreshFilterBasedOnParamMap(map: Map<string, Array<string>>): void{
        if (map.has(this.filterConfig.filterParamName)){
            if (map.has(this.filterConfig.filterParamName)) {
                const year = parseInt(map.get(this.filterConfig.yearParamName)[0], 10);
                if (!this.filterConfig.onlyYear) {
                    const month = parseInt(map.get(this.filterConfig.filterParamName)[0], 10) - 1;
                    this.chosenDate = new Date(year, month);
                } else {
                    this.chosenDate = new Date(year, 0);
                }
            } else {
                this.chosenDate = null;
            }
        }
        else {
        }
    }

    ngAfterViewInit(): void{
    }


    chosenYearHandler(year: Date, datepicker: MatDatepicker<any>): void {
        if (!this.chosenDate){
            this.chosenDate = new Date();
        }
        this.chosenDate.setFullYear(year.getFullYear());
        if (this.filterConfig.onlyYear){
            this.queryParamService.setQueryParam(this.filterConfig.yearParamName, [year.getFullYear().toString()]);
            datepicker.close();
        }
    }

    chosenMonthHandler(month: Date, datepicker: MatDatepicker<any>): void {
        if (!this.chosenDate){
            this.chosenDate = new Date();
        }
        this.chosenDate.setMonth(month.getMonth());
        this.queryParamService.addQueryParams(
            new Map([
                [this.filterConfig.filterParamName, [(month.getMonth() + 1).toString()]],
                [this.filterConfig.yearParamName, [this.chosenDate.getFullYear().toString()]]
            ]),
        );
        datepicker.close();
    }

    getDateFormat(): string {
        if (this.filterConfig.onlyYear){
            return 'yyyy';
        }
        else {
            return 'yyyy/MM';
        }
    }

    clearDates(): void {
        this.chosenDate = null;
        this.queryParamService.removeAllQueryParams(this.filterConfig.getAllFilterParamNames());
    }

}
