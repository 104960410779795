import {BackendParamService} from '../_filters/backend-param.service';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {OrderDeadlineSummary} from '../order-deadline-summary/order-deadline-summary';
import {OrderDeadline} from '../order-deadline/order-deadline';

export class LocalFilterService<T> {

    constructor(
        private queryParamService: BackendParamService,
    ) {
    }

    private unfilteredObjects: T[] = [];
    private objects: T[] = [];

    private changeSubject: Subject<unknown> = new Subject();

    private filterCallbacks: Map<string,
        ((value: Array<string>, objects: T[]) => T[])> =
        new Map<string, ((value: Array<string>, objects: T[]) => T[])>();

    public onInit(initialFilters: Map<string, Array<string>>, filterCallbacks: Map<string,
        ((value: Array<string>, objects: T[]) => T[])> =
        new Map<string, ((value: Array<string>, objects: T[]) => T[])>()): void {
        this.setInitialFilters(initialFilters);
        this.subscribeOnChanges();
        this.filterCallbacks = filterCallbacks;
    }

    public observable(): Observable<unknown> {
        return this.changeSubject.asObservable();
    }

    public setObjects(data: T[]): void {
        this.unfilteredObjects.length = 0;
        this.unfilteredObjects.push(...data);
        this.filterData();
    }

    public getObjects(): readonly T[] {
        return this.objects;
    }


    private setInitialFilters(initialFilters: Map<string, Array<string>>): void{
        const queryParams = this.queryParamService.getQueryParamMap();
        for (const key of initialFilters.keys()){
            if (!queryParams.has(key)) {
                initialFilters.set(key, initialFilters.get(key));
            }
        }
        this.queryParamService.addQueryParams(initialFilters, true);
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => {
            this.filterData();
        });
    }

    private filterData(): void {
        const filters = this.queryParamService.getQueryParamMap();
        let filteredObjects = this.unfilteredObjects;
        for (const key of filters.keys()){
            if (this.filterCallbacks.has(key)){
                filteredObjects = this.filterCallbacks.get(key)(filters.get(key), filteredObjects);
            }
        }
        this.objects.length = 0;
        this.objects.push(...filteredObjects);
        this.changeSubject.next();
    }
}


@Injectable()
export class DeadlineSummaryLocalFilterService extends LocalFilterService<OrderDeadlineSummary>{
    constructor(
        queryParamService: BackendParamService,
    ) {
        super(queryParamService);
    }
}

@Injectable()
export class DeadlineLocalFilterService extends LocalFilterService<OrderDeadline>{
    constructor(
        queryParamService: BackendParamService,
    ) {
        super(queryParamService);
    }
}
