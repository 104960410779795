<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">Grupy maszyn</h1>

            <span class="spacer"></span>
            <div class="actions">
                <a class="button-spacer mat-button-custom mat-add-button" mat-button routerLink="/machine-group/create"
                        *ngIf="permissionService.checkPermission('perm_write_machine_group')"><span
                        class="add-circle">+</span> Dodaj grupę maszyn
                </a>
            </div>
        </mat-toolbar>

        <mat-table [dataSource]="machineGroups" class="mat-effects">

            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef class="mat-lp" i18n="@@ID">ID</mat-header-cell>
                <mat-cell *matCellDef="let machineGroup" class="mat-lp"> {{machineGroup.id}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef i18n="@@Name">Nazwa</mat-header-cell>
                <mat-cell *matCellDef="let machineGroup"> {{machineGroup.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="codename">
                <mat-header-cell *matHeaderCellDef i18n="@@Codename">Kod</mat-header-cell>
                <mat-cell *matCellDef="let machineGroup"> {{machineGroup.codename}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="workingCostPerHour">
                <mat-header-cell *matHeaderCellDef i18n="@@WorkingCost">Stawka r-g</mat-header-cell>
                <mat-cell *matCellDef="let machineGroup"> {{moneyService.parseMoney(machineGroup.workingCostPerHour)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="external">
                <mat-header-cell *matHeaderCellDef i18n="@@External">Op. zewnętrzna</mat-header-cell>
                <mat-cell *matCellDef="let machineGroup">
                    <mat-icon>
                        {{isChecked('external', machineGroup)}}
                    </mat-icon>
                </mat-cell>
            </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@Operations">Opcje</mat-header-cell>
            <mat-cell *matCellDef="let machineGroup" class="mat-operations">
				<a routerLink="/machine-group/{{machineGroup.id}}/edit">
					<mat-icon matTooltip="Edycja grupy maszyn"
							  *ngIf="permissionService.checkPermission('perm_write_machine_group')"
							  >edit
					</mat-icon>
				</a>
				<a routerLink="/machine-group/{{machineGroup.id}}">
					<mat-icon matTooltip="Szczegóły grupy maszyn"
							  >list_alt
					</mat-icon>
				</a>
                <mat-icon (click)="modalService.deleteModalForm('/machine-group', machineGroup)"
                          *ngIf="permissionService.checkPermission('perm_write_machine_group')
                          && !queryParamService.isFilterActive('in_trash')"
                          matTooltip="Usunięcie grupy maszyn">delete
                </mat-icon>
                <mat-icon (click)="modalService.restoreModalForm('/machine-group', machineGroup)"
                          matTooltip="Przywrócenie grupy maszyn"
                          *ngIf="permissionService.checkPermission('perm_write_machine_group')
                          && queryParamService.isFilterActive('in_trash')">restore_from_trash
                </mat-icon>

                </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>

            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
