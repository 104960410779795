import { Permission } from './permission';

export class IGroup {
    id: number;
    name: string;
    permissions: Permission[];

}

export class Group implements IGroup{
    id: number;
    name: string;
    permissions: Permission[];
    permissionIds: number[] = [];

    constructor(object?: IGroup) {
        if (object) {
            this.id = object.id;
            this.name = object.name;
            this.permissions = object.permissions;
            this.permissionIds = object.permissions.map(el => el.id);
        }
    }

    static getModelName(): string {
        return 'Grupa Użytkowników';
    }

    getName(): string {
        return this.name;
    }

    getPath(): string {
        return 'group';
    }
}
