export interface IToEarn {
    value: number;
    year: number;
    month: number;
}

export interface IWriteToEarn {
    value: number;
}

export class ToEarn implements IToEarn {
    month: number;
    value: number;
    year: number;


    constructor(month: number, value: number, year: number) {
        this.month = month;
        this.value = value;
        this.year = year;
    }
}

export class ToEarnFactory {
    public static fromBackend(toEarn: IToEarn): ToEarn {
        return new ToEarn(
            toEarn.month,
            toEarn.value,
            toEarn.year,
        );
    }

    public static toBackend(toEarn: ToEarn): IWriteToEarn {
        return {
            value: toEarn.value,
        };
    }
}
