import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatTable} from '@angular/material/table';
import {
    ApiTranslatorService,
    ErrorHandlerService,
    ModalService,
    OrderService
} from '../../_services';
import { Title } from '@angular/platform-browser';
import { Order } from '../../_models/order';
import { PaginatedTranslatedResponse } from '../../_interfaces/PaginatedApiResponse';
import {Subscription, timer} from 'rxjs';
import {BackendFilterService} from '../../_filters/backend-filter.service';
import {PaginationService} from '../../_services/pagination.service';
import {BackendPaginationService} from '../../_services/backend-pagination.service';
import {MultiSelectionService} from '../../_services/multi-selection.service';
import {BackendParamService} from '../../_filters/backend-param.service';
import {InMemoryParamService} from '../../_filters/in-memory-param.service';
import {HiddenNavbarService} from '../../_services/hidden-navbar.service';
import {DateService} from '../../_primitive_services';
import { RefreshService } from '../../_services/refresh.service';

@Component({
    selector: 'app-order-tv-list',
    templateUrl: './order-tv-list.component.html',
    styleUrls: ['./order-tv-list.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService, MultiSelectionService,
        { provide: BackendParamService, useClass: InMemoryParamService },
        OrderService],
})
export class OrderTvListComponent implements OnInit, OnDestroy {
    private refreshSubscription: Subscription = null;

    constructor(public orderService: OrderService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                public modalService: ModalService,
                private errorHandler: ErrorHandlerService,
                private hiddenNavbarService: HiddenNavbarService,
                private refreshService: RefreshService
    ) {
        this.titleService.setTitle('Zamówienia');
    }

    time = new Date();
    clock;

    orders: Order[] = [];
    columnsToDisplay = ['contractorName', 'contractorOrderNumber', 'plannedEndDate', 'progress', ];
    dateService = DateService;
    outdatedOrders: number;
    reclamationOrders: number;

    private SESSION_TIME_IN_MINUTES = 1;
    private countdown = timer(this.SESSION_TIME_IN_MINUTES * 60 * 1000, this.SESSION_TIME_IN_MINUTES * 60 * 1000);

    @ViewChild(MatTable) matPositions: MatTable<any>;

    private static getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        const ret = new Map<string, string>();
        ret.set('ordering', '-reclamation,planned_end_date');
        ret.set('finished', 'false');
        ret.set('page', '1');
        ret.set('page_size', '500');
        ret.set('inner', 'false');
        return ret;
    }

    ngOnInit(): void {
        this.refreshData();
        this.enableTimer();
        this.refreshService.refreshSystemTimer();
        this.hiddenNavbarService.hide();

        this.clock = setInterval(() => {
            this.time = new Date();
        }, 1000);
    }

    ngOnDestroy(): void {
        this.hiddenNavbarService.show();
        if (this.refreshSubscription !== null){
            this.refreshSubscription.unsubscribe();
            this.refreshSubscription = null;
        }

        clearInterval(this.clock);
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        this.orderService.getAll(OrderTvListComponent.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<Order>) => {
            this.errorHandler.showLoader(false);
            this.orders.length = 0;
            this.orders.push(...response.results);
            this.countOrders();
            this.matPositions.renderRows();
        });
    }

    countOrders(): void {
        this.reclamationOrders = 0;
        this.outdatedOrders = 0;
        this.orders.forEach(order => {
            if (order.reclamation) {
                this.reclamationOrders += 1;
            }
            if (order.plannedEndDate < new Date()) {
                this.outdatedOrders += 1;
            }
        });
    }

    getRowType(row: Order): string {
        if (row.plannedEndDate < new Date() && row.reclamation){
            return 'outdatedReclamation';
        }
        if (row.plannedEndDate < new Date()) {
            return 'outdated';
        }
        if (row.reclamation) {
            return 'reclamation';
        }
        return 'basic';
    }

    private enableTimer(): void{
        this.refreshSubscription = this.countdown.subscribe(_ => {
            this.refreshData();
        });
    }
}
