<mat-drawer-container class="page" autosize>
    <div class="content">

        <div class="search-wrapper">
            <div class="not-search">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">{{title}}</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="guide && isEditComponent">
                <button class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/guide', guide)">Usuń
                </button>
                <span class="line">|</span>
                <button
                    *ngIf="permissionService.checkPermission('perm_write_guide_state')"
                    class="button-spacer mat-button-custom" mat-button
                    routerLink="/guide/{{guide.id}}/state-edit">Edycja czasu
                </button>
                <span *ngIf="permissionService.checkPermission('perm_write_guide_state')" class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <div *ngIf="!isEditComponent" class="toggle-sidenav" [class]="{'opened': search.opened}">
            <button type="button" mat-button (click)="search.toggle()">Wyszukiwarka</button>
        </div>
        <mat-card class="mat-effects" *ngIf="formGroup">
            <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                <div *ngIf="isEditComponent" class="form-group row align-items-center">
                    <label for="number" class="col-sm-1 col-form-label">Numer przewodnika</label>
                    <div class="col-sm-3">
                        <!--suppress TypeScriptUnresolvedVariable -->
                        {{guide.number}}
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <label for="contractorName" class="col-sm-1 col-form-label">Kontrahent</label>
                    <div class="col-sm-3">
						<div id="contractorName">
							<a routerLink="/contractor/{{guide.contractor}}">{{guide.contractorName}}</a>
						</div>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <label for="orderPosition" class="col-sm-1 col-form-label">Zamówienie</label>

                    <div class="col-sm-3">
						<div id="orderPosition">
							<a routerLink="/order/{{guide.order}}">{{guide.contractorOrderNumber}}</a>
						</div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <label class="col-sm-1 col-form-label">Cecha</label>
                    <div class="col-sm-3">
                        {{guide.feature}}
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <label class="col-sm-1 col-form-label">Nazwa detalu</label>
                    <div class="col-sm-3">
                        {{guide.name}}
                    </div>
                </div>
                <button type="button" class="btn btn-primary" (click)="onAddPositionElement()">
                    Dodaj
                    operację
                </button>
                <mat-table [dataSource]="guide.operations" cdkDropList [cdkDropListData]="guide.operations"
                           (cdkDropListDropped)="onDropPositionElement($event)" class="mat-effects positions">


                    <ng-container matColumnDef="drag">
                        <mat-header-cell *matHeaderCellDef class="mat-lp">
                            <mat-icon [matTooltip]="'Aby zamienić kolejność kliknij na ikonkę jednego z elementów ' +
                                 'i przeciągnij w dół lub w górę'">swap_vert
                            </mat-icon>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="mat-lp col-form-label">
                            <mat-icon cdkDragHandle>drag_handle</mat-icon>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lp">
                        <mat-header-cell *matHeaderCellDef i18n="@@Name" class="mat-lp col-sm-1">Lp.</mat-header-cell>
                        <mat-cell *matCellDef="let guideOperation" class="mat-lp col-sm-1"> {{guideOperation.lp}} </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="machineGroup">
                        <mat-header-cell class="col-sm-2" *matHeaderCellDef>Grupa maszyn</mat-header-cell>
                        <mat-cell class="col-sm-2 machineGroups-select" *matCellDef="let guideOperation">
                            <ng-select
                                    required
                                    [items]="machineGroups"
                                    [multiple]="false"
                                    [closeOnSelect]="true"
                                    [hideSelected]="false"
                                    placeholder="Grupa Maszyn"
                                    bindLabel="machineGroup"
                                    formControlName="txtMachineGroup{{guideOperation.uniqueId}}"
                                    [clearSearchOnAdd]="false"
                                    [searchFn]="filterMachineGroup"
                                    (change)="changeMachineGroup(guideOperation, $event)"
                                    (blur)="onMachineGroupBlur(guideOperation)"
                                    (search)="onMachineGroupSearch(guideOperation, $event)"
                                    appendTo="ng-select"
                                    class="machineGroups-ng-select"

                                    [matTooltip]="formGroup.get('txtMachineGroup'+guideOperation.uniqueId).invalid ?
                                   'Grupa maszyn jest wymagana': ''"
                                    [class]="{'form-error': formGroup.get('txtMachineGroup'+guideOperation.uniqueId).invalid}"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    {{item.codename}} {{item.name}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    {{item.codename}} {{item.name}}
                                </ng-template>
                            </ng-select>


                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="name" class="align-content-center">
                        <mat-header-cell *matHeaderCellDef i18n="@@Name" class="col-sm-3">Treść operacji
                        </mat-header-cell>

                        <mat-cell *matCellDef="let guideOperation" class="col-sm-3 align-content-center">
                            <!--suppress TypeScriptUnresolvedVariable -->
                            <textarea cdkTextareaAutosize cdkAutosizeMinRows="3"
                                      [class]="{'form-error': formGroup.get('txtName'+guideOperation.uniqueId).invalid}"
                                      required type="text" class="form-control" [value]="guideOperation.name"
                                      formControlName="txtName{{guideOperation.uniqueId}}"
                                      (input)="guideOperation.name=$event.target.value"
                                      [matTooltip]="formGroup.get('txtName'+guideOperation.uniqueId).invalid ?
                                   'Treść operacji jest wymagana': ''"></textarea>
                        </mat-cell>

                    </ng-container>

                    <ng-container matColumnDef="qualityControl">
                        <mat-header-cell *matHeaderCellDef i18n="@@IsQualityControlRequired" class="col-sm-1">Kontrola
                            jakości
                        </mat-header-cell>
                        <mat-cell *matCellDef="let guideOperation" class="mat-lp" style="justify-content: center">
                            <label for="qualityControlCheckBox"></label>
                            <!--suppress TypeScriptUnresolvedVariable -->
                            <input type="checkbox" id="qualityControlCheckBox"
                                   [checked]="guideOperation.isQualityControlRequired"
                                   (change)="guideOperation.isQualityControlRequired = $event.target.checked">
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="declaredTimeInSeconds">
                        <mat-header-cell *matHeaderCellDef i18n="@@Quantity">Czas</mat-header-cell>
                        <mat-cell *matCellDef="let guideOperation">
                            <label class="col-sm-1 col-form-label" i18n="@@WorkingCostEdit"></label>
                            <div class="col-sm-3 form-group">
                                <div class="input-group-prepend">
                                    <label for="declaredTimeInSeconds{{guideOperation.uniqueId}}">
                                    </label><input type="number" class="form-control time_container"
                                                   id="declaredTimeInSeconds{{guideOperation.uniqueId}}"
                                                   placeholder="0"
                                                   [value]="guideOperation.getHours()"
                                                   (change)="connectTime($event, guideOperation, 'declaredTimeInSecondsHr')">
                                    <span class="input-group-text" id="basic-addon1">h</span>

                                    <label for="declaredTimeInSeconds2{{guideOperation.uniqueId}}">
                                    </label><input type="number" class="form-control time_container"
                                                   id="declaredTimeInSeconds2{{guideOperation.uniqueId}}"
                                                   placeholder="00"
                                                   [value]="guideOperation.getMinutes()"
                                                   (change)="connectTime($event, guideOperation, 'declaredTimeInSecondsMin')">
                                    <span class="input-group-text" id="basic-addon2">min</span>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="remove">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let guideOperation" style="justify-content: end">
                            <mat-icon matTooltip="Usuń pozycję" (click)="onRemovePositionElement(guideOperation)">
                                remove_circle
                            </mat-icon>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: columnsToDisplay;" cdkDragLockAxis="y" cdkDrag
                             [style]="'background-color: ' + getColor(row)"
                             [cdkDragData]="row">
                    </mat-row>
                </mat-table>

                <br>
                <br>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <button class="button-spacer mat-button-custom mat-button-big button" mat-button>
                            Zapisz
                        </button>
                    </div>
                </div>
            </form>
        </mat-card>
            </div>
        </div>
    </div>
    <mat-drawer #search position="end" mode="side">
        <app-guide-search></app-guide-search>
    </mat-drawer>
</mat-drawer-container>
