<div class="container text-right">
<div class="close-modal" (click)="modalService.close()">&times;</div>
</div>
<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="container">
	<div class="form-group align-items-center row">
		<label class="col-sm-4 col-form-label" i18n="@@OfferAuthor">Powód nieobecności</label>
		<div class="col-sm-8">
			<ng-select
					[items]="absenceCauses"
					[multiple]="false"
					[closeOnSelect]="true"
					[hideSelected]="false"
					placeholder="Powód nieobecności"
					bindLabel="name"
					(change)="changeCauseOfAbsence($event)"
					formControlName="causeOfAbsence"
			>
				<ng-template ng-label-tmp let-item="item">
					{{ translatorService.translate(item) }}
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-search="searchTerm">
					{{ translatorService.translate(item) }}
				</ng-template>
			</ng-select>
		</div>
	</div>

	<div class="form-group align-items-center row">
		<label for="causeOfAbsenceDescription" class="col-sm-4 col-form-label" i18n="@@NameEdit">Opis powodu nieobecności</label>
		<div class="col-sm-8">
			<textarea class="form-control" id="causeOfAbsenceDescription" placeholder="Opis powodu nieobecności"
				  formControlName="causeOfAbsenceDescription"
				  (change)="changeSimpleValue($event, 'causeOfAbsenceDescription')"></textarea>
		</div>
	</div>

	<div class="form-group align-items-center row">
		<label for="pauseOffsetInSeconds" class="col-sm-4 col-form-label" i18n="@@NameEdit">Przesunięcie pauzy w minutach</label>
		<div class="col-sm-8">
			<input type="number" class="form-control" id="pauseOffsetInSeconds" placeholder="Przesunięcie pauzy w minutach"
				   formControlName="pauseOffsetInSeconds"
				   (change)="changeTimeValue($event, 'pauseOffsetInSeconds')">
		</div>
	</div>

	<div class="form-group align-items-center row">
		<label for="workTimeOffsetInSeconds" class="col-sm-4 col-form-label" i18n="@@NameEdit">Przesunięcie czasu pracy w minutach</label>
		<div class="col-sm-8">
			<input type="number" class="form-control" id="workTimeOffsetInSeconds" placeholder="Przesunięcie czasu pracy w minutach"
				   formControlName="workTimeOffsetInSeconds"
				   (change)="changeTimeValue($event, 'workTimeOffsetInSeconds')">
		</div>
	</div>

	<div class="form-group align-items-center row">
		<label for="guideWorkTimeOffsetInSeconds" class="col-sm-4 col-form-label" i18n="@@NameEdit">Przesunięcie czasu pracy z przewodników</label>
		<div class="col-sm-8">
			<input type="number" class="form-control" id="guideWorkTimeOffsetInSeconds" placeholder="Przesunięcie czasu pracy z przewodników"
				   formControlName="workTimeOffsetInSeconds"
				   (change)="changeTimeValue($event, 'guideWorkTimeOffsetInSeconds')">
		</div>
	</div>

	<div class="form-group align-items-center row">
		<div class="col-sm-12 text-right">
			<button class="button-spacer mat-button-custom mat-button-big" mat-button>
				Zapisz
			</button>
		</div>
	</div>

</form>
