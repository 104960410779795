import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {ContractorService, ErrorHandlerService, ModalService, OrderService, PermissionService} from '../../_services';
import { DateService } from '../../_primitive_services';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '../../_models/order';
import { Title } from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {MatTable} from '@angular/material/table';
import {OrderPosition} from '../../_models/order-position';
import {OrderSet} from '../../_models/order-set';
import {AlertService} from '../../_primitive_services';
import {Invoice} from '../../_models/invoice';
import { v4 as uuidv4 } from 'uuid';
import { OfferSearchComponent } from '../../offer/search/offer-search.component';
import { Offer } from '../../_models';
import { OfferPosition } from '../../_models/offerPosition';

@Component({
    selector: 'app-edit',
    templateUrl: './order-edit.component.html',
    styleUrls: ['./order-edit.component.css']
})
export class OrderEditComponent implements OnInit, AfterViewInit {


    constructor(private orderService: OrderService,
                private activatedRoute: ActivatedRoute,
                private titleService: Title,
                private formBuilder: FormBuilder,
                public modalService: ModalService,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                private router: Router,
                private contractorService: ContractorService,
                public permissionService: PermissionService
                ) {
        this.titleService.setTitle('Zamówienie - edycja');
    }

    get f(): any {
        return this.formGroup.controls;
    }
    order: Order;

    private dummyOrderSets: OrderSet[] = [];
    formGroup: FormGroup;
    orderPositionColumnsToDisplay = ['position_drag', 'position_feature', 'position_name', 'position_price', 'position_quantity', 'position_with_guide', 'position_remove', ];
    public isEditComponent = true;
    contractors = [];

    @ViewChildren(MatTable) allMatTables: QueryList<MatTable<any>>;
    @ViewChild(OfferSearchComponent) offerSearch: OfferSearchComponent;

    header: string;

    ngOnInit(): void {
        this.errorHandler.showLoader(true);

        this.isEditComponent = this.activatedRoute.snapshot.paramMap.has('id');

        if (this.isEditComponent){
            this.header = 'Zamówienie - edycja';
            this.getOrderFromBackend();
        }
        else {
            this.header = 'Zamówienie - dodawanie';
            this.order = this.createNewOrder();
            this.buildForm();
        }

        this.errorHandler.showLoader(false);

        this.contractorService.getFilterAllContractors().subscribe(contractors => {
            this.contractors = contractors;
        });
    }

    createNewOrder(): Order {
        return new Order({
            id: undefined,
            contractor: null,
            creationDate: DateService.dateToStringDate(new Date()),
            plannedEndDate: null,
            contractorOrderNumber: '',
            reclamation: false,
            contractorName: null,
            progress: '0',
            progressLimit: '0',
            endDate: null,
            invoices: [],
            positions: [],
            orderSets: [new OrderSet([], {
                id: undefined,
                name: 'Inne',
                quantity: 1,
                clientGeneratedId: undefined,
                dummy: true,
                wzSetPositions: undefined,
                positions: [],
                tableGeneratedId: undefined,
                price: undefined
            })],
            wzs: [],
            inner: false,
            includeInStatistics: true,
            includeInSchedule: true,
        });
    }

    getOrderFromBackend(): void {
        this.orderService.getOrder(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(order => {
            this.dummyOrderSets.length = 0;
            this.order = order;
            const orderSetIdToInstance = new Map<number, OrderSet>();
            const nonEmptyOrderSetIds = new Set<number>(this.order.positions.map(el => el.orderSetId));
            for (const orderSet of this.order.orderSets){
                if (orderSet.dummy){
                    if (nonEmptyOrderSetIds.has(orderSet.id)) {
                        this.dummyOrderSets.push(orderSet);
                    }
                }
                else {
                    orderSetIdToInstance.set(orderSet.id, orderSet);
                }
            }
            const dummyOrderSet = new OrderSet([], {
                id: undefined,
                name: 'Inne',
                quantity: 1,
                clientGeneratedId: undefined,
                dummy: true,
                wzSetPositions: undefined,
                positions: [],
                tableGeneratedId: undefined,
                price: undefined
            });
            orderSetIdToInstance.set(-1, dummyOrderSet);
            for (const position of order.positions){
                if (orderSetIdToInstance.has(position.orderSetId)){
                }
                else {
                    dummyOrderSet.positions.push(position);
                }
            }
            this.order.orderSets = [...orderSetIdToInstance.values()];
            console.log(this.order);
            this.buildForm();
            if (this.order.innerOrder){
                this.disableInnerOrderForms();
            }
        });
    }

    onSubmit(): any {
        if (this.formGroup.invalid) {
            console.log('Form group invalid: ', this.formGroup);
            return;
        }
        const orderSets = [];
        const orderPositions = [];
        const invoices = [];

        for (const orderSet of this.order.orderSets){
            if (orderSet.dummy) {
                for (const position of orderSet.positions){
                    position.orderSetClientGeneratedId = undefined;
                    orderPositions.push(position);
                }
            }
            else {
                orderSets.push(orderSet);
                for (const position of orderSet.positions){
                    position.orderSetClientGeneratedId = orderSet.clientGeneratedId;
                    orderPositions.push(position);
                }
            }
        }

        for (const invoice of this.order.invoices) {
            invoices.push(invoice);
        }

        const order: Order = this.order.clone();

        order.positions = orderPositions;
        order.orderSets = orderSets;
        order.invoices = invoices;
        const dummyOrderSet = this.order.orderSets.filter(el => el.dummy)[0];
        for (const orderSet of [...this.dummyOrderSets]){
            if (dummyOrderSet.positions.filter(el => el.orderSetId === orderSet.id).length === 0){
                this.dummyOrderSets.splice(this.dummyOrderSets.indexOf(orderSet), 1);
            }
        }
        order.orderSets.push(...this.dummyOrderSets);

        if (this.isEditComponent) {
            this.orderService.updateOrder(order).subscribe(
                data => {
                    this.router.navigate(['/order', this.order.id]);
                    this.alertService.success(Order.modelName + ': <strong>' + data.contractorOrderNumber + '' +
                        '</strong> zostało pomyślnie edytowane');

                }, error => {
                    this.alertService.error(Order.modelName + ' nie zostało edytowane. Błąd: ', this.errorHandler.error(error));

                }
            );
        }
        else {
            this.orderService.createOrder(order).subscribe(
                data => {
                    this.router.navigate(['/order', data.id]);
                    this.alertService.success(Order.modelName + ': <strong>' + data.contractorOrderNumber + '' +
                        '</strong> zostało utworzone');

                }, error => {
                    this.alertService.error(Order.modelName + ' nie zostało utworzone. Błąd: ', this.errorHandler.error(error));

                }
            );
        }
    }

    buildForm(): void {
        this.formGroup = this.formBuilder.group({
            contractorName: [this.order.contractorName, Validators.required],
            orderNumber: [this.order.contractorOrderNumber, Validators.required],
            creationDate: [DateService.dateToStringDate(this.order.creationDate), Validators.required],
            plannedEndDate: [DateService.dateToStringDate(this.order.plannedEndDate), Validators.required],
        });
        this.order.positions.forEach((position) => {
            this.addPositionToForm(position);
        });

        this.order.orderSets.forEach((orderSet) => {
            this.addOrderSetToForm(orderSet);
        });

        this.order.invoices.forEach((invoice) => {
            this.addInvoicesToForm(invoice);
        });

        if (this.order.innerOrder) {
            this.disableInnerOrderForms();
        }
    }

    changeValue(name: string, event): void {
        this.order[name] = event.target.value;
    }

    changeContractor(event): void {
        if (event) {
            this.order.contractor = event.id;
            this.order.contractorName = event.name;
        } else {
            this.order.contractor = null;
            this.order.contractorName = null;
        }
    }

    addPositionToForm(position: OrderPosition): void {
        this.formGroup.addControl('positionFeature' + position.clientId, this.formBuilder.control(position.feature));
        this.formGroup.addControl('positionPriceZl' + position.clientId, this.formBuilder.control(position.getPriceZl()));
        this.formGroup.addControl('positionPriceGr' + position.clientId, this.formBuilder.control(position.getPriceGr()));
        this.formGroup.addControl('positionQuantity' + position.clientId, this.formBuilder.control(position.quantity));
        this.formGroup.addControl('positionWithGuide' + position.clientId, this.formBuilder.control(position.withGuide));
    }

    removePositionFromForm(index: number): void{
        this.formGroup.removeControl('positionFeature' + index);
        this.formGroup.removeControl('positionPriceZl' + index);
        this.formGroup.removeControl('positionPriceGr' + index);
        this.formGroup.removeControl('positionQuantity' + index);
        this.formGroup.removeControl('positionWithGuide' + index);
    }

    addOrderSetToForm( orderSet: OrderSet): void{
        this.formGroup.addControl('setName' + orderSet.tableGeneratedId, this.formBuilder.control(orderSet.name));
        this.formGroup.addControl('setPriceZl' + orderSet.tableGeneratedId,
            this.formBuilder.control(orderSet.getPriceZl()));
        this.formGroup.addControl('setPriceGr' + orderSet.tableGeneratedId,
            this.formBuilder.control(orderSet.getPriceGr()));
        this.formGroup.addControl('setQuantity' + orderSet.tableGeneratedId,
            this.formBuilder.control(orderSet.quantity));
        if (orderSet.dummy){
            this.formGroup.get('setName' + orderSet.tableGeneratedId).disable();
            this.formGroup.get('setPriceZl' + orderSet.tableGeneratedId).disable();
            this.formGroup.get('setPriceGr' + orderSet.tableGeneratedId).disable();
            this.formGroup.get('setQuantity' + orderSet.tableGeneratedId).disable();
        }
    }

    removeOrderSetFromForm(index: number): void{
        this.formGroup.removeControl('setName' + index);
        this.formGroup.removeControl('setPriceZl' + index);
        this.formGroup.removeControl('setPriceGr' + index);
        this.formGroup.removeControl('setQuantity' + index);
    }

    onRemoveOrderSet(orderSet: OrderSet): void {

        for (const position of [...orderSet.positions]){
            this.onRemoveOrderPosition(position, orderSet);
        }

        const index: number = this.order.orderSets.indexOf(orderSet);
        if (index !== -1) {
            this.removeOrderSetFromForm(orderSet.tableGeneratedId);
            this.order.orderSets.splice(index, 1);
        }
    }

    private refreshOrderPositionMatTable(index: number): void{
        if (index !== -1) {
            this.allMatTables.toArray()[index].renderRows();
        }
    }

    onAddOrderSet(): void {
        this.order.orderSets.push(new OrderSet([], {
            id: undefined,
            name: '',
            quantity: 1,
            clientGeneratedId: uuidv4(),
            dummy: false,
            wzSetPositions: undefined,
            positions: [],
            tableGeneratedId: undefined,
            price: undefined
        }));
        this.addOrderSetToForm(this.order.orderSets[this.order.orderSets.length - 1]);
    }

    onDropOrderPosition(event: CdkDragDrop<OrderPosition[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            const orderPosition = event.previousContainer.data[event.previousIndex];
            orderPosition.orderSetId = undefined;
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        for (const matTable of this.allMatTables){
            matTable.renderRows();
        }
    }

    onAddOrderPosition(orderSet: OrderSet): void {
        orderSet.positions.push(new OrderPosition({
            id: undefined,
            name: '',
            quantity: 1,
            price: 0,
            withGuide: true,
            orderSetClientGeneratedId: orderSet.clientGeneratedId,
            currentGuide: null,
            currentGuideNumber: '',
            feature: '',
            clientGeneratedId: orderSet.clientGeneratedId,
            latestQualityControl: 'not_yet_reviewed'
        }));
        this.addPositionToForm(orderSet.positions[orderSet.positions.length - 1]);
        this.refreshOrderPositionMatTable(this.order.orderSets.indexOf(orderSet));
    }

    onRemoveOrderPosition(orderPosition: OrderPosition, orderSet: OrderSet): void {
        const index: number = orderSet.positions.indexOf(orderPosition);
        if (index !== -1) {
            this.removePositionFromForm(orderPosition.clientId);
            orderSet.positions.splice(index, 1);
            this.refreshOrderPositionMatTable(this.order.orderSets.indexOf(orderSet));
        }
    }

    changeDate(name: string, event): void {
        this.order[name] = DateService.stringDateToDateSkipTime(event.target.value);
    }

    switchInnerOrder(): void {
        this.order.innerOrder = !this.order.innerOrder;
        if (this.order.innerOrder) {
            this.disableInnerOrderForms();
        }
        else {
            this.formGroup.get('orderNumber').enable();
            this.formGroup.get('contractorName').enable();
            this.formGroup.get('plannedEndDate').enable();
        }
    }

    disableInnerOrderForms(): void {
        this.formGroup.get('orderNumber').disable();
        this.formGroup.get('orderNumber').setValue('');
        this.formGroup.get('contractorName').disable();
        this.formGroup.get('contractorName').setValue(null);
        this.formGroup.get('plannedEndDate').disable();
        this.formGroup.get('plannedEndDate').setValue(new Date());
        this.order.contractorOrderNumber = '';
        this.order.contractor = null;
        this.order.includeInStatistics = false;
        this.order.includeInSchedule = false;
        this.order.plannedEndDate = new Date();
    }

    switchIncludeInStatistics(): void {
        this.order.includeInStatistics = !this.order.includeInStatistics;
    }


    switchIncludeInSchedule(): void {
        this.order.includeInSchedule = !this.order.includeInSchedule;
    }

    switchReclamation(): void {
        this.order.reclamation = !this.order.reclamation;
    }

    addInvoicesToForm(invoice: Invoice): void {
        this.formGroup.addControl('invoiceName' + invoice.clientGeneratedId, this.formBuilder.control(invoice.name));
        this.formGroup.addControl('invoicePriceZl' + invoice.clientGeneratedId, this.formBuilder.control(invoice.getPriceZl()));
        this.formGroup.addControl('invoicePriceGr' + invoice.clientGeneratedId, this.formBuilder.control(invoice.getPriceGr()));
        console.log('Add invoice: ', this.order);
    }

    removeInvoiceFromForm(index: string): void {
        this.formGroup.removeControl('invoiceName' + index);
        this.formGroup.removeControl('invoicePriceZl' + index);
        this.formGroup.removeControl('invoicePriceGr' + index);
    }

    onAddInvoice(): void {
        this.order.invoices.push(new Invoice({
            name: '',
            price: 0,
        }));

        this.addInvoicesToForm(this.order.invoices[this.order.invoices.length - 1]);
    }

    onRemoveInvoice(invoice: Invoice): void {
        const idx = this.order.invoices.indexOf(invoice);
        if (idx !== -1) {
            this.removeInvoiceFromForm(invoice.clientGeneratedId);
            this.order.invoices.splice(idx, 1);
        }
    }

    ngAfterViewInit(): void {
        this.offerSearch.offerSubject.asObservable().subscribe(offer => this.selectOffer(offer));
    }

    selectOffer(offer: Offer): void {
        console.log(offer);
        console.log(this.order);
        if (offer.positions && offer.positions.length > 0) {
            this.fillOperations(offer.positions);
        }
        // else {
        //     this.selectGuideId(guide.id);
        // }
    }

    private fillOperations(positions: OfferPosition[]): void {
        const dummyOrderSet = this.order.orderSets.find(x => x.dummy === true);

        if (dummyOrderSet.positions.length > 0) {
            while (dummyOrderSet.positions.length > 0) {
                this.onRemoveOrderPosition(dummyOrderSet.positions[0], dummyOrderSet);
            }
            this.refreshOrderPositionMatTable(this.order.orderSets.indexOf(dummyOrderSet));
            // this.allMatTables.forEach(matTable => {
            //     matTable.renderRows();
            // });
        }
        for (const position of positions) {
            this.onAddPosition(position, dummyOrderSet);
        }
    }

    onAddPosition(position: OfferPosition, orderSet: OrderSet): void {
        orderSet.positions.push(new OrderPosition({
            id: undefined,
            name: position.name,
            quantity: position.quantity,
            price: position.price,
            withGuide: true,
            orderSetClientGeneratedId: orderSet.clientGeneratedId,
            currentGuide: null,
            currentGuideNumber: '',
            feature: position.feature,
            clientGeneratedId: orderSet.clientGeneratedId,
            latestQualityControl: 'not_yet_reviewed',
        }));

        this.addPositionToForm(orderSet.positions[orderSet.positions.length - 1]);
        this.refreshOrderPositionMatTable(this.order.orderSets.indexOf(orderSet));
    }
}
