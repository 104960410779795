export class PositionsWithoutGuides {
    static modelName = 'Pozycje bez zamówien';
    static path = 'guide';
    id: number;
    name: string;
    order: number;
    feature: string;
    declaredCost: number;
    contractorName: string;
    contractorOrderNumber: string;
    currentGuide: number;
    checked: boolean;
    contractor: number;

    constructor(object?: any) {
        if (object) {
            this.id = object.id;
            this.name = object.name;
            this.order = object.order;
            this.feature = object.feature;
            this.declaredCost = object.declaredCost;
            this.contractorName = object.contractorName;
            this.contractorOrderNumber = object.contractorOrderNumber;
            this.currentGuide = object.currentGuide || null;
            this.contractor = object.contractor;
        }
    }

    getModelName(): string {
        return PositionsWithoutGuides.modelName;
    }

    getPath(): string {
        return PositionsWithoutGuides.path;
    }

    getName(): string {
        return this.name;
    }
}
