import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ErrorHandlerService, ModalService } from '../../_services';
import { IndividualReportService } from '../../_services/individual-report.service';
import { WorkTime, WorkTimes } from '../../_models/work-time';
import { Guide } from '../../_models';
import { first } from 'rxjs/operators';
import { AlertService } from '../../_primitive_services';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'app-work-time-individual-report',
    templateUrl: './work-time-individual-report.component.html',
    styleUrls: ['./work-time-individual-report.component.css']
})
export class WorkTimeIndividualReportComponent implements OnInit {
    @Input() dayToEdit: string;
    @Input() employeeId: number;

    @Output() refreshData: EventEmitter<any> = new EventEmitter();

    @ViewChild('table') matTable: MatTable<any>;

    workTimes: WorkTime[] = [];
    formGroup: FormGroup;
    method = 'patch';

    constructor(
        public modalService: ModalService,
        private errorHandler: ErrorHandlerService,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private router: Router,
        private individualReportService: IndividualReportService) {
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        this.individualReportService.getEmployeeWorkTime(this.employeeId, this.dayToEdit)
            .subscribe((response: WorkTimes) => {
                this.errorHandler.showLoader(false);
                this.workTimes.length = 0;
                response.workTimes.map((workTime: WorkTime) => {
                    this.workTimes.push(workTime);
                });
                this.buildForm();
            });
    }

    buildForm(): void {
        this.formGroup = this.formBuilder.group({
            workTimes: this.formBuilder.array([]),
        });

        if (this.workTimes.length > 0) {
            for (const workTime of this.workTimes) {
                this.addWorkTimeToForm(workTime);
            }
        } else {
            this.method = 'put';
            this.addWorkTimeToForm(new WorkTime());
        }
    }

    get workTimesArray(): FormArray {
        return this.formGroup.get('workTimes') as FormArray;
    }

    addWorkTime(): void {
        this.addWorkTimeToForm(new WorkTime());
        this.refreshMatTable();
    }

    addWorkTimeToForm(workTime: WorkTime): void {
        this.workTimesArray.push(
            this.formBuilder.group({
                autoClosed: workTime?.autoClosed,
                end: workTime?.end,
                id: workTime?.id,
                start: workTime?.start,
                canSendNull: workTime?.canSendNull
            })
        );
    }

    removeWorkTime(index: number): void {
        this.workTimesArray.removeAt(index);
        this.refreshMatTable();
    }

    refreshMatTable(): void {
        this.matTable.renderRows();
    }

    onInput(row: FormGroup, event: Event): void {
        const element = (event.target as HTMLInputElement);
        if (row.value.end === '') {
            element.classList.add('is-invalid');
            row.get('end').setErrors(['empty']);
        } else {
            element.classList.remove('is-invalid');
            row.get('end').setErrors(null);
        }
    }

    onSubmit(): any {
        if (this.formGroup.invalid) {
            console.log('invalid');
            console.log(this.formGroup);
            return;
        }

        this.errorHandler.showLoader(true);
        this.individualReportService.updateOrCreateEmployeeWorkTime(this.method, this.employeeId, this.dayToEdit, this.formGroup.value)
            .pipe(first()).subscribe(
            data => {
                this.errorHandler.showLoader(false);
                this.refreshData.emit();
                this.modalService.close();
                this.alertService.success('Dzień <strong>' + this.dayToEdit + '</strong> został pomyślnie edytowany.');

            }, error => {
                this.errorHandler.showLoader(false);
                console.log(error);
                this.alertService.error('Dzień <strong>' + this.dayToEdit + '</strong> został pomyślnie edytowany. Błąd: ',
                    this.errorHandler.error(error));

            }
        );
    }
}
