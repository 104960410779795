import {IBackendScheduleOrderSet, ScheduleOrderSet} from './schedule-order-set';

export class IBackendSchedule {
    id: number;
    year: number;
    month: number;
    earned: number;
    toEarn: number;
    orderSets: IBackendScheduleOrderSet[];
}

export class Schedule{

    earned: number;
    id: number;
    month: number;
    orderSets: ScheduleOrderSet[];
    toEarn: number;
    year: number;

    constructor(object?: IBackendSchedule) {
        if (object) {
            this.earned = object.earned;
            this.id = object.id;
            this.month = object.month;
            this.orderSets = object.orderSets.map(set => new ScheduleOrderSet(set));
            this.toEarn = object.toEarn;
            this.year = object.year;
        }
    }
}
