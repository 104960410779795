export class DayType {
    day: string;
    type: string;
    description: string;

    constructor(object?: DayType) {
        if (object) {
            this.day = object.day;
            this.type = object.type;
            this.description = object.description;
        }
    }
}
