<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UserDetailsPage">Użytkownik - szczegóły</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="user">
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@StatiscticsDetails" mat-button
                        routerLink="/user/{{user.id}}/statstics">Statystyki
                </button>
                <span class="line" *ngIf="permissionService.checkPermission('perm_write_employee')">|</span>
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@EditDetails" mat-button
                        *ngIf="permissionService.checkPermission('perm_write_employee')"
                        routerLink="/user/{{user.id}}/edit">Edytuj
                </button>
                <span class="line" *ngIf="permissionService.checkPermission('perm_write_employee')">|</span>
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@DeleteDetails" mat-button
                        *ngIf="permissionService.checkPermission('perm_write_employee')"
                        (click)="modalService.deleteModalForm('/user', user)">Usuń
                </button>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="user">
            <mat-card-content>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Imię</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ user.firstName }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Nazwisko</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ user.lastName }}
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Nazwa użytkownika</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ user.username }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">E-mail</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        <a href="mailto:{{ user.email }}">{{ user.email }}</a>
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Grupy</div>
                    <div class="col-sm-11 clearfix font-weight-bold dark-color">
                        <div *ngFor="let group of user.groups">{{ group.name }}</div>
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Grupy maszyn</div>
                    <div class="col-sm-11 clearfix font-weight-bold dark-color">
                        <div *ngFor="let machineGroup of user.machineGroups">{{ machineGroup.codename }}</div>
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Karty</div>
                    <div class="col-sm-11 clearfix font-weight-bold dark-color">
                        <div *ngFor="let card of user.rfids">{{ card.rfid }}</div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Generuj listę obecności</div>
                    <div class="col-sm-11 clearfix font-weight-bold dark-color">
						<mat-icon>
                        {{user.includeInAttendanceList ? 'done' : 'close'}}
						</mat-icon>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Przewodniki na wielu grupach maszyn</div>
                    <div class="col-sm-11 clearfix font-weight-bold dark-color">
						<mat-icon>
                        {{user.allowMultipleMachineGroups ? 'done' : 'close'}}
						</mat-icon>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
