import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BackendPaginationService} from '../_services/backend-pagination.service';
import {Observable} from 'rxjs';
import {PaginatedTranslatedResponse} from '../_interfaces/PaginatedApiResponse';
import {ActionHistory, ActionHistoryFactory, ContentType} from './action-history';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ActionHistoryService {

    constructor(
        private http: HttpClient,
        private backendPaginationService: BackendPaginationService,
    ) {
    }

    public getAll(params?: Map<string, string | number | Array<string | number>>, options?: {headers?: HttpHeaders}):
        Observable<PaginatedTranslatedResponse<ActionHistory>> {
        return this.backendPaginationService.getAll<ActionHistory>(
            'action_history',
            data => ActionHistoryFactory.fromBackend(data),
            params,
            options,
        );
    }

    public getAllContentTypes(): Observable<ContentType[]> {
        return this.http.get<ContentType[]>(`${environment.apiUrl}/filters/content_type/`, );
    }

}
