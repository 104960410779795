<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@QualityControlEditPage">Kontrola jakości - ocena</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="qualityControl">
                <app-back-button></app-back-button>
            </div>

        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="qualityControl">
            <mat-card-content>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label" i18n="@@QualityControlGuideNumber">Nr przewodnika</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ qualityControl.guideNumber }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label" i18n="@@QualityControlFeature">Cecha</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ qualityControl.feature }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label" i18n="@@QualityControlOperation">Zadanie</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ qualityControl.operationName }}
                    </div>
                </div>
                <hr>

                <form *ngIf="editQualityControlForm" [formGroup]="editQualityControlForm" (ngSubmit)="onSubmit()">
                    <div class="form-group row align-items-center">
                        <label class="col-sm-1 col-form-label" i18n="@@QualityControlGrade">Ocena</label>
                        <div class="col-sm-3">
                            <ng-select i18n="@@QualityControlGradeResult"
                                       [items]="grades"
                                       [multiple]="false"
                                       [closeOnSelect]="true"
                                       [hideSelected]="true"
                                       bindLabel="name"
                                       placeholder="Nie ocenione"
                                       [formControlName]="'grade2'"
                                       (change)="changeGrade($event)">
                            </ng-select>
                            <div *ngIf="f.grade2.hasError('required')" i18n="@@GradeIsRequired">Ocena jest
                                <strong>wymagana</strong></div>
                            <div *ngIf="f.grade2.hasError('forbiddenValue')">Ocena jest <strong>wymagana</strong></div>
                        </div>
                    </div>

                    <div class="form-group row align-items-center">
                        <label for="name" class="col-sm-1 col-form-label">Notatka</label>
                        <div class="col-sm-3">
						<textarea class="form-control" id="name" placeholder="Notatka"
                                  [formControlName]="'note'"></textarea>
                        </div>
                    </div>

                    <div class="form-group row align-items-center">
                        <label class="col-sm-1 col-form-label" i18n="@@QualityControlAuthor">Kontroler</label>
                        <div class="col-sm-3">
                            <ng-select
                                [items]="users"
                                [multiple]="false"
                                [closeOnSelect]="true"
                                [hideSelected]="true"
                                bindLabel="authUserId"
                                placeholder="Autor"
                                [formControlName]="'author'"
                                (change)="changeAuthor($event)">
                                <ng-template ng-label-tmp let-item="item">
                                    {{item.firstName}} {{item.lastName}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    {{item.firstName}} {{item.lastName}}
                                </ng-template>
                            </ng-select>
                            <div *ngIf="f.author.hasError('required')">Autor jest <strong>wymagany</strong></div>
                        </div>
                    </div>

                    <div class="form-group row align-items-center">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-11">
                            <button class="button-spacer mat-button-custom mat-button-big" mat-button>
                                Oceń
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>
