import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export class Alert {
    id: string;
    type: AlertType;
    message: string;
    json: any;
    autoClose = false;
    keepAfterRouteChange: boolean;
    fade: boolean;

    constructor(init?: Partial<Alert>) {
        Object.assign(this, init);
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}


@Injectable({providedIn: 'root'})
export class AlertService {
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';

    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    success(message: string, options?: any): any {
        this.alert(new Alert({...options, autoClose: true, type: AlertType.Success, message}));
    }

    error(message: string, json?: any, options?: any): any {
        this.alert(new Alert({...options, type: AlertType.Error, message, json}));
    }

    info(message: string, options?: any): any {
        this.alert(new Alert({...options, type: AlertType.Info, message}));
    }

    warn(message: string, options?: any): any {
        this.alert(new Alert({...options, type: AlertType.Warning, message}));
    }

    // main alert method
    alert(alert: Alert): any {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId): any {
        this.subject.next(new Alert({id}));
    }
}
