import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { IndividualReport } from '../_models/individual-report';
import { ErrorHandlerService, ModalService, PermissionService, TranslatorService } from '../_services';
import { Title } from '@angular/platform-browser';
import { IndividualReportService } from '../_services/individual-report.service';
import { MatTableDataSource } from '@angular/material/table';
import {DateService} from '../_primitive_services';
import { BackendFilterService } from '../_filters/backend-filter.service';
import { EmployeeFilterConfig } from '../_filters/generic-filter/sample-filter-configs';
import { BackendParamService } from '../_filters/backend-param.service';
import {QueryParamService} from '../_filters/query-param.service';
import { environment } from 'src/environments/environment';
import {EditIndividualReportService} from './edit/edit-individual-report.service';
import {Subscription} from 'rxjs';
import { EmployeeDay } from '../_models/employee-day';
import { EmployeeDayAdditionalInfo } from '../_models/employee-day-additional-info';
import {FilterConfigYearMonth} from '../_filters/components/filter-year-month/filter-year-month.component';

@Component({
    selector: 'app-individual-report',
    templateUrl: './individual-report.component.html',
    styleUrls: ['./individual-report.component.css'],
    providers: [
        {provide: BackendParamService, useClass: QueryParamService},
    ]
})
export class IndividualReportComponent implements OnInit, OnDestroy {
    individualReport: IndividualReport;
    columnsToDisplay = ['day', 'workStartHour', 'workEndHour', 'guideWorkTime', 'workTime', 'totalBreak', 'causeOfAbsence', 'actions'];
    filtersToDisplay = ['year', 'month', 'employee'];
    dateService = DateService;
    dataSource: MatTableDataSource<IndividualReport>;
    dayToEdit: string;
    additionalInfo: EmployeeDayAdditionalInfo;
    month: string | number | string[] | number[];
    year: string | number | string[] | number[];
    employeeId: number;
    environment = environment;
    @ViewChild('editModal') editModal;
    @ViewChild('workTimeModal') workTimeModal;
    private editSubscription: Subscription = null;

    constructor(public individualReportService: IndividualReportService,
                private filterService: BackendFilterService,
                public queryParamService: BackendParamService,
                private errorHandler: ErrorHandlerService,
                private titleService: Title,
                public permissionService: PermissionService,
                public modalService: ModalService,
                public translatorService: TranslatorService,
                private editIndividualReportService: EditIndividualReportService,
                ) {
        this.titleService.setTitle('Indywidualny raport');
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new EmployeeFilterConfig('employee', 'Pracownik', false),
            new FilterConfigYearMonth('month', 'Wybierz miesiąc'),
        ]);
        this.setInitialFilters();
        this.subscribeOnChanges();
        this.editSubscription = this.editIndividualReportService.subscribe(() => this.refreshData());
    }

    private setInitialFilters(): void{
        if (!this.getBackendParamMap().has('month')) {
            const initialFilters = new Map<string, Array<string>>();
            initialFilters.set('year', [new Date().getFullYear().toString()]);
            initialFilters.set('month', [(new Date().getMonth() + 1).toString()]);
            this.queryParamService.addQueryParams(initialFilters, true);
        }
    }

    refreshData(): void {
        const newParamMap = new Map(this.getBackendParamMap());
        if (newParamMap.has('month') && newParamMap.has('employee')) {
            const employeeId = newParamMap.get('employee');
            newParamMap.delete('employee');
            this.year = newParamMap.get('year');
            this.month = newParamMap.get('month');
            this.employeeId = parseInt(employeeId.toString(), 10);
            this.errorHandler.showLoader(true);
            this.individualReportService.getAll(this.employeeId, newParamMap).subscribe(individualReports => {
                this.errorHandler.showLoader(false);
                this.individualReport = individualReports;
            });
        }
    }

    selectDay(day: EmployeeDay, modal: string): void {
        this.dayToEdit = day.day;
        this.additionalInfo = day.employeeDayAdditionalInfo;

        this.modalService.open(this[modal]);
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => {
            this.refreshData();
        });
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }

    ngOnDestroy(): void {
        if (this.editSubscription !== null){
            this.editSubscription.unsubscribe();
            this.editSubscription = null;
        }
    }

}
