<p i18n="@@UserFilter" class="filter-title">{{filterConfig.title}}</p>
<div>
    <app-filter-choice-list
        [filterConfig]="converter.toChoiceList(filterConfig)"
        *ngIf="filterConfig.type===FilterType.ChoiceList"
    ></app-filter-choice-list>
    <app-filter-checkbox
        [filterConfig]="converter.toCheckbox(filterConfig)"
        *ngIf="filterConfig.type===FilterType.Checkbox"
    ></app-filter-checkbox>
    <app-filter-string-input
        *ngIf="filterConfig.type===FilterType.StringInput"
        [filterConfig]="converter.toStringInput(filterConfig)"
    ></app-filter-string-input>
    <app-filter-date-range
        [filterConfig]="converter.toDateRange(filterConfig)"
        *ngIf="filterConfig.type===FilterType.DatePicker"
    ></app-filter-date-range>
    <app-filter-year-month
        *ngIf="filterConfig.type===FilterType.DateMonthPicker"
        [filterConfig]="converter.toYearMonth(filterConfig)"
    ></app-filter-year-month>
</div>
<hr>
