import {Injectable} from '@angular/core';
import {Sort, SortDirection} from '@angular/material/sort';
import {BackendParamService} from '../_filters/backend-param.service';

@Injectable({
    providedIn: 'root'
})
export class SortService {



    constructor(private queryParamService: BackendParamService) {
    }

    private sortObject: Sort;

    private static getActiveFromParams(params: Array<string>): string {
        let active = '';
        for (const param of params){
            if (active !== ''){
                active += ',';
            }
            if (param[0] === '-'){
                active += param.substr(1);
            }
            else {
                active += param;
            }
        }

        return active;
    }

    private static getDirectionFromParams(params: Array<string>): SortDirection {
        if (params[0][0] === '-') {
            return 'desc';
        }
        else {
            return 'asc';
        }
    }

    private getParams(): Map<string, Array<string>>{


        const ret = new Map<string, Array<string>>();
        if (!this.sortObject) {
            return ret;
        }
        const direction = this.sortObject.direction;
        const sortFields = [];
        sortFields.push(...this.sortObject.active.split(','));
        let directionPrefix = '';
        if (direction === 'desc'){
            directionPrefix = '-';
        }
        else if (direction !== 'asc'){
            sortFields.length = 0;
        }
        ret.set('ordering', [directionPrefix + sortFields.join(',' + directionPrefix)]);
        return ret;

    }

    enableSorting(sortObject: Sort | null): void{
        if (sortObject){
            this.sortObject = sortObject;
        }
        this.refreshSorting(this.queryParamService.getQueryParamMap());
        this.queryParamService.addQueryParams(this.getParams(), true);

        this.queryParamService.subscribe(() => {
            this.refreshSorting(this.queryParamService.getQueryParamMap());
        });
    }

    refreshSorting(map: Map<string, Array<string>>): void{
        const params = map.get('ordering');
        if (params && params.length > 0) {
            if (params[0].length > 0) {
                this.sortObject.direction = SortService.getDirectionFromParams(params);
                this.sortObject.active = SortService.getActiveFromParams(params);
            }
        }
    }

    sortData(sortObject: Sort): void {
        this.sortObject = sortObject;
        this.queryParamService.addQueryParams(this.getParams(), true);
    }
}
