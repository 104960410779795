import { Injectable } from '@angular/core';
import { Contractor } from 'src/app/_models/contractor';
import { HttpClient } from '@angular/common/http';
import { ApiTranslatorService } from './api-translator.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {HttpService} from './http.service';
import {MultiRequestService} from './multi-request.service';

@Injectable({
    providedIn: 'root'
})
export class ContractorService {

    constructor(
        private http: HttpClient,
        private apiTranslator: ApiTranslatorService,
        private httpService: HttpService,
        private multiRequestService: MultiRequestService,
    ) {
    }

    getAll(params: Map<string, string | number | Array<string | number>>): Observable<any> {
        const path = this.httpService.parseURL(params);
        return this.multiRequestService.getAll<Contractor[]>(`${environment.apiUrl}/contractor/${path}`)
            .pipe(map(contractors => contractors.map(data => new Contractor(data))));
    }

    getFilterAllContractors(): Observable<Contractor[]> {
        return this.http.get<Contractor[]>(`${environment.apiUrl}/filters/contractor/`).pipe(map(contractors => {
            return contractors.map(x => new Contractor(x));
        }));
    }

    getDetails(id): Observable<Contractor> {
        return this.http.get<Contractor>(`${environment.apiUrl}/contractor/` + id + `/`).pipe(map(x => new Contractor(x)));
    }

    updateContractor(contractor): any {
        contractor = this.apiTranslator.modelToSnakeCase(contractor);
        return this.http.put<Contractor>(`${environment.apiUrl}/contractor/` + contractor.id + `/`, contractor);
    }

    createContractor(contractor): Observable<Contractor> {
        contractor = this.apiTranslator.modelToSnakeCase(contractor);
        console.log(contractor);
        return this.http.post<Contractor>(`${environment.apiUrl}/contractor/`, contractor);
    }
}
