import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { Contractor, Offer, User } from '../../_models';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ApiTranslatorService,
    ContractorService,
    ErrorHandlerService,
    ModalService,
    OfferService, PermissionService,
    UserService
} from '../../_services';
import { Title } from '@angular/platform-browser';
import { OfferPosition } from '../../_models/offerPosition';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { first } from 'rxjs/operators';
import {AlertService, MoneyService} from '../../_primitive_services';
import {Feature} from '../../_models/feature';
import {FeatureSearchComponent} from '../feature-search/feature-search.component';

@Component({
    selector: 'app-offer-edit',
    templateUrl: './offer-edit.component.html',
    styleUrls: ['./offer-edit.component.css']
})
export class OfferEditComponent implements OnInit, AfterViewInit {
    private readonly DEFAULT_OFFER_NOTE: string = '' +
        'Termin wykonania obowiązuje w przypadku dostarczenia pełnej dokumentacji - rysunki ' +
        'płaskie z wymiarami oraz modele 3D. W innym przypadku termin realizacji wydłuża się o ' +
        'jeden tydzień a cena wzrasta o 10%';

    constructor(private activatedRoute: ActivatedRoute,
                private offerService: OfferService,
                private userService: UserService,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                private contractorService: ContractorService,
                private apiTranslator: ApiTranslatorService,
                private formBuilder: FormBuilder,
                private titleService: Title,
                private router: Router,
                public modalService: ModalService,
                private fb: FormBuilder,
                public permissionService: PermissionService,
                ) {

        this.titleService.setTitle('Oferta - edycja');
        this.rows = this.fb.array([]);
    }

    get f(): any {
        return this.editOfferForm.controls;
    }
    offer: Offer;
    editOfferForm: FormGroup;
    author: { authUserId: number, fullName: string };
    rows: FormArray;
    users: User[];
    contractor: { id: number, name: string };
    contractors: Contractor[] = [];
    units = ['zł/szt.', 'zł/kpl.', 'zł.', '€/szt', '€/kpl', '€'];
    columnsToDisplay = ['feature', 'name', 'quantity', 'price', 'remove'];
    languages: Array<string> = ['en', 'pl'];
    loading: boolean;
    isEditComponent: boolean;
    title: string;

    @ViewChild(MatTable) matPositions: MatTable<any>;
    @ViewChild(FeatureSearchComponent) featureSearch: FeatureSearchComponent;


    private lastContractorSearch = '';
    private lastUnitsSearch: string = null;
    private lastAuthorSearch = '';
    moneyService = MoneyService;

    ngOnInit(): void {
        this.isEditComponent = this.activatedRoute.snapshot.paramMap.has('id');
        this.setTitle();
        this.errorHandler.showLoader(true);
        this.userService.getFilterAllUsers().subscribe(users => {
            this.users = users;
        });
        this.contractorService.getFilterAllContractors().subscribe(contractors => {
            this.contractors = contractors;
        });
        if (this.isEditComponent){
            this.offerService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(offer => {
                this.offer = offer;
                this.contractor = {id: offer.contractor, name: offer.contractorName};
                this.author = {authUserId: offer.author, fullName: offer.authorName};
                this.buildForm();
            });
        }else {
            this.offer = new Offer();
            this.offer.author = this.permissionService.userProfile.authUserId;
            this.offer.authorName = this.permissionService.userProfile.getName();
            this.offer.note = this.DEFAULT_OFFER_NOTE;
            this.offer.language = this.languages[1];
            this.contractor = null;
            this.offer.paymentCondition = null;
            this.author = {authUserId: this.offer.author, fullName: this.offer.authorName};
            this.buildForm();
        }
    }

    buildForm(): void {
        this.editOfferForm = this.formBuilder.group({
            contractorName: [this.offer.contractorName],
            contractor: [this.contractor, Validators.required],
            expirationDuration: [this.offer.expirationDuration, [Validators.required, Validators.maxLength(1024)]],
            executionDuration: [this.offer.executionDuration, [Validators.required]],
            author: [this.author, Validators.required], // [this.offer.author], // this.offer.author,
            note: [this.offer.note],
            topNote: [this.offer.topNote],
            paymentCondition: [this.offer.paymentCondition],
            language: [this.offer.language, Validators.required], // this.languages,
        });
        if (!this.permissionService.checkPermission('perm_write_offer_author')) {
            this.editOfferForm.get('author').disable();
        }
        this.offer.positions.forEach((position, index) => {
            this.addOperationToForm(position.uniqueId, position);
        });

        this.errorHandler.showLoader(false);
    }

    addOperationToForm(index: number, position: OfferPosition): void{
        this.editOfferForm.addControl('positionName' + index, this.formBuilder.control(position.name));
        this.editOfferForm.addControl('positionFeature' + index, this.formBuilder.control(position.feature));
        this.editOfferForm.addControl('positionPriceZl' + index, this.formBuilder.control(position.getPriceZl()));
        this.editOfferForm.addControl('positionPriceGr' + index, this.formBuilder.control(position.getPriceGr()));
        this.editOfferForm.addControl('positionPriceUnits' + index, this.formBuilder.control(position.priceUnits));
    }

    removeOperationFromForm(index: number): void{
        this.editOfferForm.removeControl('positionName' + index);
        this.editOfferForm.removeControl('positionFeature' + index);
        this.editOfferForm.removeControl('positionPriceZl' + index);
        this.editOfferForm.removeControl('positionPriceGr' + index);
        this.editOfferForm.removeControl('positionPriceUnits' + index);
    }

    private setTitle(): void{
        this.title = this.isEditComponent ? 'Oferta - edycja' : 'Oferta - dodawanie';
        this.titleService.setTitle(this.title);
    }

    onSubmit(): any {
        if (this.editOfferForm.invalid) {
            return;
        }
        if (this.isEditComponent){
            this.offerService.updateOffer(this.offer).pipe(first()).subscribe(
                data => {
                    this.router.navigate(['/offer', this.offer.id]);
                    this.alertService.success(Offer.modelName + ': <strong>' + data.number + '</strong> została pomyślnie edytowana');

                }, error => {
                    this.alertService.error(Offer.modelName + ' nie została edytowana. Błąd: ', this.errorHandler.error(error));

                }
            );
        }else {
            this.offerService.createOffer(this.offer).pipe(first()).subscribe(
                data => {
                    this.router.navigate(['/offer', data.id]);
                    this.alertService.success(
                        Offer.modelName + ': <strong>' + data.number + '</strong> została pomyślnie utworzona', {autoClose: true});
                }, error => {
                    console.log(error);
                    this.alertService.error(Offer.modelName + ' nie została utworzona. Błąd: ', this.errorHandler.error(error));

                    // this.loading = false;
                }
            );
        }
    }

    isInvalid(name: string): boolean {
        console.log(name);
        return this.editOfferForm.get(name).invalid;
    }

    changeLanguage(event): any {
        this.offer.language = event;
    }

    onAddPositionElement(): void {
        this.offer.positions.push(new OfferPosition({
            id: undefined,
            quantity: null,
            price: 0,
            priceUnits: '',
            name: null,
            feature: null
        }));
        this.addOperationToForm(
            this.offer.positions[this.offer.positions.length - 1].uniqueId, this.offer.positions[this.offer.positions.length - 1]);
        this.matPositions.renderRows();
    }

    onRemovePositionElement(position: OfferPosition): void {
        const index: number = this.offer.positions.indexOf(position);
        if (index !== -1) {
            this.removeOperationFromForm(position.uniqueId);
            this.offer.positions.splice(index, 1);
            this.matPositions.renderRows();
        }
    }

    onDropPositionElement(event: CdkDragDrop<OfferPosition[]>): void {
        moveItemInArray(this.offer.positions, event.previousIndex, event.currentIndex);
        this.matPositions.renderRows();
    }

    inputQuantity(position: any, event: Event): void {
        // @ts-ignore
        position.quantity = event.target.value === '' ? null : event.target.value;
    }

    changeContractor(event): void {
        this.lastContractorSearch = '';
        if (event) {
            this.offer.contractor = event.id;
            this.offer.contractorName = event.name;
        } else {
            this.offer.contractor = null;
            this.offer.contractorName = null;
        }
    }

    onContractorSearch(event: {term: string, items: any[]}): void {
        this.lastContractorSearch = event.term;
    }

    onContractorBlur(): void {
        if (this.lastContractorSearch) {
            this.offer.contractor = null;
            this.offer.contractorName = this.lastContractorSearch;
            this.editOfferForm.get('contractor').patchValue({id: null, name: this.lastContractorSearch});
        }
    }

    changeAuthor(event): void {
        this.lastAuthorSearch = '';
        if (event) {
            this.offer.author = event.authUserId;
            this.offer.authorName = event.fullName;
        } else {
            this.offer.author = null;
            this.offer.authorName = null;
        }
    }


    onAuthorSearch(event: {term: string, items: any[]}): void {
        this.lastAuthorSearch = event.term;
    }

    onAuthorBlur(): void {
        if (this.lastAuthorSearch) {
            this.offer.author = null;
            this.offer.authorName = this.lastAuthorSearch;
            this.editOfferForm.get('author').patchValue({id: null, fullName: this.lastAuthorSearch});
        }
    }


    changeUnits(position: OfferPosition, event): void {
        this.lastUnitsSearch = null;
        if (event) {
            position.priceUnits = event;
        } else {
            position.priceUnits = null;
        }
    }

    onUnitsSearch(position: OfferPosition, event: {term: string, items: any[]}): void {
        this.lastUnitsSearch = event.term;
    }

    onUnitsBlur(position: OfferPosition, ): void {
        if (this.lastUnitsSearch !== null) {
            position.priceUnits = this.lastUnitsSearch;
            this.editOfferForm.get('positionPriceUnits' + position.uniqueId).patchValue(this.lastUnitsSearch);
        }
    }

    changeSimpleValue(event, field: string): void {
        this.offer[field] = event.target.value;
    }

    ngAfterViewInit(): void {
        this.featureSearch.featureSubject.asObservable().subscribe(offer => this.selectOffer(offer));
    }

    selectOffer(feature: Feature): void {
        this.addFeature(feature);
    }

    addFeature(feature: Feature): void {
        this.offer.positions.push(new OfferPosition({
            id: undefined,
            quantity: feature.quantity,
            price: feature.price,
            priceUnits: '',
            name: feature.name,
            feature: feature.feature
        }));
        this.addOperationToForm(
            this.offer.positions[this.offer.positions.length - 1].uniqueId, this.offer.positions[this.offer.positions.length - 1]);
        this.matPositions.renderRows();
    }

}
