import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../_services';
import {BackendParamService} from '../../backend-param.service';
import {FilterConfig, FilterType} from '../../generic-filter/filter-config';
import {DisplayElement} from '../../generic-filter/display-element';

export class FilterConfigCheckbox extends FilterConfig {
    filterParamName: string;
    choices: DisplayElement[];
    title: string;
    type: FilterType;


    constructor(filterParamName: string, choices: DisplayElement[], title: string) {
        super(title, FilterType.Checkbox, filterParamName);
        this.choices = choices;
    }
}

@Component({
    selector: 'app-filter-checkbox',
    templateUrl: './filter-checkbox.component.html',
    styleUrls: ['./filter-checkbox.component.css']
})
export class FilterCheckboxComponent implements OnInit, AfterViewInit {


    @Input() filterConfig: FilterConfigCheckbox;

    constructor(
        public translator: TranslatorService,
        private queryParamService: BackendParamService
    ) { }

    ngOnInit(): void {
    }

    refreshFilterBasedOnParamMap(map: Map<string, Array<string>>): void{

        const filterNamesToCheck = [this.filterConfig.filterParamName];
        if (this.filterConfig.choices){
            for (const item of this.filterConfig.choices){
                if (item.filterParamOverride){
                    if (filterNamesToCheck.indexOf(item.filterParamOverride) === -1) {
                        filterNamesToCheck.push(item.filterParamOverride);
                    }
                }
            }
        }
        for (const choice of this.filterConfig.choices) {
            choice.checked = false;
        }
        for (const filterParamName of filterNamesToCheck) {
            if (map.has(filterParamName)) {
                const chosenIds = new Set(map.get(filterParamName));
                for (const choice of this.filterConfig.choices) {
                    if ((choice.filterParamOverride || this.filterConfig.filterParamName) === filterParamName) {
                        choice.checked = choice.checked || chosenIds.has(choice.id.toString());
                    }
                }
            }
        }
    }

    ngAfterViewInit(): void{
        this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        this.queryParamService.subscribe(() => {
            this.refreshFilterBasedOnParamMap(this.queryParamService.getQueryParamMap());
        });
    }

    removeItem(item: DisplayElement): void {
        this.queryParamService.popFromQueryParam(item.filterParamOverride || this.filterConfig.filterParamName,
            item.id);
    }

    addItem(item: DisplayElement): void {
        this.queryParamService.appendToQueryParam(item.filterParamOverride || this.filterConfig.filterParamName,
            item.id);
    }

    changeSelection(item: DisplayElement): void {
        item.checked = !item.checked;
        if (item.checked){
            this.addItem(item);
        }
        else{
            this.removeItem(item);
        }
    }
}
