import {Component, Input} from '@angular/core';
import {Clickable} from '../../_services/multi-selection.service';
import {FilterConfig, FilterType} from './filter-config';
import {FilterConfigConverter} from './filter-config-converter';

@Component({
    selector: 'app-generic-filter',
    templateUrl: './generic-filter.component.html',
    styleUrls: ['./generic-filter.component.css']
})
export class GenericFilterComponent {

    FilterType = FilterType;
    converter = FilterConfigConverter;

    @Input() filterConfig: FilterConfig;


    constructor() {
    }
}
