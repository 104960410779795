<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UsersPage">Konfiguracja skanerów</h1>
            <span class="spacer"></span>
        </mat-toolbar>
        <mat-table [dataSource]="scannerConfigs"
                   *ngIf="scannerConfigs" class="mat-effects">
            <ng-container matColumnDef="scannerId">
                <mat-header-cell *matHeaderCellDef >Numer seryjny
                </mat-header-cell>
                <mat-cell *matCellDef="let scannerConfig"> {{scannerConfig.scannerId}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="scannerType">
                <mat-header-cell *matHeaderCellDef >Rodzaj
                </mat-header-cell>
                <mat-cell *matCellDef="let scannerConfig"> {{scannerConfig.getType()}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="scannerAction">
                <mat-header-cell *matHeaderCellDef >Funkcja
                </mat-header-cell>
                <mat-cell *matCellDef="let scannerConfig">
                    <mat-select [(value)]="scannerConfig.action" (selectionChange)="updateAction(scannerConfig, $event)">
                        <mat-option *ngFor="let action of actions" [value]="action.id">
                            {{action.name}}
                        </mat-option>
                    </mat-select> </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="mat-operations">Opcje
                </mat-header-cell>
                <mat-cell *matCellDef="let scannerConfig" class="mat-operations">

                    <mat-icon (click)="modalService.deleteModalForm('/scanner-config', scannerConfig)"
                              matTooltip="Usunięcie skanera"
                              *ngIf="permissionService.checkPermission('perm_write_system_settings')">delete
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
