import {Component, OnInit} from '@angular/core';
import * as CanvasJS from 'src/assets/js/canvasjs.min';
import {DateService, MoneyService} from '../_primitive_services';
import {OrderDeadlineService} from '../order-deadline/order-deadline.service';
import {Title} from '@angular/platform-browser';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {ErrorHandlerService, TranslatorService} from '../_services';
import {BackendParamService} from '../_filters/backend-param.service';
import {OrderYearlyStat} from './order-yearly-stat';
import {YearFilterConfig} from '../_filters/generic-filter/sample-filter-configs';
import {QueryParamService} from '../_filters/query-param.service';
import {ChartData} from './chart-data';

@Component({
    selector: 'app-order-yearly-chart',
    templateUrl: './order-yearly-chart.component.html',
    styleUrls: ['./order-yearly-chart.component.css'],
    providers: [
        BackendFilterService, {provide: BackendParamService, useClass: QueryParamService},
    ]
})
export class OrderYearlyChartComponent implements OnInit {

    private orderYearlyStats: OrderYearlyStat[] = [];

    dateService = DateService;
    moneyService = MoneyService;
    private chart: CanvasJS.Chart;

    constructor(
        private orderDeadlineService: OrderDeadlineService,
        private titleService: Title,
        private filterService: BackendFilterService,
        private errorHandler: ErrorHandlerService,
        public queryParamService: BackendParamService,
        private translatorService: TranslatorService,
    ) {
        this.titleService.setTitle('Zamówienia - wykres');
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new YearFilterConfig('year', 'Rok', ),
        ]);
        this.setInitialFilters();
        this.subscribeOnChanges();
    }

    private setInitialFilters(): void{
        if (!this.queryParamService.getQueryParamMap().has('year')) {
            const initialFilters = new Map<string, Array<string>>();
            initialFilters.set('year', [new Date().getFullYear().toString()]);
            this.queryParamService.addQueryParams(initialFilters, true);
        }
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        const params = this.getBackendParamMap();
        this.orderDeadlineService.getYearlyStats(params).subscribe((response: OrderYearlyStat[]) => {
            this.errorHandler.showLoader(false);
            this.orderYearlyStats.length = 0;
            this.orderYearlyStats.push(...response);
            this.drawChart();
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => {
            this.refreshData();
        });
    }

    private getBackendParamMap(): Map<string, Array<string>> {
        const queryParamMap = this.queryParamService.getQueryParamMap();
        const hasYear = queryParamMap.has('year');
        const year = hasYear ? parseInt(queryParamMap.get('year')[0], 10) : undefined;
        const start: Date = hasYear ? new Date(year, 0) : new Date(2000, 0);
        const end: Date = hasYear ? new Date(year + 1, 0) : new Date(2100, 0);
        return new Map<string, Array<string>>([
            ['start', [this.dateService.dateToStringDate(start)]],
            ['end', [this.dateService.dateToStringDate(end)]],
        ]);
    }

    private drawChart(): void {

        const singleYear = this.isSingleYear();
        const title = this.getTitle(singleYear);

        this.chart = new CanvasJS.Chart('chartContainer', {
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: title,
            },
            axisY: {
                labelFormatter: e => {
                    return CanvasJS.formatNumber( e.value ) + ' zł';
                },
            },
            legend: {
                cursor: 'pointer',
                itemclick : e => this.toggleDataSeries(e),
            },
            toolTip: {
                shared: true,
                content: e => this.toolTipFormatter(e),
            },
            data: this.prepareChartData(singleYear),
        });
        this.chart.render();
    }


    private prepareChartData(singleYear: boolean): ChartData[] {
        const data: Map<number, ChartData> = new Map<number, ChartData>();

        for (const yearlyStat of this.orderYearlyStats){
            if (!data.has(yearlyStat.year)){
                data.set(yearlyStat.year, {
                    type: 'column',
                    showInLegend: !singleYear,
                    name: yearlyStat.year.toString(),
                    dataPoints: [],
                });
            }
        }

        for (const year of data.keys()){
            const dataPoints = data.get(year).dataPoints;
            for (let month = 1; month <= 12; month += 1){
                const yearlyStat = this.orderYearlyStats.find(el => el.year === year && el.month === month);
                dataPoints.push({
                    y: Math.floor((yearlyStat?.totalCost || 0) / 100),
                    label: this.translatorService.translateMonth(month),
                });
            }
        }
        return [...data.values()];
    }

    private isSingleYear(): boolean {
        if (this.orderYearlyStats.length === 0) {
            return true;
        } else {
            const firstYear = this.orderYearlyStats[0].year;
            const lastYear = this.orderYearlyStats[this.orderYearlyStats.length - 1].year;
            return firstYear === lastYear;
        }
    }

    private getTitle(singleYear: boolean): string {
        if (singleYear){
            const queryParamMap = this.queryParamService.getQueryParamMap();
            if (queryParamMap.has('year')){
                return `Wykres za rok ${queryParamMap.get('year')[0]}`;
            } else {
                return 'Wykres';
            }
        }
        else {
            return 'Zestawienie porównawcze';
        }
    }

    public toolTipFormatter(e): string {
        let str = '';
        for (const entry of e.entries){
            const str1 = '<span style= "color:' + entry.dataSeries.color + '">'
                + entry.dataSeries.name + '</span>: <strong>' +  CanvasJS.formatNumber(entry.dataPoint.y) + ' zł'
                + '</strong> <br/>' ;
            str = str.concat(str1);
        }
        return str;
    }

    public toggleDataSeries(e): void {
        if (this.chart) {
            e.dataSeries.visible = typeof (e.dataSeries.visible) !== 'undefined' && !e.dataSeries.visible;
            this.chart.render();
        }
    }
}
