import {OfferPosition} from './offerPosition';
import {DateService, MoneyService} from '../_primitive_services';


export class Offer {
    static modelName = 'Oferta';

    id: number;
    contractorName: string;
    creationDate: Date;
    // dateCreationDate: Date;
    number: string;
    contractor: number;
    expirationDuration: string;
    executionDuration: string;
    author: number;
    authorName: string;
    note: string;
    paymentCondition: string;
    language: string;
    positions: OfferPosition[] = [];
    checked: boolean;
    topNote: string;


    constructor(object?: Offer) {
        if (object) {
            this.id = object.id;
            this.contractorName = object.contractorName;
            // this.creationDate = object.creationDate;
            this.creationDate = this.stringToDate(object);
            this.number = object.number;
            this.contractor = object.contractor;
            this.expirationDuration = object.expirationDuration;
            this.executionDuration = object.executionDuration;
            this.author = object.author;
            this.authorName = object.authorName;
            this.note = object.note;
            this.paymentCondition = object.paymentCondition || null;
            this.language = object.language;
            this.positions = object.positions?.map(position => new OfferPosition(position));
            this.checked = false;
            this.topNote = object.topNote;

        }
    }

    topNoteWithBreaks(): string {
        return this.topNote.split('\n').join('<br>');
    }


    getModelName(): string {
        return 'Oferta';
    }

    getPath(): string {
        return 'offer';
    }

    stringToDate(object: any): Date {
        return DateService.stringDateToDate(object.creationDate);

    }

    dateToString(str): string {
        return DateService.dateToStringDate(str);
    }


    getName(): string {
        return this.number;
    }

    getPriceSumStr(): string {
        let sum = 0;
        for (const position of this.positions) {
            if (position.quantity === undefined || position.quantity === null) {
                sum += position.price;
            } else {
                sum += position.quantity * position.price;
            }
        }
        let units = 'zł';
        if (this.positions.length > 0 && this.positions[0].priceUnits.includes('€')){
            units = '€';
        }
        return MoneyService.parseMoneyWithUnits(sum, units);
    }

}
