<div class="modal-header">
    <h2 class="modal-title modal-title-custom" i18n="@@DeleteModalHeader">Potwierdzenie zamknięcia</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <span i18n="@@DeleteModal">Czy na pewno chcesz zamknąć element:</span> {{ model.getModelName() }} {{ model.getName() }}
</div>
<div class="modal-footer">
    <button mat-button class="mat-button-big button-spacer mat-button-cancel"
            (click)="activeModal.close('Close click')">Anuluj
    </button>
    <button mat-button class="mat-button-big" (click)="closeModel()">Zakończ</button>
</div>