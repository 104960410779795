import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, NavigationStart, Params, Router} from '@angular/router';
import {BackendParamService} from './backend-param.service';
import {Injectable} from '@angular/core';

@Injectable()
export class QueryParamService extends BackendParamService{
    private lastRoute?: string;

    constructor(private route: ActivatedRoute, private router: Router) {
        super();
        this.route.queryParamMap.subscribe((_) => {
            this.paramsCache = this.getQueryParamMap();
            this.filtersSubject.next(undefined);

        });
        this.router.events.subscribe(next => {
            if (next instanceof NavigationStart){
                if (this.lastRoute){
                    if (this.lastRoute !== next.url.split('?')[0]){
                        this.filtersSubject.complete();
                        this.filtersSubject = new BehaviorSubject<unknown>(undefined);
                    }
                }
                this.lastRoute = next.url.split('?')[0];
            }
        });
    }

    private static mapToObject(map: Map<string, Array<string>>): Params{
        const ret = {};
        for (const [key, value] of map.entries()){
            if (value.length > 0) {
                ret[key] = value.join(',');
            }
            else {
                ret[key] = null;
            }
        }
        return ret;
    }


    navigateToParamsWithChanges(replaceUrl: boolean = false): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: QueryParamService.mapToObject(this.paramsCache),
            replaceUrl,
        }).then(_ => undefined);
    }

    getQueryParamMap(): Map<string, Array<string>>{
        const ret: Map<string, Array<string>> =
            new Map<string, Array<string>>();
        const queryParams = this.route.snapshot.queryParamMap;
        for (const key of queryParams.keys){
            const paramsStr: string = queryParams.get(key);
            ret.set(key, paramsStr.split(','));
        }
        return ret;
    }

    isFilterActive(filterName: string): boolean {
        return this.paramsCache.has(filterName) && this.paramsCache.get(filterName).length > 0;
    }

}
