
export interface IOrderStatisticsOrder {
    id: number;
    month: string;
    contractor: number;
    contractorName: string;
    cost: number;
    contractorOrderNumber: string;
    creationDate: Date;
}

export interface IOrderStatistics {
    month: number;
    year: number;
    totalCost: number;
    orders: IOrderStatisticsOrder[];
}

export class OrderStatistics implements IOrderStatistics{
    month: number;
    orders: IOrderStatisticsOrder[];
    totalCost: number;
    year: number;

    constructor(month: number, orders: IOrderStatisticsOrder[], totalCost: number, year: number) {
        this.month = month;
        this.orders = orders;
        this.totalCost = totalCost;
        this.year = year;
    }
}


export class OrderStatisticsFactory {
    public static fromBackend(o: IOrderStatistics): OrderStatistics {
        return new OrderStatistics(
            o.month,
            o.orders,
            o.totalCost,
            o.year
        );
    }
}
