export class IScannerConfig {
    id: number;
    scannerId: string;
    type: string;
    action: string;
}

export class IWriteScannerConfig {
    action: string;
}


export class ScannerConfig {
    action: string;
    id: number;
    scannerId: string;
    type: string;

    constructor(action: string, id: number, scannerId: string, type: string) {
        this.action = action;
        this.id = id;
        this.scannerId = scannerId;
        this.type = type;
    }

    getType(): string {
        return this.type.toUpperCase();
    }

    getModelName(): string {
        return 'Konfiguracja skanera';
    }

    getPath(): string {
        return 'scanner_config';
    }
    getName(): string {
        return this.scannerId;
    }
}

export class ScannerConfigFactory {
    public static fromBackend(scannerConfig: IScannerConfig): ScannerConfig {
        return new ScannerConfig(
            scannerConfig.action,
            scannerConfig.id,
            scannerConfig.scannerId,
            scannerConfig.type,
        );
    }

    public static toBackend(scannerConfig: ScannerConfig): IWriteScannerConfig {
        return {
            action: scannerConfig.action,
        };
    }
}
