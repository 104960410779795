import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {BackendParamService} from './backend-param.service';
import {FilterConfig} from './generic-filter/filter-config';

@Injectable({
    providedIn: 'root'
})
export class BackendFilterService {

    constructor(private queryParamService: BackendParamService) {
    }

    filterConfigs: Array<FilterConfig> = [];
    private filtersClearSubject = new ReplaySubject();



    setFilterConfigs(filterConfigs: FilterConfig[]): void{
        this.filterConfigs.length = 0;
        this.filterConfigs.push(...filterConfigs);
    }

    addFilterConfig(filterConfig: FilterConfig): void{
        this.filterConfigs.push(filterConfig);
    }

    subscribeOnClear(callback: () => void): void {
        this.filtersClearSubject.asObservable().subscribe(callback);
    }


    removeAllFilters(): void {
        const paramsToRemove: string[] = [];
        for (const filterConfig of this.filterConfigs) {
            paramsToRemove.push(...filterConfig.getAllFilterParamNames());
        }
        this.queryParamService.removeAllQueryParams(paramsToRemove);
        this.filtersClearSubject.next();
    }

    joinMaps(maps: Array<Map<string, any>>): Map<string, any> {
        const joined = new Map<string, any>();
        for (const map of maps) {
            for (const [attr, value] of map.entries()) {
                joined.set(attr, value);
            }
        }
        return joined;
    }
}
