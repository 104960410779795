<mat-drawer-container class="page" autosize>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@OfferEdition">{{header}}</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="order">
                <button class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/order', order)">Usuń
                </button>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <mat-card class="mat-effects" *ngIf="order">
                <div class="form-group row align-items-center">
                    <label class="col-sm-1 col-form-label" i18n="@@OfferAuthor">Nazwa kontrahenta</label>
                    <div class="col-sm-3">
                        <ng-select
                            [items]="contractors"
                            [multiple]="false"
                            [closeOnSelect]="true"
                            [hideSelected]="false"
                            placeholder="Kontrahent"
                            bindLabel="name"
                            formControlName="contractorName"
                            (change)="changeContractor($event)"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                {{item.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                {{item.name}}
                            </ng-template>
                        </ng-select>
                        <div class="text-danger" *ngIf="f.contractorName.hasError('required')">
                            Kontrahent jest <strong>wymagany</strong>
                        </div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <label for="orderNumber" class="col-sm-1 col-form-label" i18n="@@NameEdit">Numer zamówienia</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="orderNumber" placeholder="Numer zamówienia"
                               formControlName="orderNumber"
                               (input)="changeValue('contractorOrderNumber', $event)">

                        <div class="text-danger" *ngIf="f.orderNumber.hasError('required')">
                            Numer jest <strong>wymagany</strong>
                        </div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <label for="creationDate" class="col-sm-1 col-form-label" i18n="@@NameEdit">Data utworzenia</label>
                    <div class="col-sm-3">
                        <input type="date" required class="form-control" id="creationDate" placeholder="Data utworzenia"
                               formControlName="creationDate"
                               (input)="changeDate('creationDate', $event)">
                    </div>
                    <mat-error *ngIf="formGroup.get('creationDate').invalid">
                        Data utworzenia jest wymagana
                    </mat-error>
                </div>
                <div class="form-group row align-items-center">
                    <label for="plannedEndDate" class="col-sm-1 col-form-label" i18n="@@NameEdit">Planowana data
                        zakończenia</label>
                    <div class="col-sm-3">
                        <input type="date" required class="form-control" id="plannedEndDate"
                               placeholder="Data ukończenia" formControlName="plannedEndDate"
                               (input)="changeDate('plannedEndDate', $event)">
                        <mat-error *ngIf="formGroup.get('plannedEndDate').invalid">
                            Planowana data zakończenia jest wymagana
                        </mat-error>
                    </div>
                </div>

                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="inner_order"
                                   [disabled]="isEditComponent"
                                   (input)="switchInnerOrder()"
                                   [checked]="order.innerOrder">
                            <label class="custom-control-label" for="inner_order">Zamówienie wewnętrzne</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="include_in_statistics"
                                   [disabled]="order.innerOrder"
                                   (input)="switchIncludeInStatistics()"
                                   [checked]="order.includeInStatistics"
                            >
                            <label class="custom-control-label" for="include_in_statistics">Uwzględnij w
                                statystykach</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="include_in_schedule"
                                   [disabled]="order.innerOrder"
                                   (input)="switchIncludeInSchedule()"
                                   [checked]="order.includeInSchedule"
                            >
                            <label class="custom-control-label" for="include_in_schedule">
                                Uwzględnij w harmonogramie</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="reclamation"
                                   (input)="switchReclamation()"
                                   [checked]="order.reclamation"
                            >
                            <label class="custom-control-label" for="reclamation">Reklamacja</label>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="form-group row align-items-center">
                    <label class="col-sm-1 col-form-label" i18n="@@Invoices">Faktury</label>
                    <div class="col-sm-3">
                        <div *ngFor="let invoice of order.invoices" class="row invoice-row">
                            <input placeholder="Nazwa faktury"
                                   type="text"
                                   formControlName="invoiceName{{invoice.clientGeneratedId}}"
                                   (input)="invoice.name = ($event.target.value)"
                                   class="form-control col-sm-7">

                            <div class="input-group input-group-multiple col-sm-5 input-group-price">
                                <input required type="number" class="form-control numeric-input input-zl" title="zł"
                                       formControlName="invoicePriceZl{{invoice.clientGeneratedId}}"
                                       (input)="invoice.setPriceZl($event.target.value)">
                                <div class="input-group-append input-group-middle">
                                    <div class="input-group-text">
                                        zł
                                    </div>
                                </div>
                                <input required type="number" class="form-control numeric-input input-gr" title="gr"
                                       formControlName="invoicePriceGr{{invoice.clientGeneratedId}}" max="99" min="0"
                                       (input)="invoice.setPriceGr($event.target.value)">
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        gr
                                    </div>
                                </div>
                            </div>

                            <mat-icon matTooltip="Usuń fakturę" class="remove-invoice"
                                      (click)="onRemoveInvoice(invoice)">
                                remove_circle
                            </mat-icon>

                        </div>

                        <div class="add-invoice" (click)="onAddInvoice()">+ Dodaj fakturę</div>
                    </div>
                    <mat-error *ngIf="formGroup.get('creationDate').invalid">
                        Planowana data zakończenia jest wymagana
                    </mat-error>
                </div>

            </mat-card>

            <button type="button" class="btn dark-blue btn-orderset" (click)="onAddOrderSet()">
                <mat-icon>add</mat-icon>
                Dodaj
                zestaw
            </button>

            <div cdkDropListGroup>
                <div *ngFor="let orderSet of order.orderSets" class="mat-spacer"
                     #orderSetsMatTable>
                    <mat-table [dataSource]="orderSet.positions" cdkDropList [cdkDropListData]="orderSet.positions"
                               (cdkDropListDropped)="onDropOrderPosition($event)" class="mat-effects">

                        <ng-container matColumnDef="orderSetName">
                            <mat-header-cell *matHeaderCellDef>
                                <!--suppress TypeScriptUnresolvedVariable -->
                                <input type="text"
                                       formControlName="setName{{orderSet.tableGeneratedId}}"
                                       (input)="orderSet.name=$event.target.value"
                                       class="form-control"
                                       [class]="{'form-error': formGroup.get('setName'+orderSet.tableGeneratedId).invalid}"
                                       [matTooltip]="formGroup.get('setName'+orderSet.tableGeneratedId).invalid ?
										   'Nazwa jest wymagana': ''"
                                       placeholder="Nazwa" required>
                            </mat-header-cell>
                        </ng-container>

                        <ng-container matColumnDef="orderSetQuantity">
                            <mat-header-cell *matHeaderCellDef>
                                <!--suppress TypeScriptUnresolvedVariable -->
                                <input *ngIf="!orderSet.dummy" type="number" matInput
                                       formControlName="setQuantity{{orderSet.tableGeneratedId}}"
                                       (input)="orderSet.quantity=$event.target.value"
                                       [matTooltip]="formGroup.get('setQuantity'+orderSet.tableGeneratedId).invalid ?
									   'Ilość jest wymagana': ''"
                                       class="form-control"
                                       [class]="{'form-error': formGroup.get('setQuantity'+orderSet.tableGeneratedId).invalid}"
                                       placeholder="Ilość" required />
                            </mat-header-cell>
                        </ng-container>

                        <ng-container matColumnDef="orderSetPrice">
                            <mat-header-cell *matHeaderCellDef>
                                <div  *ngIf="!orderSet.dummy">
                                    <input required type="number" class="form-control col-sm-3 numeric-input input-zl input-zl-big"
                                           formControlName="setPriceZl{{orderSet.tableGeneratedId}}"
                                           (input)="orderSet.setPriceZl($event.target.value)"
                                           [class]="{'form-error': formGroup.get('setPriceZl' + orderSet.tableGeneratedId).invalid}"
                                           [matTooltip]="formGroup.get('setPriceZl' + orderSet.tableGeneratedId).invalid ?
                                       'Cena jest wymagana': ''">
                                    <div class="input-group-append input-group-middle">
                                        <div class="input-group-text">
                                            zł
                                        </div>
                                    </div>
                                    <input
                                        required type="number" class="form-control col-sm-2 numeric-input input-gr"
                                        formControlName="setPriceGr{{orderSet.tableGeneratedId}}"
                                        (input)="orderSet.setPriceGr($event.target.value)"
                                        [class]="{
                                    'form-error': formGroup.get('setPriceGr' + orderSet.tableGeneratedId).invalid}"
                                        [matTooltip]="formGroup.get('setPriceGr' + orderSet.tableGeneratedId).invalid ?
                                    'Cena jest wymagana': ''"
                                    >
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            gr
                                        </div>
                                    </div>

                                </div>
                            </mat-header-cell>
                        </ng-container>

                        <ng-container matColumnDef="headerActions">
                            <mat-header-cell *matHeaderCellDef>
                                <mat-icon matTooltip="Dodaj pozycję" class="add-circle"
                                          (click)="onAddOrderPosition(orderSet)">add_circle
                                </mat-icon>
                                <mat-icon *ngIf="!orderSet.dummy" matTooltip="Usuń zestaw" (click)="onRemoveOrderSet(orderSet)">
                                    delete
                                </mat-icon>
                            </mat-header-cell>
                        </ng-container>

                        <ng-container matColumnDef="position_drag">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let position" class="col-form-label">
                                <mat-icon cdkDragHandle>drag_handle</mat-icon>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="position_feature">
                            <mat-header-cell *matHeaderCellDef>Cecha</mat-header-cell>
                            <mat-cell *matCellDef="let position">
                                <!--suppress TypeScriptUnresolvedVariable -->
                                <textarea cdkTextareaAutosize cdkAutosizeMinRows="1"
                                          [class]="{'form-error': formGroup.get('positionFeature'+position.clientId).invalid}"
                                          required type="text" class="form-control" [value]="position.feature"
                                          formControlName="positionFeature{{position.clientId}}"
                                          (input)="position.feature=$event.target.value"
                                          placeholder="Cecha"
                                          [matTooltip]="formGroup.get('positionFeature'+position.clientId).invalid ? 'Cecha jest wymagana': ''"></textarea>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="position_name">
                            <mat-header-cell *matHeaderCellDef>Nazwa</mat-header-cell>
                            <mat-cell *matCellDef="let position">
                                <!--suppress TypeScriptUnresolvedVariable -->
                                <textarea cdkTextareaAutosize cdkAutosizeMinRows="1"
                                          class="form-control" [value]="position.name"
                                          (input)="position.name=$event.target.value"
                                          placeholder="Nazwa"
                                ></textarea>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="position_price">
                            <mat-header-cell *matHeaderCellDef>Cena</mat-header-cell>
                            <mat-cell *matCellDef="let position">

                                <div class="input-group input-group-multiple col-sm-5 input-group-price">
                                    <input required type="number" class="form-control col-sm-3 numeric-input input-zl input-zl-big"
                                           formControlName="positionPriceZl{{position.clientId}}"
                                           (input)="position.setPriceZl($event.target.value)"
                                           [class]="{'form-error': formGroup.get('positionPriceZl' + position.clientId).invalid}"
                                           [matTooltip]="formGroup.get('positionPriceZl' + position.clientId).invalid ? 'Cena jest wymagana': ''">
                                    <div class="input-group-append input-group-middle">
                                        <div class="input-group-text">
                                            zł
                                        </div>
                                    </div>
                                    <input required type="number" class="form-control col-sm-2 numeric-input input-gr"
                                           formControlName="positionPriceGr{{position.clientId}}"
                                           (input)="position.setPriceGr($event.target.value)"
                                           [class]="{'form-error': formGroup.get('positionPriceGr' + position.clientId).invalid}"
                                           [matTooltip]="formGroup.get('positionPriceGr' + position.clientId).invalid ? 'Cena jest wymagana': ''">
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            gr
                                        </div>
                                    </div>
                                </div>


                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="position_quantity">
                            <mat-header-cell *matHeaderCellDef class="col-sm-3">Ilość</mat-header-cell>
                            <mat-cell *matCellDef="let position" class="col-sm-3">
                                <!--suppress TypeScriptUnresolvedVariable -->
                                <input
                                    [class]="{'form-error': formGroup.get('positionQuantity'+position.clientId).invalid}"
                                    required type="number"
                                    class="form-control col-sm-12"
                                    formControlName="positionQuantity{{position.clientId}}"
                                    (input)="position.quantity=$event.target.value"
                                    placeholder="Ilość"
                                    [matTooltip]="formGroup.get('positionQuantity'+position.clientId).invalid ? 'Ilość jest wymagana': ''">
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="position_with_guide">
                            <mat-header-cell *matHeaderCellDef>Wystawić przewodnik</mat-header-cell>
                            <mat-cell *matCellDef="let position">
                                <!--suppress TypeScriptUnresolvedVariable -->
                                <mat-checkbox formControlName="positionWithGuide{{position.clientId}}"
                                              (input)="position.withGuide=$event.target.checked"></mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="position_remove">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let position" style="justify-content: end">
                                <mat-icon matTooltip="Usuń pozycję"
                                          (click)="onRemoveOrderPosition(position, orderSet)">
                                    remove_circle
                                </mat-icon>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row
                            *matHeaderRowDef="['orderSetName', 'orderSetQuantity', 'orderSetPrice', 'headerActions']"
                            class="mat-header-row-inputs"></mat-header-row>
                        <mat-header-row *matHeaderRowDef="orderPositionColumnsToDisplay"
                                        class="mat-row-positions"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: orderPositionColumnsToDisplay;"
                                 cdkDragLockAxis="y" cdkDrag class="mat-row-positions"
                                 [cdkDragData]="row">
                        </mat-row>
                    </mat-table>

                </div>

            </div>

            <mat-card *ngIf="order" class="mat-effects">
                <mat-card-content class="totals">
                    <div class="sum">
                        <p>Suma:</p>
                        <span>{{order.getPriceSum()}}</span>
                    </div>
                    <div class="invoiced">
                        <p>Zafakturowane:</p>
                        <span>{{order.getInvoicedSum()}}</span>
                    </div>
                </mat-card-content>
            </mat-card>

            <div class="form-group row align-items-center">
                <div class="col-sm-12">
                    <button class="mat-button-custom mat-button-big" mat-button>
                        Zapisz
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="!isEditComponent && permissionService.checkPermission('perm_read_offer')" class="toggle-search"
         [class]="{'opened': sidenav.opened}">
        <button type="button" mat-button (click)="sidenav.toggle()">Wyszukiwarka</button>
    </div>
    <mat-drawer #sidenav position="end" mode="side">
        <app-offer-search #offerSearch></app-offer-search>
    </mat-drawer>
</mat-drawer-container>
