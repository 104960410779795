import {DateService} from '../_primitive_services';

export interface IOrderDeadline {
    accumulatedCost: number;
    contractorName: string;
    contractorOrderNumber: string;
    cost: number;
    creationDate: string;
    firstFeature: string;
    firstGuide: number;
    firstGuideNumber: string;
    guideCount: number;
    id: number;
    invoiceDate: string;
    order: number;
    plannedEndDate: string;
}

export class OrderDeadline {
    accumulatedCost: number;
    contractorName: string;
    contractorOrderNumber: string;
    cost: number;
    creationDate: Date;
    firstFeature: string;
    firstGuideNumber: string;
    guideCount: number;
    id: number;
    invoiceDate: Date;
    order: number;
    plannedEndDate: Date;
    firstGuide: number;

    constructor(accumulatedCost: number, contractorName: string, contractorOrderNumber: string, cost: number,
                creationDate: Date, firstFeature: string, firstGuideNumber: string, guideCount: number, id: number,
                invoiceDate: Date, order: number, plannedEndDate: Date, firstGuide: number) {
        this.accumulatedCost = accumulatedCost;
        this.contractorName = contractorName;
        this.contractorOrderNumber = contractorOrderNumber;
        this.cost = cost;
        this.creationDate = creationDate;
        this.firstFeature = firstFeature;
        this.firstGuideNumber = firstGuideNumber;
        this.guideCount = guideCount;
        this.id = id;
        this.invoiceDate = invoiceDate;
        this.order = order;
        this.plannedEndDate = plannedEndDate;
        this.firstGuide = firstGuide;
    }
}


export class OrderDeadlineFactory {
    public static fromBackend(orderDeadline: IOrderDeadline): OrderDeadline{
        return new OrderDeadline(
            orderDeadline.accumulatedCost,
            orderDeadline.contractorName,
            orderDeadline.contractorOrderNumber,
            orderDeadline.cost,
            DateService.stringDateToDateSkipTime(orderDeadline.creationDate),
            orderDeadline.firstFeature,
            orderDeadline.firstGuideNumber,
            orderDeadline.guideCount,
            orderDeadline.id,
            DateService.stringDateToDateSkipTime(orderDeadline.invoiceDate),
            orderDeadline.order,
            DateService.stringDateToDateSkipTime(orderDeadline.plannedEndDate),
            orderDeadline.firstGuide,
        );
    }
}
