import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor() {}

    parseURL(params: Map<string, string | number| Array<string|number>>): string {
        let path = '?';
        let addAmp = false;
        for (const [attr, value] of params.entries()) {
            path = path + `${addAmp ? '&' : ''}${attr}=${value.toString()}`;
            addAmp = true;
        }
        if (path === '?'){
            return '';
        }
        return path;

    }
}
