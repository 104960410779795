import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IndividualReport } from '../_models/individual-report';
import {HttpService} from './http.service';
import {map} from 'rxjs/operators';
import { EmployeeDayAdditionalInfo, IEmployeeDay } from '../_models/employee-day-additional-info';
import { EmployeeDay } from '../_models/employee-day';
import { IOrderStatistics, OrderStatisticsFactory } from '../_models/order-statistics';
import { MultiRequestService } from './multi-request.service';
import { IWorkTimes, WorkTimes, WorkTimesWrite } from '../_models/work-time';

@Injectable({
    providedIn: 'root'
})
export class IndividualReportService {
    reports: IndividualReport;
    i = 0;

    constructor(private http: HttpClient,
                private httpService: HttpService,
                private multiRequestService: MultiRequestService) {
    }

    public get allIndividualReports(): IndividualReport {
        return this.reports;
    }

    getAll(id: number, params?: Map<string, string | number | Array<string | number>>): Observable<IndividualReport> {
        let path = '';
        if (params) {
            if (params.size !== 0) {
                path = this.httpService.parseURL(params);
            }
        }
        return this.http.get<IndividualReport>(`${environment.apiUrl}/rcp/rcp_single_employee/${id}/${path}`)
            .pipe(map((reports) => {
                this.reports = new IndividualReport(reports);
                return new IndividualReport(reports);
            }));
    }

    updateEmployeeAdditionalInfo(employee, date, body: EmployeeDayAdditionalInfo): Observable<EmployeeDayAdditionalInfo> {
        date = date.split('-');
        const year = date[0];
        const month = date[1];
        const day = date[2];
        const requestBody: IEmployeeDay = { employeeDayAdditionalInfo: body };
        console.log(body);

        return this.http.patch<EmployeeDayAdditionalInfo>(
            `${environment.apiUrl}/rcp/rcp_single_employee/${employee}/${year}/${month}/${day}/`, requestBody);
    }

    getEmployeeWorkTime(employee: number, date: string): Observable<WorkTimes> {
        const dateArr = date.split('-');
        const year = dateArr[0];
        const month = dateArr[1];
        const day = dateArr[2];

        return this.multiRequestService
            .getAll<IWorkTimes>(`${environment.apiUrl}/rcp/rcp_multi_edit/${employee}/${year}/${month}/${day}/`)
            .pipe(map((data: IWorkTimes) => {
                return new WorkTimes(data);
            }));
    }

    updateOrCreateEmployeeWorkTime(method: string, employee: number, date: string, body: IWorkTimes): Observable<WorkTimes> {
        const dateArr = date.split('-');
        const year = dateArr[0];
        const month = dateArr[1];
        const day = dateArr[2];
        const bodyWrite = new WorkTimesWrite(date, body);
        console.log(bodyWrite);

        if (method === 'patch') {
            return this.http
                .patch<IWorkTimes>(`${environment.apiUrl}/rcp/rcp_multi_edit/${employee}/${year}/${month}/${day}/`, bodyWrite);
        } else if (method === 'put') {
            return this.http
                .put<IWorkTimes>(`${environment.apiUrl}/rcp/rcp_multi_edit/${employee}/${year}/${month}/${day}/`, bodyWrite);
        }
    }

    dayRecognizer(day: EmployeeDay): any {
        if (day) {
            const wasAbsent = !day.employeeDayAdditionalInfo || !day.employeeDayAdditionalInfo.causeOfAbsence;
            const isInThePast = new Date(day.day).getTime() < new Date().getTime();
            if (day.dayType.type === 'saturday') {
                return 'saturday';
            } else if (day.dayType.type === 'sunday') {
                return 'sunday';
            } else if (day.dayType.type === 'holiday') {
                return 'holiday';
            } else if (day.workTime === 0 && wasAbsent && isInThePast) {
                return 'absence';
            } else {
                return 'workday';
            }

        }
    }

}
