import { Component, OnInit } from '@angular/core';

import { AuthenticationService, PermissionService } from './_services';

// tslint:disable:component-selector
@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit {

    constructor(
        public authenticationService: AuthenticationService,
        public permissionService: PermissionService
    ) {
    }

    ngOnInit(): void {
        if (this.authenticationService.getCurrentAuthentication()){
            this.permissionService.getPermissions().then();
        }
        else {
            this.authenticationService.authenticationChangedObservable.subscribe(_ => {
                this.permissionService.getPermissions().then();
            });
        }
    }

    logout(): void {
        this.authenticationService.logout();
    }
}
