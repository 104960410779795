import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {multicast} from 'rxjs/operators';

@Injectable()
export class BarcodeScanQueueService {

    private queue: Subject<string> = new Subject<string>();
    private subscriber = this.queue.pipe(multicast(this.queue));

    constructor() {
    }

    public subscription(): Observable<string>{
        return this.subscriber;
    }

    public pushValue(barcode: string): void {
        this.queue.next(barcode);
    }

}
