<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UsersPage">Zamówienia</h1>
            <span class="spacer"></span>
            <a class="button-spacer mat-button-custom mat-add-button" mat-button routerLink="/order/create"
                    *ngIf="permissionService.checkPermission('perm_write_order')">
                <span class="add-circle">+</span> Dodaj zamówienie
            </a>
        </mat-toolbar>
        <pagination-controls id="main_list_pagination"
                             (pageChange)="paginationService.pageChanged($event)"
                             (pageBoundsCorrection)="paginationService.pageChanged($event)"
                             maxSize="13"
                             directionLinks="true"
                             responsive="true"
                             previousLabel="Wstecz"
                             nextLabel="Dalej"
        >
        </pagination-controls>
        <mat-table [dataSource]="orders  | paginate: {
              id: 'main_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }  | paginate: {
              id: 'bottom_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }" *ngIf="orders" class="mat-effects" matSort (matSortChange)="sortService.sortData($event)"
                   matSortActive="{{initialSort.active}}" matSortDirection="{{initialSort.direction}}">
            <ng-container matColumnDef="contractorName">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderCreation" mat-sort-header="contractor__name">Nazwa <br>kontrahenta
                </mat-header-cell>
                <mat-cell *matCellDef="let order"> {{order.contractorName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="contractorOrderNumber">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderContractor">
                    Numer <br>zlecenia
                </mat-header-cell>
                <mat-cell *matCellDef="let order"> {{order.contractorOrderNumber}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderCreation" mat-sort-header="creation_date">Data <br>stworzenia
                </mat-header-cell>
                <mat-cell *matCellDef="let order"> {{ dateService.dateToStringDate(order.creationDate) }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="plannedEndDate">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderCreation" mat-sort-header="planned_end_date">Planowana
                    data zakończenia
                </mat-header-cell>
                <mat-cell *matCellDef="let order"> {{dateService.dateToStringDate(order.plannedEndDate)}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="progress">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderCreation">Postęp</mat-header-cell>
                <mat-cell *matCellDef="let order"> {{order.progress}}/{{order.progressLimit}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@UserOperations">Opcje
                </mat-header-cell>
                <mat-cell *matCellDef="let order" class="mat-operations">

					<a routerLink="/order/{{order.id}}/">
						<mat-icon #tooltip="matTooltip"
								  i18n-matTooltip="@@OrderPoisitions"
								  matTooltip="Pozycje zamówienia">clear_all
						</mat-icon>
					</a>

					<a routerLink="/order/{{ order.id }}/edit">
						<mat-icon #tooltip="matTooltip"
								  i18n-matTooltip="@@OrderPoisitions"
								  matTooltip="Edytuj zamówienie">create
						</mat-icon>
					</a>

                    <mat-icon (click)="modalService.deleteModalForm('/order', order)" #tooltip="matTooltip"
                              i18n-matTooltip="@@OrderRemove" matTooltip="Usunięcie zamówienia"
                              *ngIf="permissionService.checkPermission('perm_write_order')
                              && !queryParamService.isFilterActive('in_trash')">delete
                    </mat-icon>
                    <mat-icon (click)="modalService.restoreModalForm('/order', order)"
                              i18n-matTooltip="@@OrderRestore"
                              matTooltip="Przywrócenie zamówienia"
                              *ngIf="permissionService.checkPermission('perm_write_order')
                              && queryParamService.isFilterActive('in_trash')">restore_from_trash
                    </mat-icon>

                    <mat-icon (click)="modalService.closeModalForm(order)" #tooltip="matTooltip"
                              i18n-matTooltip="@@OrderClose" matTooltip="Zakończenie zamówienia"
                              *ngIf="!order.isFinished()"
                    >highlight_off

                    </mat-icon>


                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="reclamation">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderCreation">Reklamacja</mat-header-cell>
                <mat-cell *matCellDef="let order">
                    <mat-icon>
                        {{isChecked('reclamation', order)}}
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;" [class]="getRowType(row)"></mat-row>
            <ng-container matColumnDef="grade">
                <mat-header-cell class="cdk-column-checkbox" *matHeaderCellDef
                                 (click)="multiSelectionService.checkboxForAllGuidesClick()">
                    <label for="gradesToChange"></label>
                    <mat-checkbox

                            (click)="$event.preventDefault()"
                            [checked]="multiSelectionService.allGuidesCheckbox.allChecked"
                            [indeterminate]="multiSelectionService.allGuidesCheckbox.indeterminated"
                    ></mat-checkbox>
                </mat-header-cell>
                <mat-cell class="cdk-column-checkbox" *matCellDef="let order" (click)="multiSelectionService.changeCheckbox(order)">
                    <mat-checkbox
                            id="gradesToChange"
                            (click)="$event.preventDefault()"
                            [checked]="order.checked"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
        </mat-table>
        <br>
        <br>
        <pagination-controls id="bottom_list_pagination"
                             (pageChange)="paginationService.pageChanged($event)"
                             (pageBoundsCorrection)="paginationService.pageChanged($event)"
                             maxSize="13"
                             directionLinks="true"
                             responsive="true"
                             previousLabel="Wstecz"
                             nextLabel="Dalej"
        >
        </pagination-controls>
    </div>
</div>
