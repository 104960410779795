<form *ngIf="filterService.filterConfigs">
    <div class="filter-form">
        <div class="filter-header">
            <span i18n="@@Filter">Filtry</span>
            <button class="button-spacer mat-button-custom" type="reset" (click)="filterService.removeAllFilters(); clear()"
                    mat-button><span class="times">&times;</span> Wyczyść
            </button>
        </div>
        <app-generic-filter class="filter-group" *ngFor="let filterConfig of filterService.filterConfigs"
          [filterConfig]="filterConfig"
          >
        </app-generic-filter>
    </div>
</form>
