import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import {BackendUpdateOrder, IOrder, Order} from '../_models/order';
import { HttpService } from './http.service';
import { PaginatedTranslatedResponse } from '../_interfaces/PaginatedApiResponse';
import { BackendPaginationService } from './backend-pagination.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {

    constructor(private http: HttpClient,
                private httpService: HttpService,
                private backendPaginationService: BackendPaginationService,
    ) {
    }

    getAll(params?: Map<string, string | number | Array<string | number>>):
        Observable<PaginatedTranslatedResponse<Order>> {
        return this.backendPaginationService.getAll<Order>('order',
            data => new Order(data), params);
    }

    getDetails(id): Observable<Order> {
        return this.http.get<IOrder>(`${environment.apiUrl}/order/` + id + `/`)
            .pipe(map(x => new Order(x)));
    }

    postOrder(order: Order): Observable<Order>{
        return this.http.post<IOrder>('${environment.apiUrl}/order/', order.convertToBackendOrder())
            .pipe(map(res => new Order(res)));
    }

    updateOrder(order: Order): Observable<Order> {
        const orderJ: BackendUpdateOrder = order.convertToBackendOrder();
        return this.http
            .put<IOrder>(`${environment.apiUrl}/order/${order.id}/`, orderJ)
            .pipe(map(res => new Order(res)));
    }

    createOrder(order: Order): Observable<Order> {
        const orderJ: BackendUpdateOrder = order.convertToBackendOrder();
        return this.http
            .post<IOrder>(`${environment.apiUrl}/order`, orderJ)
            .pipe(map(res => new Order(res)));
    }

    getOrder(id): Observable<Order> {
        return this.http.get<IOrder>(`${environment.apiUrl}/order/` + id + `/`).pipe(map(x => new Order(x)));
    }

}
