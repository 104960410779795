export class IMachineGroup {
    id: number;
    name: string;
    codename: string;
    workingCostPerHour: number;
    external?: boolean;
    activeGuides: IActiveGuide[];
}

export interface IActiveGuide {
    id: number;
    number: string;
}

export class MachineGroup implements IMachineGroup{
    static modelName = 'Grupa maszyn';
    static path = 'machine_group';

    id: number;
    name: string;
    codename: string;
    workingCostPerHour: number;
    external?: boolean;
    activeGuides: IActiveGuide[];


    constructor(object?: IMachineGroup) {
        if (object) {
            this.id = object.id;
            this.name = object.name;
            this.codename = object.codename;
            this.workingCostPerHour = object.workingCostPerHour;
            this.external = object.external;
            this.activeGuides = object.activeGuides;
        }
    }

    getModelName(): string {
        return MachineGroup.modelName;
    }

    getPath(): string {
        return MachineGroup.path;
    }
    getName(): string {
        return this.name;
    }
}
