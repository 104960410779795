export interface IOrderYearlyStat {
    month: number;
    year: number;
    totalCost: number;
}


export class OrderYearlyStat implements IOrderYearlyStat {
    month: number;
    totalCost: number;
    year: number;


    constructor(month: number, totalCost: number, year: number) {
        this.month = month;
        this.totalCost = totalCost;
        this.year = year;
    }
}

export class OrderYearlyStatFactory {
    public static fromBackend(o: IOrderYearlyStat): OrderYearlyStat {
        return new OrderYearlyStat(
            o.month,
            o.totalCost,
            o.year,
        );
    }
}
