import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IndividualReportService } from '../../_services/individual-report.service';
import { ErrorHandlerService, ModalService, TranslatorService } from '../../_services';
import { ActivatedRoute, Router } from '@angular/router';
import {EditIndividualReportService} from './edit-individual-report.service';
import { EmployeeDayAdditionalInfo } from '../../_models/employee-day-additional-info';
import { AlertService } from '../../_primitive_services';
import { Order } from '../../_models/order';

@Component({
    selector: 'app-edit-individual-report',
    templateUrl: './edit-individual-report.component.html',
    styleUrls: ['./edit-individual-report.component.css']
})
export class EditIndividualReportComponent implements OnInit {
    @Input() dayToEdit: string;
    @Input() employeeId: number;
    @Input() additionalInfo: EmployeeDayAdditionalInfo;

    formGroup: FormGroup;
    absenceCauses: string[] = ['leave', 'leave_on_request', 'care', 'unpaid_leave', 'l4', 'other'];

    constructor(
        private individualReportService: IndividualReportService,
        private formBuilder: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public modalService: ModalService,
        private alertService: AlertService,
        private errorHandler: ErrorHandlerService,
        public translatorService: TranslatorService,
        private editIndividualReportService: EditIndividualReportService,
        ){
    }

    ngOnInit(): void {
        this.buildForm();
        // console.log(this.dayToEdit);
        if (!this.additionalInfo) {
            this.additionalInfo = new EmployeeDayAdditionalInfo();
            this.additionalInfo.pauseOffsetInSeconds = 0;
            this.additionalInfo.workTimeOffsetInSeconds = 0;
            this.additionalInfo.guideWorkTimeOffsetInSeconds = 0;
        }
    }

    buildForm(): void {
        this.formGroup = this.formBuilder.group({
            causeOfAbsence: [this.additionalInfo?.causeOfAbsence],
            causeOfAbsenceDescription: [this.additionalInfo?.causeOfAbsenceDescription],
            pauseOffsetInSeconds: [this.additionalInfo?.pauseOffsetInSeconds / 60],
            workTimeOffsetInSeconds: [this.additionalInfo?.workTimeOffsetInSeconds / 60],
            guideWorkTimeOffsetInSeconds: [this.additionalInfo?.guideWorkTimeOffsetInSeconds / 60],
        });
    }

    onSubmit(): void {
        this.individualReportService.updateEmployeeAdditionalInfo(this.employeeId, this.dayToEdit, this.additionalInfo).subscribe(
            data => {
                this.editIndividualReportService.next();
                this.modalService.close();
                this.alertService.success('Dzień <strong>' + this.dayToEdit + '</strong> został pomyślnie edytowany.');
            }, error => {
                this.alertService.error('Dzień <strong>' + this.dayToEdit + '</strong> nie został edytowany. Błąd: ',
                    this.errorHandler.error(error));
                console.log(error);
            }
        );
    }

    changeSimpleValue(event, field: string): void {
        this.additionalInfo[field] = event.target.value;
    }

    changeCauseOfAbsence(event, causeOfAbsence: string): void {
        this.additionalInfo.causeOfAbsence = event;
    }

    changeTimeValue(event, timeField: string): void {
        this.additionalInfo[timeField] = event.target.value * 60;
    }
}
