<mat-form-field class="picker" appearance="fill">
    <mat-label>{{filterConfig.title}}</mat-label>
    <mat-date-range-input [rangePicker]="picker" disabled>
        <!--suppress AngularUndefinedBinding -->
        <input (dateInput)="setStartDate($event.target.value)" matStartDate placeholder="Data początkowa" [value]="startDate">
        <!--suppress AngularUndefinedBinding -->
        <input (dateInput)="setEndDate($event.target.value)" matEndDate placeholder="Data zakończenia" [value]="endDate">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix (click)="clearDates()">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker touchUi #picker disabled="false"></mat-date-range-picker>
</mat-form-field>
