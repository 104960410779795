import { Component, OnInit } from '@angular/core';
import { Offer } from '../../_models';
import { ActivatedRoute } from '@angular/router';
import {
    ApiTranslatorService, ErrorHandlerService,
    ModalService,
    OfferService,
    PermissionService,
    PositionService
} from '../../_services';
import { Title } from '@angular/platform-browser';
import { OfferPosition } from '../../_models/offerPosition';
import {DateService, MoneyService} from '../../_primitive_services';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-offer-details',
    templateUrl: './offer-details.component.html',
    styleUrls: ['./offer-details.component.css']
})
export class OfferDetailsComponent implements OnInit {
    offer: Offer;
    positions: OfferPosition[];
    columnsToDisplay = ['features', 'name', 'quantity', 'price'];
    moneyService = MoneyService;
    dateService = DateService;

    constructor(private activatedRoute: ActivatedRoute,
                private offerService: OfferService,
                private positionService: PositionService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                private errorHandler: ErrorHandlerService,
                public modalService: ModalService,
                public permissionService: PermissionService) {
        this.titleService.setTitle('Oferta - szczegóły');
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        this.offerService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(offer => {
            this.offer = offer;
            this.positions = this.offer.positions;

            this.errorHandler.showLoader(false);
        });
    }

    getPDF(id: number): void {
        const urlAdress = `${environment.apiUrl}/offer/${id}/pdf`;
        window.open(urlAdress, '_blank');

    }

}

