<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">

        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UsersPage">Kontrola jakości</h1>
            <span class="spacer"></span>
            <div class="actions">
                <button class="button-spacer mat-button-custom" mat-button (click)="setOK()"
                        *ngIf="permissionService.checkPermission('perm_write_quality_control')"> Ustaw jako OK
                </button>
                <span class="line" *ngIf="permissionService.checkPermission('perm_write_quality_control')">|</span>
                <button class="button-spacer mat-button-custom" mat-button (click)="setNotOK()"
                        *ngIf="permissionService.checkPermission('perm_write_quality_control')">Ustaw jako nie OK
                </button>
            </div>
        </mat-toolbar>
        <pagination-controls id="main_list_pagination"
                             (pageChange)="paginationService.pageChanged($event)"
                             (pageBoundsCorrection)="paginationService.pageChanged($event)"
                             maxSize="13"
                             directionLinks="true"
                             responsive="true"
                             previousLabel="Wstecz"
                             nextLabel="Dalej"
        >
        </pagination-controls>
        <mat-table [dataSource]="qualityControls | paginate: {
              id: 'main_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }  | paginate: {
              id: 'bottom_list_pagination',
              itemsPerPage: paginationService.getPageSize(),
              currentPage: paginationService.getPage(),
              totalItems: paginationService.getCount()
            }" class="mat-effects" matSort (matSortChange)="sortService.sortData($event)"
                   matSortActive="{{initialSort.active}}" matSortDirection="{{initialSort.direction}}">

            <ng-container matColumnDef="guide_number">
                <mat-header-cell *matHeaderCellDef i18n="@@UserFirstName"
                                 mat-sort-header="operation__guide__year','operation__guide__lp">
                    Numer przewodnika
                </mat-header-cell>
                <mat-cell *matCellDef="let qualityControl"><a
                        routerLink="/guide/{{qualityControl.guide.toString()}}}"> {{qualityControl.guideNumber}} </a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operationTime">
                <mat-header-cell *matHeaderCellDef i18n="@@OperationName">Czas w produkcji</mat-header-cell>
                <mat-cell *matCellDef="let qualityControl">
                    {{dateService.getTimeFromSec(qualityControl.operationTime)}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation_name">
                <mat-header-cell *matHeaderCellDef i18n="@@OperationName">Operacja</mat-header-cell>
                <mat-cell *matCellDef="let qualityControl"> {{qualityControl.operationName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="grade">
                <mat-header-cell *matHeaderCellDef i18n="@@Grade" mat-sort-header="grade">Ocena</mat-header-cell>
                <mat-cell *matCellDef="let qualityControl">
                    {{translator.translate(qualityControl.grade)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="select">
                <mat-header-cell class="cdk-column-checkbox" *matHeaderCellDef
                                 (click)="multiSelectionService.checkboxForAllGuidesClick()">
                    <label for="selectionsToChange"></label>
                    <mat-checkbox
                            (click)="$event.preventDefault()"
                            [checked]="multiSelectionService.allGuidesCheckbox.allChecked"
                            [indeterminate]="multiSelectionService.allGuidesCheckbox.indeterminated"
                    ></mat-checkbox>
                </mat-header-cell>
                <mat-cell class="cdk-column-checkbox" *matCellDef="let qualityControl"
                          (click)="multiSelectionService.changeCheckbox(qualityControl)">
                    <mat-checkbox
                            id="selectionsToChange"
                            (click)="$event.preventDefault()"
                            [checked]="qualityControl.checked"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@UserOperations">Opcje
                </mat-header-cell>
                <mat-cell *matCellDef="let qualityControl" class="mat-operations">
					<a routerLink="/quality-control/{{qualityControl.id}}/edit">
						<mat-icon *ngIf="(qualityControl.grade !== 'not_yet_reviewed' &&
								  permissionService.checkPermission('perm_write_quality_control_change_grade')) ||
								  (qualityControl.grade === 'not_yet_reviewed' &&
								  permissionService.checkPermission('perm_write_quality_control'))"
								  i18n-matTooltip="@@UserEdit" matTooltip="Sprawdzenie">
							edit
						</mat-icon>
					</a>
					<a routerLink="/quality-control/{{qualityControl.id}}">
						<mat-icon
								  i18n-matTooltip="@@UserDetails" matTooltip="Szczegóły kontroli jakości">list_alt
						</mat-icon>
					</a>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
        <br>
        <br>
        <pagination-controls id="bottom_list_pagination"
                             (pageChange)="paginationService.pageChanged($event)"
                             (pageBoundsCorrection)="paginationService.pageChanged($event)"
                             maxSize="13"
                             directionLinks="true"
                             responsive="true"
                             previousLabel="Wstecz"
                             nextLabel="Dalej"
        >
        </pagination-controls>
    </div>
</div>
