<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UserGroupsPage">Grupy Użytkowników</h1>
            <span class="spacer"></span>
            <a class="button-spacer mat-button-custom mat-add-button" mat-button routerLink="/group/create"
                    *ngIf="permissionService.checkPermission('perm_write_group')"><span
                    class="add-circle">+</span> Dodaj grupę użytkowników
            </a>
        </mat-toolbar>
        <mat-table [dataSource]="groups" class="mat-effects">
            <!-- Position Column -->
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef class="mat-lp" i18n="@@GroupID">ID</mat-header-cell>
                <mat-cell *matCellDef="let group" class="mat-lp"> {{group.id}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef i18n="@@GroupName">Nazwa</mat-header-cell>
                <mat-cell *matCellDef="let group"> {{group.name}}</mat-cell>
            </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@UserGroupOperations">Opcje
            </mat-header-cell>
            <mat-cell *matCellDef="let group" class="mat-operations">
				<a routerLink="/group/{{group.id}}/edit">
					<mat-icon i18n-matTooltip="@@GroupEdit"
							  *ngIf="permissionService.checkPermission('perm_write_group')"
							  matTooltip="Edycja grupy użytkowników">edit
					</mat-icon>
				</a>
				<a routerLink="/group/{{group.id}}">
					<mat-icon i18n-matTooltip="@@GroupDetails"
							  matTooltip="Szczegóły grupy użytkowników">list_alt
					</mat-icon>
				</a>
                <mat-icon (click)="modalService.deleteModalForm('/group',group)"
                          *ngIf="permissionService.checkPermission('perm_write_group')
                          && !queryParamService.isFilterActive('in_trash')"
                          i18n-matTooltip="@@GroupRemove" matTooltip="Usunięcie grupy użytkowników">delete
                </mat-icon>
                <mat-icon (click)="modalService.restoreModalForm('/group', group)"
                          i18n-matTooltip="@@GroupRestore"
                          matTooltip="Przywrócenie grupy użytkowników"
                          *ngIf="permissionService.checkPermission('perm_write_group')
                          && queryParamService.isFilterActive('in_trash')">restore_from_trash
                </mat-icon>
            </mat-cell>
        </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
