import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractorService, ErrorHandlerService, ModalService, PermissionService } from 'src/app/_services';
import { Title } from '@angular/platform-browser';
import { Contractor } from 'src/app/_models/contractor';

@Component({
  selector: 'app-contractor-details',
  templateUrl: './contractor-details.component.html',
  styleUrls: ['./contractor-details.component.css']
})
export class ContractorDetailsComponent implements OnInit {
    contractor: Contractor;

    constructor(private activatedRoute: ActivatedRoute,
                private contractorService: ContractorService,
                private titleService: Title,
                private errorHandler: ErrorHandlerService,
                public modalService: ModalService,
                public permissionService: PermissionService) {
        this.titleService.setTitle('Kontrahent - szczegóły');
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        this.contractorService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(contractor => {
            this.errorHandler.showLoader(false);
            this.contractor = contractor;
        });
    }

    isChecked(fieldName: string, index): string {
        return index[fieldName] ? 'done' : 'close';
    }

}
