import {Component, OnInit, ViewChild} from '@angular/core';
import {Contractor, Wz} from '../../_models';
import { AlertService, DateService, MoneyService } from '../../_primitive_services';
import { ContractorService, ErrorHandlerService, ModalService, OrderService, TranslatorService } from '../../_services';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '../../_models/order';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WzSetPositions } from '../../_models/wz-set-positions';
import { WzService } from '../../_services/wz.service';
import {MatTable} from '@angular/material/table';

@Component({
    selector: 'app-wz-create',
    templateUrl: './wz-create.component.html',
    styleUrls: ['./wz-create.component.css']
})
export class WzCreateComponent implements OnInit {

    wz: Wz = new Wz();
    order: Order;
    dateService = DateService;
    moneyService = MoneyService;
    columnsToDisplayWzPositions = ['lp', 'detail', 'price', 'quantity', 'check'];
    formGroup: FormGroup;
    formBuilder = new FormBuilder();
    contractors: Contractor[];
    isEditComponent: boolean;
    pageTitle: string;
    @ViewChild(MatTable) matPositions: MatTable<any>;

    private lastContractorSearch = '';

    constructor(
        public translatorService: TranslatorService,
        public titleService: Title,
        public modalService: ModalService,
        public activatedRoute: ActivatedRoute,
        public orderService: OrderService,
        public contractorService: ContractorService,
        public wzService: WzService,
        public router: Router,
        public alertService: AlertService,
        public errorHandler: ErrorHandlerService,
    ) {

    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        if (!this.activatedRoute.snapshot.paramMap.has('id')) {
            this.pageTitle = 'wz_create';
            this.isEditComponent = false;
            if (this.activatedRoute.snapshot.queryParams.order_id) {
                this.orderService.getDetails(parseInt(this.activatedRoute.snapshot.queryParams.order_id, 10)).subscribe(order => {
                    this.order = order;
                    this.wz.order = order.id;
                    this.wz.contractor = this.order.contractor;
                    this.wz.contractorOrderNumber = this.order.contractorOrderNumber;
                    this.wz.contractorName = this.order.contractorName;
                    this.wz.creationDate = new Date();
                    this.wz.wzSetPositions = this.order.orderSets.map(set => new WzSetPositions({
                        orderSet: set.id,
                        orderSetName: set.getNameForWz(),
                        price: set.getPriceForWz(),
                        quantity: set.getQuantityForWz()
                    }));
                    this.wz.wzSetPositions.forEach(set => set.checked = true);
                    this.buildForm();
                });
            } else {
                this.errorHandler.showLoader(false);
                this.order = null;
                this.wz.order = null;
                this.wz.contractor = null;
                this.wz.contractorOrderNumber = '';
                this.wz.contractorName = '';
                this.wz.creationDate = new Date();
                this.wz.wzSetPositions = [];
                this.wz.hasOrder = false;
                this.buildFormNoOrder();
            }
        } else {
            this.isEditComponent = true;
            this.pageTitle = 'wz_edit';
            this.wzService.getWz(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(wz => {
                this.wz = wz;
                this.wz.wzSetPositions.forEach(set => set.checked = true);
                if (wz.hasOrder) {
                    this.orderService.getOrder(wz.order).subscribe(order => {
                        this.order = order;
                        this.wz.wzSetPositions = this.wz.wzSetPositions.concat(...this.order.orderSets
                            .filter(set => this.wz.wzSetPositions.findIndex(wzSet => wzSet.orderSet === set.id) === -1)
                            .map(set => new WzSetPositions({
                                    orderSet: set.id,
                                    orderSetName: set.getNameForWz(),
                                    price: set.getPriceForWz(),
                                    quantity: set.getQuantityForWz()
                                })
                            )
                        );
                        this.buildForm();
                    });
                } else {
                    this.errorHandler.showLoader(false);
                    this.buildFormNoOrder();
                }
            });
        }
        this.contractorService.getFilterAllContractors().subscribe(contractors => {
            this.contractors = contractors;
        });
        this.pageTitle = this.translatorService.translate(this.pageTitle);
        this.titleService.setTitle(this.pageTitle);

    }

    buildForm(): void {
        this.formGroup = this.formBuilder.group({
            creationDate: [this.dateService.dateToStringDate(this.wz.creationDate), Validators.required],
            contractor: [{value: this.wz.contractorName, disabled: true}]
        });
        this.wz.wzSetPositions.forEach((position, _) => {
            this.addWzOrderPosition(position);
        });

        this.errorHandler.showLoader(false);
    }

    buildFormNoOrder(): void {
        this.formGroup = this.formBuilder.group({
            creationDate: [this.dateService.dateToStringDate(this.wz.creationDate), Validators.required],
            contractor: [this.wz.contractorName, Validators.required],
            contractorOrderNumber: [this.wz.contractorOrderNumber, Validators.required]
        });
        this.wz.wzSetPositions.forEach((position, _) => {
            this.addWzOrderPositionNoOrder(position);
        });
    }

    get f(): any {
        return this.formGroup.controls;
    }

    onSubmit(): void {
        if (this.formGroup.invalid) {
            console.log('Form group invalid: ', this.formGroup);
            return;
        }

        if (this.isEditComponent) {
            const subscription = this.wz.hasOrder ?
                this.wzService.updateWz(this.wz) : this.wzService.updateWzNoOrder(this.wz);
            subscription.subscribe(data => {
                    this.router.navigate(['wz', this.wz.id]);
                    this.alertService.success(Wz.modelName + ': <strong>' + data.contractorOrderNumber + '' +
                        '</strong> zostało pomyślnie edytowane');

                }, error => {
                    this.alertService.error(Wz.modelName + ' nie zostało edytowane. Błąd: ', this.errorHandler.error(error));

                }
            );
        }
        else {
            const subscription = this.wz.hasOrder ?
                this.wzService.createWz(this.wz) : this.wzService.createWzNoOrder(this.wz);
            subscription.subscribe(data => {
                    this.router.navigate(['wz', data.id]);
                    this.alertService.success(Wz.modelName + ': <strong>' + data.contractorOrderNumber + '' +
                        '</strong> zostało utworzone');

                }, error => {
                    this.alertService.error(Wz.modelName + ' nie zostało utworzone. Błąd: ', this.errorHandler.error(error));

                }
            );
        }
    }

    changeContractor(event): void {
        this.lastContractorSearch = '';
        if (event) {
            this.wz.contractor = event.id;
            this.wz.contractorName = event.name;
        } else {
            this.wz.contractor = null;
            this.wz.contractorName = null;
        }
    }

    onContractorSearch(event: { term: string, items: any[] }): void {
        this.lastContractorSearch = event.term;
    }

    onContractorBlur(): void {
        if (this.lastContractorSearch) {
            this.wz.contractor = null;
            this.wz.contractorName = this.lastContractorSearch;
            this.formGroup.get('contractor').patchValue({id: null, name: this.lastContractorSearch});
        }
    }

    changeDate(name: string, event): void {
        this.wz[name] = DateService.stringDateToDateSkipTime(event.target.value);
    }

    getSum(): number {
        return this.wz.getSumPrice();
    }

    addWzOrderPosition(position: WzSetPositions): void {
        const index = position.clientGeneratedId;
        this.formGroup.addControl('positionQuantity' + index, this.formBuilder.control(position.quantity));
        this.formGroup.addControl('positionPriceZl' + index, this.formBuilder.control(position.getPriceZl()));
        this.formGroup.addControl('positionPriceGr' + index, this.formBuilder.control(position.getPriceGr()));
    }

    addWzOrderPositionNoOrder(position: WzSetPositions): void {
        const index = position.clientGeneratedId;
        this.formGroup.addControl('positionQuantity' + index, this.formBuilder.control(position.quantity));
        this.formGroup.addControl('positionPriceZl' + index, this.formBuilder.control(position.getPriceZl()));
        this.formGroup.addControl('positionPriceGr' + index, this.formBuilder.control(position.getPriceGr()));
        this.formGroup.addControl('positionName' + index, this.formBuilder.control(position.orderSetName));
    }

    removeWzOrderPositionNoOrder(index: string): void{
        this.formGroup.removeControl('positionQuantity' + index);
        this.formGroup.removeControl('positionPriceZl' + index);
        this.formGroup.removeControl('positionPriceGr' + index);
        this.formGroup.removeControl('positionName' + index);
    }

    checkPosition(position: WzSetPositions): void {
        position.checked = !position.checked;
    }

    changeContractorOrderNumber(event: Event): void {
        this.wz.contractorOrderNumber = (event.target as HTMLInputElement).value;
    }

    onAddPosition(): void {
        const wzSetPosition = new WzSetPositions({
            id: undefined,
            price: 0,
            quantity: 1,
            orderSetName: '',
            orderSet: null,
        });
        wzSetPosition.checked = true;
        this.wz.wzSetPositions.push(wzSetPosition);
        this.addWzOrderPositionNoOrder(wzSetPosition);
        this.matPositions.renderRows();
    }

    onRemovePosition(position: WzSetPositions): void {
        const index: number = this.wz.wzSetPositions.indexOf(position);
        if (index !== -1) {
            this.removeWzOrderPositionNoOrder(position.clientGeneratedId);
            this.wz.wzSetPositions.splice(index, 1);
            this.matPositions.renderRows();
        }
    }
}
