import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {delay, switchMap, takeUntil} from 'rxjs/operators';
import {PaginatedApiResponse} from 'src/app/_interfaces/PaginatedApiResponse';

@Injectable({
    providedIn: 'root'
})
export class MultiRequestService {

    constructor(private http: HttpClient, ) {
    }

    private pendingHTTPRequests$ = new Subject<void>();
    private latestUrl: string;
    private lastRequestTimeWindow = 0;

    public getAll<T>(url: string, options?: { headers?: HttpHeaders }
    ): Observable<T> {
        this.cancelPendingRequests();
        this.latestUrl = url;

        const delayTime = this.getDelayTime();
        console.log('Launching url with interval', url, delayTime);
        return of(url).pipe(
            delay(delayTime),
            switchMap(oldUrl => {
                    if (oldUrl === this.latestUrl) {
                        this.lastRequestTimeWindow = Date.now();
                        return this.http.get<T>(url, options).pipe(
                            takeUntil(this.onCancelPendingRequests()),
                            );
                    }
                    return EMPTY;
                }
            ));

    }


    private getDelayTime(): number {

        const maxDelayTime = 400;

        const now = Date.now();
        const timeFromLastRequest = now - this.lastRequestTimeWindow;
        if (timeFromLastRequest >= maxDelayTime){
            this.lastRequestTimeWindow = now;
            return 0;
        }
        else if (timeFromLastRequest >= 0){
            return maxDelayTime - timeFromLastRequest;
        }
        else {
            console.error('Invalid time from last request', timeFromLastRequest);
            return 0;
        }
    }

    private cancelPendingRequests(): void {
        this.pendingHTTPRequests$.next();
    }

    private onCancelPendingRequests(): Observable<any> {
        return this.pendingHTTPRequests$.asObservable();
    }
}
