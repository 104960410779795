<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">Oferta - szczegóły</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="offer">
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@Edit" mat-button
                        *ngIf="permissionService.checkPermission('perm_write_offer')"
                        routerLink="/offer/{{offer.id}}/edit">Edytuj
                </button>
                <span class="line" *ngIf="permissionService.checkPermission('perm_write_offer')">|</span>
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@DeleteDetails" mat-button
                        *ngIf="permissionService.checkPermission('perm_write_offer')"
                        (click)="modalService.deleteModalForm('/offer', offer)">Usuń
                </button>
				<span class="line">|</span>
                <button class="button-spacer mat-button-custom" mat-button (click)="getPDF(offer.id)" i18n="@@PDF">PDF
                </button>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="offer">
            <mat-card-content>
                <p class="card-row"><span class="label" i18n="@@ContractorName">Kontrahent</span> <span
                        class="value">{{ offer.contractorName }}</span></p>
                <hr>
                <p class="card-row"><span class="label" i18n="@@CreationDate">Data utworzenia</span> <span
                        class="value">{{ dateService.dateToString(offer.creationDate) }}</span></p>
                <p class="card-row"><span class="label" i18n="@@Number">Numer</span><span
                        class="value">{{ offer.number }}</span>
                </p>
                <hr>
                <p class="card-row"><span class="label">Opis oferty</span><span
                        class="value">{{ offer.topNote }} </span>
                </p>
                <p class="card-row"><span class="label" i18n="@@ExpirationDate">Termin ważności oferty</span><span
                        class="value">{{ offer.expirationDuration }}</span></p>
                <p class="card-row"><span class="label" i18n="@@ExecutionDate">Termin wykonania</span><span
                        class="value">{{ offer.executionDuration }}</span></p>
                <hr>
                <p class="card-row"><span class="label" i18n="@@AuthorName">Autor</span><span
                        class="value">{{ offer.authorName }}</span></p>
                <p class="card-row"><span class="label" i18n="@@Note">Uwagi</span><span
                        class="value">{{ offer.note }}</span>
                </p>
                <p class="card-row"><span class="label" i18n="@@PaymentCondition">Warunki płatności</span><span
                        class="value">{{ offer.paymentCondition }}</span></p>
                <p class="card-row"><span class="label" i18n="@@Language">Język</span><span
                        class="value">{{ offer.language }}</span></p>
            </mat-card-content>
        </mat-card>

        <mat-table [dataSource]="positions" class="mat-effects mat-table-spacer">
            <ng-container matColumnDef="features">
                <mat-header-cell *matHeaderCellDef i18n="@@Feature">Cecha</mat-header-cell>
                <mat-cell *matCellDef="let positions"> {{positions.feature}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef i18n="@@Feature">Nazwa</mat-header-cell>
                <mat-cell *matCellDef="let positions"> {{positions.name}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef i18n="@@Feature">Ilość sztuk</mat-header-cell>
                <mat-cell *matCellDef="let positions"> {{positions.quantity}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="price">
                <mat-header-cell *matHeaderCellDef i18n="@@Feature">Cena</mat-header-cell>
                <mat-cell *matCellDef="let positions"> {{positions.getPriceStr()}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>

		<mat-card class="mat-effects mat-totals" *ngIf="offer">
			<mat-card-content class="totals">
				<div class="sum">
					<p>Suma:</p>
					<span>{{offer.getPriceSumStr()}}</span>
				</div>
			</mat-card-content>
		</mat-card>
    </div>
</div>
