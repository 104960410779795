import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {UserService} from './user.service';
import {Observable} from 'rxjs';
import {User} from '../_models';

@Injectable({
    providedIn: 'root'
})
export class UserFilterResolverService implements Resolve<any> {

    constructor(private userService: UserService) {
    }

    resolve(): Observable<User[]> {
        return this.userService.getFilterAllUsers();
    }
}
