export interface IOrderDeadlineSummary {
    year: number;
    month: number;
    nonInvoice: number;
    nonInvoiceSum: number;
    invoice: number;
    invoiceSum: number;
    toEarnInMonth: number;
    totalToEarn: number;
    leftToEarn: number;
    totalBehind: number;
}

export class OrderDeadlineSummary implements IOrderDeadlineSummary {
    invoice: number;
    invoiceSum: number;
    leftToEarn: number;
    month: number;
    nonInvoice: number;
    nonInvoiceSum: number;
    toEarnInMonth: number;
    totalBehind: number;
    totalToEarn: number;
    year: number;

    constructor(invoice: number, invoiceSum: number, leftToEarn: number, month: number, nonInvoice: number,
                nonInvoiceSum: number, toEarnInMonth: number, totalBehind: number, totalToEarn: number, year: number) {
        this.invoice = invoice;
        this.invoiceSum = invoiceSum;
        this.leftToEarn = leftToEarn;
        this.month = month;
        this.nonInvoice = nonInvoice;
        this.nonInvoiceSum = nonInvoiceSum;
        this.toEarnInMonth = toEarnInMonth;
        this.totalBehind = totalBehind;
        this.totalToEarn = totalToEarn;
        this.year = year;
    }
}


export class OrderDeadlineSummaryFactory {
    public static fromBackend(o: IOrderDeadlineSummary): OrderDeadlineSummary {
        return new OrderDeadlineSummary(
          o.invoice,
          o.invoiceSum,
          o.leftToEarn,
          o.month,
          o.nonInvoice,
          o.nonInvoiceSum,
          o.toEarnInMonth,
          o.totalBehind,
          o.totalToEarn,
          o.year,
        );
    }
}
