<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UsersPage">Indywidualny raport</h1>
            <span class="spacer"></span>
			<a class="button-spacer mat-button-custom" mat-button *ngIf="employeeId" target="_blank"
					href="{{environment.apiUrl}}/rcp/rcp_single_employee/{{employeeId}}/pdf?year={{year}}&month={{month}}">
				 Generuj PDF
			</a>
        </mat-toolbar>

        <mat-table [dataSource]="individualReport?.days" class="mat-effects">
            <ng-container matColumnDef="day">
                <mat-header-cell *matHeaderCellDef i18n="@@IndividualReportDate">Data</mat-header-cell>
                <mat-cell *matCellDef="let day"> {{day.day}} </mat-cell>
				<mat-footer-cell *matFooterCellDef><strong>SUMA</strong></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="workStartHour">
                <mat-header-cell *matHeaderCellDef i18n="@@IndividualReportDate">Godzina rozpoczęcia <br>pracy
                </mat-header-cell>
                <mat-cell *matCellDef="let day"> {{dateService.getTime(day.workStartHour) }} </mat-cell>
				<mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="workEndHour">
                <mat-header-cell *matHeaderCellDef i18n="@@IndividualReportDate">Godzina zakończenia <br>pracy
                </mat-header-cell>
                <mat-cell *matCellDef="let day"> {{dateService.getTime(day.workEndHour) }} </mat-cell>
				<mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="guideWorkTime">
                <mat-header-cell *matHeaderCellDef i18n="@@IndividualReportDate">Przewodnikowy <br>czas pracy
                </mat-header-cell>
                <mat-cell *matCellDef="let day"> {{dateService.getTimeFromSec(day.guideWorkTime) }} </mat-cell>
				<mat-footer-cell *matFooterCellDef>&nbsp;
                    {{ dateService.getTimeFromSec(dateService.roundDownSecondsTo15Min(individualReport?.totalGuideWorkTime)) }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="workTime">
                <mat-header-cell *matHeaderCellDef i18n="@@IndividualReportDate">Czas pracy
                </mat-header-cell>
                <mat-cell *matCellDef="let day"> {{dateService.getTimeFromSec(day.workTime) }} </mat-cell>
				<mat-footer-cell *matFooterCellDef>&nbsp;{{ dateService.getTimeFromSec(dateService.roundDownSecondsTo15Min(individualReport?.totalWorkTime)) }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="totalBreak">
                <mat-header-cell *matHeaderCellDef i18n="@@IndividualReportDate">Przerwa
                </mat-header-cell>
                <mat-cell *matCellDef="let day"> {{dateService.getTimeFromSec(day.totalBreak) }} </mat-cell>
				<mat-footer-cell *matFooterCellDef>&nbsp;{{ dateService.getTimeFromSec(individualReport?.totalBreaks) }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef i18n="@@IndividualReportDate">Typ dnia</mat-header-cell>
                <mat-cell *matCellDef="let day"> {{day.dayType.type}} </mat-cell>
				<mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="causeOfAbsence">
                <mat-header-cell *matHeaderCellDef i18n="@@IndividualReportDate">Urlop</mat-header-cell>
                <mat-cell *matCellDef="let day">
					{{ translatorService.translate(day.employeeDayAdditionalInfo?.causeOfAbsence) }}
					<br *ngIf="day.employeeDayAdditionalInfo?.causeOfAbsenceDescription"/>
					{{ day.employeeDayAdditionalInfo?.causeOfAbsenceDescription }}
				</mat-cell>
				<mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef class="mat-operations" i18n="@@IndividualReportOperations">Opcje
				</mat-header-cell>
				<mat-cell *matCellDef="let day" class="mat-operations">
					<mat-icon (click)="selectDay(day, 'workTimeModal')"
							  i18n-matTooltip="@@IndividualReportEditWorkTime" matTooltip="Edycja czasu pracy"
							  *ngIf="permissionService.checkPermission('perm_write_rcp_single_employee')">list_alt
					</mat-icon>
					<mat-icon (click)="selectDay(day, 'editModal')"
							  i18n-matTooltip="@@IndividualReportEdit" matTooltip="Edycja"
							  *ngIf="permissionService.checkPermission('perm_write_rcp_single_employee')">edit
					</mat-icon>
				</mat-cell>
				<mat-footer-cell *matFooterCellDef class="mat-operations"></mat-footer-cell>
			</ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay"
                     [class]="row.cssClass"></mat-row>
			<mat-footer-row *matFooterRowDef="columnsToDisplay"></mat-footer-row>
        </mat-table>
    </div>
</div>
<ng-template #editModal>
	<app-edit-individual-report [dayToEdit]="dayToEdit" [employeeId]="employeeId" [additionalInfo]="additionalInfo">
	</app-edit-individual-report>
</ng-template>
<ng-template #workTimeModal>
	<app-work-time-individual-report [dayToEdit]="dayToEdit" [employeeId]="employeeId" (refreshData)="refreshData()">
	</app-work-time-individual-report>
</ng-template>
