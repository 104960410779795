import {Component, OnInit, ViewChild} from '@angular/core';
import {DateService, MoneyService} from '../_primitive_services';
import {MatTable} from '@angular/material/table';
import {ErrorHandlerService, ModalService, PermissionService, TranslatorService} from '../_services';
import {Title} from '@angular/platform-browser';
import {OrderDeadlineService} from '../order-deadline/order-deadline.service';
import {OrderDeadlineSummary} from './order-deadline-summary';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {BackendParamService} from '../_filters/backend-param.service';
import {QueryParamService} from '../_filters/query-param.service';
import {YearFilterConfig} from '../_filters/generic-filter/sample-filter-configs';
import {DeadlineSummaryLocalFilterService} from '../_services/local-filter.service';

@Component({
    selector: 'app-order-deadline-summary',
    templateUrl: './order-deadline-summary.component.html',
    styleUrls: ['./order-deadline-summary.component.css'],
    providers: [
        BackendFilterService, {provide: BackendParamService, useClass: QueryParamService}, OrderDeadlineService,
        DeadlineSummaryLocalFilterService,
    ],
})
export class OrderDeadlineSummaryComponent implements OnInit {

    columnsToDisplay = ['year', 'month', 'nonInvoice', 'nonInvoiceSum',
        'invoice', 'toEarnInMonth', 'leftToEarn', 'totalBehind', 'actions'];
    dateService = DateService;
    moneyService = MoneyService;

    @ViewChild(MatTable) matPositions: MatTable<any>;
    toEarnEditDeadlineSummary: OrderDeadlineSummary;

    @ViewChild('editModal') editModal;

    constructor(
        private orderDeadlineService: OrderDeadlineService,
        private titleService: Title,
        public modalService: ModalService,
        private errorHandler: ErrorHandlerService,
        public translatorService: TranslatorService,
        public permissionService: PermissionService,
        private filterService: BackendFilterService,
        public queryParamService: BackendParamService,
        public deadlineSummaryLocalFilterService: DeadlineSummaryLocalFilterService,
    ) {
        this.titleService.setTitle('Zamówienia - terminy, podsumowanie');
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new YearFilterConfig('year', 'Rok', ),
        ]);
        this.deadlineSummaryLocalFilterService.onInit(
            new Map<string, Array<string>>([['year', [new Date().getFullYear().toString()]]]),
            new Map([[
                'year',
                (value: Array<string>, deadlines: OrderDeadlineSummary[]) => {
                    const year = parseInt(value[0], 10);
                    return deadlines.filter(deadline => deadline.year === year);
                }]
            ]),
        );
        this.subscribeOnChanges();
        this.refreshData();
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        this.orderDeadlineService.getSummary(
        ).subscribe((summaries: OrderDeadlineSummary[]) => {
            this.errorHandler.showLoader(false);
            this.deadlineSummaryLocalFilterService.setObjects(summaries);
        });
    }


    editToEarn(deadlineSummary: OrderDeadlineSummary): void {
        this.toEarnEditDeadlineSummary = deadlineSummary;
        this.modalService.open(this.editModal);
    }

    private subscribeOnChanges(): void {
        this.deadlineSummaryLocalFilterService.observable().subscribe(_ => this.matPositions.renderRows());
    }
}
