<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">{{title}}</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="contractor">
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="contractorForm">
            <form *ngIf="contractorForm" [formGroup]="contractorForm" (ngSubmit)="onSubmit()">
                <div class="form-group row align-items-center">
                    <label for="name" class="col-sm-1 col-form-label">Nazwa</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="name" placeholder="nazwa" formControlName="name">
                        <div *ngIf="f.name.hasError('required')">Nazwa jest <strong>wymagana</strong></div>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <label for="firstName" class="col-sm-1 col-form-label" i18n="@@CodenameEdit">Imię</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="firstName" placeholder="Imię"
                               formControlName="firstName">
                        <div *ngIf="f.firstName.hasError('required')">Imię jest <strong>wymagane</strong></div>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <label for="lastName" class="col-sm-1 col-form-label" i18n="@@NameEdit">Nazwisko</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="lastName" placeholder="Nazwisko"
                               formControlName="lastName">
                        <div *ngIf="f.lastName.hasError('required')">Nazwisko jest <strong>wymagane</strong></div>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <label for="email" class="col-sm-1 col-form-label" i18n="@@NameEdit">E-Mail</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="email" placeholder="E-Mail" formControlName="email">
                        <div class="text-danger" *ngIf="f.email.hasError('email')">Wprowadzony e-mail jest <strong>nieprawidłowy</strong>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="orderStatsUniqueColumn"
                                   formControlName="orderStatsUniqueColumn">
                            <label class="custom-control-label" for="orderStatsUniqueColumn">Pokaż w osobnej
                                kolumnie</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11">
                        <button class="button-spacer mat-button-custom mat-button-big" mat-button>
                            Zapisz
                        </button>
                    </div>
                </div>

            </form>
        </mat-card>
    </div>
</div>
