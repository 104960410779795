import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Guide, GuideFactory, GuideOperation, MachineGroup} from 'src/app/_models';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ErrorHandlerService,
    GuidesService,
    ModalService,
    PermissionService,
} from 'src/app/_services';
import { Title } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import {first} from 'rxjs/operators';
import {AlertService} from '../../_primitive_services';
import {GuideSearchComponent} from '../guide-search/guide-search.component';
import {PositionsWithoutGuidesService} from '../../_services/positions-without-guides.service';

@Component({
    selector: 'app-guide-edit',
    templateUrl: './guide-edit.component.html',
    styleUrls: ['./guide-edit.component.css']
})
export class GuideEditComponent implements OnInit, AfterViewInit {

    constructor(private activatedRoute: ActivatedRoute,
                private guideService: GuidesService,
                private formBuilder: FormBuilder,
                private errorHandler: ErrorHandlerService,
                private positionsService: PositionsWithoutGuidesService,
                private titleService: Title,
                private alertService: AlertService,
                private router: Router,
                public modalService: ModalService,
                public permissionService: PermissionService,
                ) {
        this.titleService.setTitle('Przewodnik - edycja');

    }

    guide: Guide;
    machineGroups: MachineGroup[] = [];
    formGroup: FormGroup;
    columnsToDisplay = ['drag', 'lp', 'machineGroup', 'name', 'qualityControl', 'declaredTimeInSeconds', 'remove'];
    @ViewChild(MatTable) matPositions: MatTable<any>;
    private lastMachineGroupSearch: string = null;
    isEditComponent = true;
    title: string;
    @ViewChild(GuideSearchComponent) guideSearch: GuideSearchComponent;

    ngOnInit(): void {
        this.isEditComponent = this.activatedRoute.snapshot.paramMap.has('id');
        this.setTitle();
        this.activatedRoute.data.subscribe(data => {
            this.machineGroups.length = 0;
            this.machineGroups.push(...data.machineGroups);
        });
        if (this.isEditComponent){
            this.getGuideFromBackend();
        } else {
            this.getOrderPositionFromBackend();
        }
    }

    ngAfterViewInit(): void {
        this.guideSearch.guideSubject.asObservable().subscribe(guide => this.selectGuide(guide));
    }

    private getGuideFromBackend(): void {
        this.errorHandler.showLoader(true);
        this.guideService.getGuide(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(guide => {
            this.guide = guide;
            this.titleService.setTitle(guide.number);
            this.buildForm();

            this.errorHandler.showLoader(false);
        });
    }

    private setTitle(): void{
        this.title = this.isEditComponent ? 'Przewodnik - edycja' : 'Przewodnik - dodawanie';
        this.titleService.setTitle(this.title);
    }


    private getOrderPositionFromBackend(): void {
        this.errorHandler.showLoader(true);
        if (this.activatedRoute.snapshot.queryParams.order_position_id) {
            const positionId = parseInt(this.activatedRoute.snapshot.queryParams.order_position_id, 10);
            this.positionsService.getPosition(positionId).subscribe(orderPosition => {
                this.guide = GuideFactory.fromOrderPosition(orderPosition);
                this.buildForm();
                if (orderPosition.currentGuide !== null) {
                    this.selectGuideId(orderPosition.currentGuide);
                }
            });
        }
    }

    selectGuide(guide: Guide): void {
        if (guide.operations && guide.operations.length > 0) {
            this.fillOperations(guide.operations);
        } else {
            this.selectGuideId(guide.id);
        }
    }

    private selectGuideId(id: number): void {
        this.errorHandler.showLoader(true);
        this.guideService.getGuide(id
        ).subscribe((response: Guide) => {
            this.errorHandler.showLoader(false);
            this.fillOperations(response.operations);
        });
    }

    private fillOperations(operations: GuideOperation[]): void {
        while (this.guide.operations.length > 0) {
            this.onRemovePositionElement(this.guide.operations[0]);
        }
        for (const operation of operations){
            this.guide.operations.push(operation);
            this.addOperationToForm(operation.uniqueId, operation);
        }
        this.matPositions.renderRows();
    }

    buildForm(): void {
        this.formGroup = this.formBuilder.group({
        });
        for (const operation of this.guide.operations){
            this.addOperationToForm(operation.uniqueId, operation);
        }
    }

    addOperationToForm(index: number, operation: GuideOperation): void{
        this.formGroup.addControl('txtName' + index, this.formBuilder.control(operation.name));
        let machineGroup = null;
        if (operation.machineGroup){
            machineGroup = {
                id: operation.machineGroup,
                name: operation.machineGroupCodename + ' ' + operation.machineGroupName
            };
        }
        this.formGroup.addControl('txtMachineGroup' + index, this.formBuilder.control(machineGroup));
    }

    removeOperationFromForm(index: number): void{
        this.formGroup.removeControl('txtName' + index);
        this.formGroup.removeControl('txtMachineGroup' + index);
    }

    onSubmit(): any {
        if (this.formGroup.invalid) {
            console.log('invalid');
            console.log(this.formGroup);
            return;
        }
        if (!this.guide.operations || this.guide.operations.length === 0){
            this.alertService.error('Przewodnik musi miać co najmniej jedną operację');
            return;
        }

        if (this.isEditComponent){
            this.updateGuide();
        } else {
            this.createGuide();
        }


    }

    private updateGuide(): void {
        this.errorHandler.showLoader(true);
        this.guideService.updateGuide(this.guide).pipe(first()).subscribe(
            data => {
                this.errorHandler.showLoader(false);
                this.router.navigate(['/guide', this.guide.id]);
                this.alertService.success(Guide.modelName + ': <strong>' + data.number + '</strong> został pomyślnie edytowany');

            }, error => {
                this.errorHandler.showLoader(false);
                console.log(error);
                this.alertService.error(Guide.modelName + ' nie został edytowany. Błąd: ', this.errorHandler.error(error));

            }
        );
    }

    private createGuide(): void {
        this.formGroup.value.id = this.guide.id;
        this.formGroup.value.operations = this.guide.operations;

        this.errorHandler.showLoader(true);
        this.guideService.createGuide(this.guide).pipe(first()).subscribe(
            (data: Guide) => {

                this.errorHandler.showLoader(false);
                this.router.navigate(['/guide', data.id]);
                this.alertService.success(Guide.modelName + ': <strong>' + data.number + '</strong> został pomyślnie utworzony');

            }, error => {
                console.log(error);
                this.errorHandler.showLoader(false);
                this.alertService.error(Guide.modelName + ' nie został utworzony. Błąd: ', this.errorHandler.error(error));

            }
        );
    }

    connectTime(event, guideOperation: GuideOperation, from): any {

        let hourPart = guideOperation.getHours();
        let minutePart = guideOperation.getMinutes();

        console.log(from);
        if (event.target.value === null || event.target.value === undefined || event.target.value === ''){
            event.target.value = '0';
        }
        let time: number = parseInt(event.target.value, 10);
        time = Math.abs(time);
        event.target.value = time.toString();
        if (from === 'declaredTimeInSecondsHr') {
            hourPart = time;
        }

        if (from === 'declaredTimeInSecondsMin') {
            minutePart = time;
        }

        guideOperation.declaredTimeInSeconds = hourPart * 3600 + minutePart * 60;


    }

    onRemovePositionElement(guideOperation: GuideOperation): void {
        const index: number = this.guide.operations.indexOf(guideOperation);
        if (index !== -1) {
            this.removeOperationFromForm(guideOperation.uniqueId);
            this.guide.operations.splice(index, 1);
            this.matPositions.renderRows();
        }
    }

    onDropPositionElement(event: CdkDragDrop<GuideOperation[]>): void {

        const minLp = Math.min(this.guide.operations[event.previousIndex].lp, this.guide.operations[event.currentIndex].lp);
        moveItemInArray(this.guide.operations, event.previousIndex, event.currentIndex);
        let lp = minLp;

        for (
            let index = Math.min(event.previousIndex, event.currentIndex);
            index <= Math.max(event.previousIndex, event.currentIndex);
            index += 1)
        {
            this.guide.operations[index].lp = lp;
            lp += 1;
        }
        this.matPositions.renderRows();
    }

    changeMachineGroup(operation: GuideOperation, event: MachineGroup): void {
        this.lastMachineGroupSearch = null;
        if (event) {
            operation.machineGroup = event.id;
        } else {
            operation.machineGroup = null;
        }
    }

    onMachineGroupSearch(operation: GuideOperation, event: {term: string, items: any[]}): void {
        this.lastMachineGroupSearch = event.term;
    }

    onMachineGroupBlur(operation: GuideOperation, ): void {
        if (this.lastMachineGroupSearch !== null) {
            const filteredMachineGroups = this.machineGroups.filter(
                (value: MachineGroup) => this.filterMachineGroup(this.lastMachineGroupSearch, value));
            if (filteredMachineGroups.length > 0) {
                operation.machineGroup = filteredMachineGroups[0].id;
                this.formGroup.get('txtMachineGroup' + operation.uniqueId).patchValue(filteredMachineGroups[0]);
            }
            this.lastMachineGroupSearch = null;
        }
    }


    filterMachineGroup(term: string, item: MachineGroup): boolean {
        return item.codename.startsWith(term) || item.name.toLowerCase().includes(term.toLowerCase());
    }

    onAddPositionElement(): void {
        let lp = 1;
        if (this.guide.operations.length > 0){
            lp = this.guide.operations[this.guide.operations.length - 1].lp + 1;
        }
        this.guide.operations.push(new GuideOperation({
            lp,
            name: null,
            declaredTimeInSeconds: 0,
            machineGroup: null,
        }));
        this.addOperationToForm(this.guide.operations[this.guide.operations.length - 1].uniqueId,
            this.guide.operations[this.guide.operations.length - 1]);
        this.matPositions.renderRows();
    }


    getColor(row: GuideOperation): string {
        if (!this.isEditComponent){
            return '#FFFFFF';
        }
        let state: string;
        if (this.guide.currentOperation === row.id){
            state = 'active';
        }else {
            state = row.currentState;
        }
        switch (state){
            case 'production': {
                return '#04f9d011';
            }
            case 'guide_new': {
                return '#FFFFFF';
            }
            case 'long_pause': {
                return '#0422FA11';
            }
            case 'pause': {
                return '#6376F811';
            }
            case 'finished': {
                return '#21C30411';
            }
            case 'waiting': {
                return '#D405D411';
            }
            case 'denied': {
                return '#FF000011';
            }
            case 'active': {
                return '#fccb1d11';
            }
            default: {
                return '#FFFFFF';
            }
        }
    }
}
