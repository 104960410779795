import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {
    ApiTranslatorService,
    ErrorHandlerService,
    ModalService,
    OrderService,
    PermissionService
} from '../../_services';
import { Title } from '@angular/platform-browser';
import { Order } from '../../_models/order';
import { Invoice } from '../../_models/invoice';
import { PaginationService } from '../../_services/pagination.service';
import { BackendPaginationService } from '../../_services/backend-pagination.service';
import { Sort } from '@angular/material/sort';
import { PaginatedTranslatedResponse } from '../../_interfaces/PaginatedApiResponse';
import {
    ContractorFilterConfig,
    FromTrashFilterConfig, OrderStateFilterConfig,
    TrueAndFalseFilterConfig,
    TrueOrEmptyFilterConfig
} from '../../_filters/generic-filter/sample-filter-configs';
import {BackendFilterService} from '../../_filters/backend-filter.service';
import {QueryParamService} from '../../_filters/query-param.service';
import {MultiSelectionService} from '../../_services/multi-selection.service';
import {BackendParamService} from '../../_filters/backend-param.service';
import {DateService} from '../../_primitive_services';
import {SortService} from '../../_services/sort.service';
import {FilterConfigStringInput} from '../../_filters/components/filter-string-input/filter-string-input.component';
import {FilterConfigDateRange} from '../../_filters/components/filter-date-range/filter-date-range.component';

@Component({
    selector: 'app-order',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService, SortService, MultiSelectionService,
        {provide: BackendParamService, useClass: QueryParamService},
        OrderService,
    ]
})
export class OrderListComponent implements OnInit {

    orders: Order[] = [];
    invoices: Invoice[];
    columnsToDisplay = ['contractorName', 'contractorOrderNumber', 'creationDate', 'plannedEndDate',
        'progress', 'reclamation', 'grade', 'actions'];
    filtersToDisplay = ['contractor', 'number', 'periodOfTime'];
    dateService = DateService;

    @ViewChild(MatTable) matPositions: MatTable<any>;
    initialSort: Sort = {active: 'creation_date', direction: 'desc'};

    constructor(public orderService: OrderService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                private filterService: BackendFilterService,
                public modalService: ModalService,
                public permissionService: PermissionService,
                public paginationService: PaginationService,
                private errorHandler: ErrorHandlerService,
                public sortService: SortService,
                public multiSelectionService: MultiSelectionService,
                public queryParamService: BackendParamService,
    ) {
        this.titleService.setTitle('Zamówienia');
    }

    ngOnInit(): void {
        this.paginationService.enablePagination();
        this.filterService.setFilterConfigs([
            new ContractorFilterConfig(),
            new FilterConfigStringInput('contractor_order_number', 'Numer zamówienia'),
            new TrueOrEmptyFilterConfig('Reklamacja', 'reclamation', 'Reklamacja'),
            new OrderStateFilterConfig('Stan'),
            new TrueAndFalseFilterConfig('Wewnętrzne', 'Zewnętrzne', 'inner', 'Źródło'),
            new FromTrashFilterConfig(),
            new FilterConfigDateRange('planned_end_date', 'Data zakończenia'),
        ]);

        this.sortService.enableSorting(this.initialSort);
        this.subscribeOnChanges();
        this.multiSelectionService.populateWithData(this.orders);
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        const previouslyCheckedIds: Set<number> = new Set(this.orders.filter(order => order.checked).map(order => order.id));
        this.orderService.getAll(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<Order>) => {
            this.errorHandler.showLoader(false);
            this.orders.length = 0;
            this.orders.push(...response.results);
            for (const order of this.orders) {
                order.checked = previouslyCheckedIds.has(order.id);
            }
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
        });
    }

    isChecked(fieldName, index): any {
        return index[fieldName] ? 'done' : 'close';
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }

    getRowType(row: Order): string {
        if (row.plannedEndDate && row.plannedEndDate < DateService.dateSkipTime(new Date()) && row.endDate === null){
            return 'outdated';
        }
        return 'basic';
    }
}
