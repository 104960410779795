<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title" i18n="@@UserGroupDetailsPage">Grupa Użytkowników - szczegóły</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="userGroup">
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@EditDetails" mat-button
                        *ngIf="permissionService.checkPermission('perm_write_group')"
                        routerLink="/group/{{userGroup.id}}/edit">Edytuj
                </button>
                <span class="line">|</span>
                <button class="button-spacer mat-button-custom" i18n-mat-button="@@DeleteDetails" mat-button
                        *ngIf="permissionService.checkPermission('perm_write_group')"
                        (click)="modalService.deleteModalForm('/group', userGroup)">Usuń
                </button>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="userGroup">
            <mat-card-content>
                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Nazwa</div>
                    <div class="col-sm-11 font-weight-bold dark-color">
                        {{ userGroup.name }}
                    </div>
                </div>
                <hr>

                <div class="form-group row align-items-center">
                    <div class="col-sm-1 col-form-label">Uprawnienia</div>
                    <div class="col-sm-11 clearfix font-weight-bold dark-color">
                        <div *ngFor="let permission of userGroup.permissions">{{ permission.name }}</div>
                    </div>
                </div>
                <hr>
            </mat-card-content>
        </mat-card>
    </div>
</div>
