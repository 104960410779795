import { EmployeeDay } from './employee-day';
import { Author } from './author';

export class IndividualReport {
    static path = 'rcp/rcp_single_employee';

    id: number;
    totalWorkTime: number;
    totalGuideWorkTime: number;
    totalBreaks: number;
    days: EmployeeDay[];


    constructor(object?: IndividualReport) {
        if (object) {
            this.id = object.id;
            this.totalWorkTime = object.totalWorkTime;
            this.totalGuideWorkTime = object.totalGuideWorkTime;
            this.totalBreaks = object.totalBreaks;
            this.days = object.days?.map(day => new EmployeeDay(day));
        }
    }
}
