import {Component, OnInit, ViewChild} from '@angular/core';
import {Schedule} from '../_models/schedule';
import {StatisticsScheduleService} from '../_services/statistics-schedule.service';
import {Title} from '@angular/platform-browser';
import {ErrorHandlerService, ModalService, PermissionService, TranslatorService} from '../_services';
import {Subject} from 'rxjs';
import {DateService, MoneyService} from '../_primitive_services';
import {environment} from '../../environments/environment';
import {
    ContractorFilterConfig,
} from '../_filters/generic-filter/sample-filter-configs';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {BackendParamService} from '../_filters/backend-param.service';
import {FilterConfigDateRange} from '../_filters/components/filter-date-range/filter-date-range.component';
import {MatTable} from '@angular/material/table';
import {ScheduleOrderSet} from '../_models/schedule-order-set';
import {QueryParamService} from '../_filters/query-param.service';


@Component({
  selector: 'app-order-scheulde',
  templateUrl: './order-schedule.component.html',
  styleUrls: ['./order-schedule.component.css'],
    providers: [{provide: BackendParamService, useClass: QueryParamService}, BackendFilterService ]
})
export class OrderScheduleComponent implements OnInit {
    columnsToDisplay = ['planedEndDate', 'guideNumber', 'numberOfGuides', 'feature', 'contractor', 'orderNumber', 'price'];
    schedule: Schedule = null;
    isDownloaded = false;
    filteredSchedule: ScheduleOrderSet[] = [];
    subject = new Subject<void>();
    cashedCost = '';
    @ViewChild('scheduleGenerate') scheduleGenerate;
    @ViewChild(MatTable) matPositions: MatTable<any>;
    constructor(
        public scheduleService: StatisticsScheduleService,
        public titleService: Title,
        public translatorService: TranslatorService,
        public permissionService: PermissionService,
        public modalService: ModalService,
        public errorHandler: ErrorHandlerService,
        public filterService: BackendFilterService,
        public queryParamService: BackendParamService,
    ) {
        titleService.setTitle('Harmonogram');
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new FilterConfigDateRange('planned_end_date', 'Planowana data zakończenia'),
            new ContractorFilterConfig('contractor'),
        ]);
        this.queryParamService.subscribe(() => {
            this.applyFilters();
        });
        this.subject.subscribe(_ => this.refreshData());
        this.refreshData();
    }

    applyFilters(): void{
        if (this.schedule === null){
            return;
        }
        let filtered = this.schedule.orderSets;
        if (this.queryParamService.getQueryParamMap().has('planned_end_date_after')
            && this.queryParamService.getQueryParamMap().has('planned_end_date_before')
        ){
            const after: Date = DateService.stringDateToDateSkipTime(
                this.queryParamService.getQueryParamMap().get('planned_end_date_after')[0]
            );

            const before: Date = DateService.stringDateToDateSkipTime(
                this.queryParamService.getQueryParamMap().get('planned_end_date_before')[0]
            );
            filtered = filtered.filter( set => set.plannedEndDate >= after && set.plannedEndDate <= before);
        }
        if (this.queryParamService.getQueryParamMap().has('contractor')){
            console.log(this.queryParamService.getQueryParamMap().get('contractor'));
            const contractor: number = parseInt(this.queryParamService.getQueryParamMap().get('contractor')[0], 10);
            filtered = filtered.filter(x => x.contractor === contractor);
        }
        this.filteredSchedule.length = 0;
        this.filteredSchedule.push(...filtered);
        this.recalculateCost();
        this.matPositions.renderRows();
    }

    refreshData(): void{
        this.errorHandler.showLoader(true);
        this.scheduleService.getSchedule().subscribe(schedule => {
            this.isDownloaded = true;
            this.errorHandler.showLoader(false);
            this.schedule = schedule;
            this.applyFilters();
        });
    }

    openGenerateSchedule(): void{
        this.modalService.open(this.scheduleGenerate);
    }

    parseMoney(cost: number): string {
        return MoneyService.parseMoney(cost);
    }

    getDate(date: Date): string{
        return DateService.dateToStringDate(date);
    }

    getPDF(): void {
        const urlAdress = `${environment.apiUrl}/order_statistics/schedule/${this.schedule.id}/pdf`;
        window.open(urlAdress, '_blank');
    }

    private recalculateCost(): void {
        this.cashedCost = this.parseMoney(this.filteredSchedule
            .map(x => x.cost)
            .reduce((acc, next) => acc + next, 0)
        );
    }
}
