import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Guide, GuideInList} from 'src/app/_models';
import { Component, OnInit, ViewChild} from '@angular/core';
import {
    ApiTranslatorService,
    ErrorHandlerService,
    GuidesService,
    ModalService,
    PermissionService
} from 'src/app/_services';
import {PaginationService} from '../../_services/pagination.service';
import {BackendPaginationService} from '../../_services/backend-pagination.service';
import {MultiRequestService} from '../../_services/multi-request.service';
import {PaginatedTranslatedResponse} from '../../_interfaces/PaginatedApiResponse';
import {BackendFilterService} from '../../_filters/backend-filter.service';
import {AlertService} from '../../_primitive_services';
import {BackendParamService} from '../../_filters/backend-param.service';
import {InMemoryParamService} from '../../_filters/in-memory-param.service';
import {Subject} from 'rxjs';
import {FilterConfigStringInput} from '../../_filters/components/filter-string-input/filter-string-input.component';

@Component({
    selector: 'app-guide-search',
    templateUrl: './guide-search.component.html',
    styleUrls: ['./guide-search.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService,
        { provide: BackendParamService, useClass: InMemoryParamService },
        GuidesService, MultiRequestService,
    ]
})
export class GuideSearchComponent implements OnInit {
    guides: GuideInList[] = [];
    columnsToDisplay = ['actions', 'number', 'feature', 'name', ];
    dataSource: MatTableDataSource<Guide>;
    @ViewChild(MatTable) matPositions: MatTable<any>;

    guideSubject: Subject<Guide> = new Subject();
    filterConfigs = [
        new FilterConfigStringInput('number', 'Numer Przewodnika'),
        new FilterConfigStringInput('feature', 'Cecha'),
        new FilterConfigStringInput('name', 'Nazwa'),
    ];

    constructor(public guideService: GuidesService,
                private apiTranslator: ApiTranslatorService,
                private filterService: BackendFilterService,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                public modalService: ModalService,
                public permissionService: PermissionService,
                public paginationService: PaginationService,
                public queryParamService: BackendParamService,
    ) {
    }

    ngOnInit(): void {
        this.queryParamService.setQueryParam('also_trash', ['true']);
        this.filterService.setFilterConfigs(this.filterConfigs);
        this.paginationService.setPageSize(10);
        this.paginationService.enablePagination();
        this.subscribeOnChanges();
        this.queryParamService.setQueryParam('ordering', ['-year,-lp']);
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        this.guideService.getAll(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<GuideInList>) => {
            this.errorHandler.showLoader(false);
            this.guides.length = 0;
            this.guides.push(...response.results);
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe( () => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        const m = this.queryParamService.getQueryParamMap();
        m.set('page_size', ['8']);
        return m;
    }

    selectGuide(guide: Guide): void{
        this.guideSubject.next(guide);
    }
}
