import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({templateUrl: 'home.component.html'})
export class HomeComponent implements OnInit {
    loading = false;

    constructor(private titleService: Title) {
        this.titleService.setTitle('SkrawPol');
    }

    ngOnInit(): void {
        this.loading = true;
    }
}
