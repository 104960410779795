<mat-drawer-container class="page">
	<div class="content">
		<mat-toolbar class="mat-toolbar-custom">
			<h1 class="page-title" i18n="@OfferEdition">{{title}}</h1>
			<span class="spacer"></span>
			<div class="actions" *ngIf="offer">
				<button class="button-spacer mat-button-custom" i18n-mat-button="@@DeleteDetails" mat-button
                        *ngIf="isEditComponent"
						(click)="modalService.deleteModalForm('/offer', offer)">Usuń
				</button>
				<span class="line" *ngIf="isEditComponent">|</span>
				<app-back-button></app-back-button>
			</div>
		</mat-toolbar>
		<form *ngIf="editOfferForm" [formGroup]="editOfferForm" (ngSubmit)="onSubmit()">
			<mat-card class="mat-effects" *ngIf="offer">
				<div class="form-group row align-items-center">
					<label class="col-sm-1 col-form-label" i18n="@@OfferAuthor">Kontrahent</label>
					<div class="col-sm-3">
						<ng-select
								[items]="contractors"
								[multiple]="false"
								[closeOnSelect]="true"
								[hideSelected]="false"
								placeholder="Kontrahent"
								bindLabel="name"
								[formControlName]="'contractor'"
								[clearSearchOnAdd]="false"
								(change)="changeContractor($event)"
								(blur)="onContractorBlur()"
								(search)="onContractorSearch($event)"
						>
							<ng-template ng-label-tmp let-item="item">
								{{item.name}}
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-search="searchTerm">
								{{item.name}}
							</ng-template>
						</ng-select>
						<div class="text-danger" *ngIf="f.contractor.hasError('required')">
							Kontrahent jest <strong>wymagany</strong>
						</div>
					</div>
				</div>

				<div class="form-group row align-items-center">
					<label for="expirationDuration" class="col-sm-1 col-form-label" i18n="@@expirationDuration">
						Termin ważności oferty
					</label>
					<div class="col-sm-3">
						<input type="text" class="form-control" id="expirationDuration" placeholder="Termin ważności"
							   formControlName="expirationDuration"
							   (change)="changeSimpleValue($event, 'expirationDuration')">
						<div class="text-danger" *ngIf="f.expirationDuration.hasError('required')">
							Termin ważności oferty jest <strong>wymagany</strong>
						</div>
					</div>
				</div>

                <div class="form-group row align-items-center">
					<label for="topNote" class="col-sm-1 col-form-label">
						Opis oferty
					</label>
					<div class="col-sm-3">
					<textarea type="text" rows="4" class="form-control" id="topNote" placeholder="Opis oferty"
							  formControlName="topNote" (change)="changeSimpleValue($event, 'topNote')"></textarea>
					</div>
				</div>

				<div class="form-group row align-items-center">
					<label for="executionDuration" class="col-sm-1 col-form-label" i18n="@@executionDuration">
						Termin wykonania
					</label>
					<div class="col-sm-3">
						<input type="text" class="form-control" id="executionDuration" placeholder="Termin wykonania"
							   formControlName="executionDuration"
							   (change)="changeSimpleValue($event, 'executionDuration')">
						<div class="text-danger" *ngIf="f.executionDuration.hasError('required')">
							Termin wykonania jest <strong>wymagany</strong>
						</div>
					</div>
				</div>

				<div class="form-group row align-items-center">
					<label for="note" class="col-sm-1 col-form-label" i18n="@@note">
						Uwagi
					</label>
					<div class="col-sm-3">
					<textarea type="text" rows="4" class="form-control" id="note" placeholder="Uwagi"
							  formControlName="note" (change)="changeSimpleValue($event, 'note')"></textarea>
					</div>
				</div>

				<div class="form-group row align-items-center">
					<label for="paymentCondition" class="col-sm-1 col-form-label" i18n="@@paymentCondition">
						Warunki płatności
					</label>
					<div class="col-sm-3">
					<textarea type="text" class="form-control" id="paymentCondition" placeholder="Warunki"
							  formControlName="paymentCondition"
							  (change)="changeSimpleValue($event, 'paymentCondition')"></textarea>
					</div>
				</div>
				<div class="form-group row align-items-center">
					<label class="col-sm-1 col-form-label" i18n="@@OfferLanguage">Język</label>
					<div class="col-sm-3">
						<ng-select
								[items]="languages"
								[multiple]="false"
								[closeOnSelect]="true"
								[hideSelected]="true"
								placeholder="Język"
								[formControlName]="'language'"
								(change)="changeLanguage($event)">

						</ng-select>
					</div>
				</div>

				<div class="form-group row align-items-center">
					<label class="col-sm-1 col-form-label" i18n="@@OfferAuthor">Autor</label>
					<div class="col-sm-3">
						<ng-select
								[items]="users"
								[multiple]="false"
								[closeOnSelect]="true"
								[hideSelected]="false"
								bindLabel="fullName"
								placeholder="Autor"
								[formControlName]="'author'"
								(change)="changeAuthor($event)"
								(blur)="onAuthorBlur()"
								(search)="onAuthorSearch($event)"
						>

							<ng-template ng-label-tmp let-item="item">
								{{item.fullName}}
							</ng-template>
							<ng-template ng-option-tmp let-item="item">
								{{item.fullName}}
							</ng-template>
						</ng-select>
						<div *ngIf="f.author.hasError('required')">Autor jest <strong>wymagany</strong></div>
					</div>
				</div>


			</mat-card>

			<mat-toolbar class="mat-toolbar-space">
				<h2 class="page-title" i18n="@@OfferCreate">Pozycje zamówienia</h2>
				<span class="spacer"></span>
			</mat-toolbar>

			<mat-table *ngIf="offer" [dataSource]="offer.positions" class="mat-effects">

				<ng-container matColumnDef="feature">
					<mat-header-cell *matHeaderCellDef i18n="@@Feature">Cecha</mat-header-cell>
					<mat-cell *matCellDef="let position">
						<!--suppress TypeScriptUnresolvedVariable -->
						<input [class]="{'form-error': editOfferForm.get('positionFeature'+position.uniqueId).invalid}"
							   required type="text" class="form-control" [value]="position.feature"
							   formControlName="positionFeature{{position.uniqueId}}"
							   (input)="position.feature=$event.target.value"
							   [matTooltip]="editOfferForm.get('positionFeature'+position.uniqueId).invalid ? 'Cecha jest wymagana': ''">
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef i18n="@@Name">Nazwa</mat-header-cell>
					<mat-cell *matCellDef="let position">
						<!--suppress TypeScriptUnresolvedVariable -->
						<input [class]="{'form-error': editOfferForm.get('positionName'+position.uniqueId).invalid}"
							   type="text" class="form-control" [value]="position.name"
							   formControlName="positionName{{position.uniqueId}}"
							   (input)="position.name=$event.target.value">
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="quantity">
					<mat-header-cell *matHeaderCellDef i18n="@@Quantity">Ilość sztuk</mat-header-cell>
					<mat-cell *matCellDef="let position">
						<!--suppress TypeScriptUnresolvedVariable -->
						<input type="number" class="form-control" [value]="position.quantity"
							   (input)="inputQuantity(position, $event)">
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="price">
					<mat-header-cell *matHeaderCellDef i18n="@@Price" class="mat-table-price">Cena</mat-header-cell>
					<mat-cell *matCellDef="let position;" class="mat-table-price">
						<!--suppress TypeScriptUnresolvedVariable -->
						<input [class]="{'form-error': editOfferForm.get('positionPriceZl'+position.uniqueId).invalid}"
							   required type="number" class="form-control col-sm-3 numeric-input"
							   [value]="position.getPriceZl()"
							   formControlName="positionPriceZl{{position.uniqueId}}"
							   (input)="position.setPriceZl($event.target.value)"
							   [matTooltip]="editOfferForm.get('positionPriceZl'+position.uniqueId).invalid ? 'Cena jest wymagana': ''">
						<label class="simple-label">zł</label>
						<!--suppress TypeScriptUnresolvedVariable -->
						<input [class]="{'form-error': editOfferForm.get('positionPriceGr'+position.uniqueId).invalid}"
							   required type="number" class="form-control col-sm-2 numeric-input"
							   [value]="position.getPriceGr()"
							   formControlName="positionPriceGr{{position.uniqueId}}"
							   (input)="position.setPriceGr($event.target.value)"
							   [matTooltip]="editOfferForm.get('positionPriceGr'+position.uniqueId).invalid ? 'Cena jest wymagana': ''">
						<label class="simple-label">gr</label>
						<ng-select class="col-sm-5"
								   [items]="units"
								   [multiple]="false"
								   [closeOnSelect]="true"
								   [hideSelected]="false"
								   placeholder="Jednostki"
								   bindLabel="units"
								   formControlName="positionPriceUnits{{position.uniqueId}}"
								   [clearSearchOnAdd]="false"
								   (change)="changeUnits(position, $event)"
								   (blur)="onUnitsBlur(position)"
								   (search)="onUnitsSearch(position, $event)"
								   appendTo="ng-select"
						>
							<ng-template ng-label-tmp let-item="item">
								{{item}}
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-search="searchTerm">
								{{item}}
							</ng-template>
						</ng-select>
					</mat-cell>
				</ng-container>


				<ng-container matColumnDef="remove">
					<mat-header-cell *matHeaderCellDef>
						<button type="button" class="btn btn-primary btn-right" (click)="onAddPositionElement()">
							Dodaj pozycję
						</button>
					</mat-header-cell>
					<mat-cell *matCellDef="let position">
						<mat-icon matTooltip="Usuń pozycję" class="mat-icon-right" (click)="onRemovePositionElement(position)">
							remove_circle
						</mat-icon>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
				<mat-row *matRowDef="let row; columns: columnsToDisplay;">
				</mat-row>
			</mat-table>

			<mat-card class="mat-effects mat-totals">
				<mat-card-content class="totals">
					<div class="sum">
						<p>Suma:</p>
						<span>{{offer.getPriceSumStr()}}</span>
					</div>
				</mat-card-content>
			</mat-card>

			<div class="form-group row align-items-center mat-button-spacer">
				<div class="col-sm-12">
					<button class="button-spacer mat-button-custom mat-button-big" mat-button>
						Zapisz
					</button>
				</div>
			</div>

		</form>
	</div>
    <div *ngIf="permissionService.checkPermission('perm_read_offer') &&
	permissionService.checkPermission('perm_read_order')" class="toggle-search" [class]="{'opened': sidenav.opened}">
		<button type="button" mat-button (click)="sidenav.toggle()">Wyszukiwarka</button>
	</div>
	<mat-drawer #sidenav position="end" mode="side">
		<app-feature-search #offerSearch></app-feature-search>
	</mat-drawer>

</mat-drawer-container>
