export class EmployeeDayAdditionalInfo{
    causeOfAbsence: string;
    causeOfAbsenceDescription: string;
    pauseOffsetInSeconds: number;
    workTimeOffsetInSeconds: number;
    guideWorkTimeOffsetInSeconds: number;
    constructor(object?: EmployeeDayAdditionalInfo) {
        if (object) {
            this.causeOfAbsence = object.causeOfAbsence;
            this.pauseOffsetInSeconds = object.pauseOffsetInSeconds;
            this.workTimeOffsetInSeconds = object.workTimeOffsetInSeconds;
            this.guideWorkTimeOffsetInSeconds = object.guideWorkTimeOffsetInSeconds;
            this.causeOfAbsenceDescription = object.causeOfAbsenceDescription;
        }
    }
}

export interface IEmployeeDay {
    employeeDayAdditionalInfo: EmployeeDayAdditionalInfo;
}
