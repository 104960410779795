import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable, of} from 'rxjs';
import { ApiTranslatorService } from './api-translator.service';
import {Offer} from '../_models';
import { map } from 'rxjs/operators';
import {BackendPaginationService} from './backend-pagination.service';
import {PaginatedTranslatedResponse} from '../_interfaces/PaginatedApiResponse';
import { Feature } from '../_models/feature';

@Injectable({
    providedIn: 'root'
})
export class OfferService {

    constructor(private http: HttpClient,
                private apiTranslator: ApiTranslatorService,
                private backendPaginationService: BackendPaginationService,
                ) {
    }

    getAll(params?: Map<string, string | number | Array<string | number>>):
        Observable<PaginatedTranslatedResponse<Offer>> {
        return this.backendPaginationService.getAll<Offer>('offer', data => new Offer(data), params);
    }

    offerAndOrderPosition(params?: Map<string, string | number | Array<string | number>>):
        Observable<PaginatedTranslatedResponse<Feature>> {
        return this.backendPaginationService.getAll<Feature>('offer_and_order_position',
                data => new Feature(data), params);
    }

    getDetails(id): Observable<Offer> {
        return this.http.get<Offer>(`${environment.apiUrl}/offer/` + id + `/`).pipe(map(x => new Offer(x)));
    }

    getPdf(id): Observable<Offer> {
        return this.http.get<Offer>(`${environment.apiUrl}/offer/` + id + `/pdf/`);
    }

    createOffer(offer): Observable<Offer> {
        offer = this.apiTranslator.modelToSnakeCase(offer);
        return this.http.post<Offer>(`${environment.apiUrl}/offer/`, offer);
    }

    updateOffer(offer: Offer): any {
        return this.http.put<Offer>(`${environment.apiUrl}/offer/` + offer.id + `/`,
            this.apiTranslator.modelToSnakeCase(offer));
    }
}
