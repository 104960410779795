import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Guide, GuideAction, Permission, User} from '../_models';
import {
    AuthenticationService,
    GuidesService,
    MultiActionResponse,
    PermissionService,
    TimeService,
    TranslatorService,
    UserService
} from '../_services';
import {catchError, map} from 'rxjs/operators';
import {throwError, timer} from 'rxjs';
import {BackendErrorResponse} from '../_interfaces/BackendErrorResponse';
import {WebsocketService} from '../_services/websocket.service';
import {DesktopSearchComponent} from './desktop-search.component';
import {BarcodeScanQueueService} from './barcode-scan-queue.service';
import {AlertService, DateService} from '../_primitive_services';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {PaginationService} from '../_services/pagination.service';
import {BackendPaginationService} from '../_services/backend-pagination.service';
import {MultiSelectionService} from '../_services/multi-selection.service';
import {BackendParamService} from '../_filters/backend-param.service';
import {InMemoryParamService} from '../_filters/in-memory-param.service';
import {Title} from '@angular/platform-browser';
import { Router } from '@angular/router';
import {MatSelectChange} from '@angular/material/select';
import {ScannerConfig} from '../_models/scanner-config';
import {ScannerConfigService} from '../_services/scanner-config.service';
import { RefreshService } from '../_services/refresh.service';


export interface ScannerChoice {
    id: number;
    name: string;
    action: string;
}

@Component({
    selector: 'app-desktop',
    templateUrl: './desktop.component.html',
    styleUrls: ['./desktop.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService, MultiSelectionService,
        { provide: BackendParamService, useClass: InMemoryParamService },
        BarcodeScanQueueService, GuidesService
    ]
})
export class DesktopComponent implements OnInit, OnDestroy {

    constructor(
        private userService: UserService,
        private guideService: GuidesService,
        private alertService: AlertService,
        private websocketService: WebsocketService,
        private barcodeService: BarcodeScanQueueService,
        private router: Router,
        public translatorService: TranslatorService,
        public permissionService: PermissionService,
        private titleService: Title,
        private authenticationService: AuthenticationService,
        private scannerConfigService: ScannerConfigService,
        private refreshService: RefreshService
    ) {
    }

    acceptingMultipleGuides = false;
    allGuides: Guide[] = [];
    employee: User = null;
    guideActions: GuideAction[] = [];
    exitButtonVisible = false;
    public scannerChoices: ScannerChoice[] = [{id: -1, name: 'Wszystkie skanery', action: 'none'}];
    selectedScannerId = -1;

    @ViewChild('desktopAdmin') desktopAdmin: DesktopSearchComponent;

    timerConfig = {
        SESSION_TIME_IN_MINUTES: 1,
        timerEnabled: false,
        countdown: timer(0, 1000),
        countdownSubscription: null,
        minuteStr: '00',
        secondStr: '00',
    };

    private keyboardConfig = {
        MAX_INTERVAL_IN_MILLIS: 50,
        lastKeyUpTime: Date.now(),
        buffer: ''
    };
    ngOnInit(): void {
        this.authenticationService.invalidateRFIDAuthentication();
        this.subscribeOnWebsocket();
        this.refreshService.refreshSystemTimer();
        this.barcodeService.subscription().subscribe(barcode => this.onKeyboardInput(barcode));
        this.titleService.setTitle('Skanowanie przewodników');
    }

    private refreshScanners(): void {
        this.scannerConfigService.getAll()
            .subscribe((response: ScannerConfig[]) => {
                while (this.scannerChoices.length > 1){
                    this.scannerChoices.pop();
                }
                this.scannerChoices.push(...response.map(el => ({id: el.id, name: el.scannerId, action: el.action})));
            });
    }

    @HostListener('window:keydown.control.y')
    navigateToHome(): void {
        this.router.navigate(['/']);
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent): void {
        const key = event.key;
        if (/^\d+$/.test(key)){
            const now: number = Date.now();
            this.keyboardConfig.lastKeyUpTime = now;
            this.keyboardConfig.buffer += event.key;
            setTimeout(() => {
                if (now === this.keyboardConfig.lastKeyUpTime) {
                    const buffer: string = this.keyboardConfig.buffer;
                    this.keyboardConfig.buffer = '';
                    if (buffer.length > 5) {
                        this.onKeyboardInput(buffer);
                    }
                }
            }, this.keyboardConfig.MAX_INTERVAL_IN_MILLIS);
        }
        else if (key === 'Enter') {
            const buffer = this.keyboardConfig.buffer;
            this.keyboardConfig.buffer = '';
            if (buffer.length > 5) {
                this.onKeyboardInput(buffer);
            }
        }
    }


    onKeyboardInput(input: string): void{
        console.log('Received keyboard input: ', input);
        this.guideService.getGuideByBarcode(input).pipe(
            catchError((error: BackendErrorResponse) => {
                if (error.code === 404){
                    this.alertService.error('Niepoprawny kod kreskowy');
                }
                return throwError('Something bad happened; please try again later.');
            })
        ).subscribe((guide: Guide) => {
            this.onReceiveNewGuide(guide);
        });

    }

    onRFIDInput(scannerId: string, input: string): void{
        if (this.selectedScannerId === -1 ||
            this.scannerChoices.find(el => el.id === this.selectedScannerId).name === scannerId) {
            this.userService.getDetailsByRFID(input).pipe(
                catchError((error: BackendErrorResponse) => {
                    if (error.code === 401) {
                        this.alertService.error('Niepoprawny kod rfid');
                    }
                    return throwError('Error getting employee.');
                })
            ).subscribe((user: User) => {
                this.onReceiveNewUser(user);
            });
        }

    }

    private onReceiveNewUser(user: User): void {
        this.employee = user;
        if (user.isSuperuser){
            this.authenticationService.injectRFIDAuthentication(user.rfids[0].rfid);
        }
        this.permissionService.getPermissionsForRFID(user.rfids[0].rfid).subscribe(
            (permissions: Permission[]) => {
                this.exitButtonVisible = this.permissionService.checkPermissionsContain(
                    'perm_write_extended_desktop', permissions);
                if (this.permissionService.checkPermission('perm_write_extended_desktop') &&
                    this.permissionService.checkPermission('perm_read_system_settings')){
                    this.refreshScanners();
                }
            }
        );

        this.showActions();
    }

    private onReceiveNewGuide(guide: Guide): void{

        const previousGuideIndex = this.allGuides.findIndex(listGuide => listGuide.id === guide.id);
        if (previousGuideIndex !== -1 && this.acceptingMultipleGuides) {
            this.allGuides[previousGuideIndex] = guide;
            return;
        }

        if (this.acceptingMultipleGuides){
            this.allGuides.push(guide);
        }
        else {
            this.allGuides.length = 0;
            this.allGuides.push(guide);
        }
        this.showActions();
        console.log('onReceiveNewGuide', this.allGuides);
    }

    resetState(): void{
        this.refreshService.enable();
        this.allGuides.length = 0;
        this.employee = null;
        this.guideActions.length = 0;
        this.disableTimer();
        this.exitButtonVisible = false;
    }

    sendRFIDToWebsocket(rfid: string): void {
        this.guideService.sendRFIDScan(rfid).subscribe(_ => undefined);
    }

    private showActions(): void{
        this.refreshService.disable();
        this.enableTimer();
        if (this.employee && this.allGuides.length > 0) {
            this.guideService.getGuidesActions(this.allGuides.map(el => el.id), this.employee.rfids[0].rfid)
                .pipe(
                    catchError((error: BackendErrorResponse) => {
                        this.alertService.error(error.description);
                        return throwError('Error getting employee.');
                    }))
                .pipe(map((actions: GuideAction[]) => {
                    return actions.filter(el => {
                        const allowedActions = ['pause', 'start', 'launch_and_start', 'finish', 'take_over'];
                        return allowedActions.indexOf(el.action) !== -1;
                    });
                }))
                .subscribe((actions: GuideAction[]) => {

                    const startAction = actions.find(el => el.action === 'start');
                    const launchAndStartAction = actions.find(el => el.action === 'launch_and_start');
                    if (launchAndStartAction.canExecute){
                        startAction.visible = false;
                    } else {
                        launchAndStartAction.visible = false;
                    }
                    this.guideActions.length = 0;
                    this.guideActions.push(...actions);
                });
        }
    }

    performAction(action: GuideAction): void {
        if (!action.canExecute){
            this.alertService.error(action.reason);
            return;
        }
        this.guideService.multiActionRFID(this.allGuides.map(el => el.id), action.action, this.employee.rfids[0].rfid).pipe(
            catchError((error: BackendErrorResponse) => {
                this.alertService.error(error.description);
                return throwError('Could not perform action');
            })
        ).subscribe((data: MultiActionResponse[]) => {
            let success = 0;
            data.forEach(response => {
                if (response.code === 200){
                    success += 1;
                }else{
                    this.alertService.error(`Nie powiodło się wykonanie operacji: `, response.description);
                }
            });
            this.alertService.success(`Udało się edytować ${success} przewodników`);
            this.guideService.getAllDetails(new Map<string, Array<string | number>>
            ([['id', this.allGuides.map(el => el.id)], ]))
                .subscribe(guides => {
                    this.allGuides.length = 0;
                    this.allGuides.push(...guides.results);
                    this.showActions();

                });
        });
    }

    private subscribeOnWebsocket(): void {
        this.guideService.getWebsocketConfiguration().pipe(
            catchError((_: BackendErrorResponse) => {
                this.alertService.error('Nie udało się podłączyć do czytnika RFID');
                return throwError('Could not perform action');
            })
        ).subscribe(config => {
            for (const rfidScannerId of config.rfid){
                this.websocketService.connect(`websocket/rfid/${rfidScannerId}/`).subscribe(
                    message => {
                        this.onRFIDInput(rfidScannerId, message);
                    }
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.websocketService.closeAllConnections();
    }

    toggleAcceptMultipleGuides(): void {
        this.refreshService.disable();
        this.enableTimer();
        this.acceptingMultipleGuides = !this.acceptingMultipleGuides;
    }

    removeGuide(guide: Guide): void {
        const index = this.allGuides.indexOf(guide);
        if (index !== -1){
            this.allGuides.splice(index, 1);
        }
        this.showActions();
    }

    private enableTimer(): void{
        this.timerConfig.timerEnabled = true;
        this.resetTimer();
    }
    private disableTimer(): void{
        this.timerConfig.timerEnabled = false;
        if (this.timerConfig.countdownSubscription !== null){
            this.timerConfig.countdownSubscription.complete();
            this.timerConfig.secondStr = DateService.padNumber(0, 2);
            this.timerConfig.minuteStr = DateService.padNumber(0, 2);
        }
    }
    private resetTimer(): void{
        if (this.timerConfig.countdownSubscription !== null){
            this.timerConfig.countdownSubscription.complete();
        }
        this.timerConfig.countdownSubscription = this.timerConfig.countdown.subscribe(value => {
            const seconds = this.timerConfig.SESSION_TIME_IN_MINUTES * 60 - value;
            const minute = Math.floor(seconds / 60);
            const second = seconds - minute * 60;
            this.timerConfig.secondStr = DateService.padNumber(second, 2);
            this.timerConfig.minuteStr = DateService.padNumber(minute, 2);
            if (seconds < 0 && minute <= 0){
                this.resetState();
            }
        });
    }

    getOperationName(guide: Guide): string {
        const currentOperation = guide.operations.find(operation => guide.currentOperation === operation.id);

        return currentOperation.name;
    }

    getConvertedTime(realTimeInSeconds: number): string {
        return TimeService.parseTime(realTimeInSeconds);
    }

    updateScanner(event: MatSelectChange): void {
        const selectedScanner = this.scannerChoices.find(el => el.id === event.value);
        this.selectedScannerId = selectedScanner.id;
    }
}
