import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {Group, IBackendUser, MachineGroup, RFID, RFIDDetail, User} from 'src/app/_models';
import {
    ApiTranslatorService,
    ErrorHandlerService,
    GroupService, GuidesService,
    MachineGroupService,
    ModalService,
    PermissionService,
    UserService
} from 'src/app/_services';
import {catchError, first} from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import {AlertService} from '../../_primitive_services';
import {BackendErrorResponse} from '../../_interfaces/BackendErrorResponse';
import {Subscription, throwError} from 'rxjs';
import {WebsocketService} from '../../_services/websocket.service';
import {RFIDService} from '../../_services/rfid.service';
import {MatButtonToggle} from '@angular/material/button-toggle';
import {MatRipple} from '@angular/material/core';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit, OnDestroy {

    constructor(private activatedRoute: ActivatedRoute,
                private userService: UserService,
                private router: Router,
                private groupService: GroupService,
                private machineGroupService: MachineGroupService,
                private apiTranslator: ApiTranslatorService,
                private formBuilder: FormBuilder,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                public permissionService: PermissionService,
                private titleService: Title,
                public modalService: ModalService,
                private guideService: GuidesService,
                private websocketService: WebsocketService,
                private rfidService: RFIDService,
                ) {
        this.titleService.setTitle('Użytkownik - edycja');
    }

    get f(): any {
        return this.formGroup.controls;
    }
    user: User;
    formGroup: FormGroup;
    machineGroups: MachineGroup[];
    groups: Group[];
    selectedMachineGroups: MachineGroup[];
    deletedCards: RFID[] = [];
    loading: boolean;
    private websocketSubscription: Subscription = null;
    isEditComponent: boolean;
    title: string;

    @ViewChild(MatButtonToggle) scanToggle: MatButtonToggle;

    @ViewChild('scanToggle', {read: MatRipple}) ripple: MatRipple;

    ngOnInit(): void {
        this.isEditComponent = this.activatedRoute.snapshot.paramMap.has('id');
        this.setTitle();
        this.errorHandler.showLoader(true);
        if (this.isEditComponent){
            this.activatedRoute.data.subscribe(data => {
                this.user = this.apiTranslator.modelToCamelCase(User, data.user);
                this.user.groupIds = this.user.groups.map(x => x.id);
                this.user.machineGroupIds = this.user.machineGroups.map(x => x.id);
                this.user.rfidIds = this.user.rfids.map(x => x.id);
                this.selectedMachineGroups = this.user.machineGroups;
                this.buildForm();
                this.machineGroups = data.machineGroups;
                this.groups = data.groups;
                this.errorHandler.showLoader(false);
            });
        }else {
            this.activatedRoute.data.subscribe(data => {
                this.user = new User({
                    allowMultipleMachineGroups: false,
                    authUserId: 0,
                    email: '',
                    firstName: '',
                    fullName: '',
                    groupIds: undefined,
                    groups: [],
                    id: 0,
                    includeInAttendanceList: false,
                    isSuperuser: false,
                    lastName: '',
                    machineGroupIds: undefined,
                    machineGroups: [],
                    password: '',
                    passwordRepeat: '',
                    permissions: [],
                    rfidIds: undefined,
                    rfids: [],
                    username: ''

                });
                this.buildForm();
                this.machineGroups = data.machineGroups;
                this.groups = data.groups;
                this.errorHandler.showLoader(false);
            });
        }
        this.subscribeOnWebsocket();
    }

    buildForm(): void {

        const controlsConfig: any = {
            firstName: [this.user.firstName, Validators.required],
            lastName: [this.user.lastName, Validators.required],
            username: [this.user.username, Validators.required],
            password: this.isEditComponent ? [''] : ['', Validators.required],
            passwordRepeat: this.isEditComponent ? [''] : ['', Validators.required],
            email: [this.user.email, Validators.email],
            machineGroups: [this.user.machineGroups],
            rfids: [this.user.rfids],
            groups: this.formBuilder.array(this.user.groups, Validators.required),
            includeInAttendanceList: [this.user.includeInAttendanceList],
            allowMultipleMachineGroups: [this.user.allowMultipleMachineGroups],
        };
        if (this.permissionService.userProfile.isSuperuser){
            controlsConfig.superadmin = [this.user.isSuperuser];
        }
        this.formGroup = this.formBuilder.group(controlsConfig);
    }

    deleteCard(rfid): any {
        const index = this.user.rfids.findIndex(x => x.id === rfid);
        const id = this.user.rfidIds.indexOf(rfid);
        if (index > -1) {
            this.deletedCards.push(this.user.rfids[index]);
            this.user.rfids.splice(index, 1);
            this.user.rfidIds.splice(id, 1);
        }
    }

    restoreCard(rfid): any {
        const index = this.deletedCards.findIndex(x => x.id === rfid);
        if (index > -1) {
            this.user.rfids.push(this.deletedCards[index]);
            this.user.rfidIds.push(rfid);
            this.deletedCards.splice(index, 1);
        }
    }

    private setTitle(): void{
        this.title = this.isEditComponent ? 'Użytkownik - edycja' : 'Użytkownik - dodawanie';
        this.titleService.setTitle(this.title);
    }

    onChangeGroup(e): any {
        const checkArray: FormArray = this.formGroup.get('groups') as FormArray;
        const value = parseInt(e.target.value, 10);
        let index: number;
        if (e.target.checked) {
            index = this.groups.findIndex(x => x.id === value);
            checkArray.push(new FormControl(this.groups[index]));
            this.user.groupIds.push(this.groups[index].id);
        } else {
            let i = 0;
            checkArray.controls.forEach((item: FormControl) => {
                if (item.value.id === value) {
                    checkArray.removeAt(i);
                    return;
                }
                i++;
            });
            index = this.user.groupIds.indexOf(value);
            this.user.groupIds.splice(index, 1);
        }
        }

    changeMachineGroup(event): any {
        this.user.machineGroupIds = event.map(x => x.id);
    }

    isUserInGroup(groupId): any {
        return this.user.groups.findIndex(x => x.id === groupId) > -1;
    }

    private subscribeOnWebsocket(): void {
        this.guideService.getWebsocketConfiguration().pipe(
            catchError((_: BackendErrorResponse) => {
                this.alertService.error('Nie udało się podłączyć do czytnika RFID');
                return throwError('Could not perform action');
            })
        ).subscribe(config => {
            for (const rfidScannerId of config.rfid){
                this.websocketSubscription = this.websocketService.connect(`websocket/rfid/${rfidScannerId}/`).subscribe(
                    message => {
                        console.log('Input received');
                        this.onRFIDInput(message);
                    }
                );
            }
        });
    }

    onSubmit(): any {
        const user: IBackendUser = Object.assign({}, this.user);

        if (this.formGroup.invalid) {
            return;
        }
        user.username = this.formGroup.value.username;
        user.firstName = this.formGroup.value.firstName;
        user.lastName = this.formGroup.value.lastName;
        user.email = this.formGroup.value.email;
        user.isSuperuser = this.formGroup.value.superadmin;
        user.includeInAttendanceList = this.formGroup.value.includeInAttendanceList;
        user.allowMultipleMachineGroups = this.formGroup.value.allowMultipleMachineGroups;
        if (!this.isEditComponent){
            user.password = this.formGroup.value.password;
            user.passwordRepeat = this.formGroup.value.passwordRepeat;
        }
        this.loading = true;

        if (!this.permissionService.userProfile.isSuperuser){
            delete user.isSuperuser;
        }
        if (this.isEditComponent){
            this.userService.updateUser(user).pipe(first()).subscribe(
                data => {
                    this.router.navigate(['/user', this.user.id]).then(_ => {});
                    this.alertService.success(User.modelName + ': <strong>' + data.username + '</strong> został pomyślnie edytowany');
                }, error => {
                    this.loading = false;
                    if (error.error_code === 713 || error.error_code === 705) {
                        this.alertService.error(
                            User.modelName + ' o nazwie: <strong>' + this.formGroup.value.username + '</strong> już istnieje ');
                    } else {
                        this.alertService.error(
                            User.modelName + ': <strong>' + this.user.username + '</strong> nie został edytowany. Błąd: ',
                            this.errorHandler.error(error));
                    }
                }
            );
        }else {
            this.userService.createUser(user).pipe(first()).subscribe(
                data => {
                    console.log(data);
                    this.router.navigate(['/user', data.id]);
                    this.alertService.success(
                        User.modelName + ': <strong>' + data.username + '</strong> został pomyślnie utworzony', {autoClose: true});
                }, error => {
                    console.log(error);
                    this.loading = false;
                    if (error.error_code === 713 || error.error_code === 705) {
                        this.alertService.error(
                            User.modelName + ' o nazwie <strong>' + this.formGroup.value.username + '</strong> już istnieje');
                    } else {
                        this.alertService.error(User.modelName + ' nie został utworzony. Błąd: ', this.errorHandler.error(error));
                    }
                }
            );
        }
    }

    private onRFIDInput(rfidStr: string): void {

        if (this.scanToggle.checked) {
            this.rfidService.get(rfidStr).subscribe((rfid: RFIDDetail) => {
                if (rfid.user) {
                    this.userService.getDetailsByRFID(rfidStr).subscribe(
                        (user: User) => {
                            this.alertService.success(
                                `Dotychczasowy właściciel karty ${rfidStr} to: <strong>${user.fullName}</strong>`);
                            this.appendRFIDId(rfid.id, rfid.rfid);
                        },
                        (errorInner: BackendErrorResponse) => {
                            this.alertService.error(errorInner.description);
                        });

                } else {
                    this.appendRFIDId(rfid.id, rfid.rfid);
                }
            }, (error: BackendErrorResponse) => {
                if (error.error_code === 404) {
                    console.log('Does not exist');

                    this.rfidService.create(rfidStr).subscribe(
                        (rfid: RFIDDetail) => {
                            this.appendRFIDId(rfid.id, rfid.rfid);
                        },
                        (errorInner: BackendErrorResponse) => {
                            this.alertService.error(errorInner.description);
                        });

                } else {
                    this.alertService.error(error.description);
                }
            });
        }
        else {
            this.ripple.launch({centered: true});
        }
    }

    private appendRFIDId(id: number, rfid: string): void {
        const index = this.user.rfids.findIndex(x => x.id === id);
        if (index === -1) {
            console.log('Appending RFID');
            this.user.rfids.push(new RFID(id, rfid));
            this.user.rfidIds.push(id);
        }
    }

    ngOnDestroy(): void {
        if (this.websocketSubscription !== null) {
            this.websocketSubscription.unsubscribe();
            this.websocketSubscription = null;
        }
    }
}
