import {BackendWzSet, BackendWzSetNoOrder, WzSetPositions} from './wz-set-positions';
import {DateService} from '../_primitive_services';


export interface IWz {
    id: number;
    number: string;
    contractor: number;
    contractorName: string;
    orderCreationDate: string;
    wzSetPositions: WzSetPositions[];
    creationDate: string;
    order: number;
    contractorOrderNumber: string;
    hasOrder: boolean;
}

export class IBackendWz{
    wzSetPositions: BackendWzSet[];
    creationDate: string;
    order: number;
}

export class IBackendWzNoOrder{
    wzSetPositions: BackendWzSetNoOrder[];
    creationDate: string;
    contractor: number;
    contractorOrderNumber: string;
}

export class Wz {
    static modelName = 'WZ';
    static path = 'wz';

    id: number;
    number: string;
    contractor: number;
    contractorName: string;
    orderCreationDate: Date | null;
    wzSetPositions: WzSetPositions[];
    creationDate: Date;
    order: number | null;
    contractorOrderNumber = '';
    hasOrder = true;

    constructor(object?: IWz) {
        if (object) {
            this.id = object.id;
            this.number = object.number;
            this.contractor = object.contractor;
            this.contractorName = object.contractorName;
            this.orderCreationDate = this.stringOrderCreationToDate(object);
            this.wzSetPositions = object.wzSetPositions.map(position => new WzSetPositions(position));
            this.creationDate = this.stringCreationToDate(object.creationDate);
            this.order = object.order;
            this.contractorOrderNumber = object.contractorOrderNumber;
            this.hasOrder = object.hasOrder;
        }else{
            this.wzSetPositions = [];
        }
    }

    convertToBackendWz(): IBackendWz{
        return {
            creationDate: this.dateToString(this.creationDate),
            order: this.order,
            wzSetPositions: this.wzSetPositions.filter(set => set.checked).map(pos => pos.convertToBackendWzSet())
        };
    }

    convertToBackendWzNoOrder(): IBackendWzNoOrder{
        return {
            creationDate: this.dateToString(this.creationDate),
            contractor: this.contractor,
            contractorOrderNumber: this.contractorOrderNumber,
            wzSetPositions: this.wzSetPositions.filter(set => set.checked).map(pos => pos.convertToBackendWzSetNoOrder())
        };
    }

    stringOrderCreationToDate(object: any): Date {
        return DateService.stringDateToDate(object.orderCreationDate);
    }

    stringCreationToDate(object: string): Date {
        return DateService.stringDateToDate(object);
    }

    getSumPrice(): number{
        return this.wzSetPositions
                .map( position => position.getValue())
                .reduce((prev, next) => prev + next, 0);
    }

    dateToString(date: Date): string {
        return DateService.dateToStringDate(date);
    }

    getModelName(): string {
        return Wz.modelName;
    }

    getPath(): string {
        return Wz.path;
    }
    getName(): string {
        return this.number;
    }

}
