import { Component, OnInit } from '@angular/core';
import {
    ApiTranslatorService,
    ErrorHandlerService,
    ModalService,
    PermissionService,
    QualityControlService,
    TranslatorService
} from 'src/app/_services';
import { QualityControl } from 'src/app/_models';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-quality-control-details',
    templateUrl: './quality-control-details.component.html',
    styleUrls: ['./quality-control-details.component.css']
})
export class QualityControlDetailsComponent implements OnInit {

    qualityControl: QualityControl;
    grade: string;

    constructor(private qualityControlService: QualityControlService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                public modalService: ModalService,
                private activatedRoute: ActivatedRoute,
                private translator: TranslatorService,
                private errorHandler: ErrorHandlerService,
                public permissionService: PermissionService) {
        this.titleService.setTitle('Kontrola jakości - szczegóły');
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        this.qualityControlService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(qualityControl => {
            this.errorHandler.showLoader(false);
            this.qualityControl = qualityControl;
            this.grade = this.translator.translate(this.qualityControl.grade);
        });
    }

}
