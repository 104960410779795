import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedTranslatedResponse } from '../_interfaces/PaginatedApiResponse';
import { Guide, GuideInList } from '../_models';
import { BackendPaginationService } from './backend-pagination.service';
import { PositionsWithoutGuides } from '../_models/positions-without-guides';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PositionsWithoutGuidesService {

    constructor(private backendPaginationService: BackendPaginationService,
                private http: HttpClient,
    ) {
    }

    getAll(params?: Map<string, string | number | Array<string | number>>):
        Observable<PaginatedTranslatedResponse<PositionsWithoutGuides>> {
        return this.backendPaginationService.getAll<PositionsWithoutGuides>('positions_without_guides',
            data => new PositionsWithoutGuides(data), params);
    }

    getPosition(id): Observable<PositionsWithoutGuides> {
        return this.http.get<PositionsWithoutGuides>(`${environment.apiUrl}/positions_without_guides/${id}`).pipe(map(position => {
            return new PositionsWithoutGuides(position);
        }));

    }
}
