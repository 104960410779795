import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslatorService, ErrorHandlerService } from 'src/app/_services';
import { ContractorStats } from 'src/app/_models';
import { Title } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';
import { ContractorsStatsService } from '../_services';
import {MoneyService} from '../_primitive_services';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {BackendParamService} from '../_filters/backend-param.service';
import {QueryParamService} from '../_filters/query-param.service';
import {MultiRequestService} from '../_services/multi-request.service';
import { ContractorFilterConfig } from '../_filters/generic-filter/sample-filter-configs';

@Component({
    selector: 'app-contractors-statistics',
    templateUrl: './contractors-statistics.component.html',
    styleUrls: ['./contractors-statistics.component.css'],
    providers: [
        BackendFilterService, {provide: BackendParamService, useClass: QueryParamService}, MultiRequestService,
    ]
})
export class ContractorsStatisticsComponent implements OnInit {
    contractors: ContractorStats[];
    columnsToDisplay = ['filer', 'contractor_name', 'order_date', 'total', 'rest'];
    otherColumnsToDisplay = ['contractor_name', 'order_number', 'order_date', 'total', 'rest'];
    otherContractors: ContractorStats;
    columnNames = ['empty', 'name_1', 'name_2', 'name_3', 'name_4'];
    otherColumnNames = ['name', 'name_1', 'name_2', 'name_3', 'name_4'];
    moneyService = MoneyService;
    totalSum = 0;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor(
        private contractorsStatsService: ContractorsStatsService,
        private titleService: Title,
        private filterService: BackendFilterService,
        public errorHandlerService: ErrorHandlerService,
        private queryParamService: BackendParamService,
        translatorService: TranslatorService
    ) {
        this.titleService.setTitle(translatorService.translate('contractors_stats'));
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new ContractorFilterConfig(),
        ]);
        this.subscribeOnChanges();
    }

    refreshData(): void {
        this.errorHandlerService.showLoader(true);
        this.contractorsStatsService.getAll(this.getBackendParamMap()).subscribe(contractors => {
            this.errorHandlerService.showLoader(false);
            this.contractors = contractors;
            this.totalSum = 0;
            this.contractors.forEach( x => (this.totalSum += x.totalRemainingInvoiceCost));
            this.otherContractors = this.contractors.find(x => x.id == null);
            this.contractors.splice(this.contractors.findIndex(x => x.id == null));
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }
}
