import {Component, OnInit, ViewChild} from '@angular/core';
import {OperationStateEdit} from './operation-state-edit';
import {ErrorHandlerService, TranslatorService} from '../../_services';
import {OperationStateEditService} from './operation-state-edit.service';
import {ActivatedRoute} from '@angular/router';
import {MatTable} from '@angular/material/table';
import {User} from '../../_models';
import {UserFilterResolverService} from '../../_services/user-filter-resolver.service';
import {MultiRequestService} from '../../_services/multi-request.service';
import {AlertService, DateService} from '../../_primitive_services';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-operation-state-edit',
    templateUrl: './operation-state-edit.component.html',
    styleUrls: ['./operation-state-edit.component.css'],
    providers: [MultiRequestService, OperationStateEditService]
})
export class OperationStateEditComponent implements OnInit {
    public columnsToDisplay = ['operation_lp',
        'operation_name', 'start', 'end', 'real_time_in_seconds',
        'author', 'state',
    ];
    public states: OperationStateEdit[] = [];

    public title = 'Przewodnik - edycja czasu pracy';

    @ViewChild(MatTable) matPositions: MatTable<any>;

    userChoices: User[];
    dateService = DateService;

    constructor(
        private errorHandler: ErrorHandlerService,
        private operationStateEditService: OperationStateEditService,
        private activatedRoute: ActivatedRoute,
        private userFilterResolverService: UserFilterResolverService,
        public alertService: AlertService,
        public translatorService: TranslatorService,
        public titleService: Title
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle(this.title);
        this.userFilterResolverService.resolve().subscribe(users => {
            this.userChoices = users;
        });
        this.refreshData();
    }

    public refreshData(): void {
        this.errorHandler.showLoader(true);
        this.operationStateEditService.getAll(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10))
            .subscribe(states => {
                this.errorHandler.showLoader(false);
                this.states.length = 0;
                this.states.push(
                    ...states
                        .filter(
                            el => el.state === 'production' || el.state === 'pause' || el.state === 'quality_control')
                        .filter(el => el.end)
                );
                this.matPositions.renderRows();
            });
    }

    // TODO style picker
    public onStartChange(state: OperationStateEdit, event: Event): void {
        const dateStr = (event.target as HTMLInputElement).value;
        if (dateStr) {
            state.start = DateService.parseDateTimeLocalString(dateStr);
            this.saveUpdate(state);
        }
    }

    public onEndChange(state: OperationStateEdit, event: Event): void {
        const dateStr = (event.target as HTMLInputElement).value;
        if (dateStr) {
            state.end = DateService.parseDateTimeLocalString(dateStr);
            this.saveUpdate(state);
        }
    }

    private saveUpdate(state: OperationStateEdit): void {
        this.errorHandler.showLoader(true);
        this.operationStateEditService.update(state)
            .subscribe(updatedState => {
                this.errorHandler.showLoader(false);
                this.alertService.success('Zaktualizowano operację');
                const index = this.states.findIndex(el => el.id === updatedState.id);
                if (index !== -1){
                    this.states[index] = updatedState;
                    this.matPositions.renderRows();
                }
            });
    }

    changeAuthor(state: OperationStateEdit, event: User): void {
        if (event) {
            state.author = event.authUserId;
            state.authorName = event.fullName;
            this.saveUpdate(state);
        } else {
            state.author = null;
            state.authorName = null;
        }
    }
}
