import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-password-reset-modal',
    templateUrl: './password-reset-modal.component.html',
    styleUrls: ['./password-reset-modal.component.css']
})
export class PasswordResetModalComponent implements OnInit {
    @Input() user;
    password: string;
    resetPasswordForm: FormGroup;

    constructor(public activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                private http: HttpClient) {
    }

    get f(): any { return this.resetPasswordForm.controls; }

    ngOnInit(): void {
        this.resetPasswordForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            passwordRepeat: ['', [Validators.required]],
        }, {
            validator: [this.checkPasswords]
        });
    }

    checkPasswords(group: FormGroup): any {
        const pass = group.get('password').value;
        const confirm = group.get('passwordRepeat').value;
        if (pass !== confirm) {
            group.get('passwordRepeat').setErrors({notSame: true});
        }
    }

    resetPassword(): any {
        if (this.resetPasswordForm.invalid) {
            return;
        }
        const id = this.user.id;
        const password = this.resetPasswordForm.value.password;
        console.log(id);
        console.log(password);
        return this.http.post<any>(`${environment.apiUrl}/employee/` + id + `/reset_password/`, { password }).subscribe(data => {
            console.log(data);
            this.activeModal.close('Password changed');
        });
    }

}
