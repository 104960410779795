import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService, MachineGroupService, ModalService, PermissionService } from 'src/app/_services';
import { MachineGroup } from 'src/app/_models';
import { Title } from '@angular/platform-browser';
import {MoneyService} from '../../_primitive_services';

@Component({
    selector: 'app-machine-group-details',
    templateUrl: './machine-group-details.component.html',
    styleUrls: ['./machine-group-details.component.css']
})
export class MachineGroupDetailsComponent implements OnInit {
    machineGroup: MachineGroup;
    moneyService = MoneyService;

    constructor(private activatedRoute: ActivatedRoute,
                private machineGroupService: MachineGroupService,
                private errorHandler: ErrorHandlerService,
                private titleService: Title,
                public modalService: ModalService,
                public permissionService: PermissionService) {
        this.titleService.setTitle('Grupa maszyn - szczegóły');
    }

    ngOnInit(): void {
        this.errorHandler.showLoader(true);
        this.machineGroupService.getDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(machineGroup => {
            this.machineGroup = machineGroup;
            this.errorHandler.showLoader(false);
        });
    }

    isChecked(fieldName, index): any {
        return index[fieldName] ? 'done' : 'close';
    }
}
