import { EmployeeDayAdditionalInfo } from './employee-day-additional-info';
import { DayType } from './day-type';
import { GuideState } from './guide-state';

export class EmployeeDay {
    day: string;
    workStartHour: string;
    workEndHour: string;
    workTime: number;
    guideWorkTime: number;
    totalBreak: number;
    employeeDayAdditionalInfo: EmployeeDayAdditionalInfo;
    dayType: DayType;
    cssClass: string;

    constructor(object?: EmployeeDay) {
        if (object) {
            this.day = object.day;
            this.workStartHour = object.workStartHour;
            this.workEndHour = object.workEndHour;
            this.guideWorkTime = object.guideWorkTime;
            this.workTime = object.workTime;
            this.totalBreak = object.totalBreak;
            this.employeeDayAdditionalInfo = object.employeeDayAdditionalInfo;
            this.dayType = new DayType(object.dayType);
            this.cssClass = this.dayRecognizer(this.dayType);
        }
    }

    dayRecognizer(dayType: DayType): string {
        const wasAbsent = !this.employeeDayAdditionalInfo || !this.employeeDayAdditionalInfo.causeOfAbsence;
        const isInThePast = new Date(this.day).getTime() < new Date().getTime();
        if (dayType.type === 'saturday') {
            this.cssClass = 'saturday';
            return 'saturday';
        } else if (dayType.type === 'sunday') {
            return 'sunday';
        } else if (dayType.type === 'holiday') {
            return 'holiday';
        } else if (this.workTime === 0 && wasAbsent && isInThePast) {
            return 'absence';
        } else {
            return 'workday';
        }
    }
}
