import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiTranslatorService } from './api-translator.service';
import { Observable } from 'rxjs';
import {IMachineGroup, MachineGroup} from 'src/app/_models';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {MultiRequestService} from './multi-request.service';
import {HttpService} from './http.service';

@Injectable({
    providedIn: 'root'
})
export class MachineGroupService {

    constructor(private http: HttpClient,
                private apiTranslator: ApiTranslatorService,
                private multiRequestService: MultiRequestService,
                private httpService: HttpService,
                ) {
    }

    getFilterAllMachineGroups(): Observable<MachineGroup[]> {
        return this.http.get<IMachineGroup[]>(`${environment.apiUrl}/filters/machine_group/`)
            .pipe(map(machineGroups => machineGroups.map(el => new MachineGroup(el))
            ));
    }

    getAll(params: Map<string, string | number | Array<string | number>>): Observable<MachineGroup[]> {
        const path = this.httpService.parseURL(params);
        const url = `${environment.apiUrl}/machine_group/${path}`;
        return this.multiRequestService.getAll<IMachineGroup[]>(url)
            .pipe(map(machineGroups => machineGroups.map(el => new MachineGroup(el))));
    }

    getDetails(id): Observable<MachineGroup> {
        return this.http.get<IMachineGroup>(`${environment.apiUrl}/machine_group/` + id + `/`)
            .pipe(map(el => new MachineGroup(el)));
    }

    updateMachineGroup(machineGroup): Observable<MachineGroup> {
        return this.http.put<IMachineGroup>(`${environment.apiUrl}/machine_group/` + machineGroup.id + `/`, machineGroup)
            .pipe(map(el => new MachineGroup(el)));
    }

    createMachineGroup(machineGroup): Observable<MachineGroup> {
        machineGroup = this.apiTranslator.modelToSnakeCase(machineGroup);
        return this.http.post<IMachineGroup>(`${environment.apiUrl}/machine_group/`, machineGroup)
            .pipe(map(el => new MachineGroup(el)));
    }
}
