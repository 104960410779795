import { Author } from './author';


export interface IGuideOperation {
    id?: number;
    lp?: number;
    machineGroup: number;
    machineGroupName?: string;
    machineGroupCodename?: string;
    name: string;
    realTimeInSeconds?: number;
    declaredTimeInSeconds: number;
    realCost?: string;
    authors?: Author[];
    isQualityControlRequired?: boolean;
    currentState?: string;
    qualityControl?: string;
    qualityControlGrade?: string;
    qualityControlNote?: string;
    guide?: number;
    qualityControlAuthorName?: string;
    qualityControlAuthor?: number;
}

export class GuideOperation implements IGuideOperation {

    constructor(object?: IGuideOperation) {
        if (object) {
            this.id = object.id;
            this.lp = object.lp;
            this.machineGroup = object.machineGroup;
            this.machineGroupName = object.machineGroupName;
            this.machineGroupCodename = object.machineGroupCodename;
            this.name = object.name;
            this.realTimeInSeconds = object.realTimeInSeconds;
            this.declaredTimeInSeconds = object.declaredTimeInSeconds;
            this.realCost = object.realCost;
            this.authors = object.authors?.sort((a1, a2) => a1.start > a2.start ? 1 : -1
            ).map(author => new Author(author));
            this.isQualityControlRequired = (object.isQualityControlRequired !== false);
            this.currentState = object.currentState;
            this.qualityControl = object.qualityControl;
            this.qualityControlGrade = object.qualityControlGrade;
            this.qualityControlNote = object.qualityControlNote;
            this.guide = object.guide;
            this.qualityControlAuthorName = object.qualityControlAuthorName;
            this.qualityControlAuthor = object.qualityControlAuthor;
            GuideOperation.uniqueId += 1;
            this.uniqueId = GuideOperation.uniqueId;
        }
    }
    private static uniqueId = 0;
    id?: number;
    lp?: number;
    machineGroup: number;
    machineGroupName?: string;
    machineGroupCodename?: string;
    name: string;
    realTimeInSeconds?: number;
    declaredTimeInSeconds: number;
    realCost?: string;
    authors?: Author[];
    isQualityControlRequired?: boolean;
    currentState?: string;
    qualityControl?: string;
    qualityControlGrade?: string;
    qualityControlNote?: string;
    guide?: number;
    qualityControlAuthorName?: string;
    qualityControlAuthor?: number;
    uniqueId: number;

    getHours(): number {
        return Math.floor(this.declaredTimeInSeconds / 3600);
    }

    getMinutes(): number {
        return Math.floor((this.declaredTimeInSeconds - 3600 * this.getHours()) / 60);
    }
}
