<div class="modal-header">
	<h2 class="modal-title modal-title-custom" i18n="@@ResetPasswordModalHeader">Reset hasła</h2>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<form *ngIf="resetPasswordForm" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
<div class="modal-body">
	<div class="form-group align-items-center">
		<input type="password" class="form-control" id="password" placeholder="Hasło" formControlName="password">
	</div>
	<div class="form-group align-items-center">
		<input type="password" class="form-control" id="passwordRepeat" placeholder="Powtórz hasło" formControlName="passwordRepeat">
		<div class="text-danger" *ngIf="f.passwordRepeat.hasError('notSame')">Hasła się nie pokrywają</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button class="mat-button-big button-spacer mat-button-cancel" type="reset" (click)="activeModal.close('Close click')">Anuluj</button>
	<button mat-button class="mat-button-big" type="submit">Zmień</button>
</div>

</form>