import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {ContractorStats} from '../_models';
import {HttpService} from './http.service';
import {MultiRequestService} from './multi-request.service';

@Injectable({
    providedIn: 'root'
})
export class ContractorsStatsService {


    constructor(
        private http: HttpClient,
        private httpService: HttpService,
        private multiRequestService: MultiRequestService
    ) { }

    getAll(params: Map<string, string | number | Array<string | number>>): Observable<ContractorStats[]> {
        const path = this.httpService.parseURL(params);
        return this.multiRequestService.getAll<ContractorStats[]>(
            `${environment.apiUrl}/order_statistics/contractor_statistics/${path}`)
            .pipe(map(contractors => {
                return contractors.map(x => new ContractorStats(x));
            }));
    }
}
