import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { UserDetailsComponent, UserEditComponent, UserListComponent } from './user';
import { AuthGuard } from './_helpers';
import { MachineGroupDetailsComponent, MachineGroupEditComponent, MachineGroupListComponent } from './machine-group';
import {
    GroupResolverService,
    MachineGroupResolverService,
    PermissionResolverService, ProfilePermissionResolverService,
    UserResolverService
} from './_resolvers';

import { UserGroupDetailsComponent, UserGroupEditComponent, UserGroupListComponent } from './user-group';
import { ContractorDetailsComponent, ContractorEditComponent, ContractorListComponent } from './contractor';
import { QualityControlDetailsComponent, QualityControlEditComponent, QualityControlListComponent } from './quality-control';
import { OfferDetailsComponent, OfferEditComponent, OfferListComponent, } from './offer';
import { EmployeesWithOperationsComponent } from './employees-with-operations/employees-with-operations.component';
import { GuideListComponent, GuideDetailsComponent, GuideEditComponent } from './guide';
import { ContractorsStatisticsComponent } from './contractors-statistics';
import { OrderStatisticsComponent } from './order-statistics/order-statistics.component';
import { ReportComponent } from './report';
import { IndividualReportComponent } from './individual-report';
import {WzListComponent, WzCreateComponent, WzDetailsComponent} from './wz';
import { OrderDetailsComponent, OrderListComponent } from './order';
import { OrderEditComponent } from './order/edit/order-edit.component';
import { PositionsWithoutGuidesListComponent } from './positions-without-guides';
import {DesktopComponent} from './desktop/desktop.component';
import {OrderTvListComponent} from './order/tv/order-tv-list.component';
import {ScannerConfigComponent} from './scanner-config/scanner-config.component';
import {ActionHistoryComponent} from './action-history/action-history.component';
import {OrderDeadlineComponent} from './order-deadline/order-deadline.component';
import {OrderDeadlineSummaryComponent} from './order-deadline-summary/order-deadline-summary.component';
import {OrderYearlyChartComponent} from './order-yearly-chart/order-yearly-chart.component';
import {OperationStateEditComponent} from './guide/operation-state-edit/operation-state-edit.component';
import {UserFilterResolverService} from './_services/user-filter-resolver.service';
import {OrderScheduleComponent} from './order-schedule/order-schedule.component';

const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},

    {
        path: 'user', component: UserListComponent, canActivate: [AuthGuard],
        resolve: {groups: GroupResolverService, machineGroups: MachineGroupResolverService}
    },

    {
        path: 'desktop', component: DesktopComponent, canActivate: [AuthGuard],
    },

    {
        path: 'ordersTv', component: OrderTvListComponent, canActivate: [AuthGuard],
    },

    {
        path: 'user/create', component: UserEditComponent, canActivate: [AuthGuard],
        resolve: {groups: GroupResolverService, machineGroups: MachineGroupResolverService,
            permissions: ProfilePermissionResolverService}
    },

    {path: 'user/:id', component: UserDetailsComponent, canActivate: [AuthGuard]},

    {
        path: 'user/:id/edit', component: UserEditComponent, canActivate: [AuthGuard],
        resolve: {user: UserResolverService, machineGroups: MachineGroupResolverService, groups: GroupResolverService,
        permissions: ProfilePermissionResolverService}
    },

    {path: 'group', component: UserGroupListComponent, canActivate: [AuthGuard]},
    {
        path: 'group/create',
        component: UserGroupEditComponent,
        canActivate: [AuthGuard],
        resolve: {
            userGroup: GroupResolverService,
            permissions: PermissionResolverService
        }
    },
    {path: 'group/:id', component: UserGroupDetailsComponent, canActivate: [AuthGuard]},
    {
        path: 'group/:id/edit',
        component: UserGroupEditComponent,
        canActivate: [AuthGuard],
        resolve: {
            userGroup: GroupResolverService,
            permissions: PermissionResolverService
        },
    },
    {path: 'quality-control', component: QualityControlListComponent, canActivate: [AuthGuard]},
    {path: 'quality-control/:id', component: QualityControlDetailsComponent, canActivate: [AuthGuard]},
    {path: 'quality-control/:id/edit', component: QualityControlEditComponent, canActivate: [AuthGuard]},
    {path: 'machine-group', component: MachineGroupListComponent, canActivate: [AuthGuard]},

    {
        path: 'machine-group/create', component: MachineGroupEditComponent, canActivate: [AuthGuard],
        resolve: {}
    },

    {path: 'machine-group/:id', component: MachineGroupDetailsComponent, canActivate: [AuthGuard]},

    {path: 'machine-group/:id/edit', component: MachineGroupEditComponent, canActivate: [AuthGuard]},

    {path: 'contractor-statistics', component: ContractorsStatisticsComponent, canActivate: [AuthGuard]},
    {path: 'contractor', component: ContractorListComponent, canActivate: [AuthGuard]},
    {path: 'contractor/create', component: ContractorEditComponent, canActivate: [AuthGuard]},
    {path: 'contractor/:id', component: ContractorDetailsComponent, canActivate: [AuthGuard]},
    {
        path: 'contractor/:id/edit', component: ContractorEditComponent, canActivate: [AuthGuard]
    },
    {path: 'report', component: ReportComponent, canActivate: [AuthGuard]},
    {path: 'individual-report', component: IndividualReportComponent, canActivate: [AuthGuard]},
    {
        path: 'individual-report/:id', component: IndividualReportComponent, canActivate: [AuthGuard],
    },

    {path: 'employees-with-operations', component: EmployeesWithOperationsComponent, canActivate: [AuthGuard]},

    {path: 'offer', component: OfferListComponent, canActivate: [AuthGuard]},
    {
        path: 'offer/create',
        component: OfferEditComponent,
        canActivate: [AuthGuard],
        resolve: {
            permissions: PermissionResolverService,
            profilePermissions: ProfilePermissionResolverService
        },
    },
    {path: 'offer/:id', component: OfferDetailsComponent, canActivate: [AuthGuard]},
    // {path: 'offer/:id/pdf', component: OfferPdfComponent, canActivate: [AuthGuard]},
    {
        path: 'offer/:id/edit',
        component: OfferEditComponent,
        canActivate: [AuthGuard],
        resolve: {
            permissions: PermissionResolverService,
            profilePermissions: ProfilePermissionResolverService
        },
    },

    {path: 'order-statistics', component: OrderStatisticsComponent, canActivate: [AuthGuard]},
    {path: 'order-yearly-chart', component: OrderYearlyChartComponent, canActivate: [AuthGuard]},
    {path: 'scanner-config', component: ScannerConfigComponent, canActivate: [AuthGuard]},
    {path: 'action-history', component: ActionHistoryComponent, canActivate: [AuthGuard]},
    {path: 'order-deadline', component: OrderDeadlineComponent, canActivate: [AuthGuard]},
    {path: 'order-deadline-summary', component: OrderDeadlineSummaryComponent, canActivate: [AuthGuard]},
    {path: 'report', component: ReportComponent, canActivate: [AuthGuard]},
    {path: 'individual-report', component: IndividualReportComponent, canActivate: [AuthGuard]},
    {
        path: 'individual-report/:id', component: IndividualReportComponent, canActivate: [AuthGuard],
    },
    {path: 'order/create', component: OrderEditComponent, canActivate: [AuthGuard]},
    {path: 'order', component: OrderListComponent, canActivate: [AuthGuard]},
    {path: 'order/schedule', component: OrderScheduleComponent, canActivate: [AuthGuard]},
    {path: 'order/:id', component: OrderDetailsComponent, canActivate: [AuthGuard]},
    {path: 'order/:id/edit', component: OrderEditComponent, canActivate: [AuthGuard]},

    {path: 'ordersWithoutGuides', component: PositionsWithoutGuidesListComponent, canActivate: [AuthGuard],
    resolve: {
            permissions: PermissionResolverService,
            profilePermissions: ProfilePermissionResolverService
        },
    },


    {path: 'guide', component: GuideListComponent, canActivate: [AuthGuard]},
    {path: 'wz', component: WzListComponent, canActivate: [AuthGuard]},
    {path: 'wz/create', component: WzCreateComponent, canActivate: [AuthGuard]},
    {path: 'wz/:id/edit', component: WzCreateComponent, canActivate: [AuthGuard]},
    {path: 'wz/:id', component: WzDetailsComponent, canActivate: [AuthGuard]},
    {
        path: 'guide/create', component: GuideEditComponent, canActivate: [AuthGuard],
        resolve: {machineGroups: MachineGroupResolverService,
        }
    },

    {path: 'guide/:id', component: GuideDetailsComponent, canActivate: [AuthGuard]},
    {
        path: 'guide/:id/edit', component: GuideEditComponent, canActivate: [AuthGuard],
        resolve: {machineGroups: MachineGroupResolverService,
        }
    },
    {
        path: 'guide/:id/state-edit', component: OperationStateEditComponent, canActivate: [AuthGuard],
        resolve: {users: UserFilterResolverService, }
    },

    // otherwise redirect to home
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: '**', redirectTo: '/home', pathMatch: 'full'},

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
