import { Injectable } from '@angular/core';
import {BackendErrorResponse} from '../_interfaces/BackendErrorResponse';
import {BackendParamService} from '../_filters/backend-param.service';

@Injectable()
export class PaginationService {

    private page = 1;
    private count = 0;
    private pageSize = 50;

    constructor(private queryParamService: BackendParamService) {
    }

    private getParams(): Map<string, Array<string>>{

        const params = new Map<string, Array<string>>();
        params.set('page', [this.page.toString()]);
        params.set('page_size', [this.pageSize.toString()]);
        return params;

    }

    enablePagination(): void{
        this.refreshPagination(this.queryParamService.getQueryParamMap());
        this.queryParamService.addQueryParams(this.getParams(), true);

        this.queryParamService.subscribe(() => {
            this.refreshPagination(this.queryParamService.getQueryParamMap());
        });
    }

    setPageSize(size: number): void {
        this.pageSize = size;
    }

    refreshPagination(map: Map<string, Array<string>>): void{
        if (map.has('page')) {
            this.page = parseInt(map.get('page')[0], 10);
        }
        if (map.has('page_size')) {
            this.pageSize = parseInt(map.get('page_size')[0], 10);
        }
    }

    pageChanged(page): void {
        this.page = page;
        this.queryParamService.addQueryParams(this.getParams());
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public getPage(): number {
        return this.page;
    }

    public setCount(count: number): void {
        this.count = count;
    }

    getCount(): number {
        return this.count;
    }

    public handleError(error: BackendErrorResponse): boolean {
        if (error.code === 404) {
            this.pageChanged(1);
            return true;
        }
        return false;
    }
}
