import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {IOrderStatistics, OrderStatistics, OrderStatisticsFactory} from '../_models/order-statistics';
import { map } from 'rxjs/operators';
import {HttpService} from './http.service';
import {MultiRequestService} from './multi-request.service';


@Injectable({
    providedIn: 'root'
})
export class OrderStatisticsService {

    constructor(
        private httpService: HttpService,
        private multiRequestService: MultiRequestService,
    ) {
    }

    public getSimpleStatistics(params?: Map<string, string | number | Array<string | number>>): Observable<OrderStatistics>{
        const path = this.httpService.parseURL(params);
        return this.multiRequestService
            .getAll<IOrderStatistics>(`${environment.apiUrl}/order_statistics/simple_order_statistics/${path}`)
            .pipe(map(ret => OrderStatisticsFactory.fromBackend(ret)));
    }
}
