import {DateService} from '../_primitive_services';


export class GuideState {

    start: Date;
    end?: Date;
    author?: number;
    authorName: string;
    state: string;

    constructor(object?: any) {
        if (object) {
            this.start = DateService.stringDateToDate(object.start);
            this.end = DateService.stringDateToDate(object.end);
            this.author = object.author;
            this.authorName = object.authorName;
            this.state = object.state;
        }
    }


}
