import { Injectable } from '@angular/core';
import { GroupService } from '../_services';
import { Resolve } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class GroupResolverService implements Resolve<any> {

    constructor(private groupService: GroupService) {
    }

    resolve(): any {
        return this.groupService.getAll();
    }
}
