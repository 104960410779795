import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { FilterConfigStringInput } from '../../_filters/components/filter-string-input/filter-string-input.component';
import { PaginationService } from '../../_services/pagination.service';
import { ErrorHandlerService, OfferService } from '../../_services';
import { BackendFilterService } from '../../_filters/backend-filter.service';
import { BackendParamService } from '../../_filters/backend-param.service';
import { PaginatedTranslatedResponse } from '../../_interfaces/PaginatedApiResponse';
import { Feature } from '../../_models/feature';

@Component({
    selector: 'app-feature-search',
    templateUrl: './feature-search.component.html',
    styleUrls: ['./feature-search.component.css']
})
export class FeatureSearchComponent implements OnInit {
    features: Feature[] = [];
    featureSubject: Subject<Feature> = new Subject();
    @ViewChild(MatTable) matPositions: MatTable<any>;

    columnsToDisplay = ['actions', 'feature', 'name', 'number'];
    filterConfigs = [
        new FilterConfigStringInput('search', 'Wyszukaj'),
    ];

    constructor(public paginationService: PaginationService,
                public offerService: OfferService,
                private filterService: BackendFilterService,
                public queryParamService: BackendParamService,
                private errorHandler: ErrorHandlerService,
    ) {
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs(this.filterConfigs);
        this.paginationService.setPageSize(10);
        this.paginationService.enablePagination();
        this.subscribeOnChanges();
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        this.offerService.offerAndOrderPosition(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<Feature>) => {
            this.errorHandler.showLoader(false);
            this.features.length = 0;
            this.features.push(...response.results);
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
            console.log(this.features);
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        const m = this.queryParamService.getQueryParamMap();
        m.set('inner', ['false']);
        m.set('page_size', ['5']);
        return m;
    }

    selectOffer(offerAndOrderPosition: Feature): void {
        this.featureSubject.next(offerAndOrderPosition);
    }

}
