import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/_services';
import {BackendErrorResponse} from 'src/app/_interfaces/BackendErrorResponse';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error: BackendErrorResponse = undefined;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.getCurrentAuthentication()) {
            this.router.navigate(['/']);
        }
    }

    // tslint:disable-next-line:typedef
    get f() {
        return this.loginForm.controls;
    }


    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    }

    onSubmit(): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                () => {
                    this.router.navigate([this.returnUrl]);
                },
                (error: BackendErrorResponse) => {
                    this.error = error;
                    this.loading = false;
                });
    }

    public getErrorMessage(): string {
        if (!this.error) {
            return '';
        }
        if (this.error.info && this.error.info.non_field_errors){
            return this.error.info.non_field_errors;
        }

    }
}
