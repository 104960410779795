import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {DateService} from '../_primitive_services';
import {ErrorHandlerService, TranslatorService} from '../_services';
import {Sort} from '@angular/material/sort';
import {Title} from '@angular/platform-browser';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {PaginationService} from '../_services/pagination.service';
import {SortService} from '../_services/sort.service';
import {BackendParamService} from '../_filters/backend-param.service';
import {PaginatedTranslatedResponse} from '../_interfaces/PaginatedApiResponse';
import {ActionHistory} from './action-history';
import {ActionHistoryService} from './action-history.service';
import {BackendPaginationService} from '../_services/backend-pagination.service';
import {QueryParamService} from '../_filters/query-param.service';
import {
    ActionHistoryFilterConfig, ContentTypeFilterConfig,
    EmployeeFilterConfig,
    TrueAndFalseFilterConfig
} from '../_filters/generic-filter/sample-filter-configs';
import {FilterConfigDateRange} from '../_filters/components/filter-date-range/filter-date-range.component';

@Component({
    selector: 'app-action-history',
    templateUrl: './action-history.component.html',
    styleUrls: ['./action-history.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService, SortService,
        {provide: BackendParamService, useClass: QueryParamService},
    ],
})
export class ActionHistoryComponent implements OnInit {

    constructor(
        private actionHistoryService: ActionHistoryService,
        private titleService: Title,
        private filterService: BackendFilterService,
        private errorHandler: ErrorHandlerService,
        public paginationService: PaginationService,
        public sortService: SortService,
        public queryParamService: BackendParamService,
        public translator: TranslatorService,
    ) {
        this.titleService.setTitle('Historia aktywności');

        const allowedContentTypeModels: Set<string> = new Set<string>(
        ['order', 'wz', 'quality-control', 'guide', 'user', 'group', 'machine-group',
                'machine-group', 'offer', 'contractor', '']);

        for (const key of allowedContentTypeModels){
            this.contentTypeLinks.set(key.replace('-', ''), key);
        }

        this.contentTypeLinks.set('employee', 'user');
    }

    actionHistoryList: ActionHistory[] = [];
    columnsToDisplay = ['user', 'action', 'date', 'success', 'object', ];
    @ViewChild(MatTable) matPositions: MatTable<any>;

    dateService = DateService;

    initialSort: Sort = {active: 'time', direction: 'desc'};

    contentTypeLinks: Map<string, string> = new Map<string, string>();

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new EmployeeFilterConfig('employee'),
            new FilterConfigDateRange('time', 'Data'),
            new TrueAndFalseFilterConfig('Sukces', 'Niepowodzenie', 'success',
                'Rezultat'),
            new ActionHistoryFilterConfig('action', 'Operacja'),
        ]);
        this.sortService.enableSorting(this.initialSort);
        this.paginationService.enablePagination();
        this.subscribeOnChanges();

        this.actionHistoryService.getAllContentTypes().subscribe(contentTypes => {
           this.filterService.addFilterConfig(
                new ContentTypeFilterConfig(contentTypes, 'content_type'),
           );
        });
    }
    refreshData(): void {
        this.errorHandler.showLoader(true);
        this.actionHistoryService.getAll(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<ActionHistory>) => {
            this.errorHandler.showLoader(false);
            this.actionHistoryList.length = 0;
            this.actionHistoryList.push(...response.results);
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
        });
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }

    isSuccessIcon(action: ActionHistory): 'done' | 'close' {
        if (action.success) {
            return 'done';
        }
        else {
            return 'close';
        }
    }

    public canDisplayLink(action: ActionHistory): boolean {
        return action.objectId && this.contentTypeLinks.has(action.contentTypeModel);
    }
}
