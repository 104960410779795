import { v4 as uuidv4 } from 'uuid';

export class IWzSetPositions{
    id?: number;
    quantity: number;
    orderSetName: string;
    price: number;
    orderSet: number;
}

export class BackendWzSet{
    quantity: number;
    price: number;
    orderSet: number;
}

export class BackendWzSetNoOrder{
    quantity: number;
    price: number;
    orderSetName: string;
}

export class WzSetPositions {

    id: number;
    quantity: number;
    orderSetName: string;
    price: number;
    orderSet: number | null;
    checked = false;
    readonly clientGeneratedId = uuidv4();

    constructor(object?: IWzSetPositions) {
        if (object){
            this.id = object.id;
            this.quantity = object.quantity;
            this.orderSetName = object.orderSetName;
            this.price = object.price;
            this.orderSet = object.orderSet;
        }
    }

    getValue(): number{
        return this.quantity * this.price;
    }

    getPriceZl(): number {
        return Math.floor(this.price / 100);
    }

    getPriceGr(): number {
        return Math.floor(this.price % 100);
    }

    setPriceZl(valueStr: string): void {
        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldGr = this.getPriceGr();
            this.price = value * 100 + oldGr;
        }
    }

    setPriceGr(valueStr: string): void {

        const value = parseInt(valueStr, 10);
        if (!isNaN(value)) {
            const oldZl = this.getPriceZl();
            this.price = 100 * oldZl + value % 100;
        }
    }


    getName(): string {
        return this.orderSetName;
    }

    isSet(): boolean {
        return true;
    }

    getOrderReferenceNumber(): number {
        return this.orderSet;
    }

    convertToBackendWzSet(): BackendWzSet{
        return {
            orderSet: this.orderSet,
            price: this.price,
            quantity: this.quantity
        };
    }

    convertToBackendWzSetNoOrder(): BackendWzSetNoOrder {
        return {
            price: this.price,
            quantity: this.quantity,
            orderSetName: this.orderSetName,
        };
    }
}
