import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import {IScannerConfig, IWriteScannerConfig, ScannerConfig, ScannerConfigFactory} from '../_models/scanner-config';

@Injectable({
    providedIn: 'root',
})
export class ScannerConfigService {

    constructor(
        private http: HttpClient,
    ) {}

    getAll():
        Observable<ScannerConfig[]> {
            return this.http
                .get<IScannerConfig[]>(`${environment.apiUrl}/scanner_config/`)
                .pipe(map(data => data.map(scannerConfig => ScannerConfigFactory.fromBackend(scannerConfig))));
    }

    updateScannerConfig(scannerConfig: ScannerConfig): Observable<ScannerConfig> {
        const scannerConfigJson: IWriteScannerConfig = ScannerConfigFactory.toBackend(scannerConfig);
        return this.http
            .put<IScannerConfig>(`${environment.apiUrl}/scanner_config/${scannerConfig.id}/`, scannerConfigJson)
            .pipe(map(res => ScannerConfigFactory.fromBackend(res)));
    }
}
