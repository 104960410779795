import { Injectable } from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {WebSocketSubject} from 'rxjs/webSocket';
import {delay, retryWhen, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {webSocket} from 'rxjs/webSocket';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    constructor() {}

    private connections: Map<string, WebSocketSubject<any>> = new Map<string, WebSocketSubject<any>>();
    private RETRY_SECONDS = 10;

    private static apiUrlToWebsocket(apiUrl: string): string {
        return apiUrl.replace(/^http/, 'ws');
    }
    connect(endpoint: string): Observable<any> {
        return of(WebsocketService.apiUrlToWebsocket(`${environment.apiUrl}/${endpoint}`)).pipe(
            switchMap(websocketUrl => {
                if (this.connections.has(endpoint)) {
                    return this.connections.get(endpoint);
                } else {
                    this.connections.set(endpoint, webSocket({
                      url: websocketUrl,
                      deserializer: (e: MessageEvent) => e.data,
                      serializer: (value: any) => value,
                    }));
                    return this.connections.get(endpoint);
                }
            }),
            retryWhen((errors) => errors.pipe(delay(this.RETRY_SECONDS)))
        );
    }

    closeConnection(endpoint: string): void {
        if (this.connections.has(endpoint) && this.connections.get(endpoint)) {
            const connection = this.connections.get(endpoint);
            connection.complete();
            this.connections.delete(endpoint);
        }
    }

    closeAllConnections(): void {
        for (const connection of this.connections.values()){
            connection.complete();
        }
        this.connections.clear();
    }

    send(endpoint: string, data: string): void {
        if (this.connections.has(endpoint) && this.connections.get(endpoint)) {
            this.connections.get(endpoint).next(data);
        }
    }
}
