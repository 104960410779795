import { Component, OnInit } from '@angular/core';
import {ApiTranslatorService, ErrorHandlerService, ModalService} from 'src/app/_services';
import { Title } from '@angular/platform-browser';
import { OrderStatisticsService } from '../_services/order-statistics.service';
import { OrderStatistics } from '../_models/order-statistics';
import {MoneyService} from '../_primitive_services';
import {BackendFilterService} from '../_filters/backend-filter.service';
import {PaginationService} from '../_services/pagination.service';
import {BackendPaginationService} from '../_services/backend-pagination.service';
import {BackendParamService} from '../_filters/backend-param.service';
import {QueryParamService} from '../_filters/query-param.service';
import { ContractorFilterConfig } from '../_filters/generic-filter/sample-filter-configs';
import {FilterConfigYearMonth} from '../_filters/components/filter-year-month/filter-year-month.component';


@Component({
    selector: 'app-order-statistics',
    templateUrl: './order-statistics.component.html',
    styleUrls: ['./order-statistics.component.css'],
    providers: [
         BackendFilterService, PaginationService, BackendPaginationService,
        {provide: BackendParamService, useClass: QueryParamService},
    ]
})
export class OrderStatisticsComponent implements OnInit {

    orderStatistics: OrderStatistics;
    columnsToDisplay = ['contractorName', 'contractorOrderNumber', 'cost'];
    moneyService = MoneyService;

    constructor(private apiTranslator: ApiTranslatorService,
                private orderStatisticsService: OrderStatisticsService,
                private titleService: Title,
                public errorHandlerService: ErrorHandlerService,
                public modalService: ModalService,
                private filterService: BackendFilterService,
                public queryParamService: BackendParamService,
    ) {

        this.titleService.setTitle('Statystyki zamówień');
    }

    ngOnInit(): void {

        this.filterService.setFilterConfigs([
            new ContractorFilterConfig('contractor'),
            new FilterConfigYearMonth('month', 'Miesiąc', 'year'),
        ]);
        this.subscribeOnChanges();
        this.refreshData();
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe(() => this.refreshData());
    }

    refreshData(): void {
        this.errorHandlerService.showLoader(true);
        this.orderStatisticsService.getSimpleStatistics(this.getBackendParamMap()).subscribe(orderStatistics => {
            this.errorHandlerService.showLoader(false);
            this.orderStatistics = orderStatistics;
        });
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }
}
