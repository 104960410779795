<div class="page">
    <div class="content">
		<div class="counters">
			<div class="numberOfOrders">Wszystkich zleceń: <strong>{{ orders.length }}</strong></div>
			<div class="outdatedOrders">Po terminie: <strong>{{ outdatedOrders }}</strong></div>
			<div class="reclamationOrders">Reklamacje: <strong>{{ reclamationOrders }}</strong></div>
			<div class="clock"><strong>{{ time | date: 'HH:mm:ss' }}</strong></div>
		</div>
        <mat-table [dataSource]="orders" *ngIf="orders" class="mat-effects">
            <ng-container matColumnDef="contractorName">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderCreation">Nazwa <br>kontrahenta</mat-header-cell>
                <mat-cell title="{{order.contractorName}}" class="tvContractorName" *matCellDef="let order"> {{order.contractorName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="contractorOrderNumber">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderContractor">Numer zlecenia</mat-header-cell>
                <mat-cell title="{{order.contractorOrderNumber}}" class="tvContractorOrderNumber" *matCellDef="let order">
                    {{order.contractorOrderNumber}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="plannedEndDate">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderCreation" >Planowana data zakończenia</mat-header-cell>
                <mat-cell class="tvPlannedEndDate" *matCellDef="let order"> {{dateService.dateToStringDate(order.plannedEndDate)}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="progress">
                <mat-header-cell *matHeaderCellDef i18n="@@OrderCreation">Postęp</mat-header-cell>
                <mat-cell class="tvProgress" *matCellDef="let order"> {{order.progress}}/{{order.progressLimit}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;" [class]="getRowType(row)"></mat-row>
        </mat-table>
    </div>
</div>
