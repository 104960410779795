<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <span class="spacer"></span>
        </mat-toolbar>
        <div id="chartContainer"></div>
    </div>
</div>
