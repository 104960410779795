import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BackendFilterService } from '../../_filters/backend-filter.service';
import {
    ContractorFilterConfig,
    TrueAndFalseFilterConfig
} from '../../_filters/generic-filter/sample-filter-configs';
import { ErrorHandlerService, PermissionService } from '../../_services';
import { PaginationService } from '../../_services/pagination.service';
import { SortService } from '../../_services/sort.service';
import { Sort } from '@angular/material/sort';
import { PositionsWithoutGuides } from '../../_models/positions-without-guides';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PaginatedTranslatedResponse } from '../../_interfaces/PaginatedApiResponse';
import { BackendPaginationService } from '../../_services/backend-pagination.service';
import { Order } from '../../_models/order';
import { QueryParamService } from '../../_filters/query-param.service';
import {MultiSelectionService} from '../../_services/multi-selection.service';
import {BackendParamService} from '../../_filters/backend-param.service';
import {FilterConfigStringInput} from '../../_filters/components/filter-string-input/filter-string-input.component';
import {PositionsWithoutGuidesService} from '../../_services/positions-without-guides.service';

@Component({
    selector: 'app-orders-without-guides',
    templateUrl: './positions-without-guides-list.component.html',
    styleUrls: ['./positions-without-guides-list.component.css'],
    providers: [
        BackendFilterService, PaginationService, BackendPaginationService, SortService, MultiSelectionService,
        { provide: BackendParamService, useClass: QueryParamService },
        PositionsWithoutGuidesService
    ]
})
export class PositionsWithoutGuidesListComponent implements OnInit {
    positionsWithoutGuides: PositionsWithoutGuides[] = [];
    orders: Order[] = [];
    initialSort: Sort = {active: 'feature', direction: 'desc'};
    columnsToDisplay = ['feature', 'contractorOrderNumber', 'contractorName', 'guide'];
    dataSource: MatTableDataSource<PositionsWithoutGuides>;
    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(private titleService: Title,
                public positions: PositionsWithoutGuidesService,
                private filterService: BackendFilterService,
                public permissionService: PermissionService,
                private errorHandler: ErrorHandlerService,
                public paginationService: PaginationService,
                public sortService: SortService,
                private queryParamService: BackendParamService,
    ) {
        this.titleService.setTitle('Zamówienia bez przewodników');
    }

    ngOnInit(): void {
        this.filterService.setFilterConfigs([
            new FilterConfigStringInput('contractor_order_number', 'Numer zamówienia'),
            new FilterConfigStringInput('feature', 'Cecha'),
            new ContractorFilterConfig('contractor'),
            new TrueAndFalseFilterConfig('Bez przewodnika', 'Kontrola nie ok',
                'guide_null', 'Przewodnik'),
        ]);
        this.sortService.enableSorting(this.initialSort);
        this.subscribeOnChanges();
        this.refreshData();
        console.log(this.positions);
        console.log(this.positionsWithoutGuides);
    }

    refreshData(): void {
        this.errorHandler.showLoader(true);
        const previouslyCheckedIds: Set<number> = new Set(this.positionsWithoutGuides.filter(position => position.checked)
            .map(position => position.id));
        this.positions.getAll(this.getBackendParamMap()
        ).subscribe((response: PaginatedTranslatedResponse<PositionsWithoutGuides>) => {
            this.errorHandler.showLoader(false);
            this.positionsWithoutGuides.length = 0;
            this.positionsWithoutGuides.push(...response.results);
            for (const position of this.positionsWithoutGuides) {
                position.checked = previouslyCheckedIds.has(position.id);
            }
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
        });
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe( () => this.refreshData());
    }

}
