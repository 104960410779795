import {Component, OnInit, ViewChild} from '@angular/core';
import {
    ApiTranslatorService, ErrorHandlerService,
    ModalService, PermissionService,
    QualityControlService, TranslatorService
} from 'src/app/_services';
import {QualityControl} from 'src/app/_models';
import { Title } from '@angular/platform-browser';
import {MatTable} from '@angular/material/table';
import {PaginationService} from '../../_services/pagination.service';
import {BackendPaginationService} from '../../_services/backend-pagination.service';
import {MultiRequestService} from '../../_services/multi-request.service';
import {SortService} from '../../_services/sort.service';
import {
    EmployeeFilterConfig, FromTrashFilterConfig,
    QualityControlFilterConfig, StateFilterConfig, TrueOrEmptyFilterConfig
} from '../../_filters/generic-filter/sample-filter-configs';
import {MultiSelectionService} from '../../_services/multi-selection.service';
import {BackendFilterService} from '../../_filters/backend-filter.service';
import {Sort} from '@angular/material/sort';
import {QueryParamService} from '../../_filters/query-param.service';
import {AlertService, DateService} from '../../_primitive_services';
import {BackendParamService} from '../../_filters/backend-param.service';
import {FilterConfigStringInput} from '../../_filters/components/filter-string-input/filter-string-input.component';

@Component({
    selector: 'app-quality-control-list',
    templateUrl: './quality-control-list.component.html',
    styleUrls: ['./quality-control-list.component.css'],
    providers: [

        BackendFilterService, PaginationService, BackendPaginationService, SortService, MultiSelectionService,
        { provide: BackendParamService, useClass: QueryParamService },
        QualityControlService, MultiRequestService,
    ]
})
export class QualityControlListComponent implements OnInit {

    initialSort: Sort = {active: 'guide_number', direction: 'desc'};
    // sort = ['operation__guide__year', 'operation__guide__lp', 'id', 'grade'];
    qualityControls: QualityControl[] = [];
    columnsToDisplay = ['guide_number', 'operationTime', 'operation_name', 'grade', 'select', 'actions'];

    dateService = DateService;
    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(private qualityControlService: QualityControlService,
                private apiTranslator: ApiTranslatorService,
                private titleService: Title,
                private filterService: BackendFilterService,
                private alertService: AlertService,
                private errorHandler: ErrorHandlerService,
                public modalService: ModalService,
                public permissionService: PermissionService,
                public paginationService: PaginationService,
                public sortService: SortService,
                public multiSelectionService: MultiSelectionService,
                public queryParamService: BackendParamService,
                public translator: TranslatorService,
    ) {
        this.titleService.setTitle('Kontrola Jakości');
    }

    ngOnInit(): void {
        this.paginationService.enablePagination();
        this.filterService.setFilterConfigs([
            new TrueOrEmptyFilterConfig('Oczekujące na kontrolę',
                'waiting_for_quality_control', 'Oczekujące', ),
            new TrueOrEmptyFilterConfig('Ocena możliwa do zmiany',
                'available_for_grade_change', 'Zmiana oceny', ),
            new EmployeeFilterConfig('author', 'Autor kontroli'),
            new FilterConfigStringInput('guide_number', 'Numer przewodnika'),
            new StateFilterConfig('operation_state'),
            new QualityControlFilterConfig('grade'),
            new FromTrashFilterConfig(),
        ]);
        this.sortService.enableSorting(this.initialSort);
        this.subscribeOnChanges();
        this.multiSelectionService.populateWithData(this.qualityControls);
    }

    private subscribeOnChanges(): void {
        this.queryParamService.subscribe( () => this.refreshData());
    }

    private getBackendParamMap(): Map<string, string | number | string[] | number[]> {
        return this.queryParamService.getQueryParamMap();
    }

    refreshData(): void {

        this.errorHandler.showLoader(true);
        const previouslyCheckedIds: Set<number> = new Set(this.qualityControls.filter(
            qualityControl => qualityControl.checked).map(
                qualityControl => qualityControl.id));
        this.qualityControlService.getAll(this.getBackendParamMap()
        ).subscribe(response => {
            this.errorHandler.showLoader(false);
            this.qualityControls.length = 0;
            this.qualityControls.push(...response.results);
            for (const qualityControl of this.qualityControls) {
                qualityControl.checked = previouslyCheckedIds.has(qualityControl.id);
            }
            this.paginationService.setCount(response.count);
            this.matPositions.renderRows();
        });
    }

    select(event, qualityControl: QualityControl): void {
        qualityControl.checked = !!event.target.checked;
    }

    setOK(): void {
        this.updateGrade('ok');
    }

    setNotOK(): void {
        this.updateGrade('not_ok');
    }

    private updateGrade(grade: string): void{
        const selectedQualityControls = this.qualityControls.filter(qualityControl => qualityControl.checked);
        if (selectedQualityControls.length === 0) {
            return;
        }
        for (const element of selectedQualityControls) {
            element.grade = grade;
        }
        this.updateSelected();
    }

    updateSelected(): void {
        this.qualityControlService.batchUpdate(this.qualityControls.filter(qualityControl => qualityControl.checked)
        ).subscribe(
            _ => {
                this.alertService.success('Oznaczono pomyślnie');
                this.refreshData();
            }, error => {
                this.alertService.error(' Błąd: ', this.errorHandler.error(error));
            }
        );
    }
}
