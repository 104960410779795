<div class="page">
    <div class="sidebar">
        <app-filter></app-filter>
    </div>
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom" *ngIf="permissionService.checkPermission('perm_write_order_schedule')">
            <h1 class="page-title" i18n="@@ScheduleFor" *ngIf="schedule">Harmonogram na {{translatorService.translateMonth(schedule.month)}} {{schedule.year}}</h1>
            <h1 class="page-title" i18n="@@MissingSchedule" *ngIf="!schedule && isDownloaded">Brak Harmonogramu</h1>
            <span class="spacer"></span>
            <a class="button-spacer mat-button-custom" mat-button target="_blank" (click)="openGenerateSchedule()">
                Generuj harmonogram
            </a>
            <span class="line" *ngIf="schedule">|</span>
            <a class="button-spacer mat-button-custom" *ngIf="schedule" mat-button target="_blank" (click)="getPDF()">
                Generuj pdf
            </a>
        </mat-toolbar>

        <mat-table [dataSource]="filteredSchedule" class="mat-effects">

            <ng-container matColumnDef="planedEndDate">
                <mat-header-cell *matHeaderCellDef i18n="@@Reportname">Planowana data zakończenia</mat-header-cell>
                <mat-cell *matCellDef="let set"> {{getDate(set.plannedEndDate)}} </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="guideNumber">
                <mat-header-cell *matHeaderCellDef i18n="@@ReportworkTime">Nr pierwszego przewodnika</mat-header-cell>
                <mat-cell *matCellDef="let set"><a routerLink="/guide/{{set.firstGuide}}"> {{set.firstGuideNumber}}</a></mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="numberOfGuides">
                <mat-header-cell *matHeaderCellDef i18n="@@ReportguideWorkTime">Ilość przewodników w zleceniu</mat-header-cell>
                <mat-cell *matCellDef="let set"> {{set.guideCount}} </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="feature">
                <mat-header-cell *matHeaderCellDef i18n="@@ReportguideWorkTime">Cecha pierwszej pozycji</mat-header-cell>
                <mat-cell *matCellDef="let set"> {{set.firstFeature}} </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="contractor">
                <mat-header-cell *matHeaderCellDef i18n="@@ReportguideWorkTime">Kontrahent </mat-header-cell>
                <mat-cell *matCellDef="let set"><a routerLink="/contractor/{{set.contractor}}">{{set.contractorName}}</a></mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="orderNumber">
                <mat-header-cell *matHeaderCellDef i18n="@@ReportguideWorkTime">Nr zlecenia</mat-header-cell>
                <mat-cell *matCellDef="let set"><a routerLink="/order/{{set.order}}">{{set.contractorOrderNumber}}</a> </mat-cell>
                <mat-footer-cell *matFooterCellDef><strong>Suma:</strong></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="price">
                <mat-header-cell *matHeaderCellDef i18n="@@ReportguideWorkTime">Łączna kwota z zamówienia</mat-header-cell>
                <mat-cell *matCellDef="let set"> {{parseMoney(set.cost)}} </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{cashedCost}}</mat-footer-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
            <mat-footer-row *matFooterRowDef="columnsToDisplay"></mat-footer-row>
        </mat-table>
    </div>
</div>
<ng-template #scheduleGenerate>
    <app-schedule-generate [subject]="subject"></app-schedule-generate>
</ng-template>
