import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {IBackendSchedule, Schedule} from '../_models/schedule';
import {environment} from '../../environments/environment';
import {map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsScheduleService {

  constructor(
      private http: HttpClient,
      private httpService: HttpService,
      ) {

  }

  getSchedule(): Observable<Schedule | null>{
      return this.http.get<IBackendSchedule>(`${environment.apiUrl}/order_statistics/schedule/`)
      .pipe(map(x => new Schedule(x)))
      .pipe(catchError((err) => {
                return of(null);
            }));
  }

  generateSchedule(year: number, month: number): Observable<{month: number, year: number}> {
      return this.http.post<{month: number, year: number}>(`${environment.apiUrl}/order_statistics/schedule/`, {month, year});
  }
}
