<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">{{title}}</h1>
            <span class="spacer"></span>
        </mat-toolbar>
        <mat-table [dataSource]="states"
                   *ngIf="states" class="mat-effects">
            <ng-container matColumnDef="operation_lp">
                <mat-header-cell *matHeaderCellDef>Lp
                </mat-header-cell>
                <mat-cell *matCellDef="let state"> {{state.operationLp}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation_name">
                <mat-header-cell *matHeaderCellDef>Treść operacji
                </mat-header-cell>
                <mat-cell *matCellDef="let state"> {{state.operationName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="start">
                <mat-header-cell *matHeaderCellDef>Rozpoczęcie
                </mat-header-cell>
                <mat-cell *matCellDef="let state">
                    <input matInput type="datetime-local" placeholder="start date"
                           [ngModel]="state.getStartLocalCompleteDate()"
                           (change)="onStartChange(state, $event)"
                    >
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="end">
                <mat-header-cell *matHeaderCellDef>Zakończenie
                </mat-header-cell>
                <mat-cell *matCellDef="let state">
                    <input matInput type="datetime-local" placeholder="end date"
                           [ngModel]="state.getEndLocalCompleteDate()"
                           (change)="onEndChange(state, $event)"
                    >
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="real_time_in_seconds">
                <mat-header-cell *matHeaderCellDef>Sumaryczny czas
                </mat-header-cell>
                <mat-cell *matCellDef="let state"> {{dateService.getTimeFromSec(state.realTimeInSeconds)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="author">
                <mat-header-cell *matHeaderCellDef>Pracownik
                </mat-header-cell>
                <mat-cell *matCellDef="let state" class="author-select">

                    <ng-select
                        [items]="userChoices"
                        [multiple]="false"
                        [closeOnSelect]="true"
                        [hideSelected]="false"
                        bindLabel="fullName"
                        placeholder="Autor"
                        bindValue="authUserId"
                        [(ngModel)]="state.author"
                        (change)="changeAuthor(state, $event)"
                    >	<ng-template ng-label-tmp let-item="item">
                        {{item.fullName}}
                    </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            {{item.fullName}}
                        </ng-template>
                    </ng-select>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef>Stan
                </mat-header-cell>
                <mat-cell *matCellDef="let state"> {{translatorService.translate(state.state)}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        </mat-table>
    </div>
</div>
