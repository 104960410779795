<div class="page">
    <div class="content">
        <mat-toolbar class="mat-toolbar-custom">
            <h1 class="page-title">Przewodnik - szczegóły</h1>
            <span class="spacer"></span>
            <div class="actions" *ngIf="guide">
                <button class="button-spacer mat-button-custom" mat-button (click)="performMultiAction('launch')"
                        *ngIf="permissionService.checkPermission('perm_write_guide_start_finish')">Uruchomienie
                    przewodnika
                </button>
                <span class="line">|</span>
                <button class="button-spacer mat-button-custom" mat-button (click)="performMultiAction('guide_finish')"
                        *ngIf="permissionService.checkPermission('perm_write_guide_start_finish')">Zakończenie
                    przewodnika
                </button>
                <span class="line">|</span>
                <button class="button-spacer mat-button-custom" mat-button (click)="performMultiAction('pause')"
                        *ngIf="permissionService.checkPermission('perm_write_guide_pause_operation')">Zapauzowanie
                    przewodnika
                </button>
                <span class="line">|</span>
                <button class="button-spacer mat-button-custom" mat-button (click)="performMultiAction('start')"
                        *ngIf="permissionService.checkPermission('perm_write_guide_start_finish_operation')">
                    Uruchomienie
                    kolejnej
                    operacji
                </button>
                <span class="line">|</span>
                <button class="button-spacer mat-button-custom" mat-button (click)="performMultiAction('finish')"
                        *ngIf="permissionService.checkPermission('perm_write_guide_start_finish_operation')">Zakończenie
                    kolejnej
                    operacji
                </button>
                <span class="line">|</span>

                <button class="button-spacer mat-button-custom" mat-button (click)="getPDF()">
                    Wyświetl pdf
                </button>
                <span class="line">|</span>
                <button
                    *ngIf="permissionService.checkPermission('perm_write_guide') && canCreateNewGuide"
                    class="button-spacer mat-button-custom" mat-button
                        routerLink="/guide/create/" [queryParams]="{order_position_id: guide.orderPosition}">
                        Wystaw nowy przewodnik
                </button>
                <span class="line" *ngIf="permissionService.checkPermission('perm_write_guide')
                && canCreateNewGuide">|</span>
                <button
                    *ngIf="permissionService.checkPermission('perm_write_guide')"
                    class="button-spacer mat-button-custom" i18n-mat-button="@@Edit" mat-button
                        routerLink="/guide/{{guide.id}}/edit">Edytuj
                </button>
                <span class="line">|</span>
                <button *ngIf="permissionService.checkPermission('perm_write_guide_state')"
                        class="button-spacer mat-button-custom" mat-button
                        routerLink="/guide/{{guide.id}}/state-edit">Edycja czasu
                </button>
                <span *ngIf="permissionService.checkPermission('perm_write_guide_state')" class="line">|</span>
                <button class="button-spacer mat-button-custom" mat-button
                        (click)="modalService.deleteModalForm('/guide', guide)"
                        i18n="@@Remove">Usuń
                </button>
                <span class="line">|</span>
                <app-back-button></app-back-button>
            </div>
        </mat-toolbar>
        <mat-card class="mat-effects" *ngIf="guide">
            <mat-card-content>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Numer</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ guide.number }}
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Data utworzenia</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ dateService.dateToStringDate(guide.creationDate) }}
                    </div>
                </div>
                <hr>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Nazwa detalu</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ guide.name }}
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Kod kreskowy</div>
                    <div class="col-sm-10 dark-color" [innerHTML]="barcode">
                        {{ barcode }}
                    </div>
                </div>
                <hr>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Zamówienie</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        <a href="/order/{{guide.order}}">{{ guide.contractorOrderNumber }}</a>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Cecha</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ guide.feature }}
                    </div>
                </div>
                <hr>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Kontrahent</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        <a routerLink="/contractor/{{guide.contractor}}">{{guide.contractorName}}
                        </a>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Czas wykonania</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ timeService.parseTime(guide.realTimeInSeconds) }}
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-sm-2 col-form-label">Koszt rzeczywisty</div>
                    <div class="col-sm-10 font-weight-bold dark-color">
                        {{ moneyService.parseMoneyWithUnits(guide.realCost, 'zł') }}
                    </div>
                </div>
                <hr>
                <br>
                <mat-table [dataSource]="operations" class="mat-effects">
                    <ng-container matColumnDef="lp">
                        <mat-header-cell *matHeaderCellDef class="mat-lp" i18n="@@Feature">Lp.</mat-header-cell>
                        <mat-cell *matCellDef="let operations" class="mat-lp"> {{operations.lp}}.</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef i18n="@@Name">Treść operacji</mat-header-cell>
                        <mat-cell *matCellDef="let operations"> {{operations.name}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="time">
                        <mat-header-cell *matHeaderCellDef>Czas dekl./rzecz.</mat-header-cell>
                        <mat-cell
                                *matCellDef="let operations">
                            {{timeService.parseTime(operations.declaredTimeInSeconds)}}/
                            {{timeService.parseTime(operations.realTimeInSeconds)}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="realTime">
                        <mat-header-cell *matHeaderCellDef>Koszt rzeczywisty</mat-header-cell>
                        <mat-cell *matCellDef="let operations">
                            {{moneyService.parseMoneyWithUnits(operations.realCost, 'zł')}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="machineGroup">
                        <mat-header-cell *matHeaderCellDef>Grupa maszyn</mat-header-cell>
                        <mat-cell *matCellDef="let operations">
                            {{operations.machineGroupCodename}} {{operations.machineGroupName}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="state">
                        <mat-header-cell *matHeaderCellDef i18n="@@State">Stan</mat-header-cell>
                        <mat-cell *matCellDef="let operations"> {{translate(operations.currentState)}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="authors">
                        <mat-header-cell *matHeaderCellDef i18n="@@State">Autorzy</mat-header-cell>
                        <mat-cell *matCellDef="let operations" [matTooltip]="getAuthors(operations.authors)"
                                  matTooltipClass="my-tooltip">
                            {{getFirstAuthor(operations.authors)}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="qualityControl">
                        <mat-header-cell *matHeaderCellDef i18n="@@QualityControl">Kontrola jakości</mat-header-cell>
                        <mat-cell *matCellDef="let operations"
                                  [matTooltip]="createQualityControl(operations.qualityControlAuthorName, operations.qualityControlNote)"
                                  [matTooltipClass]="'my-tooltip'">
                            <a routerLink="/quality-control/{{operations.qualityControl}}">{{translate(operations.qualityControlGrade)}}</a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="columnsToDisplayPositions"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: columnsToDisplayPositions;" [style]="'background-color: ' + getColor(row)"></mat-row>
                </mat-table>
            </mat-card-content>
        </mat-card>
    </div>
</div>
