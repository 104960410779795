import { Group, MachineGroup, Permission, RFID } from './';


export class IBackendUser {
    id: number;
    authUserId: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    passwordRepeat?: string;
    groups?: Group[];
    groupIds?: Array<number>;
    machineGroups?: MachineGroup[];
    machineGroupIds?: Array<number>;
    isSuperuser: boolean;
    permissions: Permission[];
    rfids?: RFID[];
    rfidIds?: Array<number>;
    fullName: string;
    includeInAttendanceList: boolean;
    allowMultipleMachineGroups: boolean;
}

export class User implements IBackendUser{
    static modelName = 'Użytkownik';
    static path = 'employee';

    id: number;
    authUserId: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    groups?: Group[];
    groupIds?: Array<number>;
    machineGroups?: MachineGroup[];
    machineGroupIds?: Array<number>;
    isSuperuser: boolean;
    permissions: Permission[];
    rfids?: RFID[];
    rfidIds?: Array<number>;
    fullName: string;
    includeInAttendanceList: boolean;
    allowMultipleMachineGroups: boolean;

    constructor(object?: IBackendUser) {
        if (object) {
            this.id = object.id;
            this.authUserId = object.authUserId;
            this.username = object.username;
            this.firstName = object.firstName;
            this.lastName = object.lastName;
            this.email = object.email;
            this.groups = object.groups;
            this.groupIds = [];
            this.machineGroups = object.machineGroups;
            this.machineGroupIds = [];
            this.isSuperuser = object.isSuperuser;
            this.permissions = object.permissions;
            this.rfids = object.rfids;
            this.rfidIds = [];
            this.fullName = this.getName();
            this.includeInAttendanceList = object.includeInAttendanceList;
            this.allowMultipleMachineGroups = object.allowMultipleMachineGroups;
        }
    }

    getModelName(): string {
        return User.modelName;
    }

    getPath(): string {
        return User.path;
    }

    getName(): string {
        let name;
        if (this.firstName && this.lastName) {
            name = this.firstName + ' ' + this.lastName;
        } else {
            name = this.username;
        }
        return name;
    }

}
