import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {RFIDDetail} from '../_models';

@Injectable({providedIn: 'root'})
export class RFIDService {
    constructor(private http: HttpClient) { }


    get(rfid: string): Observable<RFIDDetail> {
        return this.http.get<RFIDDetail>(`${environment.apiUrl}/rfid/` + rfid + `/`).pipe(map(x => new RFIDDetail(x)));
    }

    create(rfid: string): Observable<RFIDDetail> {
        return this.http.post<RFIDDetail>(`${environment.apiUrl}/rfid/`, {rfid});
    }
}
