import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {
    Guide,
    GuideAction,
    GuideFactory,
    GuideInList,
    IGuide,
    IGuideInFilter,
    WriteGuideAction
} from 'src/app/_models';
import { PaginatedTranslatedResponse } from '../_interfaces/PaginatedApiResponse';
import { BackendPaginationService } from './backend-pagination.service';
import {WebsocketConfig} from '../_models/websocket-config';

@Injectable({
    providedIn: 'root',
})
export class GuidesService {

    constructor(private http: HttpClient,
                private backendPaginationService: BackendPaginationService,
    ) {
    }

    getAll(params?: Map<string, string | number | Array<string | number>>, options?: {headers?: HttpHeaders}):
        Observable<PaginatedTranslatedResponse<GuideInList>> {
        return this.backendPaginationService.getAll<GuideInList>(
                'guide',
                data => new GuideInList(data),
                params,
                options,
            );
    }

    getAllDetails(params?: Map<string, string | number | Array<string | number>>, options?: {headers?: HttpHeaders}):
        Observable<PaginatedTranslatedResponse<Guide>> {
        params.set('details', ['true']);
        return this.backendPaginationService.getAll<Guide>(
                'guide',
                data => GuideFactory.fromBackend(data),
                params,
                options,
            );
    }

    getAllFromFilter(params?: Map<string, string | number | Array<string | number>>):
        Observable<PaginatedTranslatedResponse<IGuideInFilter>> {
        return this.backendPaginationService.getAll<IGuideInFilter>(
                'filters/guide',
                data => data,
                params,
            );
    }


    getGuide(id): Observable<Guide> {
        return this.http.get<IGuide>(`${environment.apiUrl}/guide/${id}`).pipe(map(guide => {
            return GuideFactory.fromBackend(guide);
        }));

    }

    getGuideByBarcode(barcode: string): Observable<Guide> {
        return this.http.get<IGuide>(`${environment.apiUrl}/guide_action/guide_by_barcode/${barcode}`).pipe(
            map(guide => {return GuideFactory.fromBackend(guide);
        }));
    }
    getGuideActions(id: number, rfid: string): Observable<GuideAction[]> {
        return this.http.get<GuideAction[]>(`${environment.apiUrl}/guide_action/guide_action/${id}`, {
            headers: new HttpHeaders({
                Authorization: `RFID ${rfid}`
            })
        }).pipe(
            map(guideActions => guideActions.map(guideAction => new GuideAction(guideAction))
        ));
    }

    getGuidesActions(ids: number[], rfid: string): Observable<GuideAction[]> {
        const params = new HttpParams();
        console.log('ids',  ids.map(el => el.toString())
            .reduce((prev, curr) => prev.toString() + curr.toString()));
        const idsStr = ids.map(el => el.toString())
            .reduce((prev, curr) => prev.toString() + ',' + curr.toString());
        return this.http.get<GuideAction[]>(`${environment.apiUrl}/guide_action/guide_action/?id=${idsStr}`, {
            params,
            headers: new HttpHeaders({
                Authorization: `RFID ${rfid}`
            })
        }).pipe(
            map(guideActions => guideActions.map(guideAction => new GuideAction(guideAction))
        ));
    }

    performAction(action: GuideAction, guide: Guide, guides: Guide[], rfid: string): Observable<{result: string}> {
        return this.http.post<{result: string}>(
            `${environment.apiUrl}/guide_action/guide_action/${guide.id}/perform_action`, new WriteGuideAction(
                action.action,
                guides.map(el => el.id),
            ), {
                headers: new HttpHeaders({
                    Authorization: `RFID ${rfid}`
                })
        });
    }

    getWebsocketConfiguration(): Observable<WebsocketConfig> {
        return this.http.get<WebsocketConfig>(
            `${environment.apiUrl}/guide_action/guide_action/websocket_configuration`,
        ).pipe(map(config => new WebsocketConfig(config.bio, config.rfid)));
    }

    getBarcode(id): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Accept', 'image/svg+xml');
        return this.http.get(`${environment.apiUrl}/guide/${id}/barcode`, {headers, responseType: 'text'}).pipe(map(barcode => {
            return barcode;
        }));
    }

    updateGuide(guide: Guide): Observable<Guide> {
        console.log(guide);
        return this.http.put<IGuide>(`${environment.apiUrl}/guide/` + guide.id + `/`,
            GuideFactory.toBackend(guide)).pipe(map(ret => GuideFactory.fromBackend(ret)));
    }

    createGuide(guide: Guide): Observable<Guide> {
        console.log(guide);
        return this.http.post<IGuide>(`${environment.apiUrl}/guide/`, GuideFactory.toBackend(guide))
            .pipe(map(ret => GuideFactory.fromBackend(ret)));
    }


    getQualityControlGrade(guide: GuideInList): string {
        return guide.currentOperationQualityControlGrade;
    }

    getColor(guide: GuideInList): string {
        if (guide.currentState) {
            if (guide.currentState === 'guide_new') {
                return 'guide_new';
            } else if (guide.currentState === 'production') {
                return 'production';
            } else if (guide.currentState === 'pause'
                && new Date(guide.currentStateStart.getTime() + 3 * 24 * 60 * 60 * 1000) < new Date()) {
                return 'long_pause';
            } else if (guide.currentState === 'pause') {
                return 'pause';
            } else if (guide.currentState === 'quality_control'
                && ['repeat', 'not_ok', 'not_ok_material'].includes(this.getQualityControlGrade(guide))) {
                return 'denied';
            } else if (guide.currentState === 'quality_control'
                && this.getQualityControlGrade(guide) === 'not_yet_reviewed') {
                return 'waiting';
            } else if (guide.currentState === 'guide_finished') {
                return 'finished';
            } else {
                return 'unknown';
            }
        }
    }

    multiAction(guideIds: number[], action: string): Observable<MultiActionResponse[]> {
        const url = `${environment.apiUrl}/guide_action/guide_action/perform_multi_action/`;
        const body: MultiActionRequest = {action, ids: guideIds};
        return this.http.post<MultiActionResponse[]>(url, body);
    }

    multiActionRFID(guideIds: number[], action: string, rfid: string): Observable<MultiActionResponse[]> {
        const url = `${environment.apiUrl}/guide_action/guide_action/perform_multi_action/`;
        const body: MultiActionRequest = {action, ids: guideIds};
        return this.http.post<MultiActionResponse[]>(url, body, {
                headers: new HttpHeaders({
                    Authorization: `RFID ${rfid}`
                })
        });
    }

    sendRFIDScan(rfidScannerId: string): Observable<any> {
        const url = `${environment.apiUrl}/scanner/rfid/desktop1/${rfidScannerId}`;
        console.log(url);
        return this.http.post<any>(url, {});
    }
}

export interface MultiActionResponse {
    code: number;
    description?: string;
}

interface MultiActionRequest {
    ids: number [];
    action: string;
}
